import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(3, 2.25, 2.25),
      height: "100%",
      overflow: "auto",
      width: "100%",
      margin: 0,
      [theme.breakpoints.down("xs")]: {
        height: "100vh",
        overflow: "visible",
      },
    },
    headingBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    title: {
      fontWeight: 500,
      marginBottom: theme.spacing(2),
      flexShrink: 0,
    },
    gridItem: {
      height: "50%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      margin: 0,
      [theme.breakpoints.down("xs")]: {
        height: "100%",
      },
    },
  }),
  {
    name: "DashboardPage",
  }
)

export default styles
