import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    inputBox: {
      marginBottom: theme.spacing(2.5),
    },
    inputLabel: {
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.main,
    },
  }),
  {
    name: "BoardDetailsQuestions",
  }
)

export default styles
