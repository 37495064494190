import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    tabs: {
      marginBottom: theme.spacing(1),
    },
    tab: {
      color: "#190D27",
      opacity: 0.3,
      fontWeight: 400,
      minWidth: "initial",
      padding: "6px 12px",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        opacity: 1,
        fontWeight: 700,
      },
    },
    tabWrapper: {
      justifyContent: "center",
    },
    boardName: {
      marginBottom: theme.spacing(2),
      flexShrink: 0,
    },
    error: {
      color: "#F84738",
    },
  }),
  {
    name: "BoardView",
  }
)

export default styles
