import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      position: "relative",
      minHeight: 0,
      [theme.breakpoints.down("xs")]: {
        overflow: "auto",
      },
    },
    entityRoot: {
      height: "100%",
      position: "relative",
      minHeight: 0,
    },
    gridItem: {
      height: "100%",
    },
    entityGridItem: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
      },
    },
    scrollBox: {
      overflow: "auto",
      height: "100%",
    },
    frozenBox: {
      height: "100%",
    },
  }),
  {
    name: "Layouts-FiftyFifty",
  }
)

export default styles
