import { Container, Paper } from "@material-ui/core"
import BoardView from "components/Clerk/Boards/BoardView"
import { BoardContainer } from "contexts/Clerk/Board"
import AddApplicantDialog from "dialogs/AddApplicant"
import EditBoardMemberDialog from "dialogs/EditBoardMember"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useRouteMatch } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const Board = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const match = useRouteMatch("/boards/:board_id")
  return (
    <Paper square className={classes.root}>
      <Container className={classes.container}>
        <Page>
          <BoardContainer boardId={match?.params?.board_id}>
            <BoardView>
              <Router routes={routes} {...rest} />
              {/* dialog for Edit Board Member*/}
              <EditBoardMemberDialog />
              <AddApplicantDialog />
            </BoardView>
          </BoardContainer>
        </Page>
      </Container>
    </Paper>
  )
}

Board.propTypes = {
  routes: PropTypes.array,
}

export default Board
