import {
  Box,
  Icon,
  InputAdornment,
  List,
  TextField,
  Typography,
} from "@material-ui/core"
import search from "assets/icon-feather-search.svg"
import AppContext from "contexts/App"
import MessagesContext from "contexts/Messages"
import { orderBy } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useRouteMatch } from "react-router"

import MessageThread from "../MessageThread"
import useStyles from "./styles"

const ThreadList = () => {
  const classes = useStyles()

  const { threads, setSelectedThread, selectedThread } =
    useContext(MessagesContext)

  const routeMatch = useRouteMatch("/messages/:message_id")

  const { isClerk, searchValue } = useContext(AppContext)
  //state for data to show
  const [displayedThreads, setDisplayedThreads] = useState([])

  const [citizenSearchVal, setCitizenSearchVal] = useState("")

  // set displayed threads to loaded threads
  useEffect(() => {
    if (threads) {
      setDisplayedThreads(threads)
    }
  }, [threads])

  // handle search
  useEffect(() => {
    if (threads) {
      let newArray = [...threads]
      let searchTerm = isClerk ? searchValue : citizenSearchVal
      // if a search val is applied
      if (searchTerm?.length > 0) {
        setSelectedThread(null)
        newArray = newArray?.filter((x) =>
          x?.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setDisplayedThreads(newArray)
      } else {
        setDisplayedThreads(newArray)
      }
    }
  }, [searchValue, citizenSearchVal, threads])

  useEffect(() => {
    const urlId = routeMatch?.params?.message_id

    if (Number(urlId) !== Number(selectedThread?.thread_id)) {
      const newSelectedThread = threads?.find(
        (thread) => Number(thread.thread_id) === Number(urlId)
      )

      setSelectedThread(newSelectedThread)
    }
  }, [])

  return (
    <>
      {/* specific search bar for citizen side */}
      {!isClerk && (
        <Box className={classes.searchWrapper}>
          <TextField
            placeholder="Search for conversation"
            className={classes.search}
            variant="filled"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setCitizenSearchVal(e.target.value)
              }
            }}
            onChange={(e) => {
              setCitizenSearchVal(e.target.value)
            }}
            InputProps={{
              classes: {
                root: classes.inputBackgroundColor,
                focused: classes.inputFocused,
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  <Icon classes={{ root: classes.iconRoot }}>
                    <img className={classes.imageIcon} src={search} alt="" />
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {displayedThreads?.length === 0 ? (
        <Box display="flex" justifyContent="center" margin="50px">
          <Typography
            style={{ opacity: 0.7, textAlign: "center" }}
            variant="h6"
          >
            {(searchValue.length || citizenSearchVal) && threads.length > 0
              ? "No messages matching your search"
              : "You have no messages"}
          </Typography>
        </Box>
      ) : (
        <List className={classes.root}>
          {orderBy(displayedThreads, ["date_sent"], ["desc"]).map(
            (thread, i) => {
              return (
                <Box key={i}>
                  <MessageThread thread={thread} />
                </Box>
              )
            }
          )}
        </List>
      )}
    </>
  )
}

ThreadList.propTypes = {}

export default ThreadList
