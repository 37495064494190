import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    overflow: "auto",
  },
  root: {
    overflow: "scroll",
  },
}))

export default styles
