import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import MemberListItem from "components/Clerk/Boards/MembersList/MemberListItem"
import AppContext from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import useStyles from "./styles"

const MemberEditCard = ({
  member,
  vacant = false,
  updateMutation,
  setMember,
  boardId,
  setVacant,
  setEditing,
  statusMutation,
  setApprovedApplicants,
}) => {
  const classes = useStyles()
  const { openSnackBar, authUser, openDialog, isClerk } = useContext(AppContext)
  const { board } = useContext(BoardContext)
  const queryClient = useQueryClient()
  const [reappointLoading, setReappointLoading] = useState(false)
  const [vacantLoading, setVacantLoading] = useState(false)

  const markAsVacant = () => {
    let data = {
      action: "mark this seat as vacant?",
      confirmText: "Mark as vacant",
    }
    //open confirmation dialog
    openDialog("clickConfirmation", data).then((res) => {
      setVacantLoading(true)
      let data = {
        member_id: member?.member_id || member?.id,
        first_name: member?.first_name || "",
        last_name: member?.last_name || "",
        board_position: member?.position_id || "",
        email: member?.email || "",
        phone: member?.phone || "",
        term_expiration: member?.term_expiration || null,
        terms_served: member?.terms_served || "",
        race: member?.race || "",
        gender: member?.gender || "",
        active: 0,
      }
      //update member to be a vacant
      updateMutation
        .mutateAsync(data)
        .then((res) => {
          if (res?.board_member?.applicant_id) {
            setApprovedApplicants((oldState) => {
              return [...oldState, res?.board_member]
            })
          }
          //clear member state
          setMember({
            member_id: null,
            first_name: "",
            last_name: "",
            position_id: res.board_member.position_id,
            email: "",
            phone: "",
            term_served: "",
            term_expiration: null,
            race: "",
            gender: "",
            position: res.board_member.position,
            applicant_id: null,
          })
          setVacantLoading(false)
          //change vacant state to be true
          setVacant(true)
          openSnackBar({ message: "Member removed" })
          //update get boards query
          queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldData) => {
              let newData = oldData.map((x) => {
                if (x.id === boardId) {
                  let data = x
                  x.board_positions = x.board_positions.map((x) => {
                    if (x.position_id === res.board_member.position_id) {
                      return {
                        member_id: null,
                        first_name: "",
                        last_name: "",
                        position_id: res.board_member.position_id,
                        email: "",
                        phone: "",
                        term_served: "",
                        term_expiration: null,
                        race: "",
                        gender: "",
                        position: res.board_member.position,
                        applicant_id: member?.applicant_id,
                      }
                    } else {
                      return x
                    }
                  })
                  return data
                } else {
                  return x
                }
              })
              return newData
            }
          )
          let statusData = {
            boardId: boardId,
            applicationId: member?.applicant_id,
            status_id: 3,
          }
          //if the member is an applicant change the status to be in consideration
          if (member?.applicant_id || member?.board_application_id) {
            statusMutation
              .mutateAsync(statusData)
              .then((res) => {
                return queryClient.setQueryData(
                  ["boardApplicants", boardId],
                  (oldState) => {
                    let newApplicants = oldState.map((x) => {
                      if (
                        x.board_application_id === member?.applicant_id ||
                        member?.board_application_id
                      ) {
                        let data = x
                        x.status_id = 3
                        x.status = "In Consideration"
                        return data
                      } else {
                        return x
                      }
                    })
                    return newApplicants
                  }
                )
              })
              .catch((err) => {
                openSnackBar({ message: "Error updating application status" })
                setVacantLoading(false)
              })
          }
        })
        .catch((err) => {
          openSnackBar({ message: "Error removing Member" })
          setVacantLoading(false)
        })
    })
  }

  //go to edit member component
  const editMember = () => {
    setEditing(true)
  }
  //dont let reappoint if we dont have memmber(Boolean(!member?.first_name))
  //or the board term is conterminous or no term limit
  //or it is vacant
  // or if board term is other & the role term is coterminous or no term limit
  const reappointDisabled =
    Boolean(!member?.first_name) ||
    board?.term?.toLowerCase() === "no term limit" ||
    board?.term?.toLowerCase() === "coterminous" ||
    (board?.term?.toLowerCase() === "other" &&
      !member?.member_term_length?.toLowerCase()?.includes("year")) ||
    vacant ||
    !member?.term_expiration

  const reappointFunc = () => {
    let data = {
      action: `re-appoint ${member?.first_name} ${member?.last_name}?`,
      confirmText: "Re-appoint",
    }
    //open confirmation dialog
    openDialog("clickConfirmation", data).then((res) => {
      setReappointLoading(true)
      let data = member
      data.board_position = data?.position_id
      //add one every time that we re=appoint to terms served
      data.terms_served = Number(data.terms_served) + 1

      //check if the board details/re-appoint has years to add
      if (board?.term?.includes("other")) {
        let date = member?.term_expiration
          ? dayjs(member?.term_expiration)
          : dayjs()
        member.term_expiration = date?.add(
          Number(member.member_term_length.split(" ")[0]),
          "year"
        )
      } else if (board?.term?.includes("year")) {
        let years = Number(board?.term?.split(" ")[0])
        let date = member?.term_expiration
          ? dayjs(member?.term_expiration)
          : dayjs()
        member.term_expiration = date?.add(years, "year")
      } else {
        data.term_expiration = null
      }

      updateMutation
        ?.mutateAsync(data)
        .then((res) => {
          setReappointLoading(false)
          //update member state
          setMember(data)
          //vacant state to false
          setVacant(false)
          setEditing(false)
          openSnackBar({ message: "Member Re-appointed" })
          //update get boards query
          queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldData) => {
              let newData = oldData.map((x) => {
                if (x.id === boardId) {
                  let data = x
                  x.board_positions = x.board_positions.map((x) => {
                    if (
                      (x.board_position_id || x.position_id) ===
                      member.position_id
                    ) {
                      return res.board_member
                    } else {
                      return x
                    }
                  })
                  return data
                } else {
                  return x
                }
              })
              return newData
            }
          )
        })
        .catch((err) => {
          setReappointLoading(false)
          openSnackBar({ message: "Error re-appointing member" })
        })
    })
  }

  return (
    <Card className={classes.container}>
      <Box className={classes.editButton}>
        <IconButton onClick={editMember}>
          <EditIcon />
        </IconButton>
      </Box>
      <MemberListItem noButton member={member} vacant={vacant} />
      <Box className={classes.buttonBox}>
        <Button
          color="secondary"
          classes={{ label: classes.btnLabel }}
          disabled={
            reappointDisabled ||
            reappointLoading ||
            transformCaseAndSpaces(board?.reappointment) === "Not Permitted"
          }
          onClick={reappointFunc}
        >
          {reappointLoading ? (
            <Box width="62px" display="flex" justifyContent="center">
              <CircularProgress size={10} color="secondary" />
            </Box>
          ) : (
            "Re-appoint"
          )}
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button
          color="secondary"
          classes={{ label: classes.btnLabel }}
          disabled={Boolean(!member?.first_name) || vacantLoading}
          onClick={markAsVacant}
        >
          {vacantLoading ? (
            <Box width="62px" display="flex" justifyContent="center">
              <CircularProgress size={10} color="secondary" />
            </Box>
          ) : (
            "Mark As Vacant"
          )}
        </Button>
        <Divider orientation="vertical" flexItem />
      </Box>
    </Card>
  )
}

MemberEditCard.propTypes = {
  member: PropTypes.object,
  vacant: PropTypes.bool,
  updateMutation: PropTypes.object,
  setMember: PropTypes.func,
  boardId: PropTypes.number,
  setVacant: PropTypes.func,
  setEditing: PropTypes.func,
  statusMutation: PropTypes.object,
  setApprovedApplicants: PropTypes.func,
}

export default MemberEditCard
