import LoaderButton from "@bit/c_t.components.loader-button"
import { Box } from "@material-ui/core"
import { getPin } from "actions/Citizen/users"
import App from "contexts/App"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router"
import serialize from "store/serialize"
import * as Yup from "yup"

import CodeInput from "../Codeinput"
import useStyles from "./styles"

const EnterPinForm = ({ resetEmail, resetPass, setPin }) => {
  const { openSnackBar } = useContext(App)
  const classes = useStyles()
  const authContext = useContext(App)

  const queryClient = useQueryClient()
  const mutation = useMutation((data) => getPin(data))
  const history = useHistory()
  const formik = useFormik({
    initialValues: {
      pin: "",
      email: `${resetEmail.email}`,
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string().required("Pin code is required"),
    }),

    onSubmit: async (values) => {
      try {
        mutation.mutateAsync(values).then((res) => {
          setPin(values?.pin)
          const user = res.data.user
          const token = res.data.token
          serialize("user", user)
            .then(async (serializedData) => {
              await authContext.setAuthData({
                token: token,
                account: serializedData,
              })
              authContext.openSnackBar({
                message: "Login successful!",
              })
              resetPass()

              return queryClient.setQueryData("user", (oldState) => {
                return serializedData
              })
            })
            .catch((err) => {
              authContext.setAuthData({
                token: token,
                account: user,
              })
              authContext.openSnackBar({
                message: "Login successful!",
              })
              resetPass()
            })
        })
      } catch (error) {
        openSnackBar({
          message: `Invalid Pin!`,
          open: true,
          error: true,
        })
      }
    },
  })
  // Set formik field to touched onblur
  const handleTouched = (e) => {
    formik.setFieldTouched(e.target.id, true)
  }
  return (
    <Box>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          formik.submitForm()
        }}
      >
        <CodeInput
          ontouchstart={handleTouched}
          onChange={(e) => {
            formik.setFieldValue("pin", e)
          }}
        />

        <Box className={classes.loaderButtonwrapper}>
          <LoaderButton
            color="primary"
            classoverrides={{
              wrapper: classes.button,
              button: {
                root: classes.buttonRoot,
              },
            }}
            variant="contained"
            type="submit"
            working={mutation.isLoading}
            fullWidth
          >
            Submit Pin
          </LoaderButton>
        </Box>
      </form>
    </Box>
  )
}

EnterPinForm.propTypes = {
  setPin: PropTypes.func,
  resetPass: PropTypes.func,
  resetEmail: PropTypes.object,

  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  FieldsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  ButtonsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
}

export default EnterPinForm
