import { Box, CircularProgress, Typography } from "@material-ui/core"
import AppContext from "contexts/App"
import MessageContext from "contexts/Message"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"

import MessageItem from "./MessageItem"
import useStyles from "./styles"

const MessagesList = ({ messages, data, isLoading }) => {
  const classes = useStyles()
  const { viewedMessageMutation } = useContext(MessageContext)
  const { isClerk } = useContext(AppContext)

  // Set messages as viewed when new messages are recieved or when component is mounted
  useEffect(() => {
    if (data) {
      const idType = isClerk ? "user_id" : "entity_id"
      const id = isClerk ? data.user_id : data.entity_id

      viewedMessageMutation.mutateAsync({
        thread_id: data.thread_id,
        [idType]: id,
        admin: isClerk,
      })
    }
  }, [messages])

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      {!isEmpty(messages) &&
        messages.map((message) => (
          <MessageItem key={message.id} data={message} user={data} />
        ))}
      {isEmpty(messages) && (
        <Box textAlign="center" paddingY={2}>
          <Typography>No messages</Typography>
        </Box>
      )}
    </Box>
  )
}

MessagesList.propTypes = {
  messages: PropTypes.array,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default MessagesList
