import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import CitizenApp from "contexts/Citizen/CitizenApp"
import PropTypes from "prop-types"
import { useState } from "react"
import { useContext } from "react"

import useStyles from "./styles"

const MobileInterestCheckboxes = ({ children }) => {
  const classes = useStyles()
  const [showAll, setShowAll] = useState(false)

  const { selectedCategories, setSelectedCategories, interests } =
    useContext(CitizenApp)

  const handleToggleCollapse = () => {
    setShowAll((oldState) => !oldState)
  }

  const handleChange = (value) => {
    if (
      selectedCategories?.filter((x) => Number(x?.id) === Number(value?.id))
        .length > 0
    ) {
      setSelectedCategories((oldState) =>
        oldState?.filter((x) => x?.id !== value?.id)
      )
    } else {
      setSelectedCategories((oldState) => [...oldState, value])
    }
  }

  return (
    <Box className={classes.inputBox}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="body2"
          color="primary"
          className={classes.inputLabel}
        >
          Board Areas of Interest ({selectedCategories?.length}/
          {interests?.length})
        </Typography>
        <IconButton onClick={handleToggleCollapse}>
          {!showAll ? (
            <KeyboardArrowDownIcon color="primary" />
          ) : (
            <KeyboardArrowUpIcon color="primary" />
          )}
        </IconButton>
      </Box>
      <Collapse in={showAll}>
        <Box
          display="flex"
          flexWrap="wrap"
          height="170px"
          zIndex={6}
          overflow="auto"
        >
          {interests?.map((element, i) => {
            return (
              <Box key={i} display="flex" alignItems="center" width="50%">
                <Checkbox
                  checked={
                    selectedCategories.filter((x) => x.id === element?.id)
                      .length > 0
                  }
                  value={element?.id}
                  onChange={() => handleChange(element)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Typography style={{ flexWrap: "wrap" }}>
                  {element?.name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Collapse>
    </Box>
  )
}

MobileInterestCheckboxes.propTypes = {
  children: PropTypes.node,
}

export default MobileInterestCheckboxes
