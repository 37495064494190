import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  card: {
    width: "100%",
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },

  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: "#898989",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },

  textContainer: {
    color: theme.palette.primary.dark,
  },
  text: {
    fontWeight: 500,
  },

  referencesButtonWithData: {
    fontWeight: 500,
    fontSize: "1.4rem",
  },
  buttonText: {
    fontSize: "1rem",
    color: theme.palette.primary.dark,
  },
}))

export default styles
