import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import { FieldArray, Form, Formik, getIn } from "formik"
import { useContext } from "react"
import InputMask from "react-input-mask"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import formatPhoneNumber from "utils/formatPhone"
import * as Yup from "yup"

import useStyles from "./styles"

const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

const validationSchema = Yup.object().shape({
  references: Yup.array().of(
    Yup.object().shape({
      full_name: Yup.string().required("Required"),
      relationship: Yup.string().required("Required"),
      phone: Yup.string()
        .required("Required")
        .matches(phoneRegex, "Phone number is not valid")
        .min(14, "Phone number is not valid")
        .max(14, "Phone number is not valid"),
      //14 char - 9 numbers, 3 special chars ()-, 1 space
      email: Yup.string()
        .email("Please enter a valid email")
        .required(`Required`),
    })
  ),
})

const AddReferencesDialog = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const authContext = useContext(AppContext)
  const { dialogs, closeDialog, token, authUser, openSnackBar } =
    useContext(AppContext)
  const { updateReferencesMutation } = useContext(AccountContext)
  const thisDialog = dialogs?.["addReferencesDialog"] || {}
  const { open = false, data = {} } = thisDialog

  const handleClose = () => {
    closeDialog("addReferencesDialog")
  }
  const emptyReference = {
    temporalId: Math.random(),
    full_name: "",
    relationship: "",
    phone: "",
    email: "",
  }

  // cleans out sepcial characters when submitting - ph. back to 9 numbers
  const cleanReferencesPhone = (obj) => {
    let regex = /[^0-9]/g
    if (obj?.references?.length > 0) {
      return obj?.references?.map((ref) => ({
        ...ref,
        phone: ref.phone.replace(regex, ""),
      }))
    }
  }

  // used in initial vals - format phone#s (###) ###-#### for consumption of the masker & validation min max
  const prettifyPhone = (array) => {
    if (array.length > 0) {
      return array.map((x) => ({ ...x, phone: formatPhoneNumber(x?.phone) }))
    }
  }

  return (
    <ConditionalDialogWrapper open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Add References</Typography>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={{
          references:
            authUser?.references?.length > 0
              ? prettifyPhone(authUser?.references)
              : [emptyReference],
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formattedRefs = cleanReferencesPhone(values)
          const formattedVals = { ...values, references: formattedRefs || [] }
          updateReferencesMutation
            .mutateAsync(formattedVals)
            .then((res) => {
              serialize("user", res.user).then((serializedData) => {
                authContext.setAuthData({
                  token: token,
                  account: serializedData,
                })
                return queryClient.setQueryData("user", (oldState) => {
                  return serializedData
                })
              })
              openSnackBar({ message: "References updated" })
              handleClose()
            })
            .catch((err) => {
              openSnackBar({ message: "Error updating references" })
            })
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          dirty,
        }) => (
          <Form noValidate autoComplete="off">
            <FieldArray name="references">
              {({ push, remove }) => (
                <DialogContent>
                  {values?.references.map((r, index) => {
                    const fullName = `references[${index}].full_name`
                    const touchedFullName = getIn(touched, fullName)
                    const errorFullName = getIn(errors, fullName)

                    const relationship = `references[${index}].relationship`
                    const touchedRelationship = getIn(touched, relationship)
                    const errorRelationship = getIn(errors, relationship)

                    const phone = `references[${index}].phone`
                    const touchedPhone = getIn(touched, phone)
                    const errorPhone = getIn(errors, phone)

                    const email = `references[${index}].email`
                    const touchedEmail = getIn(touched, email)
                    const errorEmail = getIn(errors, email)
                    return (
                      <Box
                        key={r.id || r.temporalId}
                        className={
                          index === 0 ? classes.firstForm : classes.otherForms
                        }
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="h6">{`Reference ${
                            index + 1
                          }`}</Typography>
                          <IconButton
                            color="primary"
                            onClick={() => remove(index)}
                            data-cy="references-remove"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          label="Full Name"
                          data-cy="references-full-name"
                          name={fullName}
                          fullWidth
                          value={r.full_name}
                          helperText={
                            touchedFullName && errorFullName
                              ? errorFullName
                              : ""
                          }
                          error={Boolean(touchedFullName && errorFullName)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          FormHelperTextProps={{
                            className: classes.formHelperText,
                          }}
                        />
                        <TextField
                          margin="normal"
                          variant="outlined"
                          label="Relationship"
                          data-cy="references-relationship"
                          fullWidth
                          name={relationship}
                          value={r.relationship}
                          helperText={
                            touchedRelationship && errorRelationship
                              ? errorRelationship
                              : ""
                          }
                          error={Boolean(
                            touchedRelationship && errorRelationship
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          FormHelperTextProps={{
                            className: classes.formHelperText,
                          }}
                        />
                        <InputMask
                          id="phone-mask"
                          mask="(999) 999-9999"
                          maskChar="_"
                          value={r.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {() => (
                            <TextField
                              margin="normal"
                              variant="outlined"
                              label="Phone"
                              data-cy="references-phone"
                              fullWidth
                              name={phone}
                              helperText={
                                touchedPhone && errorPhone ? errorPhone : ""
                              }
                              error={Boolean(touchedPhone && errorPhone)}
                              FormHelperTextProps={{
                                className: classes.formHelperText,
                              }}
                            />
                          )}
                        </InputMask>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          label="Email"
                          data-cy="references-email"
                          fullWidth
                          name={email}
                          value={r.email}
                          helperText={
                            touchedEmail && errorEmail ? errorEmail : ""
                          }
                          error={Boolean(touchedEmail && errorEmail)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          FormHelperTextProps={{
                            className: classes.formHelperText,
                          }}
                        />
                      </Box>
                    )
                  })}
                  <IconButton
                    style={{ padding: 10, borderRadius: 0 }}
                    onClick={() => push(emptyReference)}
                    data-cy="references-add-button"
                  >
                    <AddCircleOutlineIcon />
                    <Typography className={classes.saveText}>
                      Add another reference
                    </Typography>
                  </IconButton>
                </DialogContent>
              )}
            </FieldArray>
            <DialogActions>
              <Box display="flex" justifyContent="center" width="100%">
                <ButtonRounded
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  type="button"
                  data-cy="references-close-button"
                >
                  Close
                </ButtonRounded>
                <ButtonRounded
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.saveButton}
                  disabled={!dirty || updateReferencesMutation?.isLoading}
                  data-cy="references-save-button"
                  classes={{ label: classes.buttonLabel }}
                >
                  {updateReferencesMutation?.isLoading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    "Save"
                  )}
                </ButtonRounded>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ConditionalDialogWrapper>
  )
}

export default AddReferencesDialog
