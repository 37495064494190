import {
  Box,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import dayjs from "dayjs"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Ocuppation = ({
  actions,
  positionTitle,
  companyName,
  startDate,
  endDate,
  current,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Card className={classes.card}>
      <Box display="flex" width="100%">
        <Box flexGrow={1}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography className={classes.title}>{positionTitle}</Typography>
            <Box>{actions}</Box>
          </Box>
          <Box className={classes.textGray} display="flex" alignItems="center">
            <Typography variant="body1">{companyName}</Typography>
            <FiberManualRecordIcon className={classes.dot} />
            {!mobileView ? (
              <Typography variant="body1">
                {dayjs(startDate).format("MMMM D, YYYY")}
                {"-"}
                {current === 1
                  ? "current"
                  : dayjs(endDate).format("MMMM D, YYYY")}
              </Typography>
            ) : (
              <Typography variant="body1">
                {dayjs(startDate).format("M/D/YYYY")}
                {"-"}
                {current === 1 ? "current" : dayjs(endDate).format("M/D/YYYY")}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

Ocuppation.propTypes = {
  actions: PropTypes.node,
  positionTitle: PropTypes.string,
  companyName: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  current: PropTypes.number,
}

export default Ocuppation
