import { Box, Card, CircularProgress } from "@material-ui/core"
import loginBackgroundImage from "assets/login-background-image.jpg"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import LoginForm from "components/Forms/LoginForm"
import AppContext from "contexts/App"
import ClerkAppContext from "contexts/Clerk/ClerkApp"
import { isEmpty } from "lodash"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import { useMemo } from "react"
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom"

import useStyles from "./styles"

const Approval = () => {
  const location = useLocation()
  const { openDialog } = useContext(AppContext)
  const { checkApprovalTokenMutation } = useContext(ClerkAppContext)
  const history = useHistory()
  const authContext = useContext(AppContext)

  const params = useMemo(() => {
    if (!isEmpty(location.search)) {
      const params = new URLSearchParams(location.search)
      const obj = {}

      // iterate over all keys
      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          obj[key] = params.getAll(key)
        } else {
          obj[key] = params.get(key)
        }
      }
      return obj
    } else {
      return {}
    }
  }, [location.search])

  useEffect(() => {
    if (!isEmpty(params)) {
      checkApprovalTokenMutation.mutate(params.token, {
        onSuccess: (res) => {
          authContext.setAuthData({
            token: res.token,
            account: res.user,
          })
          history.push("/dashboard")

          if (res?.approved && res?.approved?.intitial_password_set === 0) {
            let data = { ...res?.approved, token: params?.token }

            openDialog("finishAccount", data)
          }
        },
        onError: (err) => {
          console.log(err)
        },
      })
    } else {
      history.replace("/")
    }
  }, [params])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  )
}

export default Approval
