import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { updatePosition } from "actions/Clerk/board"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import * as Yup from "yup"

import useStyles from "./styles"

const schema = Yup.object().shape({
  position: Yup.string(),
})

const PositionName = ({ member, boardId, setMember }) => {
  const updatePositionMutation = useMutation(updatePosition)
  const { openSnackBar, authUser, isClerk } = useContext(AppContext)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const queryClient = useQueryClient()

  const classes = useStyles()

  const onSubmit = (values) => {
    //when we are deleting positions values.position is equal to empty string
    //when the position doesnt have a member attached needs to pass member id === null

    //modify loading state if we are deleting or saving positions
    if (values?.position === "" || !values?.position) {
      setDeleteLoading(true)
    } else {
      setSaveLoading(true)
    }
    let data = {
      boardId: boardId,
      positionId: member?.position_id,
      memberId: member?.id || member?.member_id || null,
      position: values.position,
    }

    updatePositionMutation
      ?.mutateAsync(data)
      .then((res) => {
        //update get boards query
        queryClient.setQueryData(
          ["boardsData", authUser && isClerk],
          (oldData) => {
            let newData = oldData.map((x) => {
              if (x.id === boardId) {
                let data = x
                x.board_positions = x.board_positions?.map((p) => {
                  if (p.position_id === member?.position_id) {
                    let newData = p
                    p.position = values?.position
                    return newData
                  } else {
                    return p
                  }
                })
                return data
              } else {
                return x
              }
            })
            setMember((oldState) => {
              return { ...oldState, position: values.position }
            })
            return newData
          }
        )
        //modify loading state if we are deleting or saving positions
        if (values?.position === "" || !values?.position) {
          setDeleteLoading(false)
        } else {
          setSaveLoading(false)
        }
        openSnackBar({ message: "Role/district updated" })
      })
      .catch((err) => {
        openSnackBar({ message: "Error updating role/district" })
        //modify loading state if we are deleting or saving positions
        if (values?.position === "" || !values?.position) {
          setDeleteLoading(false)
        } else {
          setSaveLoading(false)
        }
      })
  }

  return (
    <Formik
      initialValues={{ position: member?.position || "" }}
      onSubmit={onSubmit}
      validationSchema={schema}
      enableReinitialize
    >
      {({
        dirty,
        values,
        errors,
        handleSubmit,
        handleChange,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Typography className={classes.label}>Role/District</Typography>
          <TextField
            type="text"
            name="position"
            onChange={handleChange}
            value={values?.position}
            style={{ marginBottom: "20px" }}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {dirty && !deleteLoading && (
                    <Button type="submit">
                      {saveLoading ? <CircularProgress size={15} /> : "Save"}
                    </Button>
                  )}
                  {member?.position && (
                    <IconButton
                      onClick={() => {
                        setFieldValue("position", "")
                        onSubmit({ position: "" })
                      }}
                    >
                      {deleteLoading ? (
                        <CircularProgress size={15} />
                      ) : (
                        <HighlightOffIcon />
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

PositionName.propTypes = {
  member: PropTypes.object,
  setMember: PropTypes.func,
  boardId: PropTypes.number,
  length: PropTypes.number,
}

export default PositionName
