import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
    },
    link: {
      fontWeight: 500,
      marginLeft: theme.spacing(2),
    },
    loginButtonBox: {
      marginLeft: theme.spacing(2),
    },
    tabColor: {
      color: theme.palette.secondary.dark,
    },
    clerkLink: {
      color: "#412A5D",
      textDecoration: "none",
      fontWeight: 500,
    },
    label: {
      textTransform: "uppercase",
      fontWeight: 500,
    },
    labelTab: {
      padding: "16px",
      minWidth: "64px",
      marginRight: theme.spacing(1),
    },
    iconTab: {
      padding: "16px",
      minWidth: "32px",
      color: theme.palette.secondary.main,
    },
    labelWrapper: {
      justifyContent: "center",
    },
    logout: {
      color: "#898989",
      minWidth: "32px",
    },
  }),
  {
    name: "AppBarMenu",
  }
)

export default styles
