import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
}))

export default styles
