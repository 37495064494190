import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  texfield: {
    marginTop: theme.spacing(4),
  },
  uploadFileContainer: {
    backgroundColor: "#E2E7EF",
    width: "100%",
    height: "124px",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
  },
  uploadFileSubcontainer: {
    width: "410px",
    height: "100px",
    border: "1px #BCBFC3 dashed",
    borderRadius: "5px",
  },
  uploadIcon: {
    marginBottom: theme.spacing(1),
  },
  textfield: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  errorText: {
    height: 18,
    color: "#f44336",
  },
  overlay: {
    background: "rgba(0, 0, 0, .1)",
    "& h6": {
      color: "#a02062",
    },
  },
  center: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%)",
  },
  resumeIcon: {
    marginRight: theme.spacing(2),
  },
  editIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.palette.primary.main,
  },
}))

export default styles
