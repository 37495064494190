import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { deferApplicationsOnAppoint } from "actions/Clerk/applicants"
import { ReactComponent as PreferredIcon } from "assets/preferred-icon.svg"
import ButtonRounded from "components/Common/ButtonRounded"
import ListItemWithContent from "components/Common/ListItemWithContent"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import useStyles from "./styles"

//this component is the list to appoint in the edit member dialog

const ReplacementMemberCard = ({
  applicant,
  statusMutation,
  boardId,
  setApprovedApplicants,
  appointApplicantMutation,
  setMember,
  setVacant,
  member,
  setEditing,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { openSnackBar, authUser, openDialog, isClerk } = useContext(AppContext)
  const [deferLoading, setDeferLoading] = useState(false)
  const [appointLoading, setAppointLoading] = useState(false)
  const [declineLoading, setDeclineLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const queryClient = useQueryClient()

  const deferApplicationsOnAppointMutation = useMutation(
    deferApplicationsOnAppoint
  )

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  //change applicant state to deferred
  const deferApplicant = () => {
    let data = {
      action: "to defer this applicant?",
      confirmText: "Defer",
    }
    openDialog("clickConfirmation", data).then((res) => {
      setDeferLoading(true)
      let data = {
        boardId: boardId,
        applicationId: applicant?.applicant_id,
        status_id: 5,
      }
      statusMutation
        .mutateAsync(data)
        .then((res) => {
          setDeferLoading(false)
          openSnackBar({ message: "Applicant deferred" })
          setApprovedApplicants((oldState) => {
            return oldState.filter(
              (x) => x.applicant_id !== applicant.applicant_id
            )
          })
          handleCloseMenu()
          //update get boards query
          return queryClient.setQueryData(
            ["boardApplicants", boardId],
            (oldState) => {
              let newApplicants = oldState.map((x) => {
                if (x.board_application_id === applicant?.applicant_id) {
                  let data = x
                  x.status_id = 5
                  x.status = "Deferred"
                  return data
                } else {
                  return x
                }
              })
              return newApplicants
            }
          )
        })
        .catch((err) => {
          openSnackBar({ message: "Error deferring applicant" })
          setDeferLoading(false)
          handleCloseMenu()
        })
    })
  }

  //send the applicant to member table
  const appoint = () => {
    setAppointLoading(true)
    let data = {
      applicantId: applicant.applicant_id,
      sitting_member_id: null,
      position_id: member?.position_id,
    }
    appointApplicantMutation
      .mutateAsync(data)
      .then((res) => {
        setAppointLoading(false)
        setVacant(false)
        //close edit box
        setEditing(false)
        setMember({
          ...res.new_member[0],
          profile_image: applicant.profile_image,
        })
        openSnackBar({ message: "Applicant Appointed" })
        setApprovedApplicants((oldState) =>
          oldState.filter((x) => x.applicant_id !== applicant.applicant_id)
        )

        //update get boards query
        queryClient.setQueryData(
          ["boardsData", authUser && isClerk],
          (oldData) => {
            let newData = oldData.map((x) => {
              if (x.id === boardId) {
                let data = x
                x.board_positions = x.board_positions.map((x) => {
                  if (x.position_id === res.new_member[0].position_id) {
                    return res.new_member[0]
                  } else {
                    return x
                  }
                })
                return data
              } else {
                return x
              }
            })
            return newData
          }
        )
        handleCloseMenu()
        //update applicant state to appointed
        queryClient.setQueryData(["boardApplicants", boardId], (oldState) => {
          // check if app is old or current
          let newActive = oldState.active_applications
          let newPrevious = oldState.previous_applications

          if (applicant.active === 1) {
            oldState.active_applications.map((x) => {
              if (x.board_application_id === applicant?.applicant_id) {
                let data = x
                x.status_id = 4
                x.status = "Appointed"
                return data
              } else {
                return x
              }
            })
            return {
              active_applications: newActive,
              previous_applications: newPrevious,
            }
          } else {
            oldState.previous_applications.map((x) => {
              if (x.board_application_id === applicant?.applicant_id) {
                let data = x
                x.status_id = 4
                x.status = "Appointed"
                return data
              } else {
                return x
              }
            })
            return {
              active_applications: newActive,
              previous_applications: newPrevious,
            }
          }
        })
      })
      .then(() => {
        let dialogData = {
          action: `to defer applications for ${applicant.first_name} ${applicant.last_name} in other positions within this entity?`,
          confirmText: "Defer All",
          cancelText: "Skip",
          deferAll: true,
          // defer all piece only used to change copy of resultant dialog per client request
        }
        openDialog("clickConfirmation", dialogData).then(() => {
          // if yes, set all applications for the appointed member to Deferred
          let data = {
            entity_id: authUser?.entity_id,
            user_id: applicant?.user_id,
            board_id: boardId,
          }
          deferApplicationsOnAppointMutation
            .mutateAsync(data)
            .then(() => {
              openSnackBar({
                message: "Appointed member's other applications deferred",
              })
            })
            .catch((err) => {
              openSnackBar({
                message: "Error deferring applicant's other applications",
              })
              console.log(err)
              setAppointLoading(false)
            })
        })
      })
      .catch((err) => {
        openSnackBar({ message: "Error appointing applicant" })
        setAppointLoading(false)
        handleCloseMenu()
      })
  }

  //send the applicant to member table
  const declineApplicant = () => {
    let data = {
      action: `to decline this applicant?`,
      confirmText: "Decline",
    }
    openDialog("clickConfirmation", data).then((res) => {
      setDeclineLoading(true)
      let data = {
        boardId: boardId,
        applicationId: applicant?.applicant_id,
        status_id: 6,
      }
      statusMutation
        .mutateAsync(data)
        .then((res) => {
          setDeclineLoading(false)
          openSnackBar({ message: "Applicant declined" })
          setApprovedApplicants((oldState) => {
            return oldState.filter(
              (x) => x.applicant_id !== applicant.applicant_id
            )
          })
          handleCloseMenu()
          //update get boards query
          return queryClient.setQueryData(
            ["boardApplicants", boardId],
            (oldState) => {
              let newApplicants = oldState.map((x) => {
                if (x.board_application_id === applicant?.applicant_id) {
                  let data = x
                  x.status_id = 6
                  x.status = "Declined"
                  return data
                } else {
                  return x
                }
              })
              return newApplicants
            }
          )
        })
        .catch((err) => {
          openSnackBar({ message: "Error declining applicant" })
          setDeclineLoading(false)
          handleCloseMenu()
        })
    })
  }

  return (
    <Box>
      <ListItemWithContent
        disableGutters
        avatar={applicant}
        ListItemTextProps={{
          disableTypography: true,
        }}
        primary={
          <Box display="flex" alignItems="center">
            <Typography variant="body2">
              {applicant?.first_name + " " + applicant?.last_name}
            </Typography>

            {member?.position_id === applicant?.preferred_position_id && (
              <Tooltip title={<Typography>Preferred role/district</Typography>}>
                <Box marginLeft="10px">
                  <PreferredIcon />
                </Box>
              </Tooltip>
            )}
          </Box>
        }
        secondary={
          <Typography variant="body1">
            {applicant?.county},{applicant?.city}
          </Typography>
        }
      >
        <Box className={classes.buttonContainer}>
          {!mobileView ? (
            <>
              <ButtonRounded
                size="small"
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={declineApplicant}
                disabled={declineLoading}
              >
                {declineLoading ? (
                  <CircularProgress size={22} color="primary" />
                ) : (
                  "Decline"
                )}
              </ButtonRounded>
              <ButtonRounded
                size="small"
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={deferApplicant}
                disabled={deferLoading}
              >
                {deferLoading ? (
                  <CircularProgress size={22} color="primary" />
                ) : (
                  "Deferred"
                )}
              </ButtonRounded>
              <ButtonRounded
                size="small"
                variant="outlined"
                disabled={appointLoading}
                className={classes.button}
                style={{ color: "#1FA073", borderColor: "#1FA073" }}
                onClick={appoint}
              >
                {appointLoading ? (
                  <CircularProgress size={22} style={{ color: "#1FA073" }} />
                ) : (
                  "Appoint"
                )}
              </ButtonRounded>
            </>
          ) : (
            <>
              <ButtonRounded
                onClick={openMenu}
                aria-haspopup="true"
                variant="text"
              >
                <MoreVertIcon />
              </ButtonRounded>
              <Menu
                id="approved-applicant-actions"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(event, reason) => {
                  if (reason === "backdropClick") {
                    setAnchorEl(null)
                  }
                }}
              >
                <MenuItem onClick={declineApplicant}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: "1.4rem" }}
                    color="primary"
                  >
                    Decline
                  </Typography>
                </MenuItem>
                <MenuItem onClick={deferApplicant}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: "1.4rem" }}
                    color="primary"
                  >
                    Deferred
                  </Typography>
                </MenuItem>
                <MenuItem onClick={appoint}>
                  <Typography
                    variant="body2"
                    style={{ fontSize: "1.4rem", color: "#1FA073" }}
                  >
                    Appoint
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </ListItemWithContent>
    </Box>
  )
}

ReplacementMemberCard.propTypes = {
  applicant: PropTypes.object,
  statusMutation: PropTypes.object,
  boardId: PropTypes.number,
  setApprovedApplicants: PropTypes.func,
  appointApplicantMutation: PropTypes.object,
  setMember: PropTypes.func,
  member: PropTypes.object,
  setVacant: PropTypes.func,
  setEditing: PropTypes.func,
  vacant: PropTypes.bool,
}

export default ReplacementMemberCard
