import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formControl: {
    width: "90%",
    height: "100%",
    overflowY: "auto",
  },
  legend: {
    color: theme.palette.primary.dark,
    fontWeight: 700,
  },
  checkboxLabel: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
  },
}))

export default styles
