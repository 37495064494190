import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      height: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        paddingRight: 0,
        padding: 0,
      },
    },
    searchRow: {
      flexShrink: "0",
      paddingBottom: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    scrollArea: {
      width: "70%",
      height: "100%",
      overflow: "auto",
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        paddingBottom: "initial",
      },
    },
    sortButton: {
      [theme.breakpoints.down("md")]: {
        width: "200px",
        height: "50px",
      },
    },
    menuItem: {
      width: "150px",
    },
    dataImportButton: {
      backgroundColor: "#0A8158",
      marginLeft: theme.spacing(2),
      "&:hover": {
        backgroundColor: "#0A8158",
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(2),
      },
    },

    outer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      overflow: "auto",
      [theme.breakpoints.down("md")]: {
        overflow: "hidden",
      },
    },

    middle: {
      width: "30%",
      height: "100%",
      overflow: "hidden",
    },
    inner: {
      overflowY: "auto",
      height: "calc(100% - 80px)",
      overflow: "hidden",
    },
  }),
  {
    name: "IframeContent-Page",
  }
)

export default styles
