import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(2),
  },
  numberStats: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export default styles
