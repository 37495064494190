import { withdrawApplication } from "actions/Citizen/applied"
import { getUserApplications } from "actions/Citizen/users"
import PropTypes from "prop-types"
import { useContext } from "react"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const AppliedContext = createContext()
export default AppliedContext

export const AppliedContainer = ({ children, ...props }) => {
  const withdrawApplicationMutation = useMutation(withdrawApplication)
  return (
    <AppliedContext.Provider value={{ withdrawApplicationMutation }}>
      {children}
    </AppliedContext.Provider>
  )
}

AppliedContainer.propTypes = {
  children: PropTypes.node,
  boardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
