import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  leftContainer: {
    height: "100%",
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: "250px",
    // },
  },
}))

export default styles
