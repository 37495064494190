import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    maxHeight: "300px",
    overflow: "auto",
  },
  question: {
    fontWeight: 700,
  },
  dot: {
    fontSize: "8px",
    marginLeft: "5px",
    marginRight: "5px",
  },
}))

export default styles
