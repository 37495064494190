import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: "#fff",
      boxShadow: "0px 3px 6px #0000001A",
    },
  }),
  {
    name: "ConsumerAppBar",
  }
)

export default styles
