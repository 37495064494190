import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import FilterByCategory from "components/Citizen/Boards/FilterByCategory"
import MobileSearchFilter from "components/Citizen/MobileSearchFilter"
import ButtonRounded from "components/Common/ButtonRounded"
import LittleLogo from "components/LittleLogo"
import CitizenApp from "contexts/Citizen/CitizenApp"
import IframeContext from "contexts/Citizen/IframeContext"
import Page from "pages/Page"
import { useContext, useEffect, useState } from "react"

import IframeBoardList from "./IframeBoardList"
import IframeSearch from "./IframeSearch"
import useStyles from "./styles"

const IframeContent = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))
  const [filteredBoards, setFilteredBoards] = useState([])

  const {
    boards,
    boardsDataLoading,
    entityId,
    setEntityId,
    searchValue,
    setSearchValue,
  } = useContext(IframeContext)
  const { selectedCategories } = useContext(CitizenApp)

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const { e: entity } = Object.fromEntries(urlSearchParams.entries())
    setEntityId(entity)
  }, [])

  //handle search and filter items
  useEffect(() => {
    if (boards) {
      let newArray = []
      if (selectedCategories.length > 0) {
        // if any categories selected
        boards.map((board) => {
          let boardMatch = selectedCategories.filter((item) =>
            board.interest.map((x) => x.name).includes(item.name)
          )
          //board match gives back an arr of category id/ category name -
          // if board contains the category, array will feature
          if (boardMatch.length) {
            // only adds to newArray if not already there
            if (newArray.map((x) => x.id !== board.id)) {
              newArray = [...newArray, board]
            }
          }
        })
        // if categories & search value
        if (searchValue?.length > 0) {
          newArray = newArray?.filter((x) => {
            return (
              x.summary.toLowerCase().includes(searchValue.toLowerCase()) ||
              x.name.toLowerCase().includes(searchValue.toLowerCase())
            )
          })
        }
      } else {
        // no categories selected
        if (searchValue?.length > 0) {
          // with a search value
          newArray = boards?.filter((x) => {
            return (
              x.summary.toLowerCase().includes(searchValue.toLowerCase()) ||
              x.name.toLowerCase().includes(searchValue.toLowerCase())
            )
          })
        } else {
          //no search value
          newArray = boards
        }
      }
      setFilteredBoards(newArray)
    }
  }, [boards, searchValue, selectedCategories])

  const onHelp = () => {
    try {
      window.$zopim.livechat.window.show()
      // setIsOpen(false)
    } catch (error) {
      console.log("%cError opening Zendesk.", "color: red")
      // setIsOpen(false)
    }
  }

  // useEffect(() => {
  //   document?.querySelector("html").classList.add("hideZendesk")
  // }, [])

  if (boardsDataLoading && !filteredBoards?.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Page>
      <Container className={classes.container}>
        <Box className={classes.searchRow}>
          {mobileView ? (
            <MobileSearchFilter
              noLocation={true}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          ) : (
            <IframeSearch />
          )}
        </Box>

        <Box className={classes.outer}>
          {!mobileView && (
            <>
              <Box
                className={classes.middle}
                style={{ flexDirection: "column" }}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ fontWeight: 300, marginBottom: "20px" }}
                >
                  Showing {filteredBoards?.length} results
                </Typography>
                <Box className={classes.inner}>
                  <FilterByCategory />
                </Box>
                <LittleLogo />
              </Box>
            </>
          )}
          <Box className={classes.scrollArea}>
            {mobileView && (
              <Box
                padding="12px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  color="primary"
                  style={{
                    fontWeight: 300,
                    width: "50%",
                  }}
                >
                  Showing {filteredBoards?.length} results
                </Typography>
                <ButtonRounded
                  variant="contained"
                  color="primary"
                  onClick={onHelp}
                  startIcon={<HelpOutlineIcon />}
                >
                  Help
                </ButtonRounded>
              </Box>
            )}
            <IframeBoardList
              data={filteredBoards}
              loading={boardsDataLoading}
            />
          </Box>
        </Box>
      </Container>
    </Page>
  )
}

export default IframeContent
