import { Box, Paper, Typography } from "@material-ui/core"
import unum from "assets/unum_sponsor_logo.png"
import scac from "assets/scac-sponsor-logo.png"
import nephron from "assets/nephron-sponsor-logo.png"
import aflac from "assets/aflac-sponsor-logo.png"
import nord from "assets/nord-sponsor-logo.png"
import kf from "assets/KF-sponsor-logo.png"

import useStyles from "./styles"

const SponsorBar = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={2}>
      <Box className={classes.container}>
        <Typography className={classes.thanksMessage} variant="subtitle2">
          Thank you to our sponsors!
        </Typography>
        <Box className={classes.sponsorContainer}>
          <img
            component="image"
            src={unum}
            alt="unum"
            height="30px"
            width="93px"
          />
          <img
            component="image"
            src={scac}
            alt="scac"
            height="47px"
            width="93px"
          />
          <img
            component="image"
            src={nephron}
            alt="nephron"
            height="56px"
            width="94px"
          />
          <img
            component="image"
            src={aflac}
            alt="aflac"
            height="32px"
            width="98px"
          />
          <img
            component="image"
            src={nord}
            alt="nord"
            height="15px"
            width="93px"
          />
          <img component="image" src={kf} alt="kf" height="30px" width="93px" />
        </Box>
      </Box>
    </Paper>
  )
}

export default SponsorBar
