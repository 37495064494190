import { Box, CircularProgress, TextField, Typography } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import uploadIcon from "assets/upload-icon.svg"
import axios from "axios"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import { Form, Formik } from "formik"
import { isEmpty } from "lodash-es"
import PropTypes from "prop-types"
import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { useDropzone } from "react-dropzone"
import api from "store/api"
import serialize from "store/serialize"
import * as Yup from "yup"

import useStyles from "./styles"

const pdfSchema = Yup.object({
  file_name: Yup.string().required("Required"),
  file: Yup.string().required("Required"),
})

const FileUpload = forwardRef(
  ({ type, handleClose = null, setFile = null, fromApply = false }, ref) => {
    const classes = useStyles()
    const formRef = useRef()
    const { openSnackBar, authUser, setAuthUserState } = useContext(AppContext)
    const { updateResumeMutation } = useContext(CitizenAppContext)
    const [isLoading, setIsLoading] = useState(false)

    const onDrop = useCallback((acceptedFiles) => {
      formRef?.current?.setFieldValue("file", acceptedFiles[0])
    }, [])

    const { getRootProps, getInputProps, acceptedFiles, rejectedFiles } =
      useDropzone({
        onDrop,
        accept:
          "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      })

    const onSubmit = async (values, formRef) => {
      if (isEmpty(formRef?.current?.errors)) {
        setIsLoading(true)
        // Request presignedURL - pass file_name and file_type
        const presignedUrlRequest = await api.get("app/users/presigned", {
          params: {
            file_name: values.file_name,
            file_type: values.file.type,
          },
        })

        const { fields, url } = presignedUrlRequest.data.data

        // Build formData with values returned from AWS presignedURL
        const formData = new FormData()
        Object.entries(fields).forEach(([name, value]) => {
          formData.append(name, value)
        })

        // Append file to form data
        formData.append("file", values.file)

        try {
          const uploadResponse = await axios({
            method: "post",
            url,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          })

          // Get file extension
          const fileExtension = fields.key.match(/(\..*?)+$/)[0]
          // Update user profile
          let mutateData
          if (type === "resume") {
            mutateData = await updateResumeMutation.mutateAsync({
              resume: values.file_name + fileExtension,
              resume_id: fields.key,
            })
            // Update user state
            const serializedUser = await serialize("user", mutateData.user)
            setAuthUserState(serializedUser)
          } else {
            if (setFile) {
              setFile({
                file_name: values.file_name + fileExtension,
                file_id: fields.key,
              })
            }
          }

          openSnackBar({
            message: `${type[0]?.toUpperCase() + type?.substring(1)} uploaded.`,
          })
        } catch (error) {
          console.log("ERROR:", error)
          openSnackBar({ message: `Upload failed.` })
        }

        setIsLoading(false)

        // Call handleClose if exists
        if (handleClose) handleClose()
      }
    }

    useImperativeHandle(ref, () => ({
      callOnSubmit: () => {
        if (formRef?.current?.values?.file_name !== "") {
          onSubmit(formRef.current.values, formRef)
        }
      },
    }))

    return (
      <Formik
        initialValues={{
          file_name: authUser?.file_name || "",
          file: authUser?.file || "",
        }}
        onSubmit={onSubmit}
        validationSchema={pdfSchema}
        innerRef={formRef}
        ref={ref}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setErrors,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box
              className={classes.uploadFileContainer}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              {...getRootProps()}
            >
              <input {...getInputProps()} data-cy="resume-upload-file" />
              {acceptedFiles?.length > 0 ? (
                <Box display="flex" width="100%" justifyContent="center">
                  <EditIcon className={classes.editIcon} />
                  <Box>
                    <PictureAsPdfIcon className={classes.resumeIcon} />
                  </Box>
                  <Box>
                    <Typography variant="h6" className={classes.resumeName}>
                      {acceptedFiles?.[0]?.name}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  className={classes.uploadFileSubcontainer}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  height="100%"
                >
                  <img src={uploadIcon} alt="" className={classes.uploadIcon} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography variant="h6" style={{ marginRight: "2px" }}>
                      Choose a file
                      <Typography
                        component="span"
                        variant="h6"
                        style={{ fontWeight: 400 }}
                      >
                        {" "}
                        or drag it here
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Typography className={classes.errorText}>
              {errors.file && touched.file && errors.file}
            </Typography>
            <TextField
              type="text"
              name="file_name"
              label="File Name"
              id="file_name"
              data-cy="resume-file-name"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.file_name && errors.file_name}
              value={values.file_name}
              variant="outlined"
              fullWidth
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              error={touched.file_name && errors.file_name}
            />
            {isLoading && (
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                className={classes.overlay}
              >
                <Box className={classes.center}>
                  <CircularProgress />
                  <Typography variant="subtitle2">Uploading...</Typography>
                </Box>
              </Box>
            )}

            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              position="relative"
              marginTop={2}
            >
              {handleClose && (
                <ButtonRounded
                  color="primary"
                  variant="outlined"
                  onClick={() => handleClose && handleClose()}
                  type="button"
                  disabled={isLoading}
                  data-cy="resume-close-button"
                >
                  Close
                </ButtonRounded>
              )}
              {!fromApply && (
                <ButtonRounded
                  color="primary"
                  variant="contained"
                  type="submit"
                  data-cy="resume-save-button"
                  className={classes.saveButton}
                  disabled={!dirty || isLoading}
                >
                  Save
                </ButtonRounded>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    )
  }
)

FileUpload.propTypes = {
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  setFile: PropTypes.func,
  fromApply: PropTypes.bool,
  ref: PropTypes.object,
}

export default FileUpload
