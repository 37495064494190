import { entitiesList, listStates } from "actions/Clerk/entities"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const EntitiesContext = createContext()
export default EntitiesContext

export const EntitiesContainer = ({ children }) => {
  const { data: statesList, isLoading: statesListLoading } = useQuery(
    "statesList",
    listStates,
    { retry: false }
  )

  return (
    <EntitiesContext.Provider
      value={{
        // entitiesData,
        // entitiesDataLoading,
        statesList,
        statesListLoading,
      }}
    >
      {children}
    </EntitiesContext.Provider>
  )
}

EntitiesContainer.propTypes = {
  children: PropTypes.node,
}
