import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  logo: {
    width: 70,
    height: 20,
  },
}))

export default styles
