import { Box, MenuItem, TextField, Typography } from "@material-ui/core"
import RequestClerkContext from "contexts/Clerk/RequestClerk"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useContext, useState } from "react"

import useStyles from "./styles"

const Step1 = ({ setEntityUsed, openSnackBar }) => {
  const classes = useStyles()
  const { entityData, checkEntityAvailabilityMutation } =
    useContext(RequestClerkContext)
  const [showError, setShowError] = useState(false)

  const { values, handleChange, touched, errors, setFieldValue, setErrors } =
    useFormikContext()

  useEffect(() => {
    if (values?.entity_id !== null) {
      setShowError(false)
      checkEntityAvailabilityMutation.mutate(values?.entity_id?.id, {
        onSuccess: (res) => {
          if (res["has-admin"] === true) {
            setShowError(true)
            setErrors({
              ...errors,
              entity_id: "Entity is already in use",
            })
            setEntityUsed(true)
          } else {
            setEntityUsed(false)
          }
        },
        onError: (res) => {
          openSnackBar({
            message: "Error checking entity availability",
          })
        },
      })
    }
  }, [values])

  return (
    <Box>
      <Typography className={classes.subtitle}>
        What state and entity are you representing?
      </Typography>
      <TextField
        select
        name="state"
        label="State"
        value={values?.state}
        data-cy="clerk-request-select-state"
        onChange={(e) => {
          handleChange(e)
          setFieldValue("entity_id", null)
          setFieldValue("entity_type", "")
        }}
        variant="outlined"
        fullWidth
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        helperText={touched.state && errors.state}
        error={touched.state && errors.state}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
      >
        {entityData &&
          Object?.keys(entityData)?.length > 0 &&
          Object?.keys(entityData)?.map((state, i) => {
            return (
              <MenuItem
                key={i}
                value={state.toLowerCase()}
                data-cy={`clerk-request-select-state-${state.toLowerCase()}`}
              >
                {state}
              </MenuItem>
            )
          })}
      </TextField>
      <TextField
        select
        name="entity_type"
        label="Entity Type"
        data-cy="clerk-request-select-entity-type"
        value={values?.entity_type}
        onChange={(e) => {
          handleChange(e)
          setFieldValue("entity_id", null)
        }}
        variant="outlined"
        fullWidth
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        helperText={touched.entity_type && errors.entity_type}
        error={touched.entity_type && errors.entity_type}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        disabled={values?.state === ""}
      >
        {entityData &&
          Object?.values(entityData)?.length > 0 &&
          Object.keys(Object.values(entityData)[0]).map((entityType, i) => {
            return (
              <MenuItem
                data-cy={`clerk-request-select-entity-type-${entityType}`}
                key={i}
                value={entityType}
                className={classes.entityTypeText}
              >
                <Box className={classes.entityTypeText}>{entityType}</Box>
              </MenuItem>
            )
          })}
      </TextField>
      <TextField
        select
        name="entity_id"
        label="Entity"
        data-cy="clerk-request-select-entity"
        value={values?.entity_id}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        helperText={(touched.entity_id || showError) && errors.entity_id}
        error={(touched.entity_id && errors.entity_id) || showError}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        disabled={values?.entity_type === "" || values?.state === ""}
      >
        {entityData &&
          Object.values(entityData)?.length > 0 &&
          Object.values(entityData)[0]
            [values.entity_type]?.map((x) => x)
            .map((x, i) => {
              return (
                <MenuItem
                  key={x.id}
                  value={x}
                  data-cy={`clerk-request-select-entity-${x.entity}`}
                >
                  {x?.entity}
                </MenuItem>
              )
            })}
      </TextField>
    </Box>
  )
}
Step1.propTypes = {
  setEntityUsed: PropTypes.func,
  openSnackBar: PropTypes.func,
}
export default Step1
