import AppBar from "components/Consumer/AppBar"
import PropTypes from "prop-types"
import React from "react"
import Router from "router"

import useStyles from "./styles"

const Clerk = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <>
      <AppBar />
      <Router routes={routes} {...rest} />
    </>
  )
}

Clerk.propTypes = {
  routes: PropTypes.array,
}

export default Clerk
