import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  errorText: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    position: "absolute",
    bottom: 80,
    left: 65,
  },
  errorText2: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
  noError: {
    height: "20px",
  },
  uploadError: {
    color: theme.palette.error.main,
  },
}))

export default styles
