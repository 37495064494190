import { ApplicantsContainer } from "contexts/Clerk/Applicants"
import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"

import useStyles from "./styles"

const Applicants = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Page>
      <ApplicantsContainer>
        <Router routes={routes} {...rest} />
      </ApplicantsContainer>
    </Page>
  )
}

Applicants.propTypes = {
  routes: PropTypes.array,
}

export default Applicants
