import { Box, CircularProgress } from "@material-ui/core"
import UpdateBoard from "components/Forms/UpdateBoard"
import BoardContext from "contexts/Clerk/Board"
import { isEmpty } from "lodash"
import Page from "pages/Page"
import { useContext } from "react"

const BoardDetails = () => {
  const { board, updateBoardMutation } = useContext(BoardContext)

  return (
    <Page>
      {isEmpty(board) ? (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <UpdateBoard board={board} updateBoardMutation={updateBoardMutation} />
      )}
    </Page>
  )
}

export default BoardDetails
