import { Badge, Box, Button, Tab, Tabs, Typography } from "@material-ui/core"
import { ChatBubbleOutline, PersonOutline } from "@material-ui/icons"
import ButtonRounded from "components/Common/ButtonRounded"
import App from "contexts/App"
import MessagesContext from "contexts/Messages"
import { findIndex, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useMemo } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

import useStyles from "./styles"

const Menu = ({ children }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const { openDialog, setAuthData, authUser, openSnackBar, removeAuthToken } =
    useContext(App)
  const { threads, selectedThread, setSelectedThread } =
    useContext(MessagesContext)

  const tabs = [
    {
      link: "/boards",
      label: "BOARDS",
      value: 0,
    },
    {
      link: "/messages",
      value: 1,
      icon: <ChatBubbleOutline data-cy="menu-messages" />,
      message: true,
    },
    {
      link: "/my-account",
      value: 2,
      icon: <PersonOutline data-cy="menu-my-acoount" />,
    },
  ]

  const currentTab = useMemo(() => {
    if (location.pathname.includes("boards")) {
      // keeps tab highlighted if board is selected (e.g., boards/:board.id)
      return 0
    } else if (location.pathname === "/applied" || location.pathname === "/") {
      return false
    } else if (location.pathname.includes("/messages")) {
      return 1
    } else {
      return findIndex(tabs, { link: location.pathname })
    }
  }, [location.pathname])

  const handleOpenLogin = () => {
    openDialog("login")
  }

  // Check for unread messages
  const unread = useMemo(() => {
    return threads?.some(
      (thread) => thread?.direction === "received" && !thread?.viewed
    )
  }, [threads])

  const openClerkSide = () => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      return "http://localhost:3000/clerk/"
    } else if (process.env.REACT_APP_NODE_ENV === "staging") {
      return "https://admin.matchboard.dev/"
    } else {
      return "https://admin.matchboard.tech/"
    }
  }

  return (
    <Box className={classes.root} data-cy="menu-box">
      {isEmpty(authUser) ? (
        <>
          <Button
            component={Link}
            to="/boards"
            className={classes.link}
            disableRipple
          >
            BOARDS
          </Button>
          <Button>
            <a className={classes.clerkLink} href={openClerkSide()}>
              ADMINISTRATORS
            </a>
          </Button>
          <Button>
            <a
              className={classes.clerkLink}
              href={"https://matchboard.tech/about/"}
            >
              ABOUT
            </a>
          </Button>
          <Box data-cy="login-box" className={classes.loginButtonBox}>
            <ButtonRounded variant="outlined" onClick={handleOpenLogin}>
              Login
            </ButtonRounded>
          </Box>
        </>
      ) : (
        <>
          <Tabs
            value={currentTab}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            classes={{ flexContainer: classes.flexContainer }}
          >
            {tabs.map((tab, i) => {
              return (
                <Tab
                  key={i}
                  component={Link}
                  data-cy={tab.label === "BOARDS" && "menu-boards"}
                  to={tab?.link}
                  label={
                    tab?.label && (
                      <Typography
                        variant="h6"
                        color={currentTab === i ? "secondary" : "primary"}
                        className={classes.label}
                      >
                        {tab.label}
                      </Typography>
                    )
                  }
                  onClick={() => {
                    if (tab.message) {
                      setSelectedThread(null)
                    }
                  }}
                  icon={
                    tab?.icon && (
                      <Badge
                        variant="dot"
                        color="primary"
                        invisible={!(tab?.message && unread)}
                      >
                        {tab.icon}
                      </Badge>
                    )
                  }
                  className={tab?.label ? classes.labelTab : classes.iconTab}
                  classes={{ wrapper: classes.labelWrapper }}
                />
              )
            })}
          </Tabs>
          <Button
            style={{ marginLeft: "8px", color: "#898989" }}
            onClick={() => window.open("https://matchboard.tech/about/")}
            data-cy="matchboard-about-page"
          >
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
            >
              About
            </Typography>
          </Button>

          <Button
            style={{ marginLeft: "8px", color: "#898989" }}
            onClick={() => {
              setAuthData({ token: "", account: {} })
              removeAuthToken()
              history.push("/")
              openSnackBar({
                message: "You have logged out successfully!",
              })
            }}
            data-cy="menu-log-out"
          >
            <Typography
              variant="button"
              style={{ textTransform: "capitalize" }}
            >
              Logout
            </Typography>
          </Button>
        </>
      )}
    </Box>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}

export default Menu
