import {
  favoriteApplicant,
  updateApplicantStatus,
} from "actions/Clerk/applicants"
import {
  addApplication,
  addBoardMember,
  appointApplicant,
  getApplicantsWithStatus,
  getBoardAnalytics,
  getBoardApplicantById,
  getBoardApplicants,
  getBoardReviews,
  getOptions,
  getResume,
  shareApplicantsForReview,
  updateBoard,
  updateBoardMember,
} from "actions/Clerk/board"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext } from "react"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

import AppContext from "../App"
import BoardsContext from "../Boards"

const BoardContext = createContext()
export default BoardContext

export const BoardContainer = ({ children, ...props }) => {
  const boardId = parseInt(props?.boardId || "0")

  const { authUser, isAdmin } = useContext(AppContext)
  const { boardsData, boardsDataLoading } = useContext(BoardsContext)
  const board = !boardsDataLoading && boardsData?.find((b) => b.id === boardId)

  const updateBoardMutation = useMutation((data) => updateBoard(boardId, data))
  const { data: boardApplicants, isLoading: boardApplicantsLoading } = useQuery(
    ["boardApplicants", boardId],
    () => getBoardApplicants(boardId),
    {
      // below needed to get correct data when appoint applicant & subsequently defer all their other applications
      cacheTime: 0,
    }
  )

  const { data: boardOptions, isLoading: boardOptionsLoading } = useQuery(
    ["boardOptions"],
    () => getOptions()
  )

  const getBoardApplicantByIdMutation = useMutation((applicantId) => {
    return getBoardApplicantById(boardId, applicantId)
  })

  const getApplicantsWithStatusMutation = useMutation(() =>
    getApplicantsWithStatus(boardId, 3)
  )

  const favoriteApplicantMutation = useMutation(favoriteApplicant)
  const updateApplicantStatusMutation = useMutation(updateApplicantStatus)
  const appointApplicantMutation = useMutation((data) =>
    appointApplicant(boardId, data)
  )
  const updateBoardMemberMutation = useMutation((data) =>
    updateBoardMember(boardId, data)
  )

  const addBoardMemberMutation = useMutation((data) =>
    addBoardMember(boardId, data)
  )

  const { data: boardAnalytics, isLoading: boardAnalyticsLoading } = useQuery(
    ["boardAnalytics", board?.id],
    () => getBoardAnalytics(boardId, authUser.entity_id)
  )

  const getResumeMutation = useMutation(getResume)

  const createApplication = useMutation((data) => addApplication(boardId, data))

  const {
    data: boardReviews,
    isLoading: boardReviewsLoading,
    refetch: boardReviewRefetch,
  } = useQuery(["boardReviews", board?.id], () => getBoardReviews(board?.id), {
    enabled: isAdmin && !isEmpty(authUser),
  })

  const shareApplicantsForReviewMutation = useMutation((data) =>
    shareApplicantsForReview(boardId, data)
  )

  return (
    <BoardContext.Provider
      value={{
        board,
        boardOptions,
        boardOptionsLoading,
        updateBoardMutation,
        boardApplicants,
        boardApplicantsLoading,
        getBoardApplicantByIdMutation,
        getApplicantsWithStatusMutation,
        favoriteApplicantMutation,
        updateApplicantStatusMutation,
        appointApplicantMutation,
        updateBoardMemberMutation,
        addBoardMemberMutation,
        getResumeMutation,
        boardReviews,
        boardReviewsLoading,
        shareApplicantsForReviewMutation,
        boardReviewRefetch,
        createApplication,
        boardAnalytics,
        boardAnalyticsLoading,
      }}
    >
      {children}
    </BoardContext.Provider>
  )
}

BoardContainer.propTypes = {
  children: PropTypes.node,
  boardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
