import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    drawer: {
      backgroundColor: "#FFFFFF",
      opacity: 1,
      position: "absolute",
      bottom: 0,
      borderBottom: "none",
      borderRadius: "22px 22px 0px 0px",
      border: "1px solid black",
      width: "100%",
      maxHeight: "90%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    collapseButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.light,
      width: "100px",
      marginTop: "20px",
      marginBottom: "20px",
      marginLeft: "auto",
      marginRight: "auto",
      position: "relative",
      "&:hover": {
        color: theme.palette.primary.dark,
      },
    },
    // collapseLine: {
    //   height: "4px",
    //   marginTop: "20px",
    //   marginBottom: "20px",
    //   marginLeft: "auto",
    //   marginRight: "auto",
    //   width: "100px",
    //   backgroundColor: "#707070",
    //   opacity: 0.21,
    //   position: "relative",
    //   top: 0,
    // },
    scrollContainer: {
      overflowY: "auto",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  }),
  {
    name: "BottomDrawer",
  }
)

export default styles
