import { Box, Button, Typography } from "@material-ui/core"
import AppContext from "contexts/App"
import RequestClerkContext from "contexts/Clerk/RequestClerk"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useContext } from "react"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const RequestClerkBox = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()
  const { openDialog, openSnackBar } = useContext(AppContext)
  const { requestClerkDataMutation, setEntityData, setLeadershipData } =
    useContext(RequestClerkContext)

  useEffect(() => {
    requestClerkDataMutation
      .mutateAsync()
      .then((res) => {
        setEntityData(res.entities)
        setLeadershipData(res.leadership_levels)
      })
      .catch((err) => {
        console.log(err)
        openSnackBar({ message: "Error getting data " })
      })
  }, [])

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="h1"
          align="left"
          classes={{ root: classes.root }}
          style={{
            fontSize: "4.7rem",
          }}
        >
          Are You a Clerk Looking to Streamline Workflow?
        </Typography>
        <Typography
          variant="subtitle1"
          align="left"
          classes={{ root: classes.root }}
        >
          Matchboard is an end-to-end solutions to help manage board and
          commission, members and applicants.{" "}
        </Typography>
      </Box>

      <Button
        variant="contained"
        size="medium"
        color="primary"
        className={classes.button}
        onClick={() => openDialog("clerkRequest")}
        data-cy="clerk-request-button"
      >
        Sign Up Your Entity
      </Button>
    </>
  )
}

RequestClerkBox.propTypes = {
  children: PropTypes.node,
}

export default RequestClerkBox
