import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    "&:hover": {
      cursor: "pointer",
    },
    transition: theme.transitions.create("border", {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.sharp,
    }),
  },
  selected: {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },
  actionsContainer: {
    borderTop: "1px solid #E5E5E5",
  },
  applicantsNumber: {
    fontSize: "3.4rem",
  },
  bottomDataContainer: {
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(1),
    borderRight: "1px solid #E5E5E5",
  },
  bottomTitle: {
    fontSize: "1rem",
    color: "#898989",
  },
  bottomData: {
    color: theme.palette.secondary.dark,
  },
}))

export default styles
