import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import { AppliedContainer } from "contexts/Citizen/Applied"
import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"

import List from "./pages/List"
import useStyles from "./styles"

const Applied = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <Page>
      <AppliedContainer>
        <Box className={classes.tableContainer}>
          <List />
          <Router routes={routes} {...rest} />
        </Box>
      </AppliedContainer>
    </Page>
  )
}

Applied.propTypes = {
  routes: PropTypes.array,
}

export default Applied
