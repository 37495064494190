import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: "468px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: "1.4rem",
  },

  saveButton: {
    marginLeft: theme.spacing(2),
  },
  textfield: {
    marginTop: theme.spacing(2),
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  buttonLabel: {
    minWidth: "36px",
  },
}))

export default styles
