import api from "store/api"
import serialize from "store/serialize"

export const getBoardData = async (entityId) => {
  try {
    const boards = await api.get(`/p/boards/${entityId}`)
    return serialize("board", boards.data.data)
  } catch (error) {
    throw new Error(error)
  }
}
