import { Resize } from "@cloudinary/url-gen/actions/resize"
import cloudinary from "utils/cloudinaryConfig"

// if image is returned and cloudinary properly set up...
// scale the image (via the scale mode from the Resize action) to a width & height that will be provided
// then apply changes and create a URL
const getScaledImage = (image = null, width, height) => {
  if (!image) {
    return null
  }
  if (!cloudinary) {
    return
  }
  return cloudinary
    .image(image)
    .resize(Resize.scale().width(width).height(height))
    .toURL()
}

export default getScaledImage
