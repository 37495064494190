const useApply = (user) => {
  if (
    !user?.first_name ||
    !user?.last_name ||
    !user?.county ||
    !user?.state ||
    !user?.address ||
    !user?.city ||
    !user?.zip_code ||
    !user?.phone ||
    !user?.email ||
    !user?.resume ||
    user?.position?.length === 0 ||
    !user?.highest_education ||
    !user?.degree ||
    user?.interest?.length === 0 ||
    user?.expertise?.length === 0 ||
    user?.experience?.length === 0
  ) {
    return false
  } else {
    return true
  }
}

export default useApply
