import { Badge, Box } from "@material-ui/core"
import classnames from "clsx"
import ListItemWithContent from "components/Common/ListItemWithContent"
import MessagesContext from "contexts/Messages"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import cleanDateTime from "utils/cleanDateTime"

import useStyles from "./styles"

const MessageThread = ({ thread }) => {
  const classes = useStyles()

  const history = useHistory()
  const routeMatch = useRouteMatch("/messages/:message_id")

  const { setSelectedThread, selectedThread, threads } =
    useContext(MessagesContext)

  const handleNavigateToMessage = (thread) => {
    setSelectedThread(thread)
    history.push(`/messages/${thread.thread_id}`)
  }

  useEffect(() => {
    const urlId = routeMatch?.params?.message_id
    if (urlId && threads?.length) {
      const paramsThread = threads?.find(
        (thread) => Number(thread.thread_id) === Number(urlId)
      )
      if (paramsThread) {
        setSelectedThread(paramsThread)
      } else {
        history.push("/messages")
      }
    }
  }, [routeMatch?.params?.message_id])

  // Check if message should display as viewed
  const viewed =
    thread?.direction !== "received" ||
    (thread?.direction === "received" && thread?.viewed)

  return (
    <ListItemWithContent
      onClick={() => handleNavigateToMessage(thread)}
      avatar={thread}
      primary={thread?.name}
      secondary={thread?.message || "No messages"}
      viewed={Boolean(viewed)}
      selected={selectedThread?.thread_id === thread.thread_id}
      className={classnames(classes.listItem, {
        [classes.selected]: selectedThread?.thread_id === thread?.thread_id,
      })}
    >
      <Box display="flex" flexDirection="column" flexShrink={0}>
        <span className={classes.dateText}>
          {cleanDateTime(thread?.date_sent)}
        </span>
        {!viewed && <Badge color="primary" variant="dot" />}
      </Box>
    </ListItemWithContent>
  )
}

MessageThread.propTypes = {
  thread: PropTypes.object,
}

export default MessageThread
