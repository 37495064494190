import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  threadsBox: {
    height: "100%",
    overflow: "auto",
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

export default styles
