import {
  Box,
  Button,
  CircularProgress,
  Container,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import home from "assets/consumer_background.png"
import pin from "assets/icon-feather-map-pin.svg"
import SponsorBar from "components/Citizen/SponsorBar"
import AppContext from "contexts/App"
import CitizenApp from "contexts/Citizen/CitizenApp"
import Page from "pages/Page"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import capitalize from "utils/capitalize"

import useStyles from "./styles"

const Index = () => {
  const classes = useStyles()
  const history = useHistory()
  const {
    autocompleteOptions,
    autocompleteOptionsLoading,
    locationSearch,
    setLocationSearch,
  } = useContext(CitizenApp)

  const { setSearchButtonClickedFromIndex } = useContext(AppContext)

  return (
    <Page>
      <Container
        disableGutters
        maxWidth="xl"
        style={{
          backgroundImage: `url(${home})`,
          margin: 0,
          padding: 0,
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <Box className={classes.contentWrapper}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography
              variant="h1"
              align="left"
              classes={{ root: classes.root }}
              style={{
                fontSize: "4.7rem",
              }}
            >
              Discover How You Can Help Your Community
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              classes={{ root: classes.root }}
            >
              Search, browse and apply for local boards and commissions
            </Typography>
          </Box>
          <Box className={classes.fieldButtonWrapper}>
            {autocompleteOptionsLoading?.isLoading ? (
              <CircularProgress />
            ) : (
              <Autocomplete
                id="list-loc-autocomplete"
                getOptionLabel={(option) => `${option.label}`}
                options={autocompleteOptions || []}
                loading={autocompleteOptionsLoading}
                value={locationSearch}
                popupIcon={null}
                onChange={(event, newValue) => {
                  setLocationSearch(newValue)
                  setSearchButtonClickedFromIndex(true)
                }}
                renderOption={(option) => {
                  return (
                    <Box
                      key={option?.id}
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-end"
                    >
                      <Typography
                        style={{
                          fontWeight: 500,
                          marginRight: "6px",
                        }}
                        variant="body2"
                      >
                        {option?.label}
                      </Typography>
                      <Typography style={{ opacity: 0.4, fontSize: "1rem" }}>
                        {capitalize(option?.type)}
                      </Typography>
                    </Box>
                  )
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      id="internal-field"
                      placeholder="Search by location"
                      className={classes.search}
                      style={{
                        paddingTop: "10px !important",
                        paddingBottom: "12px !important",
                      }}
                      variant="filled"
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginLeft: "10px", marginBottom: "10px" }}
                          >
                            <Icon classes={{ root: classes.iconRoot }}>
                              <img
                                className={classes.imageIcon}
                                src={pin}
                                alt=""
                              />
                            </Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
                classes={{
                  root: classes.autocomplete,
                  inputRoot: classes.inputRoot,
                }}
              />
            )}
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.button}
              onClick={() => {
                if (locationSearch) {
                  history.push("/boards")
                }
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
        <Box className={classes.sponsorContainer}>
          <SponsorBar />
        </Box>
      </Container>
    </Page>
  )
}

export default Index
