import ThreadList from "components/Common/Messages/ThreadList"
import PropTypes from "prop-types"

import DashboardCard from "../DashboardCard"
import useStyles from "./styles"

const MessageThreadList = ({ children }) => {
  const classes = useStyles()

  return (
    <DashboardCard>
      <ThreadList />
    </DashboardCard>
  )
}

MessageThreadList.propTypes = {
  children: PropTypes.node,
}

export default MessageThreadList
