import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core"
import BoardDetailsCheckboxes from "components/Clerk/Boards/BoardDetailsCheckboxes"
import BoardDetailsQuestions from "components/Clerk/Boards/BoardDetailsQuestions"
import ButtonRounded from "components/Common/ButtonRounded"
import CharacterCounter from "components/Common/CharacterCounter"
import AppContext from "contexts/App"
import { Form, Formik } from "formik"
import { isEmpty } from "lodash-es"
import PropTypes from "prop-types"
import { useContext, useEffect, useRef, useState } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import normalizeString from "utils/normalizeString"
import * as Yup from "yup"

import useStyles from "./styles"

const schema = Yup.object().shape({
  name: Yup.string()
    .max(255, "Name must be less/equal than 255 characters")
    .required("Required"),
  summary: Yup.string()
    .max(2000, "Summary must be less/equal than 2000 characters")
    .required("Required"),
  term: Yup.string().required("Required"),
  appointing_authority: Yup.string().required("Required"),
  enabling_legislation: Yup.string()
    .max(500, "Enabling Legislation must be less/equal than 500 characters")
    .nullable(true),
  reappointment: Yup.string().required("Required"),
  app_type: Yup.string().required("Required"),
  board_url: Yup.string()
    .max(255, "Board Url must be less/equal than 255 characters")
    .nullable(true),
  minutes_url: Yup.string()
    .max(255, "Minutes Url must be less/equal than 255 characters")
    .nullable(true),
  minimum_degree: Yup.string(),
  occupation_required: Yup.boolean().nullable(true),
  interest: Yup.array().min(1, "Select at least one"),
  all_expertise: Yup.boolean().nullable(true),
  expertise: Yup.array()
    .min(1, "Select at least one")
    .when("all_expertise", {
      is: true,
      then: Yup.array().min(0),
    }),
  all_experience: Yup.boolean().nullable(true),
  experience: Yup.array()
    .min(1, "Select at least one")
    .when("all_experience", {
      is: true,
      then: Yup.array().min(0),
    }),
  members: Yup.array(),
  default_questions: Yup.array(),
  additional_questions: Yup.array(),
  active: Yup.bool(),
  application_override: Yup.bool(),
})

const UpdateBoard = ({
  board,
  updateBoardMutation,
  openFrom = "boards",
  handleClose = () => {},
}) => {
  const classes = useStyles()
  const formRef = useRef()
  const { openSnackBar, authUser, isClerk } = useContext(AppContext)
  const queryClient = useQueryClient()
  const [defaultQuestions, setDefaultQuestions] = useState([])
  const [additionalQuestions, setAdditionalQuestions] = useState([])
  const [newQuestion, setNewQuestion] = useState("")
  const [areasOfInterest, setAreasOfInterest] = useState([])
  const [areasOfExperience, setAreasOfExperience] = useState([])
  const [areasOfExpertise, setAreasOfExpertise] = useState([])
  const [showApplicationSwitch, setShowApplicationSwitch] = useState(
    Boolean(!board?.review_period_rolling && board?.app_type !== "invite only")
  )

  //populate interest, expertise and experience arrays when board is loading
  useEffect(() => {
    if (!isEmpty(board)) {
      setAreasOfInterest(board?.interest)
      setAreasOfExperience(board?.experience)
      setAreasOfExpertise(board?.expertise)
      setDefaultQuestions(board?.default_questions?.map((x) => x.id))
      setAdditionalQuestions(board?.additional_questions)
      formRef?.current?.setFieldValue(
        "interest",
        board?.interest?.map((x) => x?.id)
      )
      formRef?.current?.setFieldValue(
        "expertise",
        board?.expertise?.map((x) => x?.id)
      )
      formRef?.current?.setFieldValue(
        "experience",
        board?.experience?.map((x) => x?.id)
      )
    }
  }, [board])

  //update board needs some changes before the call
  const onSubmit = () => {
    let data = formRef?.current?.values
    data.experience = areasOfExperience.map((x) => {
      if (typeof x === "object") {
        return x.id
      } else {
        return x
      }
    })
    data.expertise = areasOfExpertise.map((x) => {
      if (typeof x === "object") {
        return x.id
      } else {
        return x
      }
    })
    data.interest = areasOfInterest.map((x) => {
      if (typeof x === "object") {
        return x.id
      } else {
        return x
      }
    })
    data.default_questions = defaultQuestions.map((x) => {
      if (typeof x === "object") {
        return x.id
      } else {
        return x
      }
    })
    delete data.additional_questions
    delete data.board_members
    updateBoardMutation
      .mutateAsync(data)
      .then((res) => {
        openSnackBar({ message: "Board updated" })
        serialize("board", res.board).then((serializedData) => {
          return queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldState) => {
              let newData = oldState?.map((x) => {
                if (x.id === serializedData.id) {
                  return serializedData
                } else {
                  return x
                }
              })
              return newData
            }
          )
        })
        if (openFrom === "entity") {
          serialize("board", res.board).then((serializedData) => {
            return queryClient.setQueryData(
              ["entityBoardsData", board?.entity_id],
              (oldState) => {
                let newData = oldState?.map((x) => {
                  if (x.id === serializedData.id) {
                    return serializedData
                  } else {
                    return x
                  }
                })
                return newData
              }
            )
          })
        }
        setShowApplicationSwitch(
          Boolean(
            !res.board?.review_period_rolling &&
              res.board?.app_type !== "invite only"
          )
        )
        handleClose()
      })
      .catch((err) => {
        openSnackBar({ message: "Error updating board" })
      })
  }

  // old data will say "no limit", fn runs to convert to new uniform value of "no term limit"
  // runs as part of initial values
  // once saved over with new value, won't get into the If statement again
  const termLimit = () => {
    if (normalizeString(board?.term) === "no limit") {
      return "no term limit"
    } else {
      return normalizeString(board?.term)
    }
  }

  return (
    <Formik
      initialValues={{
        ...board,
        term: termLimit(),
        appointing_authority: normalizeString(board?.appointing_authority),
        app_type: normalizeString(board?.app_type),
        minimum_degree: normalizeString(board?.minimum_degree),
        reappointment: normalizeString(board?.reappointment),
      }}
      validationSchema={schema}
      innerRef={formRef}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        values,
        errors,
        touched,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }) => {
        return (
          <Form onSubmit={handleSubmit} className={classes.form}>
            <Box
              className={
                openFrom === "entity"
                  ? classes.scrollAreaEntity
                  : classes.scrollArea
              }
            >
              <Box className={classes.inputBox}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className={classes.label}>Board Name</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(values?.active)}
                        onChange={handleChange}
                        name="active"
                      />
                    }
                    label={
                      <Typography
                        className={
                          values?.active === false ? classes.redText : null
                        }
                      >
                        {values?.active ? "Active" : "Inactive"}
                      </Typography>
                    }
                  />
                </Box>
                <TextField
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.name}
                  style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
                  variant="outlined"
                  fullWidth
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Box>
              <Box className={classes.inputBox}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography className={classes.label}>
                    Summary of Function
                  </Typography>
                  <CharacterCounter
                    currentValue={values?.summary?.length}
                    length={2000}
                  />
                </Box>

                <TextField
                  type="text"
                  name="summary"
                  id="summary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.summary}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
                  helperText={touched.summary && errors.summary}
                  error={Boolean(touched.summary && errors.summary)}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
                {showApplicationSwitch && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={Boolean(values?.application_override)}
                        onChange={handleChange}
                        name="application_override"
                      />
                    }
                    label={
                      <Typography>
                        {values?.application_override
                          ? "Application Open"
                          : "Application Closed"}
                      </Typography>
                    }
                  />
                )}
              </Box>
              <Box className={classes.inputBox}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Term Length
                    </Typography>
                    <TextField
                      select
                      name="term"
                      value={values?.term}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      helperText={touched.term && errors.term}
                      error={Boolean(touched.term && errors.term)}
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    >
                      <MenuItem value={"1 year"}>1 year</MenuItem>
                      <MenuItem value={"2 years"}>2 years</MenuItem>
                      <MenuItem value={"3 years"}>3 years</MenuItem>
                      <MenuItem value={"4 years"}>4 years</MenuItem>
                      <MenuItem value={"5 years"}>5 years</MenuItem>
                      <MenuItem value={"6 years"}>6 years</MenuItem>
                      <MenuItem value={"coterminous"}>Coterminous</MenuItem>
                      <MenuItem value={"no term limit"}>No term limit</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Appointing Authority
                    </Typography>
                    <TextField
                      select
                      name="appointing_authority"
                      value={values.appointing_authority}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      helperText={
                        touched.appointing_authority &&
                        errors.appointing_authority
                      }
                      error={Boolean(
                        touched.appointing_authority &&
                          errors.appointing_authority
                      )}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    >
                      <MenuItem value={"council"}>Council</MenuItem>
                      <MenuItem value={"governor"}>Governor</MenuItem>
                      <MenuItem value={"outside organizations"}>
                        Outside Organizations
                      </MenuItem>
                      <MenuItem value={"board/commission members"}>
                        Board/Commission Members
                      </MenuItem>
                      <MenuItem value={"legislative delegation"}>
                        Legislative Delegation
                      </MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={6} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Enabling Legislation
                    </Typography>
                    <TextField
                      type="text"
                      name="enabling_legislation"
                      id="enabling_legislation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Paste here"
                      value={values.enabling_legislation}
                      variant="outlined"
                      fullWidth
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      helperText={
                        touched.enabling_legislation &&
                        errors.enabling_legislation
                      }
                      error={Boolean(
                        touched.enabling_legislation &&
                          errors.enabling_legislation
                      )}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box className={classes.inputBox}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={3} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Re-Appointment
                    </Typography>
                    <TextField
                      select
                      value={values.reappointment}
                      onChange={(e) =>
                        setFieldValue("reappointment", e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      helperText={touched.reappointment && errors.reappointment}
                      error={Boolean(
                        touched.reappointment && errors.reappointment
                      )}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    >
                      <MenuItem value={"not permitted"}>Not Permitted</MenuItem>
                      <MenuItem value={"2 terms"}>2 Terms</MenuItem>
                      <MenuItem value={"3 terms"}>3 Terms</MenuItem>
                      <MenuItem value={"4 terms"}>4 Terms</MenuItem>
                      <MenuItem value={"5 terms"}>5 Terms</MenuItem>
                      <MenuItem value={"no limits"}>No Limits</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={6} sm={3} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Type of App
                    </Typography>
                    <TextField
                      select
                      value={values.app_type}
                      onChange={(e) =>
                        setFieldValue("app_type", e.target.value)
                      }
                      variant="outlined"
                      fullWidth
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      helperText={touched.app_type && errors.app_type}
                      error={Boolean(touched.app_type && errors.app_type)}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    >
                      <MenuItem value={"open"}>Open</MenuItem>
                      <MenuItem value={"invite only"}>Invite Only</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={6} className={classes.gridItem}>
                    <Typography className={classes.label}>Board Url</Typography>
                    <TextField
                      type="text"
                      name="board_url"
                      id="boardUrl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.board_url}
                      variant="outlined"
                      fullWidth
                      placeholder="Paste here"
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      helperText={touched.board_url && errors.board_url}
                      error={Boolean(touched.board_url && errors.board_url)}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Minutes Url
                    </Typography>
                    <TextField
                      type="text"
                      name="minutes_url"
                      id="minutesUrl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.minutes_url}
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      variant="outlined"
                      fullWidth
                      placeholder="Paste here"
                      helperText={touched.minutes_url && errors.minutes_url}
                      error={Boolean(touched.minutes_url && errors.minutes_url)}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Minimum Degree
                    </Typography>
                    <TextField
                      select
                      name="minimum_degree"
                      value={values.minimum_degree}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      helperText={Boolean(
                        touched.minimum_degree && errors.minimum_degree
                      )}
                      error={touched.minimum_degree && errors.minimum_degree}
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    >
                      <MenuItem value={"high school or equivalent"}>
                        High School or Equivalent{" "}
                      </MenuItem>
                      <MenuItem value={"professional certificate"}>
                        Professional Certificate
                      </MenuItem>
                      <MenuItem value={"associates"}>Associates</MenuItem>
                      <MenuItem value={"bachelor's"}>{"Bachelor’s"}</MenuItem>
                      <MenuItem value={"master's"}>{"Master's"}</MenuItem>
                      <MenuItem value={"ph.d"}>Ph.D </MenuItem>
                      <MenuItem value={"md"}>MD</MenuItem>
                      <MenuItem value={"jd"}>JD</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={3} className={classes.gridItem}>
                    <Typography className={classes.label}>
                      Occupation Required
                    </Typography>
                    <TextField
                      select
                      name="occupation_required"
                      value={values.occupation_required}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      helperText={
                        touched.occupation_required &&
                        errors.occupation_required
                      }
                      error={Boolean(
                        touched.occupation_required &&
                          errors.occupation_required
                      )}
                      style={{
                        backgroundColor: "#F8F8F8",
                        marginBottom: "25px",
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    >
                      <MenuItem value={1}>True </MenuItem>
                      <MenuItem value={0}>False</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Box>

              <BoardDetailsQuestions
                defaultQuestions={defaultQuestions}
                setDefaultQuestions={setDefaultQuestions}
                additionalQuestions={additionalQuestions}
                setAdditionalQuestions={setAdditionalQuestions}
                newQuestion={newQuestion}
                setNewQuestion={setNewQuestion}
                boardId={board?.id}
              />
              <BoardDetailsCheckboxes
                type="Interest"
                areasArray={areasOfInterest}
                setAreasArray={setAreasOfInterest}
                errors={errors}
              />
              <BoardDetailsCheckboxes
                type="Expertise"
                areasArray={areasOfExpertise}
                setAreasArray={setAreasOfExpertise}
                errors={errors}
              />
              <BoardDetailsCheckboxes
                type="Experience"
                areasArray={areasOfExperience}
                setAreasArray={setAreasOfExperience}
                errors={errors}
              />
            </Box>
            <Box className={classes.buttonBox}>
              <ButtonRounded
                variant="outlined"
                color="primary"
                type="submit"
                style={{ width: "150px" }}
                disabled={updateBoardMutation?.isLoading}
              >
                {updateBoardMutation?.isLoading ? (
                  <CircularProgress size={25} className={classes.spinner} />
                ) : (
                  "Save Changes"
                )}
              </ButtonRounded>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

UpdateBoard.propTypes = {
  board: PropTypes.object,
  updateBoardMutation: PropTypes.object,
  openFrom: PropTypes.string,
  handleClose: PropTypes.func,
}

export default UpdateBoard
