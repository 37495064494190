import {
  approveRequest,
  declineRequest,
  requestsDataList,
} from "actions/Clerk/requests"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const RequestsContext = createContext()
export default RequestsContext

export const RequestsContainer = ({ children }) => {
  const { data: requestsData, isLoading: requestsDataLoading } = useQuery(
    "requestsData",
    requestsDataList,
    { retry: false }
  )

  return (
    <RequestsContext.Provider
      value={{
        requestsData,
        requestsDataLoading,
      }}
    >
      {children}
    </RequestsContext.Provider>
  )
}

RequestsContainer.propTypes = {
  children: PropTypes.node,
}
