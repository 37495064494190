import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  select: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    color: "#F84738",
  },
  noError: {
    height: "20px",
  },
}))

export default styles
