import {
  AppBar,
  Avatar,
  Box,
  Card,
  CircularProgress,
  Drawer,
  Grid,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { sendReview } from "actions/Clerk/reviews"
import logo from "assets/logo-icon.svg"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import { isEmpty } from "lodash-es"
import PropTypes from "prop-types"
import { useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import getScaledImage from "utils/getScaledImage"

import RevieweeInfo from "./RevieweeInfo"
import RevieweesList from "./RevieweesList"
import useStyles from "./styles"

const InternalReviewContent = ({ data, loading, token }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))
  const [selectedReviewee, setSelectedReviewee] = useState(null)
  const [completed, setCompleted] = useState(false)
  const { openSnackBar } = useContext(AppContext)

  const [answers, setAnswers] = useState([])

  useEffect(() => {
    if (!mobileView) {
      setSelectedReviewee(data?.applicants?.[0])
    }
  }, [data])

  useEffect(() => {
    setAnswers(
      data?.applicants.map((x) => {
        return {
          application_id: x.application_id,
          answer: {},
        }
      })
    )
  }, [data?.applicants])

  const sendReviewMutation = useMutation(sendReview)

  const reviewAnswered = useMemo(() => {
    let answersLenght = answers?.filter((x) => {
      if (!isEmpty(x.answer)) {
        return x
      }
    }).length
    if (answersLenght === data?.applicants?.length) {
      return true
    } else {
      return false
    }
  }, [answers])

  const submitReview = () => {
    if (reviewAnswered) {
      let data = {
        token: token,
        reviewer_responses: answers?.map((x) => {
          return {
            applicant_id: x?.application_id,
            review: x?.answer?.id,
          }
        }),
      }
      sendReviewMutation
        ?.mutateAsync(data)
        .then((res) => {
          setCompleted(true)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      openSnackBar({ message: "Answer every review" })
    }
  }

  const scaledImage = (img) => {
    return getScaledImage(img, 200, 200)
  }

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="100%"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Box height="100%">
        <AppBar
          position="static"
          color="secondary"
          elevation={0}
          className={classes.root}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.logoBox}>
              {/* need the entity image, e.g., seal of county/muni */}
              {/* <Logo style={{ width: 150 }} /> */}
              <Avatar
                src={
                  data?.entity?.entity_image
                    ? scaledImage(data?.entity?.entity_image)
                    : logo
                }
                alt={data?.entity?.entity}
              />
            </Box>
            <Box flexGrow="1" />
          </Toolbar>
          <Box className={classes.dataBar}>
            <Box className={classes.boardData}>
              <Box className={classes.boardName}>
                <Typography variant="subtitle1" color="primary">
                  Board Name
                </Typography>
                <Typography variant="h6">{data?.review?.name}</Typography>
              </Box>
              <Box className={classes.boardTerm}>
                <Typography variant="subtitle1" color="primary">
                  Term
                </Typography>
                <Typography variant="h6">{data?.review?.term}</Typography>
              </Box>
              <Box flexGrow={1} />
              {!completed && data?.review?.review_complete === 0 && (
                <Box className={classes.buttonContainer}>
                  <ButtonRounded
                    variant="outlined"
                    onClick={submitReview}
                    disabled={sendReviewMutation?.isLoading}
                  >
                    {sendReviewMutation?.isLoading ? (
                      <Box width="100px">
                        <CircularProgress size={15} />
                      </Box>
                    ) : (
                      "Submit Reviews"
                    )}
                  </ButtonRounded>
                </Box>
              )}
            </Box>
          </Box>
        </AppBar>
        {completed || data?.review?.review_complete ? (
          <Box display="flex" justifyContent="center" marginTop="50px">
            <Typography variant="h2">Review completed</Typography>
          </Box>
        ) : (
          <Grid container className={classes.gridContainer}>
            <Grid item sm={6} xs={12} className={classes.gridItem}>
              <Card style={{ borderRadius: 0 }}>
                <Box
                  height="80px"
                  display="flex"
                  alignItems="center"
                  width="90%"
                  margin="0 auto"
                >
                  <Typography
                    className={classes.showingText}
                  >{`Showing ${data?.applicants?.length}`}</Typography>
                </Box>
                <RevieweesList
                  reviewees={data?.applicants}
                  selected={selectedReviewee}
                  setSelected={setSelectedReviewee}
                  answers={answers}
                />
              </Card>
            </Grid>
            {!mobileView ? (
              <Grid
                item
                sm={6}
                className={classes.gridItem}
                style={!selectedReviewee ? { display: "none" } : null}
              >
                <Card style={{ borderRadius: 0 }}>
                  <RevieweeInfo
                    selected={selectedReviewee}
                    options={data?.options}
                    answers={answers}
                    setAnswers={setAnswers}
                    answer={answers?.filter(
                      (x) =>
                        x.application_id === selectedReviewee?.application_id
                    )}
                    token={token}
                    allCategory={{
                      all_experience: data?.review?.all_experience,
                      all_expertise: data?.review?.all_expertise,
                      all_interest: data?.review?.all_interest,
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Drawer
                anchor="bottom"
                open={selectedReviewee}
                style={{ height: "80%" }}
                classes={{ paperAnchorBottom: classes.drawer }}
              >
                <Box
                  className={classes.collapseLine}
                  onClick={() => setSelectedReviewee(null)}
                />
                {/* <Button onClick={() => setSelectedReviewee(null)}>Back</Button> */}
                <RevieweeInfo
                  selected={selectedReviewee}
                  options={data.options}
                  answers={answers}
                  setAnswers={setAnswers}
                  answer={answers?.filter(
                    (x) => x.application_id === selectedReviewee?.application_id
                  )}
                  token={token}
                  allCategory={{
                    all_experience: data?.review?.all_experience,
                    all_expertise: data?.review?.all_expertise,
                    all_interest: data?.review?.all_interest,
                  }}
                />
              </Drawer>
            )}
          </Grid>
        )}
      </Box>
    )
  }
}

InternalReviewContent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  token: PropTypes.string,
}

export default InternalReviewContent
