import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  table: {
    borderRadius: "6px",
  },
  head: {
    backgroundColor: "#898989",
  },
  row: {
    width: "100%",
  },
  cell: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
}))

export default styles
