import api from "store/api"
import serialize from "store/serialize"

export const getApplicants = (entityId) => {
  return api
    .get(`/admin/entities/${entityId}/applicants`)
    .then((res) => res.data.data)
}

export const favoriteApplicant = (data) => {
  const { boardId, applicationId } = data
  return api
    .put(`/admin/boards/${boardId}/applicants/${applicationId}/favorite`)
    .then((res) => res.data.data)
}
//hide appointed status
export const getStatusList = () => {
  return api
    .get(`/admin/boards/status-list`)
    .then((res) => res.data.data.statuses)
}

export const updateApplicantStatus = (moreData) => {
  const { boardId, applicationId } = moreData
  let data = { status_id: moreData?.status_id }
  return api
    .put(`/admin/boards/${boardId}/applicants/${applicationId}/status`, data)
    .then((res) => res.data.data)
}

export const getApplicantApplications = (entityId, userId) => {
  return api
    .get(`/admin/entities/${entityId}/applicants/${userId}`)
    .then((res) => res.data.data)
}

export const getBoardApplicantById = (data) => {
  const { boardId, applicationId } = data
  return api
    .get(`/admin/boards/${boardId}/applicants/${applicationId}`)
    .then((res) => serialize("applicantResponse", res.data.data))
}

// export const getApplicationPdf = (applicationId, data) => {
//   window.open(
//     `${process.env.REACT_APP_API_ENDPOINT}admin/boards/application/${applicationId}?fields=${data}`
//   )
// }

export const deferApplicationsOnAppoint = (data) => {
  const entityId = data?.entity_id
  const moreData = {
    board_id: data?.board_id,
    user_id: data?.user_id,
  }
  return api
    .post(`/admin/entities/${entityId}/defer`, moreData)
    .then((res) => res)
}
