import { Box } from "@material-ui/core"
import MembersList from "components/Clerk/Boards/MembersList"
import Page from "pages/Page"

import useStyles from "./styles"

const Members = () => {
  const classes = useStyles()

  return (
    <Page>
      <Box className={classes.container}>
        <MembersList />
      </Box>
    </Page>
  )
}

export default Members
