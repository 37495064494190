import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      "& .infinite-scroll-component__outerdiv": {
        width: "100%",
      },
    },
  }),
  {
    name: "ApplicantHistory",
  }
)

export default styles
