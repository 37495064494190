import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  contentWrapper: {
    width: "30%",
    height: "80%",
    display: "flex",
    marginTop: "50px",
    marginLeft: "100px",
    marginRight: "100px",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "10%",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
      margin: "0 auto",
    },
  },
  root: {
    color: "#FFFFFF",
    marginBottom: theme.spacing(4),
  },
  search: {
    borderRadius: "4px",
  },
  button: {
    width: "25%",
    [theme.breakpoints.down("350")]: {
      marginTop: theme.spacing(2),
    },
  },
  fieldButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.down("350")]: {
      flexWrap: "wrap",
    },
  },
  autocomplete: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("350")]: {
      marginRight: 0,
    },
  },
  inputRoot: {
    // maxWidth: "240px",
    // minWidth: "200px",
    // width: "100%",
    paddingTop: "0 !important",
    paddingRight: "20px !important",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "240px",
      minWidth: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  inputBackgroundColor: {
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
  sponsorContainer: {
    width: "90%",
    display: "flex",
    justifyContent: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
    },
  },
}))

export default styles
