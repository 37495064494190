import api from "store/api"

export const requestClerkData = () => {
  return api.get("/admin/users/register").then((res) => res.data.data)
}

export const checkPhone = ({ phone }) => {
  return api.get(`/admin/users/phone/${phone}`).then((res) => res.data)
}

export const checkEmail = ({ email }) => {
  return api.get(`/admin/users/email/${email}`).then((res) => res.data)
}

export const checkEntityAvailability = (entityId) => {
  return api
    .get(`/admin/entities/availability/${entityId}`)
    .then((res) => res.data)
}

export const clerkRequest = (data) => {
  return api.post(`/admin/users/`, data).then((res) => res.data)
}
