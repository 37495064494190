import api from "store/api"
import serialize from "store/serialize"

export const updateDetails = (data) => {
  return api.put(`/app/users/details`, data).then((res) => res.data)
}

export const updateContact = (data) => {
  return api.put(`/app/users/contact`, data).then((res) => res.data)
}

export const updatePosition = (data) => {
  return api.put(`/app/users/position`, data).then((res) => res.data)
}

export const updateEducation = (data) => {
  return api.put(`/app/users/education`, data).then((res) => res.data)
}

export const updateReferences = (data) => {
  return api.put(`/app/users/references`, data).then((res) => res.data)
}

export const updateExpertise = (data) => {
  return api.put(`/app/users/expertise`, data).then((res) => res.data)
}

export const updateInterest = (data) => {
  return api.put(`/app/users/interest`, data).then((res) => res.data)
}
export const updateExperience = (data) => {
  return api.put(`/app/users/experience`, data).then((res) => res.data)
}

export const updateResume = (data) => {
  return api.put(`/app/users/resume`, data).then((res) => res.data)
}

export const updateProfileImage = (data) => {
  return api.put(`/app/users/profile-image`, data).then((res) => res.data)
}

export const getOptions = () => {
  return api.get("/app/users/options").then((res) => res.data.data)
}

export const getAutocompleteOptions = () => {
  return api.get(`/app/entities/`).then((res) => res.data.data.list)
}
export const getResume = (resumeId) => {
  return api
    .get(`/app/users/file/`, {
      params: {
        id: resumeId,
      },
    })
    .then((res) => res.data.data)
}
