import {
  getOptions,
  getResume,
  updateContact,
  updateDetails,
  updateEducation,
  updateExperience,
  updateExpertise,
  updateInterest,
  updatePosition,
  updateProfileImage,
  updateReferences,
  updateResume,
} from "actions/Citizen/account"
import { updateNewUser } from "actions/Citizen/users"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { createContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

const AccountContext = createContext()
export default AccountContext

export const AccountContainer = ({ children, ...props }) => {
  const updateDetailsMutation = useMutation(updateDetails)
  const updateContactMutation = useMutation(updateContact)
  const updateEducationMutation = useMutation(updateEducation)
  const updateReferencesMutation = useMutation(updateReferences)
  const updatePositionMutation = useMutation(updatePosition)
  const updateInterestMutation = useMutation(updateInterest)
  const updateExpertiseMutation = useMutation(updateExpertise)
  const updateExperienceMutation = useMutation(updateExperience)
  const updateNewUserMutation = useMutation(updateNewUser)
  const updateProfileImageMutation = useMutation(updateProfileImage)
  const getResumeMutation = useMutation(getResume)

  const { data: options, isLoading: optionsLoading } = useQuery("options", () =>
    getOptions()
  )

  return (
    <AccountContext.Provider
      value={{
        updateDetailsMutation,
        updateContactMutation,
        updateEducationMutation,
        updateReferencesMutation,
        updatePositionMutation,
        updateInterestMutation,
        updateExpertiseMutation,
        updateExperienceMutation,
        options,
        optionsLoading,
        updateNewUserMutation,
        getResumeMutation,
        updateProfileImageMutation,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

AccountContainer.propTypes = {
  children: PropTypes.node,
}
