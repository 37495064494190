import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core"
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"
import App from "contexts/App"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"
import PropTypes from "prop-types"
import { useEffect, useRef } from "react"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import serialize from "store/serialize"
import * as Yup from "yup"

import useStyles from "./styles"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required(`Required`),
  password: Yup.string().required("Password is required"),
})
const LoginForm = ({ signinClick, forgotClick, boardPath }) => {
  const history = useHistory()
  const classes = useStyles()
  const queryClient = useQueryClient()

  // for show/conceal password field
  const [visibility, setVisibility] = useState(true)
  const authContext = useContext(App)
  const { closeDialog, loginMutation, isClerk, openDialog } = useContext(App)

  const formRef = useRef()

  const onSubmit = (values, actions) => {
    let submissionValues = { data: values, isClerk: isClerk }
    loginMutation.mutate(submissionValues, {
      onSuccess: (res) => {
        serialize("user", res.user)
          .then(async (serializedData) => {
            await authContext.setAuthData({
              token: res.token,
              account: serializedData,
            })
            authContext.openSnackBar({
              message: "Login successful!",
            })
            if (localStorage.getItem("board") !== "") {
              history.push(localStorage.getItem("board"))
            }
            //if the user is in the app close the dialog and redirect to my account
            if (!isClerk) {
              closeDialog("login")
              if (!history?.location?.pathname?.includes("boards")) {
                history.push("/boards")
              }
            }
            return queryClient.setQueryData("user", (oldState) => {
              return serializedData
            })
          })
          .catch((err) => {
            authContext.setAuthData({
              token: res.token,
              account: res.account,
            })
          })
      },
      onError: (err) => {
        console.error(err)
        authContext.openSnackBar({
          message: "Incorrect email address or password",
        })
      },
    })
    actions.setSubmitting(false)
  }

  const navToCitizenLogin = () => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      window.open("http://localhost:3000/citizen/")
    } else if (process.env.REACT_APP_NODE_ENV === "staging") {
      window.open("https://app.matchboard.dev/")
    } else {
      window.open("https://app.matchboard.tech/")
    }
  }

  const navToJoin = () => {
    if (process.env.REACT_APP_NODE_ENV === "local") {
      window.open("http://localhost:3000/clerk/join")
    } else if (process.env.REACT_APP_NODE_ENV === "staging") {
      window.open("https://admin.matchboard.dev/join")
    } else {
      window.open("https://admin.matchboard.tech/join")
    }
  }

  const clerkForgot = () => {
    return openDialog("clerkResetPassword")
  }

  useEffect(() => {
    if (authContext && authContext.token && isClerk) {
      if (authContext?.authUser?.super_admin === 1) {
        history.push("/requests")
      } else {
        history.push("/dashboard")
      }
    }
  }, [authContext])

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={onSubmit}
        validationSchema={loginSchema}
        innerRef={formRef}
      >
        {({ isSubmitting, handleSubmit }) => (
          <div className={classes.inputContainer}>
            <Form onSubmit={handleSubmit}>
              <Field
                component={TextField}
                type="text"
                name="email"
                placeholder="Email"
                label="Email"
                className={classes.topTextField}
                variant="outlined"
                fullWidth
                disabled={loginMutation?.isLoading}
                data-cy="login-form-email"
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
              <Field
                component={TextField}
                type={visibility ? "password" : "text"}
                name="password"
                placeholder="Password"
                label="Password"
                variant="outlined"
                size="small"
                fullWidth
                disabled={loginMutation?.isLoading}
                data-cy="login-form-password"
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => setVisibility(!visibility)}
                      >
                        {visibility ? (
                          <VisibilityOutlined
                            color="primary"
                            fontSize="small"
                          />
                        ) : (
                          <VisibilityOffOutlined
                            color="primary"
                            fontSize="small"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" flexDirection="row-reverse">
                {!isClerk ? (
                  <Typography
                    variant="caption"
                    className={classes.forgotPassword}
                    onClick={forgotClick}
                  >
                    Forgot Password?
                  </Typography>
                ) : null}
              </Box>
              <Box display="flex" flexDirection="row-reverse" marginBottom={2}>
                {isClerk ? (
                  <Typography
                    variant="caption"
                    className={classes.forgotPassword}
                    onClick={clerkForgot}
                  >
                    Forgot Password?
                  </Typography>
                ) : null}
              </Box>
              <Button
                data-cy="login-form-submit-button"
                type="submit"
                className={classes.loginButton}
                variant="contained"
                color="primary"
                fullWidth
              >
                {loginMutation?.isLoading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  <Typography variant="button">Log in</Typography>
                )}
              </Button>
              {isClerk ? (
                <Box display="flex" flexDirection="column" marginTop={2}>
                  <Box display="flex" flexShrink={0} alignSelf="flex-end">
                    <Typography
                      className={classes.notAdmin}
                      onClick={navToCitizenLogin}
                    >
                      Not an Administrator? Click Here!
                    </Typography>
                  </Box>
                  <Box display="flex" flexShrink={0} alignSelf="flex-end">
                    <Typography
                      className={classes.notAdmin}
                      onClick={navToJoin}
                    >
                      Register Your Entity Here!
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </Form>
          </div>
        )}
      </Formik>
      {!isClerk && (
        <>
          {/* <Box className={classes.orContainer}>
            <Typography>OR</Typography>
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.googleButton}
            startIcon={
              <Box
                bgcolor="white"
                height="15px"
                width="15px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
              >
                <GoogleLogo
                  style={{ height: 11.5, position: "relative", top: -0.5 }}
                />
              </Box>
            }
          >
            Log in with google
          </Button> */}
          <Box
            display="flex"
            justifyContent="center"
            marginTop="30px"
            marginBottom={2}
          >
            <Button
              size="small"
              onClick={signinClick}
              disableRipple
              className={classes.signupButton}
              data-cy="login-form-register-button"
            >
              Don’t have an account? Sign Up Here
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

LoginForm.propTypes = {
  signinClick: PropTypes.func,
  forgotClick: PropTypes.func,
  boardPath: PropTypes.string,
}

export default LoginForm
