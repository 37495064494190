import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  textField: {
    marginBottom: "24px",
  },
  buttonRoot: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: "1.2rem",
    minHeight: 45,
  },
  orContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3, 0),
    backgroundImage: `linear-gradient(to right, ${theme.palette.divider} 30%, ${theme.palette.divider} 0%)`,
    backgroundPosition: "center",
    backgroundSize: "10px 1px",
    backgroundRepeat: "repeat-x",
    "& p": {
      display: "inline-block",
      padding: theme.spacing(0, 1),
      width: "auto",
      fontSize: "1rem",
      fontFamily: ["Roboto Mono", "monospace"].join(","),
      textAlign: "center",
      backgroundColor: "#fff",
    },
  },
  googleButton: {
    backgroundColor: "#4285F4",
    fontSize: "1.2rem",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: 500,
    minHeight: 42,
    "&:hover": {
      backgroundColor: "#3164b9",
    },
  },
  signupButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))

export default styles
