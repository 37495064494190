const ApplicantModel = {
  position: "array",
  profile_image: "array",
  references: "array",
  interest: "array",
  expertise: "array",
  experience: "array",
  admin_emails: "array",
  default_answers: "array",
  additional_answers: "array",
  user_experience: "array",
  user_interest: "array",
  user_expertise: "array",
}

export default ApplicantModel
