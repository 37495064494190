import { Box } from "@material-ui/core"

import useStyles from "./styles"
import UploadInfoCard from "./UploadInfoCard"

const UploadInformationContainer = () => {
  const classes = useStyles()

  const typeList = [
    { type: "resume", label: "Resume" },
    { type: "position", label: "Occupation/ Current Position" },
    { type: "highest_education", label: "Education" },
    { type: "references", label: "References" },
  ]

  return (
    <Box className={classes.wrapper}>
      {typeList.map((element, i) => {
        return <UploadInfoCard key={i} element={element} />
      })}
    </Box>
  )
}

UploadInformationContainer.propTypes = {}

export default UploadInformationContainer
