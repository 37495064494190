import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  buttonContainer: {
    border: "1px dashed #A01F62",
    marginRight: theme.spacing(4),
    cursor: "pointer",
  },
}))

export default styles
