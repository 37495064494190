import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    buttonBox: {
      borderTop: "1px solid #70707033",
      display: "flex",
      flexDirection: "row",
    },
    btnLabel: {
      fontSize: "1.25rem",
    },
    container: {
      position: "relative",
    },
    editButton: {
      position: "absolute",
      right: 0,
      top: 0,
      zIndex: 1,
    },
  }),

  {
    name: "MemberEditCard",
  }
)

export default styles
