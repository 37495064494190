import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  textGray: {
    color: "#898989",
  },
  educationText: {
    fontSize: "1.4rem",
  },
  card: {
    // maxWidth: "527px",
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
}))

export default styles
