import { getBoards } from "actions/boards"
import {
  getEntityById,
  getReviewersList,
  updateEntityImage,
} from "actions/Clerk/entities"
import PropTypes from "prop-types"
import { createContext, useContext } from "react"
import { useMutation, useQuery } from "react-query"

import AppContext from "../App"
import EntitiesContext from "./Entities"

const EntityContext = createContext()
export default EntityContext

export const EntityContainer = ({ children, ...props }) => {
  const { isClerk, isSuperAdmin } = useContext(AppContext)

  const entityId = parseInt(props?.entityId)

  const { data: entityData, isLoading: entityDataLoading } = useQuery(
    ["entityData", entityId],
    () => getEntityById(entityId)
  )

  const { data: entityBoards, isLoading: entityBoardsLoading } = useQuery(
    ["entityBoardsData", entityId],
    () => getBoards(entityId, isClerk),
    { retry: false, enabled: Boolean(entityId) }
  )

  const { data: reviewersData, isLoading: reviewersDataLoading } = useQuery(
    ["reviewersData", entityId],
    () => getReviewersList(entityId)
  )

  const updateEntityImageMutation = useMutation((data) =>
    updateEntityImage(data, entityId)
  )

  return (
    <EntityContext.Provider
      value={{
        entityBoards,
        entityBoardsLoading,
        updateEntityImageMutation,
        entityData,
        entityDataLoading,
        reviewersData,
        reviewersDataLoading,
      }}
    >
      {children}
    </EntityContext.Provider>
  )
}

EntityContainer.propTypes = {
  children: PropTypes.node,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
