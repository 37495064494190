import { Box, CircularProgress } from "@material-ui/core"
import PieChartCard from "components/Clerk/Analytics/PieChartCard"
import BoardContext from "contexts/Clerk/Board"
import { useContext } from "react"

const AnalyticsCard = () => {
  const { boardAnalytics, boardAnalyticsLoading } = useContext(BoardContext)

  return (
    <>
      {boardAnalyticsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          height="100%"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box textAlign="center" paddingY={2}>
          <Box paddingY={2}>
            <PieChartCard
              title="Gender"
              graphData={boardAnalytics.genderData}
              board={true}
            />
          </Box>
          <Box paddingY={2}>
            <PieChartCard
              title="Race"
              graphData={boardAnalytics.raceData}
              board={true}
            />
          </Box>
          <Box paddingY={2}>
            <PieChartCard
              title="Age"
              graphData={boardAnalytics.ageRanges}
              board={true}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default AnalyticsCard
