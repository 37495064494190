import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    rigthContainer: {
      height: "100vh",
      // display: "flex",
      // justifyContent: "center",
    },
  },
}))

export default styles
