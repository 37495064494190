import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  InputLabel,
  Typography,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import ClerkAppContext from "contexts/Clerk/ClerkApp"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"
import { useState } from "react"
import { useContext } from "react"

import useStyles from "./styles"
const BoardDetailsCheckboxes = ({
  type,
  areasArray,
  setAreasArray,
  errors,
}) => {
  const [showAll, setShowAll] = useState(false)

  const handleToggleCollapse = () => {
    setShowAll((oldState) => !oldState)
  }
  const classes = useStyles()
  const { options } = useContext(ClerkAppContext)
  const { values, setFieldValue } = useFormikContext()

  const handleChange = (value) => {
    if (
      areasArray?.filter((x) => Number(x?.id) === Number(value?.id)).length > 0
    ) {
      setAreasArray((oldState) => oldState?.filter((x) => x?.id !== value?.id))
      setFieldValue(
        type?.toLowerCase(),
        values?.[type?.toLowerCase()]?.filter((x) => x !== value?.id)
      )
    } else {
      setAreasArray((oldState) => [...oldState, value])
      setFieldValue(type?.toLowerCase(), [
        ...values?.[type?.toLowerCase()],
        value?.id,
      ])
    }
  }

  return (
    <Box className={classes.inputBox}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <InputLabel className={classes.inputLabel}>
          Board Areas of {type}* (
          {values?.[`all_${type.toLowerCase()}`] && type !== "Interest"
            ? areasArray?.length + 1
            : areasArray?.length}
          /
          {type !== "Interest"
            ? options?.[type.toLowerCase() + "s"]?.length + 1
            : options?.[type.toLowerCase() + "s"]?.length}
          )
        </InputLabel>
        <IconButton onClick={handleToggleCollapse}>
          {!showAll ? (
            <KeyboardArrowDownIcon color="primary" />
          ) : (
            <KeyboardArrowUpIcon color="primary" />
          )}
        </IconButton>
      </Box>
      {errors?.[type?.toLowerCase()] ? (
        <Typography className={classes.error}>
          {errors?.[type?.toLowerCase()]}
        </Typography>
      ) : (
        <Box height="15px" />
      )}
      <Collapse in={showAll}>
        <>
          {type !== "Interest" && (
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              onClick={() =>
                setFieldValue(
                  [`all_${type.toLowerCase()}`],
                  !values?.[`all_${type.toLowerCase()}`]
                )
              }
            >
              <Checkbox
                checked={Boolean(values?.[`all_${type.toLowerCase()}`])}
                value={values?.[`all_${type.toLowerCase()}`]}
                inputProps={{
                  "aria-label": `all-${type.toLowerCase()}-types-welcome`,
                }}
              />
              <Typography>{`All ${type} Types Welcome`}</Typography>
            </Box>
          )}
          <Box display="flex" flexWrap="wrap" alignItems="flex-start">
            {options?.[type.toLowerCase() + "s"]?.map((element, i) => {
              return (
                <Box
                  key={i}
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  width="33%"
                  onClick={() => handleChange(element)}
                >
                  <Checkbox
                    checked={
                      areasArray.filter((x) => x.id === element?.id).length > 0
                    }
                    value={element?.id}
                    inputProps={{
                      "aria-label": `checkbox-${type.toLowerCase()}-${
                        element?.name
                      }`,
                    }}
                  />
                  <Typography>{element?.name}</Typography>
                </Box>
              )
            })}
          </Box>
        </>
      </Collapse>
    </Box>
  )
}

BoardDetailsCheckboxes.propTypes = {
  type: PropTypes.string,
  areasArray: PropTypes.array,
  setAreasArray: PropTypes.func,
  errors: PropTypes.object,
}

export default BoardDetailsCheckboxes
