import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
  },
  text: {
    color: "#FFFFFF",
  },
  label: {
    color: theme.palette.primary.dark,
  },
  icon: {
    color: "#FFF",
    marginBottom: theme.spacing(3),
  },
}))

export default styles
