import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      border: "1px solid #E2E2E2",
      borderRadius: "10px",
      height: "100%",
    },
    container: {
      width: 245,
      height: 255,
      margin: theme.spacing(2),
      "&:hover": {
        cursor: "pointer",
      },
      // [theme.breakpoints.down("sm")]: {
      //   width: "100%",
      //   height: "100%",
      // },
    },
    cardMedia: {
      width: "100%",
      minHeight: "56%",
      backgroundColor: "#E2E7EF",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    media: {
      height: 145,
    },
    title: {
      fontWeight: 600,
      lineHeight: 1.2,
    },
    entityImage: {
      height: "120px",
      width: "auto",
      backgroundColor: "#FFFFFF",
      border: "none",
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topTextContainer: {
      marginBottom: theme.spacing(1),
    },
    bottomTextContainer: {
      width: "70%",
      display: "flex",
      marginTop: theme.spacing(1.5),
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
  {
    name: "EntitiesCard",
  }
)

export default styles
