/* eslint-disable indent */

import { Chip } from "@material-ui/core"
import PropTypes from "prop-types"

const TermRenewChip = (member) => {
  const text =
    member?.member?.renewal_interest === 1
      ? "Willing to renew"
      : member?.member?.renew_valid === 0 &&
        member?.member?.renewal_interest === 0
      ? "Not willing to renew"
      : ""

  return (
    <Chip
      variant="outlined"
      color="primary"
      size="small"
      label={text}
      style={{ width: "100%", borderStyle: "hidden" }}
    />
  )
}

TermRenewChip.propTypes = {
  member: PropTypes.object,
}

export default TermRenewChip
