import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    button: {
      marginRight: "2px",
      marginLeft: "2px",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "45%",
      [theme.breakpoints.down("xs")]: {
        width: "20%",
        justifyContent: "flex-end",
      },
    },
  }),
  {
    name: "ReplacementMemberCard",
  }
)

export default styles
