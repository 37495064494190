import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  tableContainer: {
    margin: theme.spacing(1),
    // [theme.breakpoints.down("sm")]: {
    //   height: "70%",
    // },
  },
}))

export default styles
