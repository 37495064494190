import api from "store/api"
import serialize from "store/serialize"

export const getMe = (isClerk) => {
  const url = isClerk ? `/admin/users/me` : `/app/users/me`
  return api.get(url).then((res) => serialize("user", res.data.user))
}

export const login = (values) => {
  const { data, isClerk } = values
  if (isClerk) {
    return api.post("/admin/users/auth", data).then((res) => res.data)
  } else {
    return api.post("/app/users/auth", data).then((res) => res.data)
  }
}

export const updateUserProfile = (data) => {
  return api.put("/admin/users", data).then((res) => res.data)
}

export const updatePassword = (data) => {
  return api.put("/admin/users/password", data).then((res) => res.data)
}

export const getPronouns = (isClerk) => {
  const url = isClerk ? `/admin/users/pronouns` : `/app/users/pronouns`
  return api.get(url).then((res) => res.data)
}
