import { Box, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const NoData = ({ image, title, subtitle, props, imgWidth }) => {
  const classes = useStyles()

  const imageWidth = imgWidth ? imgWidth : "212px"

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      style={{
        ...props,
      }}
    >
      <img
        src={image}
        alt="no boards"
        style={{ width: imageWidth }}
        className={classes.noDataImage}
      />
      <Typography className={classes.noDataTitle} variant="h5">
        {title}
      </Typography>
      {subtitle && <Typography varaint="body1">{subtitle}</Typography>}
    </Box>
  )
}

NoData.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imgWidth: PropTypes.string,
  props: PropTypes.object,
}

export default NoData
