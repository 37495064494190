const transformCaseAndSpaces = (string) => {
  if (string) {
    let target = string
    if (target.includes("/")) {
      // preserve slash, but capitalize words bookending it & pass that forward to next step
      const splitHyphen = target.trim().split(/\//)
      target = splitHyphen
        .map((w) => w.charAt(0)?.toUpperCase() + w.slice(1))
        .join("/")
    }
    // handle remaining capitalization on words delimited by - or space
    const splitString = target.trim().split(/-| /)
    return splitString
      .map((w) => w.charAt(0)?.toUpperCase() + w?.slice(1))
      .join(" ")
  } else {
    return ""
  }
}

export default transformCaseAndSpaces
