import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import noBoards from "assets/no-boards-icon.png"
import NoData from "components/Common/NoData"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory } from "react-router-dom"

import IframeBoardCard from "../IframeBoardCard"
import useStyles from "./styles"

const IframeBoardList = ({ data, loading }) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("xs"))

  const [displayedBoards, setDisplayedBoards] = useState([])
  const pageSize = 15
  const loadData = () => {
    setDisplayedBoards(() => data?.slice(0, displayedBoards.length + pageSize))
  }

  useEffect(() => {
    if (data) {
      setDisplayedBoards(data?.slice(0, pageSize))
    }
  }, [data])

  // if a search returns no results
  // useEffect(() => {
  //   if (query && !data.length) {
  //     setMoreBoards(false)
  //   }
  // }, [query, queue])

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress size={30} />
    </Box>
  ) : data?.length === 0 ? (
    <NoData
      image={noBoards}
      title={"No boards listed"}
      subtitle=""
      marginTop="100px"
      imgWidth="300px"
      props={{
        padding: "20px",
        borderRadius: "20px",
        marginTop: "80px",
      }}
    />
  ) : (
    <Grid
      className={classes.list}
      container
      justify="center"
      id="scrollable-iframe-boards"
    >
      <InfiniteScroll
        dataLength={displayedBoards?.length || 15}
        next={loadData}
        hasMore={data?.length > displayedBoards?.length || false}
        scrollableTarget="scrollable-iframe-boards"
        loader={
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        endMessage={
          <Box width="100%" textAlign="center">
            {displayedBoards?.length > 20 && (
              <Typography className={classes.postsInfiniteText}>
                End of Results
              </Typography>
            )}
          </Box>
        }
      >
        {displayedBoards?.map?.((board) => {
          return <IframeBoardCard key={board?.id} data={board} />
        })}
      </InfiniteScroll>
    </Grid>
  )
}

IframeBoardList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
}

export default IframeBoardList
