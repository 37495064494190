import { Container, Paper } from "@material-ui/core"
import BoardView from "components/Citizen/Boards/BoardView"
import { BoardContainer } from "contexts/Citizen/Board"
import QuickApplyDialog from "dialogs/QuickApply"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useRouteMatch } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const Board = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const match = useRouteMatch()

  return (
    <Paper square className={classes.root}>
      <BoardContainer boardId={match?.params?.board_id}>
        <Container className={classes.container}>
          <Page>
            <BoardView>
              <Router routes={routes} {...rest} />
              {/* dialogs below if needed */}
            </BoardView>
          </Page>
        </Container>
        <QuickApplyDialog />
      </BoardContainer>
    </Paper>
  )
}

Board.propTypes = {
  routes: PropTypes.array,
}

export default Board
