import { Box, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"

import useStyles from "./styles"

const CharacterCounter = ({ currentValue, length }) => {
  const classes = useStyles()

  const whatColor = () => {
    if (currentValue > length) {
      return "#F84738"
    } else {
      return "#1FA073"
    }
  }

  return (
    <Box display="flex">
      <Typography style={{ color: whatColor() }}>{currentValue}</Typography>
      <Typography>/</Typography>
      <Typography>{length}</Typography>
    </Box>
  )
}

CharacterCounter.propTypes = {
  currentValue: PropTypes.number,
  length: PropTypes.number,
}

export default CharacterCounter
