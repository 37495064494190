import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  searchWrapper: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  search: {
    width: "100%",
  },
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
  icon: {
    color: "#898989",
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default styles
