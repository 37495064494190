import { Box, CircularProgress, Typography } from "@material-ui/core"
import CategoryAreaListItem from "components/Common/CategoryAreaListItem"
import { sortBy } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"

import ButtonRounded from "../ButtonRounded"
import useStyles from "./styles"

const CategoryAreaList = ({
  data,
  allCategoryWelcome,
  board = false,
  moreDataString = "",
  type,
  setHide,
  setMoreData,
}) => {
  const classes = useStyles()
  const [sortedArray, setSortedArray] = useState([])
  const [userResponses, setUserResponses] = useState([])

  const openMore = () => {
    setHide(true)
    setMoreData({ type: type, string: moreDataString })
  }
  useEffect(() => {
    if (data) {
      let matching = sortBy(
        data?.board?.filter((x, i) => x.matched === 1),
        ["name"]
      )
      let missing = sortBy(
        data?.board?.filter((x, i) => x.matched === null),
        ["name"]
      )
      let extra = sortBy(
        data?.user?.map((x, i) => {
          x = { ...x, user_selection: true }
          return x
        }),
        ["name"]
      )
      // two arrays set for two use cases in ternary in return statement
      setSortedArray([...matching, ...missing, ...extra])
      setUserResponses([
        ...data?.board?.filter((x) => x.matched),
        ...data?.user,
      ])
    }
  }, [data])

  return (
    <Box className={classes.container}>
      {board ? (
        // Display this (colors, percentages) only if coming from board/applicants tab
        <>
          <Box
            className={moreDataString !== "" ? classes.areasContainer : null}
          >
            <Typography variant="body2">Board:</Typography>
            <Box minHeight="100px">
              {data?.board?.map((x, i) => (
                <CategoryAreaListItem key={i} value={x} board={board} />
              ))}
              {/* Display All *TYPE* except for interest */}
              {Boolean(allCategoryWelcome) && type !== "interest" && (
                <CategoryAreaListItem
                  value={`All ${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  }s Welcome`}
                  board={board}
                />
              )}
            </Box>
          </Box>
          <Box
            className={moreDataString !== "" ? classes.areasContainer : null}
          >
            <Typography variant="body2">Applicant:</Typography>
            <Box minHeight="100px">
              {userResponses?.map((x, i) => (
                <CategoryAreaListItem key={i} value={x} board={board} />
              ))}
            </Box>
          </Box>
        </>
      ) : (
        // Display this (all same with pink checks) only if coming from Applicants sidebar
        <Box className={moreDataString !== "" ? classes.areasContainer : null}>
          {sortedArray?.map((x, i) => (
            <CategoryAreaListItem key={i} value={x} board={board} />
          ))}
        </Box>
      )}
      {board && moreDataString !== "" ? (
        <ButtonRounded style={{ fontSize: "12px" }} onClick={openMore}>
          More {type}
        </ButtonRounded>
      ) : null}
    </Box>
  )
}

CategoryAreaList.propTypes = {
  data: PropTypes.object,
  allCategoryWelcome: PropTypes.number,
  setHide: PropTypes.func,
  setMoreData: PropTypes.func,
  board: PropTypes.bool,
  moreData: PropTypes.string,
  type: PropTypes.string,
  moreDataString: PropTypes.string,
}

export default CategoryAreaList
