import { getBoardData } from "actions/Citizen/iframe"
import PropTypes from "prop-types"
import { createContext, useState } from "react"
import { useQuery } from "react-query"

const IframeContext = createContext()
export default IframeContext

export const IframeContainer = ({ children, ...props }) => {
  const [entityId, setEntityId] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [searchValue, setSearchValue] = useState("")

  // Get board data
  const {
    data: boards,
    isLoading: boardsDataLoading,
    refetch: refetchBoards,
  } = useQuery(["iframeBoards", entityId], () => getBoardData(entityId), {
    enabled: Boolean(entityId),
  })

  return (
    <IframeContext.Provider
      value={{
        boards,
        boardsDataLoading,
        refetchBoards,
        entityId,
        setEntityId,
        selectedCategories,
        setSelectedCategories,
        searchValue,
        setSearchValue,
      }}
    >
      {children}
    </IframeContext.Provider>
  )
}

IframeContainer.propTypes = {
  children: PropTypes.node,
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
