import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      width: "70%",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "80%",
        marginRight: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      color: "#898989",
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
    },
    typeContainer: {
      borderBottom: "1px #E5E5E5 solid",
      padding: theme.spacing(2),
    },
    redContainer: {
      border: `3px solid ${theme.palette.error.main}`,
      borderRadius: "5px",
      padding: theme.spacing(2),
    },
    textContainer: {
      color: theme.palette.primary.dark,
      width: "80%",
    },
    text: {
      fontWeight: 500,
    },
    buttonText: {
      fontSize: "1rem",
      color: theme.palette.primary.dark,
    },
    titleWithData: {
      fontSize: "1.4rem",
      color: theme.palette.primary.dark,
    },
  }),
  {
    name: "FormsContainer",
  }
)

export default styles
