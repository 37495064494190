import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    width: "inherit",
    height: "70px",
    borderRadius: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      height: "400px",
      paddingBottom: theme.spacing(2),
    },
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-around",
    },
  },
  thanksMessage: {
    marginLeft: theme.spacing(6),
    alignItems: "center",
    fontSize: "14px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  sponsorContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "75%",
    marginRight: theme.spacing(6),
    resize: "contain",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      flexDirection: "column",
      justifyContent: "space-between",
      height: "80%",
    },
  },
}))

export default styles
