import {
  Box,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import { getPronouns } from "actions/authentication"
import AppContext from "contexts/App"
import { useFormikContext } from "formik"
import { useContext } from "react"
import InputMask from "react-input-mask"
import { useQuery } from "react-query"

import useStyles from "./styles"

const Step2 = () => {
  const classes = useStyles()
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext()
  const { isClerk } = useContext(AppContext)
  const { data: pronounsList, isLoading: pronounsListLoading } = useQuery(
    "pronouns",
    () => getPronouns(isClerk)
  )

  return pronounsListLoading ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress size={15} />
    </Box>
  ) : (
    <Box>
      <Typography className={classes.subtitle}>
        Please provide the following credentials:{" "}
      </Typography>
      <TextField
        data-cy="clerk-request-title"
        name="title"
        label={values?.title ? "Title" : "Title*"}
        type="text"
        id="title"
        value={values.title}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        helperText={touched.title && errors.title}
        error={Boolean(touched.title && errors.title)}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
      />
      <TextField
        type="text"
        name="first_name"
        data-cy="clerk-request-first-name"
        label={values?.title ? "First Name" : "First Name*"}
        id="first_name"
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.first_name && errors.first_name}
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        value={values.first_name}
        variant="outlined"
        fullWidth
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        error={Boolean(touched.first_name && errors.first_name)}
      />
      <TextField
        type="text"
        name="last_name"
        data-cy="clerk-request-last-name"
        label={values?.title ? "Last Name" : "Last Name*"}
        id="last_name"
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.last_name && errors.last_name}
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        value={values.last_name}
        variant="outlined"
        fullWidth
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        error={Boolean(touched.last_name && errors.last_name)}
      />
      <TextField
        type="text"
        name="email"
        label={values?.title ? "Work Email" : "Work Email*"}
        data-cy="clerk-request-email"
        id="work_email"
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email}
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        value={values.email}
        variant="outlined"
        fullWidth
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        error={Boolean(touched.email && errors.email)}
      />
      <InputMask
        mask="(999) 999-9999"
        maskChar="_"
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {() => (
          <TextField
            type="text"
            name="phone"
            label={values?.title ? "Work Phone Number" : "Work Phone Number*"}
            data-cy="clerk-request-phone"
            id="work_phone"
            helperText={touched.phone && errors.phone}
            style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
            variant="outlined"
            fullWidth
            InputProps={{
              classes: {
                root: classes.inputBackgroundColor,
              },
            }}
            FormHelperTextProps={{
              className: classes.formHelperText,
            }}
            error={Boolean(touched.phone && errors.phone)}
          />
        )}
      </InputMask>
      <TextField
        data-cy="register-pronouns"
        label="Pronouns"
        select
        name="pronoun_id"
        value={values?.pronoun_id}
        onChange={(e) => setFieldValue("pronoun_id", e.target.value)}
        variant="outlined"
        fullWidth
        style={{
          backgroundColor: "#F8F8F8",
        }}
        className={classes.textField}
      >
        {pronounsList?.data?.pronouns?.map((p) => {
          return (
            <MenuItem key={p?.id} value={p?.id}>
              {p?.pronoun}
            </MenuItem>
          )
        })}
      </TextField>
    </Box>
  )
}

export default Step2
