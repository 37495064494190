import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import { useContext } from "react"

import AddButton from "../AddButton"
import ApplyError from "../ApplyError"
import useStyles from "./styles"

const FormsContainer = () => {
  const classes = useStyles()
  let types = ["Experience", "Expertise", "Interest"]

  const { authUser, openDialog } = useContext(AppContext)
  const { applied } = useContext(CitizenAppContext)
  const {
    updateInterestMutation,
    updateExpertiseMutation,
    updateExperienceMutation,
  } = useContext(AccountContext)

  const whatSubtitle = (type) => {
    if (type === "Interest") {
      return "Category of Boards and Commissions you are interested in, if applicable. Select all that apply"
    } else {
      return "Select all that apply"
    }
  }
  const whatFormPlaceholder = (type) => {
    if (type === "Interest") {
      return "If you are interested in specific board and/or commission(s), please list here"
    } else {
      return `Other areas of ${type.toLowerCase()} (optional)`
    }
  }
  const whatMutation = (type) => {
    if (type === "Interest") {
      return updateInterestMutation
    } else if (type === "Expertise") {
      return updateExpertiseMutation
    } else {
      return updateExperienceMutation
    }
  }

  const openEditQualifiers = (type) => {
    let data = {
      type: type,
      subtitle: whatSubtitle(type),
      formPlaceholder: whatFormPlaceholder(type),
      updateMutation: whatMutation(type),
    }
    openDialog("addQualifiersDialog", data)
  }

  let errors =
    authUser?.interest?.length === 0 ||
    authUser?.interest === null ||
    authUser?.experience?.length === 0 ||
    authUser?.experience === null ||
    authUser?.expertise?.length === 0 ||
    authUser?.expertise === null

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="subtitle1">
        Qualifiers
      </Typography>
      <>
        <Card>
          {types?.map((type, i) => {
            let userType = authUser?.[type?.toLowerCase()]
            return userType && userType.length > 0 ? (
              <Box className={classes.typeContainer} key={i}>
                <Box display="flex" width="100%">
                  <Box flexGrow={1}>
                    <Typography className={classes.titleWithData}>
                      {type}
                    </Typography>
                  </Box>
                  <Box>
                    <ButtonRounded
                      onClick={() => openEditQualifiers(type)}
                      data-cy={`${type?.toLowerCase()}-button`}
                    >
                      <Typography className={classes.buttonText}>
                        EDIT
                      </Typography>
                    </ButtonRounded>
                  </Box>
                </Box>
                {authUser?.[type?.toLowerCase()].map((x, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      control={<Checkbox checked />}
                      label={x.name}
                      style={{ width: "100%" }}
                      disabled
                    />
                  )
                })}
              </Box>
            ) : (
              <Box
                key={i}
                display="flex"
                alignItems="center"
                className={classes.typeContainer}
              >
                <Box className={classes.textContainer}>
                  <Typography variant="h6" className={classes.text}>
                    {type}
                  </Typography>
                </Box>
                <AddButton
                  onClick={() => openEditQualifiers(type)}
                  type={`${type?.toLowerCase()}`}
                  width="29px"
                  height="29px"
                  buttonSize="20px"
                />
              </Box>
            )
          })}
        </Card>
        {errors && applied && <ApplyError />}
      </>
    </Box>
  )
}

FormsContainer.propTypes = {}

export default FormsContainer
