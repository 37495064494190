import {
  Box,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core"
import HelpIcon from "@material-ui/icons/Help"
// import BoardContext from "contexts/Citizen/Board"
import PropTypes from "prop-types"
import { useContext, useMemo, useState } from "react"

import useStyles from "./styles"

const Questions = ({
  data,
  defaultQuestionsArray,
  setDefaultQuestionsArray,
  clicked,
  additionalQuestionsArray,
  setAdditionalQuestionsArray,
  setPreferredPosition,
  positions,
  board,
  setErrorState,
  disableText = false,
}) => {
  const classes = useStyles()
  // const { board } = useContext(BoardContext)

  const selectDefaultAnswers = (answerId, questionId) => {
    setDefaultQuestionsArray((oldState) => {
      if (oldState.filter((x) => x.question_id === questionId).length > 0) {
        return [
          ...oldState.filter((x) => x.question_id !== questionId),
          { question_id: questionId, answer_id: answerId },
        ]
      } else {
        return [...oldState, { question_id: questionId, answer_id: answerId }]
      }
    })
  }

  const addAditionalAnswers = (answer, questionId) => {
    setAdditionalQuestionsArray((oldState) => {
      if (oldState.filter((x) => x.question_id === questionId).length > 0) {
        return [
          ...oldState.filter((x) => x.question_id !== questionId),
          { question_id: questionId, answer: answer },
        ]
      } else {
        return [...oldState, { question_id: questionId, answer: answer }]
      }
    })
  }

  const defaultAnswersError = (id) => {
    if (
      clicked &&
      defaultQuestionsArray.filter((x) => x.question_id === id).length === 0
    ) {
      setErrorState(true)
      return true
    } else {
      setErrorState(false)
      return false
    }
  }

  const additionalAnswersError = (id) => {
    if (
      (additionalQuestionsArray.length > 0 &&
        (additionalQuestionsArray?.filter((x) => x.question_id === id)[0]
          ?.answer === "" ||
          additionalQuestionsArray?.filter((x) => x.question_id === id)
            .length === 0)) ||
      additionalQuestionsArray?.filter((x) => x.question_id === id)[0]?.answer
        ?.length > 1000
    ) {
      setErrorState(true)
      return true
    } else {
      setErrorState(false)
      return false
    }
  }

  return (
    <>
      {!disableText && (
        <Typography variant="body1">
          Thank you for your interest in joining{" "}
          <span style={{ fontWeight: 700 }}> {data?.name}</span>. Your profile
          will be sent over to the committee for internal review. Please answer
          these additional questions required of this board:
        </Typography>
      )}
      <Box display="flex" flexDirection="column" marginTop={1}>
        {board?.default_questions?.map((q, i) => (
          <Box key={i} className={classes.questionContainer}>
            <Box display="flex" alignItems="center">
              <Typography color="primary">
                Question {i + 1}: {q.question}
              </Typography>
              {q.question?.includes("council distric") && (
                <Tooltip
                  title={<Typography>Find you council district</Typography>}
                >
                  <IconButton
                    onClick={() =>
                      window.open(
                        "https://info.scvotes.sc.gov/eng/voterinquiry/VoterInformationRequest.aspx?PageMode=VoterInfo "
                      )
                    }
                  >
                    <HelpIcon style={{ fontSize: "15px" }} color="primary" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Select
              onChange={(e) => selectDefaultAnswers(e.target.value, q.id)}
              key={i}
              variant="outlined"
              className={classes.select}
            >
              {q?.answers?.map((a, i) => (
                <MenuItem
                  key={a.answer_id}
                  value={a.answer_id}
                  defaultValue="Select one..."
                >
                  {a?.answer}
                </MenuItem>
              ))}
            </Select>
            {defaultAnswersError(q.id) ? (
              <Typography className={classes.errorText}>Required</Typography>
            ) : (
              <Box className={classes.noError} />
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" marginTop={1}>
        {board?.additional_questions?.map((q, i) => (
          <Box key={i} className={classes.questionContainer}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography color="primary">
                Question {i + board?.default_questions.length + 1}: {q.question}
              </Typography>
              <Box display="flex" width="30%">
                <Typography variant="body">max 1000 char</Typography>
              </Box>
            </Box>
            <TextField
              style={{ marginTop: "10px" }}
              fullWidth
              variant="outlined"
              value={
                additionalQuestionsArray.filter((x) => x.id === q.id).answer
              }
              onChange={(e) => addAditionalAnswers(e.target.value, q.id)}
            />
            {additionalAnswersError(q.id) ? (
              <Typography className={classes.errorText}>Required</Typography>
            ) : (
              <Box className={classes.noError} />
            )}
          </Box>
        ))}
      </Box>
      <Box>
        {positions?.length > 0 && (
          <>
            <Typography color="primary">
              Select your preferred role/district
            </Typography>
            <Select
              onChange={(e) => setPreferredPosition(e.target.value)}
              variant="outlined"
              className={classes.select}
            >
              {positions?.map((p, i) => (
                <MenuItem
                  key={p?.position_id}
                  value={p}
                  defaultValue="Select one..."
                >
                  {p?.position}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
    </>
  )
}

Questions.propTypes = {
  data: PropTypes.object,
  defaultQuestionsArray: PropTypes.array,
  setDefaultQuestionsArray: PropTypes.func,
  clicked: PropTypes.bool,
  additionalQuestionsArray: PropTypes.array,
  setAdditionalQuestionsArray: PropTypes.func,
  setPreferredPosition: PropTypes.func,
  positions: PropTypes.array,
  board: PropTypes.object,
  disableText: PropTypes.bool,
  setErrorState: PropTypes.func,
}

export default Questions
