import { Container, Typography } from "@material-ui/core"
import FiftyFifty from "Layouts/FiftyFifty"
import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"

import useStyles from "./styles"

const List = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  return (
    <Page>
      <FiftyFifty
        left={
          <Container>
            {/* citizen boards list goes here */}
            <Typography>Consumer Boards List</Typography>
          </Container>
        }
        right={<Router routes={routes} {...rest} />}
      />
    </Page>
  )
}

List.propTypes = {
  routes: PropTypes.array,
}

export default List
