import { Box, Divider, Typography } from "@material-ui/core"
import classnames from "clsx"
import ListItemWithContent from "components/Common/ListItemWithContent"
import PropTypes from "prop-types"

import useStyles from "./styles"

const RevieweesList = ({ reviewees, selected, setSelected, answers }) => {
  const classes = useStyles()

  const handleSelection = (applicant) => {
    setSelected(applicant)
  }

  return (
    <Box>
      {reviewees?.map((x, i) => {
        return (
          <Box
            key={i}
            className={classnames(classes.row, {
              [classes.selected]: selected?.user_id === x?.user_id,
            })}
            style={{ cursor: "pointer" }}
          >
            <ListItemWithContent
              style={{
                width: "90%",
                margin: "0 auto",
              }}
              disableGutters
              avatar={x}
              ListItemTextProps={{
                disableTypography: true,
              }}
              primary={
                <Typography className={classes.nameText}>
                  {x?.first_name + " " + x?.last_name}
                </Typography>
              }
              secondary={
                <Typography variant="body1">
                  {x?.county}, {x?.city}, {x?.zip_code}
                </Typography>
              }
              onClick={() => handleSelection(x)}
            >
              <Box display="flex" flexDirection="column" flexShrink={0}>
                <Typography className={classes.responseText}>
                  {answers?.filter(
                    (y) => y.application_id === x?.application_id
                  )?.[0]?.answer?.response || "Review needed"}
                </Typography>
              </Box>
            </ListItemWithContent>
            <Divider />
          </Box>
        )
      })}
    </Box>
  )
}

RevieweesList.propTypes = {
  reviewees: PropTypes.array,
  selected: PropTypes.object,
  answers: PropTypes.array,
  setSelected: PropTypes.func,
}

export default RevieweesList
