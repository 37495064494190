import { Box, Grid } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Messages = ({ left, middle, right }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={3} className={classes.gridItem}>
        <Box className={classes.scrollBox}>{left}</Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
        <Box className={classes.scrollBox}>{middle}</Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} className={classes.gridItem}>
        <Box className={classes.scrollBox}>{right}</Box>
      </Grid>
    </Grid>
  )
}

Messages.propTypes = {
  left: PropTypes.node,
  middle: PropTypes.node,
  right: PropTypes.node,
}

export default Messages
