import { Box, Card, Typography } from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import Education from "components/Common/DisplayData/Education"
import Ocuppation from "components/Common/DisplayData/Occupation"
import References from "components/Common/DisplayData/References"
import Resume from "components/Common/DisplayData/Resume"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import PropTypes from "prop-types"
import { useContext } from "react"

import AddButton from "../../AddButton"
import ApplyError from "../../ApplyError"
import useStyles from "./styles"

const UploadInfoCard = ({ element }) => {
  const classes = useStyles()
  const { authUser, openDialog } = useContext(AppContext)
  const { applied } = useContext(CitizenAppContext)
  const { getResumeMutation } = useContext(AccountContext)
  const errors = {
    resumeError: authUser?.resume?.length === 0 || !authUser?.resume,
    positionError: authUser?.position?.length === 0 || !authUser?.position,
    highest_educationError: !authUser?.highest_education,
  }

  const handleOpenDialog = (type) => {
    if (type === "resume") {
      let title = "Add Resume"
      let data = { type: type, title: title }
      openDialog("uploadPdfDialog", data)
    } else if (type === "references") {
      openDialog("addReferencesDialog")
    } else if (type === "position") {
      openDialog("addOccupation")
    } else if (type === "highest_education") {
      openDialog("addEducation")
    }
  }

  let dataRender = () => {
    if (element?.type === "resume") {
      return (
        <Resume
          name={authUser?.resume}
          resumeId={authUser?.resume_id}
          openMutation={getResumeMutation}
          actions={
            <Box>
              <ButtonRounded
                onClick={() => handleOpenDialog(element.type)}
                data-cy="resume-button"
              >
                <Typography className={classes.buttonText}>REPLACE</Typography>
              </ButtonRounded>
            </Box>
          }
        />
      )
    } else if (element?.type === "position") {
      return (
        <Ocuppation
          positionTitle={authUser?.position?.[0]?.title}
          companyName={authUser?.position?.[0]?.company_name}
          startDate={authUser?.position?.[0]?.start_date}
          endDate={authUser?.position?.[0]?.end_date}
          current={authUser?.position?.[0]?.current}
          actions={
            <ButtonRounded
              onClick={() => handleOpenDialog(element.type)}
              data-cy="position-button"
            >
              <Typography className={classes.buttonText}>EDIT</Typography>
            </ButtonRounded>
          }
        />
      )
    } else if (element?.type === "highest_education") {
      return (
        <Education
          educationAttained={authUser.highest_education}
          degree={authUser?.degree}
          actions={
            <ButtonRounded
              onClick={() => handleOpenDialog(element.type)}
              data-cy="highest_education-button"
            >
              <Typography className={classes.buttonText}>EDIT</Typography>
            </ButtonRounded>
          }
        />
      )
    } else if (element?.type === "references") {
      return (
        <References
          references={authUser?.references}
          myAccountAcctions
          addReferences={
            <Box
              display="flex"
              alignItems="center"
              marginBottom="20px"
              justifyContent="space-between"
            >
              <Box display="flex">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <AddButton
                    width="17px"
                    height="17px"
                    buttonSize="10px"
                    onClick={() => handleOpenDialog(element.type)}
                  />
                </Box>
                <Box className={classes.textContainer}>
                  <Typography className={classes.referencesButtonWithData}>
                    {"Add " + element?.label}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <ButtonRounded
                  onClick={() => handleOpenDialog(element.type)}
                  data-cy={"references-button"}
                >
                  <Typography className={classes.buttonText}>EDIT</Typography>
                </ButtonRounded>
              </Box>
            </Box>
          }
        />
      )
    }
  }

  return (
    <>
      <Typography className={classes.title} variant="subtitle1">
        {element?.label}
      </Typography>

      {!authUser?.[element.type] || authUser?.[element.type].length === 0 ? (
        <>
          <Card className={classes.card}>
            <>
              <AddButton
                onClick={() => handleOpenDialog(element.type)}
                type={element?.type}
              />
              <Box className={classes.textContainer}>
                <Typography variant="h5" className={classes.text}>
                  {"Add " + element?.label}
                </Typography>
              </Box>
            </>
          </Card>
          {errors?.[`${element.type}Error`] && applied ? <ApplyError /> : null}
        </>
      ) : (
        <Box width="100%">{dataRender()}</Box>
      )}
    </>
  )
}

UploadInfoCard.propTypes = {
  element: PropTypes.object,
}

export default UploadInfoCard
