import { RequestsContainer } from "contexts/Clerk/Requests"
import ChooseRequestImage from "dialogs/ChooseRequestImage"
import RequestReview from "dialogs/RequestReview"
import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"

import useStyles from "./styles"

const Requests = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  return (
    <Page>
      <RequestsContainer>
        <Router routes={routes} {...rest} />
        {/* dialogs here */}
        <RequestReview />
        <ChooseRequestImage />
      </RequestsContainer>
    </Page>
  )
}

Requests.propTypes = {
  routes: PropTypes.array,
}

export default Requests
