import Route from "components/Route"
import NotFound from "pages/NotFound"
import PropTypes from "prop-types"
import { Redirect,Switch } from "react-router-dom"

const PageComponents = {}
const componentsReq = require.context("./pages", true, /^(.*\.(jsx$))[^.]*$/im)
componentsReq.keys().filter(x => x.indexOf("index.jsx") > -1).forEach(x => {
  // https://regexr.com/54uqs - selects ./ and /pages/ from file locations
  // https://regexr.com/54uqv - selects index.jsx files
  const componentName = x.replace(/\/(\w|:|-)+\.jsx$/g, "").replace(/(\/pages\/|\.\/)/g, "")
  PageComponents[componentName] = componentsReq(x).default
})
export const citizenRouteConfig = [
  {
    "path": "/",
    "component": "Citizen",
    "routes": [
      {
        "path": "/",
        "exact": true,
        "protected": false,
        "component": "CitizenIndex"
      },
      {
        "path": "/kitchensink",
        "exact": true,
        "protected": false,
        "component": "KitchenSink"
      },
      {
        "path": "/pub",
        "exact": true,
        "protected": false,
        "component": "CitizenIframe"
      },
      {
        "path": "/my-account",
        "exact": true,
        "protected": true,
        "component": "CitizenMyAccount"
      },
      {
        "path": "/applied",
        "exact": true,
        "protected": true,
        "component": "CitizenApplied"
      },
      {
        "path": "/applied",
        "protected": true,
        "component": "CitizenApplied",
        "routes": [
          {
            "path": "/applied",
            "protected": true,
            "exact": true,
            "component": "CitizenAppliedList"
          }
        ]
      },
      {
        "path": "/renew",
        "exact": true,
        "protected": false,
        "component": "CitizenRenewTerm"
      },

      {
        "path": "/boards",
        "protected": false,
        "component": "CitizenBoards",
        "routes": [
          {
            "path": "/boards",
            "protected": false,
            "component": "CitizenBoardsList",
            "routes": [
              {
                "exact": true,
                "path": "/boards/:board_id",
                "protected": false,
                "component": "CitizenBoardsListBoard"
              }
            ]
          }
        ]
      },
      {
        "path": "/messages",
        "protected": true,
        "component": "Messages",
        "routes": [
          {
            "path": "/messages",
            "protected": true,
            "component": "MessagesList",
            "routes": [
              {
                "exact": true,
                "path": "/messages/:message_id",
                "protected": true,
                "component": "MessagesListMessage"
              }
            ]
          }
        ]
      }
    ]
  }
]
export const clerkRouteConfig = [
  {
    "path": "/",
    "component": "Clerk",
    "routes": [
      {
        "path": "/",
        "exact": true,
        "protected": false,
        "component": "ClerkIndex"
      },
      {
        "path": "/join",
        "exact": true,
        "protected": false,
        "component": "ClerkRequestInvitation"
      },
      {
        "path": "/dashboard",
        "exact": true,
        "protected": true,
        "component": "ClerkDashboard"
      },
      {
        "path": "/applicants",
        "protected": true,
        "component": "ClerkApplicants",
        "routes": [
          {
            "path": "/applicants",
            "protected": true,
            "exact": true,
            "component": "ClerkApplicantsList"
          },
          {
            "path": "/applicants/:applicant_id",
            "exact": true,
            "protected": true,
            "component": "ClerkApplicantsList"
          }
        ]
      },
      {
        "path": "/review",
        "exact": true,
        "protected": false,
        "component": "ClerkInternalReview"
      },
      {
        "path": "/boards",
        "protected": true,
        "component": "ClerkBoards",
        "routes": [
          {
            "path": "/boards",
            "protected": true,
            "component": "ClerkBoardsList",
            "routes": [
              {
                "path": "/boards/:board_id",
                "protected": true,
                "component": "ClerkBoardsListBoard",
                "routes": [
                  {
                    "exact": true,
                    "path": "/boards/:board_id",
                    "protected": true,
                    "component": "ClerkBoardsListBoardIndex"
                  },
                  {
                    "exact": true,
                    "path": "/boards/:board_id/board-details",
                    "protected": true,
                    "component": "ClerkBoardsListBoardBoardDetails"
                  },
                  {
                    "exact": true,
                    "path": "/boards/:board_id/members",
                    "protected": true,
                    "component": "ClerkBoardsListBoardMembers"
                  },
                  {
                    "exact": true,
                    "path": "/boards/:board_id/reviews",
                    "protected": true,
                    "component": "ClerkBoardsListBoardReviews"
                  },
                  {
                    "exact": true,
                    "path": "/boards/:board_id/analytics",
                    "protected": true,
                    "component": "ClerkBoardsListBoardAnalytics"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "path": "/messages",
        "protected": true,
        "component": "Messages",
        "routes": [
          {
            "path": "/messages",
            "protected": true,
            "component": "MessagesList",
            "routes": [
              {
                "exact": true,
                "path": "/messages/:message_id",
                "protected": true,
                "component": "MessagesListMessage"
              }
            ]
          }
        ]
      },
      {
        "path": "/requests",
        "protected": true,
        "component": "ClerkRequests",
        "routes": [
          {
            "path": "/requests",
            "protected": true,
            "exact": true,
            "component": "ClerkRequestsList"
          },
          {
            "path": "/requests/:request_id",
            "exact": true,
            "protected": true,
            "component": "ClerkRequestsList"
          }
        ]
      },
      {
        "path": "/entities",
        "protected": true,
        "component": "ClerkEntities",
        "routes": [
          {
            "path": "/entities",
            "protected": true,
            "exact": true,
            "component": "ClerkEntitiesList"
          },
          {
            "path": "/entities/:entity_id",
            "exact": true,
            "protected": true,
            "component": "ClerkEntitiesListEntity"
          }
        ]
      },
      {
        "path": "/admins",
        "protected": true,
        "component": "ClerkAdmins",
        "routes": [
          {
            "path": "/admins",
            "protected": true,
            "exact": true,
            "component": "ClerkAdminsList"
          },
          {
            "path": "/admins/:admin_id",
            "exact": true,
            "protected": true,
            "component": "ClerkAdminsList"
          }
        ]
      },
      {
        "path": "/approved",
        "protected": false,
        "exact": false,

        "component": "ClerkApproval"

      },
      {
        "path": "/analytics",
        "protected": true,
        "exact": true,
        "component": "ClerkAnalytics"
      }
    ]
  }
]
export const Router = ({ routes, match }) => {
  let path = ""
  if (match && match?.path !== "/") {
    path = match.path
  }

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)+window.location.search} />
      {routes.map(({ component, ...rest }, i) => (
        <Route key={i} {...rest} component={PageComponents[component]} />
      ))}
      <Route path={`${path}/*`} component={NotFound} />
    </Switch>
  )
}

Router.propTypes = {
  "routes": PropTypes.array,
  "match": PropTypes.object
}
export default Router
