import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      padding: "0 0 0 30px",
      height: "100%",
      [theme.breakpoints.down("xs")]: {
        padding: "0 0 0 15px",
      },
    },
    filterContainer: {
      paddingTop: "30px",
      width: "35%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    listContainer: {
      width: "65%",
      paddingTop: "30px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        paddingTop: 0,
      },
    },
    header: {
      minHeight: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
      },
    },
  }),
  {
    name: "Pages-BoardsList",
  }
)

export default styles
