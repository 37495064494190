import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  primary: {
    margin: 0,
  },
}))

export default styles
