import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import * as Yup from "yup"

import useStyles from "./styles"

const educationSchema = Yup.object({
  highest_education: Yup.string().required("Required"),
  degree: Yup.string().required("Required"),
})

const AddEducationDialog = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const authContext = useContext(AppContext)

  const { dialogs, closeDialog, authUser, openSnackBar, token } =
    useContext(AppContext)
  const { updateEducationMutation } = useContext(AccountContext)
  const thisDialog = dialogs?.["addEducation"] || {}
  const { open = false, data = {} } = thisDialog

  const handleClose = () => {
    closeDialog("addEducation")
  }

  const onSubmit = (values, actions) => {
    updateEducationMutation
      .mutateAsync(values)
      .then((res) => {
        serialize("user", res.user).then((serializedData) => {
          authContext.setAuthData({
            token: token,
            account: serializedData,
          })
          return queryClient.setQueryData("user", (oldState) => {
            return serializedData
          })
        })
        openSnackBar({ message: "Education updated" })
        handleClose()
      })
      .catch((err) => {
        openSnackBar({ message: "Error updating education" })
      })
  }
  return (
    <ConditionalDialogWrapper open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Add Education</Typography>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          highest_education: authUser?.highest_education || "",
          degree: authUser?.degree || "",
        }}
        onSubmit={onSubmit}
        validationSchema={educationSchema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setErrors,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <TextField
                  select
                  data-cy="education-highest-education"
                  name="highest_education"
                  label="Highest Education Attained"
                  value={values?.highest_education}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "25px" }}
                  helperText={
                    touched.highest_education && errors.highest_education
                  }
                  error={touched.highest_education && errors.highest_education}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                >
                  <MenuItem value={"High School or Equivalent "}>
                    High School or Equivalent{" "}
                  </MenuItem>
                  <MenuItem value={"Professional Certificate"}>
                    Professional Certificate
                  </MenuItem>
                  <MenuItem value={"Associates"}>Associates</MenuItem>
                  <MenuItem value={"Bachelor’s"}>{"Bachelor’s"}</MenuItem>
                  <MenuItem value={"Master's"}>{"Master's"}</MenuItem>
                  <MenuItem value={"Ph.D"}>Ph.D </MenuItem>
                  <MenuItem value={"MD"}>MD</MenuItem>
                  <MenuItem
                    value={"JD"}
                    data-cy={"education-highest-education-jd"}
                  >
                    JD
                  </MenuItem>
                </TextField>

                <TextField
                  type="text"
                  name="degree"
                  label="Degree"
                  id="degree-user"
                  data-cy={"education-degree"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.degree && errors.degree}
                  style={{ marginBottom: "25px" }}
                  value={values.degree}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  error={touched.degree && errors.degree}
                />
              </DialogContent>
              <DialogActions>
                <Box display="flex" justifyContent="center" width="100%">
                  <ButtonRounded
                    color="primary"
                    variant="outlined"
                    onClick={handleClose}
                    type="button"
                    data-cy="education-close-button"
                  >
                    Close
                  </ButtonRounded>
                  <ButtonRounded
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.saveButton}
                    disabled={!dirty}
                    data-cy="education-save-button"
                    classes={{ label: classes.buttonLabel }}
                  >
                    {updateEducationMutation?.isLoading ? (
                      <CircularProgress size={20} style={{ color: "#fff" }} />
                    ) : (
                      "Save"
                    )}
                  </ButtonRounded>
                </Box>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </ConditionalDialogWrapper>
  )
}

export default AddEducationDialog
