import { Card } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const DashboardCard = ({ children }) => {
  const classes = useStyles()

  return <Card className={classes.root}>{children}</Card>
}

DashboardCard.propTypes = {
  children: PropTypes.node,
}

export default DashboardCard
