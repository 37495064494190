import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    paper: {
      minWidth: "480px",
      minHeight: "400px",
      overflow: "auto",
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
    },
    labels: {
      fontSize: "1.4rem",
      fontWeight: 500,
    },
    scrollWrapper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      overflow: "auto",
      border: "1px solid #eef2f2",
      boxShadow:
        "inset 0 -11px 3px -10px #eef2f2, inset 0 11px 3px -10px #eef2f2",
      marginBottom: theme.spacing(1),
    },
    chipContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: theme.spacing(1),
    },
    chipRoot: {
      borderRadius: "6px",
      border: `1px solid ${theme.palette.primary.main}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipLabel: {
      textTransform: "capitalize",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: "1.4rem",
      fontWeight: 500,
    },
    emailChipContainer: {
      maxHeight: "90px",
      overflow: "auto",
    },
    emailChipLabel: {
      textTransform: "none",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: "1.2rem",
      fontWeight: 500,
    },
    inputWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    checkboxWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    errorText: {
      color: "#F84738",
      fontStyle: "italic",
    },
    notchedOutline: {
      border: "1px solid #F84738",
      "&:hover": {
        border: "1px solid #F84738",
      },
    },
  }),
  {
    name: "ShareForReview",
  }
)

export default styles
