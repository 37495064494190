import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  cardRoot: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    overflow: "auto",
    width: "100%",
    height: "100%",
    border: "none",
  },
  importButton: {
    backgroundColor: "#0A8158",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#0A8158",
    },
  },
}))

export default styles
