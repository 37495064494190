import { AdminsContainer } from "contexts/Clerk/Admins"
import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"

import useStyles from "./styles"

const Admins = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  return (
    <Page>
      <AdminsContainer>
        <Router routes={routes} {...rest} />
      </AdminsContainer>
    </Page>
  )
}

Admins.propTypes = {
  routes: PropTypes.array,
}

export default Admins
