import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    pageContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        flexGrow: 1,
      },
    },
    routerContainer: {
      minHeight: "0",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    routes: {
      flexGrow: 1,
      overflow: "auto",
    },
    headerPhoneView: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    button: {
      padding: "6px 26px 6px 20px",
      display: "flex",
      flexShrink: 0,
    },
    label: {
      fontSize: "1.4rem",
      color: "rgba(0, 0, 0, 0.54)",
    },
  }),
  {
    name: "ClerkPage",
  }
)

export default styles
