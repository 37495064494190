import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import noBoards from "assets/no-boards-icon.png"
import NoData from "components/Common/NoData"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useHistory, useLocation } from "react-router-dom"

import BoardCard from "../BoardCard"
import useStyles from "./styles"

const List = ({ data, loading }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("xs"))
  const tablet = useMediaQuery(theme.breakpoints.down("sm"))

  const [displayedBoards, setDisplayedBoards] = useState([])
  const pageSize = 15
  const loadData = () => {
    setDisplayedBoards(() => data.slice(0, displayedBoards.length + pageSize))
  }

  // UE handles conditional load of board view defaulting to 1st in list
  useEffect(() => {
    if (data) {
      setDisplayedBoards(data?.slice(0, pageSize))
      // IF:
      // boards data has loaded AND
      // desktop view AND
      // no board id passed AND
      // no sub-url sent (e.g., members)
      if (
        data?.[0]?.id &&
        !tablet &&
        !location.pathname.split("/")[2] &&
        !location.pathname.split("/")[3]
      ) {
        // DO: load the first board in the board list
        history.push(`/boards/${data?.[0]?.id}`)
      }
    }
  }, [data])

  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress size={30} />
    </Box>
  ) : data?.length === 0 ? (
    <NoData
      image={noBoards}
      title="No boards have been added yet"
      subtitle="Get started by clicking “Add New Board” or “Data Import”"
      marginTop="100px"
      imgWidth="300px"
      props={{
        padding: "20px",
        borderRadius: "20px",
        marginTop: "80px",
      }}
    />
  ) : (
    <Grid className={classes.list} container justify="center">
      <InfiniteScroll
        dataLength={displayedBoards?.length || 15}
        next={loadData}
        hasMore={data?.length > displayedBoards?.length || false}
        scrollableTarget="board-scroll-clerk"
        loader={
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
      >
        {displayedBoards?.map?.((board) => {
          return <BoardCard key={board?.id} data={board} />
        })}
      </InfiniteScroll>
    </Grid>
  )
}

List.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
}

export default List
