import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(5, 2),
    },
    messageUserAvatar: {
      height: 140,
      width: 140,
      marginBottom: theme.spacing(1),
      fontSize: "50px",
    },
    applicationHistoryList: {
      width: "100%",
      padding: 0,
    },
    listItem: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
    },
    appliedChip: {
      backgroundColor: "#FFCCA7",
      color: "#F88938",
    },
    declinedChip: {
      backgroundColor: "#FFC7C3",
      color: "#F84738",
    },
  }),
  {
    name: "MessageSidebar",
  }
)

export default styles
