import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import noReviews from "assets/no-reviews-icon.png"
import NoData from "components/Common/NoData"
import ClerkApp from "contexts/Clerk/ClerkApp"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import getColor from "utils/getColor"

import DashboardCard from "../DashboardCard"
import useStyles from "./styles"

const ReviewsList = () => {
  const classes = useStyles()

  const history = useHistory()

  const { entityReviews, entityReviewsLoading } = useContext(ClerkApp)

  // shows right-side board container & triggers conditional border styling
  // state object is passed to ReviewsList to get the chosen review accordion opened
  const navigateToBoardView = (boardId, reviewId) => {
    history.push({
      pathname: `/boards/${boardId}/reviews`,
      state: { chosenReview: reviewId },
    })
  }

  if (entityReviewsLoading) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <>
        {entityReviews?.length > 0 ? (
          <List className={classes.root}>
            {entityReviews?.map?.((review, i) => (
              <ListItem
                key={i}
                className={classes.listItem}
                onClick={() =>
                  navigateToBoardView(review?.board_id, review?.review_id)
                }
              >
                <ListItemText
                  classes={{
                    multiline: classes.listItemText,
                  }}
                  disableTypography
                  primary={
                    <Box>
                      <Typography variant="h6" style={{ marginBottom: "4px" }}>
                        {review?.name}
                      </Typography>
                      <Typography variant="body1">
                        Review sent {dayjs(review?.created).format("MM/DD/YY")}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography
                      style={getColor(review?.response_rate)}
                      variant="caption"
                      className={classes.secondaryText}
                    >
                      {review?.response_rate}% of responses
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <NoData
            image={noReviews}
            title="No applicants currently under review"
            props={{
              backgroundColor: "#f4f4f4",
              padding: "20px",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </>
    )
  }
}

const Review = ({ children }) => {
  return (
    <DashboardCard>
      <ReviewsList />
    </DashboardCard>
  )
}

Review.propTypes = {
  children: PropTypes.node,
}

export default Review
