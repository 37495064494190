import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.primary.dark,
    minWidth: "600px",
  },
  papper: {
    minWidth: "600px",
  },
  select: {
    width: "50%",
    marginTop: theme.spacing(1),
  },
  btnLabel: {
    minWidth: "36px",
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  questionContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      bottom: 0,
      width: "100%",
    },
  },
}))

export default styles
