import {
  Box,
  Container,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core"
import CharacterCounter from "components/Common/CharacterCounter"
import FileUpload from "components/FileUpload"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useState } from "react"

import useStyles from "./styles"

const CoverLetter = ({
  file,
  setFile,
  clicked,
  text,
  setText,
  fileUploadRef,
  uploadFile,
  setUploadFile,
}) => {
  const classes = useStyles()

  const handleChange = (event) => {
    setUploadFile((oldState) => {
      setFile({})
      setText("")
      return !oldState
    })
  }

  const error = isEmpty(file) && clicked && text === ""

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginBottom="20px"
      >
        <Typography
          variant="subtitle1"
          style={
            uploadFile === false ? { color: "#A01F62", fontWeight: 700 } : null
          }
        >
          Type Cover Letter
        </Typography>
        <Switch color="primary" checked={uploadFile} onChange={handleChange} />
        <Typography
          variant="subtitle1"
          style={uploadFile ? { color: "#A01F62", fontWeight: 700 } : null}
        >
          Upload PDF
        </Typography>
      </Box>
      {uploadFile ? (
        <Box position="relative">
          <FileUpload
            setFile={setFile}
            type="cover-letter"
            fromApply={true}
            ref={fileUploadRef}
          />
          {/* {error ? (
            <Typography className={classes.uploadError}>Required</Typography>
          ) : (
            <Box className={classes.noError} />
          )} */}
        </Box>
      ) : (
        <Box style={{ marginBottom: "150px" }}>
          <Box display="flex" justifyContent="flex-end" marginBottom="5px">
            <CharacterCounter currentValue={text?.length} length={2000} />
          </Box>

          <TextField
            placeholder="Type Cover Letter"
            variant="filled"
            fullWidth
            multiline
            rows={10}
            onChange={(e) => {
              setText(e.target.value)
            }}
          />
          {error ? (
            <Typography className={classes.errorText2}>Required</Typography>
          ) : (
            <Box className={classes.noError} />
          )}
        </Box>
      )}
    </>
  )
}

CoverLetter.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func,
  clicked: PropTypes.bool,
  text: PropTypes.string,
  setText: PropTypes.func,
  fileUploadRef: PropTypes.object,
  uploadFile: PropTypes.bool,
  setUploadFile: PropTypes.func,
}

export default CoverLetter
