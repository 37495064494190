import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core"
import classnames from "clsx"
import AppBar from "components/Citizen/AppBar"
import AppBarMobile from "components/Citizen/AppBarMobile"
import BottomNavMobile from "components/Citizen/BottomNavMobile"
import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const Citizen = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const history = useHistory()

  return (
    <Container maxWidth="xl" classes={{ root: classes.root }}>
      {mobileView ? <AppBarMobile /> : <AppBar />}
      <Box
        id="scrollableDiv"
        className={classnames(classes.routes, {
          [classes.noScroll]:
            history.location.pathname === "/my-account" || "/applied",
        })}
      >
        <Router routes={routes} {...rest} />
      </Box>
      {mobileView && <BottomNavMobile />}
    </Container>
  )
}

Citizen.propTypes = {
  routes: PropTypes.array,
}

export default Citizen
