import { Box, Typography } from "@material-ui/core"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import React from "react"

import useStyles from "./styles"
const LittleLogo = () => {
  const classes = useStyles()

  return (
    // <Box position="absolute" bottom={10} marginLeft={3}>
    <Box
      className={classes.root}
      onClick={() => window.open(`https://app.matchboard.tech/`)}
    >
      <Typography>Powered by</Typography>
      <Logo className={classes.logo} />
    </Box>
  )
}

export default LittleLogo
