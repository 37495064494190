import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      borderRight: `1px solid ${theme.palette.divider}`,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      [theme.breakpoints.down("md")]: {
        overflow: "hidden",
      },
    },
  }),
  {
    name: "MessagePage",
  }
)

export default styles
