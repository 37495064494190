import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import CategoryAreaListItem from "components/Common/CategoryAreaListItem"
import HoverText from "components/Common/HoverText"
import SeatMemberListItem from "components/Common/SeatMemberListItem"
import App from "contexts/App"
import BoardContext from "contexts/Citizen/Board"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import MessagesContext from "contexts/Messages"
import dayjs from "dayjs"
import useApply from "hooks/useApply"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useMemo } from "react"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import capitalize from "utils/capitalize"
import checkBoardReviewPeriod from "utils/checkBoardReviewPeriod"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import useStyles from "./styles"

const BoardView = ({ children }) => {
  const classes = useStyles()
  const { board, boardLoading, refetchBoard } = useContext(BoardContext)
  const { setApplied, appliedData } = useContext(CitizenAppContext)
  const { openDialog, authUser, openSnackBar, isIframe } = useContext(App)
  const { createThreadMutation, refetchThreads } = useContext(MessagesContext)
  const history = useHistory()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const accountReadyToApply = useApply(authUser)

  const handleQuickApply = () => {
    //if the user is not log in
    if (isEmpty(authUser)) {
      openDialog("login")
      //if the board app type is not open should let them send an email to an admin
    } else if (board?.app_type !== "open") {
      window.location = `mailto:${board?.admin_emails.join()}?subject= ${
        board?.name
      } inquiry`
      //if the user account it is not ready to apply
    } else if (!accountReadyToApply) {
      history.push("/my-account")
      setApplied(true)
      openSnackBar({ message: "Please complete every form" })
      //user is ready to apply
    } else if (accountReadyToApply && board?.app_type === "open") {
      openDialog("quickApply", board)
    }
  }

  const handleMessage = async () => {
    if (isEmpty(authUser)) {
      openDialog("login")
    } else {
      try {
        const response = await createThreadMutation.mutateAsync({
          user_id: authUser.id,
          entity_id: board.entity_id,
          admin: false,
        })

        await refetchThreads()

        history.push(`/messages/${response.thread_id}`)
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  const validApp = useMemo(() => {
    const validApp = authUser?.id
      ? appliedData.filter((app) => app.board_id === board?.id)
      : []

    return validApp
  }, [authUser, appliedData, board?.id])
  //quick apply button text
  const quickApplyText = useMemo(() => {
    // check if current date is in window application
    if (
      !board?.review_period_rolling &&
      !checkBoardReviewPeriod(board?.review_periods) &&
      !board?.application_override
    ) {
      return "Currently Not Accepting Applications"
    } else if (
      board?.applied === 1 &&
      validApp.length > 0 &&
      validApp[0].active
    ) {
      return "Applied"
    } else if (board?.app_type !== "open") {
      return "Contact Board"
    } else if (board?.membersExpiring === 0 && board?.vacants === 0) {
      return "No positions available"
    } else {
      return "Quick Apply"
    }
  }, [board, validApp])

  // refetch when login happens from inside app
  // (e.g., board page - click Quick Apply or Message Clerk when not logged in)
  useEffect(() => {
    refetchBoard()
  }, [authUser?.id, refetchBoard])

  const disabledQuickApply = useMemo(() => {
    if (
      (!board?.application_override &&
        !board?.review_period_rolling &&
        !checkBoardReviewPeriod(board?.review_periods)) ||
      (board?.applied === 1 && validApp.length > 0 && validApp[0].active) ||
      (board?.membersExpiring === 0 && board?.vacants === 0)
    ) {
      return true
    } else {
      return false
    }
  }, [board, validApp])

  const findOpenPositions = board?.board_positions.filter(
    (x) =>
      !x.active ||
      dayjs(x.term_expiration).isBefore(dayjs(new Date()).add(90, "days"))
  )
  const findfilledPositions = board?.board_positions.filter(
    (x) =>
      x.active &&
      !dayjs(x.term_expiration).isBefore(dayjs(new Date()).add(90, "days"))
  )
  const openPositions = findOpenPositions?.length
  const filledPositions = findfilledPositions?.length
  const totalPositions = filledPositions + openPositions

  const showReviewPeriods = () => {
    if (board?.review_period_rolling === 1) {
      return (
        <Typography
          className={classes.infoBoxData}
          variant="body1"
          style={isIframe ? { fontSize: "1.4rem" } : null}
        >
          Rolling
        </Typography>
      )
    } else {
      return board?.review_periods?.map((x, i) => {
        return (
          <Typography
            key={i}
            className={classes.infoBoxData}
            variant="body1"
            style={isIframe ? { fontSize: "1.4rem" } : null}
          >
            {capitalize(x.review_period_start)}
            {" - "}
            {capitalize(x.review_period_end)}
          </Typography>
        )
      })
    }
  }
  return boardLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      height="100%"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box className={classes.container}>
      <Box className={classes.scrollArea}>
        <Box width="100%" display="flex" flexGrow="1">
          <Box className={classes.titleBox}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-end"
              marginTop={1}
              marginBottom={1}
            >
              <Typography
                color="primary"
                variant="body1"
                className={classes.entityName}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                {board?.entity}, {board?.state}
              </Typography>
              <Typography
                component="span"
                variant="body1"
                className={classes.entityType}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                {capitalize(board?.entity_type)}
              </Typography>
            </Box>
            <Typography variant="h6">{board?.name}</Typography>
          </Box>
          <Box className={classes.upperButtonBox}>
            {!mobileView && (
              <Box height="30px">
                <ButtonRounded
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleMessage}
                >
                  Message Clerk
                </ButtonRounded>
              </Box>
            )}
          </Box>
        </Box>
        {/* start first data row */}
        <Box className={classes.dataContainer}>
          <Grid container spacing={2} className={classes.dataRow}>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                POSITIONS
              </Typography>
              <Typography
                className={classes.infoBoxData}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                <span style={{ fontWeight: "bold" }}>{openPositions}</span> of{" "}
                <span style={{ fontWeight: "bold" }}>{totalPositions}</span>{" "}
                available
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                TERM
              </Typography>
              <Typography
                className={classes.infoBoxData}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                {transformCaseAndSpaces(board?.term)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                APPOINTING AUTHORITY
              </Typography>
              <Typography
                className={classes.infoBoxData}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                {transformCaseAndSpaces(board?.appointing_authority)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                ENABLING LEGISLATION
              </Typography>
              <Typography
                className={classes.urlLink}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
                onClick={() =>
                  window.open(
                    board?.enabling_legislation?.includes("http")
                      ? board?.enabling_legislation
                      : "https://" + board?.enabling_legislation
                  )
                }
              >
                {board?.enabling_legislation ? (
                  <Link target="_blank" color="inherit" underline="always">
                    <HoverText
                      variant="body1"
                      desktopSize={60}
                      phoneSize={20}
                      data={board?.enabling_legislation}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* end first row */}
        {/* second row */}
        <Box className={classes.dataContainer}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                TYPE OF APP
              </Typography>
              <Typography
                className={classes.infoBoxData}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                {transformCaseAndSpaces(board?.app_type)}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                WEBSITE & URL
              </Typography>
              <Typography
                className={classes.urlLink}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
                onClick={() =>
                  window.open(
                    board?.board_url?.includes("http")
                      ? board?.board_url
                      : "https://" + board?.board_url
                  )
                }
              >
                {board?.board_url ? (
                  <Link target="_blank" color="inherit" underline="always">
                    <HoverText
                      variant="body1"
                      desktopSize={60}
                      phoneSize={20}
                      data={board?.board_url}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                RE-APPOINTMENT
              </Typography>
              <Typography
                className={classes.infoBoxData}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                {transformCaseAndSpaces(board?.reappointment)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                REVIEW PERIODS
              </Typography>
              <Box>{showReviewPeriods()}</Box>
            </Grid>
          </Grid>
        </Box>
        {/* end second row*/}
        {/* start third row */}
        <Box className={classes.dataContainer}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                MINUTES URL
              </Typography>
              <Typography
                className={classes.urlLink}
                variant="body1"
                style={isIframe ? { fontSize: "1.4rem" } : null}
                onClick={() =>
                  window.open(
                    board?.minutes_url?.includes("http")
                      ? board?.minutes_url
                      : "https://" + board?.minutes_url
                  )
                }
              >
                {board?.minutes_url ? (
                  <Link target="_blank" color="inherit" underline="always">
                    <HoverText
                      variant="body1"
                      desktopSize={60}
                      phoneSize={20}
                      data={board?.minutes_url}
                    />
                  </Link>
                ) : (
                  <Box height="14px" />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* end third row*/}

        <Box
          className={classes.dataContainer}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Typography
            variant="body1"
            style={
              isIframe
                ? { fontSize: "1.4rem", whiteSpace: "pre-wrap" }
                : { whiteSpace: "pre-wrap" }
            }
          >
            {board?.summary}
          </Typography>
        </Box>
        <Box className={classes.dataContainer}>
          {board?.minimum_degree && (
            <>
              <Typography
                className={classes.infoBoxTitle}
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                SUGGESTED REQUIREMENTS
              </Typography>
              <Box className={classes.dataContainer}>
                <Typography
                  color="primary"
                  style={isIframe ? { fontSize: "1.4rem" } : null}
                >
                  Education
                </Typography>
                <Typography
                  variant="body1"
                  style={isIframe ? { fontSize: "1.4rem" } : null}
                >
                  {transformCaseAndSpaces(board?.minimum_degree)}
                </Typography>
              </Box>
            </>
          )}
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography
                color="primary"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                Areas of Interest
              </Typography>
              {board?.interest?.map((x, i) => (
                <CategoryAreaListItem key={i} value={x.name} />
              ))}
              {/* {Boolean(board?.all_interest) && (
                <CategoryAreaListItem value={`All Interest Types Welcome`} />
              )} */}
            </Grid>
            <Grid item xs={4}>
              <Typography
                color="primary"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                Areas of Experience
              </Typography>
              {board?.experience?.map((x, i) => (
                <CategoryAreaListItem key={i} value={x.name} />
              ))}
              {Boolean(board?.all_experience) && (
                <CategoryAreaListItem value={`All Experience Types Welcome`} />
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography
                color="primary"
                style={isIframe ? { fontSize: "1.4rem" } : null}
              >
                Areas of Expertise
              </Typography>
              {board?.expertise?.map((x, i) => (
                <CategoryAreaListItem key={i} value={x.name} />
              ))}
              {Boolean(board?.all_expertise) && (
                <CategoryAreaListItem value={`All Expertise Types Welcome`} />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.dataContainer}>
          <Typography
            className={classes.infoBoxTitle}
            style={isIframe ? { fontSize: "1.4rem" } : null}
          >
            CURRENT SEAT MEMBERS
          </Typography>
          <Grid container spacing={3}>
            {board?.board_positions.map((p, i) => {
              return (
                <Grid item key={i} xs={6} sm={4}>
                  <SeatMemberListItem
                    member={p}
                    vacant={p?.first_name ? false : true}
                    boardTerm={board?.term}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </Box>
      {mobileView ? (
        <Box className={classes.buttonBoxMobile}>
          <ButtonRounded
            variant="outlined"
            onClick={handleMessage}
            style={{
              color: "#FFFFFF",
              borderColor: "#FFFFFF",
              minWidth: "145px",
            }}
          >
            Message Clerk
          </ButtonRounded>

          <ButtonRounded
            variant="outlined"
            onClick={handleQuickApply}
            style={{
              color: "#FFFFFF",
              borderColor: "#FFFFFF",
              minWidth: "145px",
            }}
            disabled={disabledQuickApply}
          >
            {quickApplyText}
          </ButtonRounded>
        </Box>
      ) : (
        <>
          <Box className={classes.buttonBox}>
            <ButtonRounded
              variant="outlined"
              onClick={handleQuickApply}
              disabled={disabledQuickApply}
            >
              {quickApplyText}
            </ButtonRounded>
          </Box>
        </>
      )}
    </Box>
  )
}

BoardView.propTypes = {
  children: PropTypes.node,
}

export default BoardView
