import {
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from "@material-ui/core"
import {
  getBoardMemberRenewal,
  updateBoardMemberRenewal,
} from "actions/Citizen/board"
import App from "contexts/App"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useMutation, useQuery } from "react-query"
import { useLocation } from "react-router-dom"

import useStyles from "./styles"

const RenewTerm = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const location = useLocation()
  const queryString = location.search

  const { openSnackBar } = useContext(App)

  const params = {
    token: queryString.split("=").pop().split("%7D")[0],
  }

  const { data: boardMember, isLoading: boardMemberLoading } = useQuery(
    ["boardMemberRenewal", params],
    () => getBoardMemberRenewal(params),
    { retry: false }
  )

  const memberRenewal = useMutation((data) =>
    updateBoardMemberRenewal(boardMember.data.id, data)
  )
  const handleSubmit = (event) => {
    const data = { renewal_interest: event.currentTarget.value }
    memberRenewal.mutateAsync(data).then((res) => {
      openSnackBar({
        message: "Your clerk will be notified of interest to renew",
      })
    })
  }
  return (
    <Paper square className={classes.root}>
      <Container className={classes.container}>
        <Page>
          {boardMemberLoading ? (
            <CircularProgress />
          ) : !boardMember ? (
            <Box className={classes.questionBox}>
              <Typography variant="h1" color="primary" align="center">
                Response Completed
              </Typography>
            </Box>
          ) : (
            <Box className={classes.questionBox}>
              <Typography variant="h1" color="primary" align="center">
                {`Are you interested in being reappointed to ${boardMember?.board?.name} for ${boardMember?.board?.entity} ${boardMember?.board?.entity_type}?`}
              </Typography>
              <Box className={classes.answerBox}>
                {memberRenewal?.isSuccess ? (
                  <Box>
                    <Typography variant="h1" color="primary" align="center">
                      Your clerk will be notified. Thank you!
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Button
                      value="1"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmit}
                    >
                      Yes
                    </Button>
                    <Button
                      value="0"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleSubmit}
                    >
                      No
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          )}
          <Typography
            variant="h3"
            align="center"
            style={{ position: "absolute", bottom: 30 }}
          >
            Please note, this lets council know whether you would like to be
            reappointed and does not guarantee that you will be reappointed.
          </Typography>
        </Page>
      </Container>
    </Paper>
  )
}

RenewTerm.propTypes = {
  routes: PropTypes.array,
}

export default RenewTerm
