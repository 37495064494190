import api from "store/api"

// Get threads
export const getThreads = async (isClerk) => {
  const userType = isClerk ? "admin" : "app"

  try {
    const response = await api.get(`/${userType}/messages/threads/`)
    return response.data.data.threads
  } catch (error) {
    throw new Error(error)
  }
}

// Get messages by thread id
export const getMessages = async (threadId, isClerk) => {
  const userType = isClerk ? "admin" : "app"

  try {
    const response = await api.get(`/${userType}/messages/threads/${threadId}`)
    return response.data.data.messages
  } catch (error) {
    throw new Error(error)
  }
}

// Create thread
export const createThread = async (data) => {
  const userType = data.admin ? "admin" : "app"
  const threadData = {
    user_id: data.user_id,
    entity_id: data.entity_id,
  }

  try {
    const response = await api.post(`/${userType}/messages/threads`, threadData)
    return response.data.data
  } catch (error) {
    throw new Error(error)
  }
}

// Create message
export const createMessage = async (data) => {
  const userType = data.admin ? "admin" : "app"
  const sentBy = data.admin ? "sent_by_entity" : "sent_by_user"
  const id = data.admin ? data.entity_id : data.user_id
  const messageData = {
    message: data.message,
    [sentBy]: id,
    thread_id: data.thread_id,
  }

  try {
    const response = await api.post(`/${userType}/messages/`, messageData)
    return response.data.data
  } catch (error) {
    throw new Error(error)
  }
}

// Viewed message
export const viewedMessage = async (data) => {
  const userType = data.admin ? "admin" : "app"

  try {
    const response = await api.put(`/${userType}/messages/viewed`, data)
    return response.data.data
  } catch (error) {
    throw new Error(error)
  }
}
