import { useMediaQuery, useTheme } from "@material-ui/core"
import MobileSearchFilter from "components/Citizen/MobileSearchFilter"
import Searchbar from "components/Citizen/Searchbar"
import App from "contexts/App"
import { BoardsContainer } from "contexts/Boards"
import Page from "pages/Page"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import Router from "router"

import useStyles from "./styles"

const Boards = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { searchValue, setSearchValue } = useContext(App)

  return (
    <Page>
      <BoardsContainer>
        {!mobileView ? (
          <Searchbar />
        ) : (
          <MobileSearchFilter
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        )}
        <Router routes={routes} {...rest} />
      </BoardsContainer>
    </Page>
  )
}

Boards.propTypes = {
  routes: PropTypes.array,
}

export default Boards
