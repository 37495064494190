import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import noBoards from "assets/no-boards-icon.png"
import NoData from "components/Common/NoData"
import BoardsContext, { BoardsContainer } from "contexts/Boards"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useHistory } from "react-router-dom"

import DashboardCard from "../DashboardCard"
import useStyles from "./styles"

const BoardsList = () => {
  const classes = useStyles()
  const history = useHistory()

  const { boardsData, boardsDataLoading } = useContext(BoardsContext)

  // shows right-side board container & triggers conditional border styling
  const navigateToBoardView = (id) => {
    history.push(`/boards/${id}`)
  }

  const showAppicants = (applicants) => {
    if (applicants) {
      return applicants === 1
        ? `${applicants} Applicant`
        : `${applicants} Applicants`
    } else {
      return ""
    }
  }
  if (boardsDataLoading) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <List className={classes.root}>
        {boardsData?.length > 0 ? (
          boardsData?.map((board) => (
            <ListItem
              key={board.id}
              className={classes.listItem}
              onClick={() => navigateToBoardView(board?.id)}
            >
              <ListItemText
                classes={{
                  multiline: classes.listItemText,
                }}
                disableTypography
                primary={
                  <Box width="70%" display="flex" justifyContent="flex-start">
                    <span className={classes.primaryText}>{board.name}</span>
                  </Box>
                }
                secondary={
                  <Box width="30%" display="flex" justifyContent="flex-end">
                    <span className={classes.secondaryText}>
                      {showAppicants(board?.applicants)}
                    </span>
                  </Box>
                }
              />
            </ListItem>
          ))
        ) : (
          <NoData
            image={noBoards}
            title="No boards have been added yet"
            props={{
              backgroundColor: "#f4f4f4",
              padding: "20px",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </List>
    )
  }
}

const Boards = ({ children }) => {
  return (
    <BoardsContainer>
      <DashboardCard>
        <BoardsList />
      </DashboardCard>
    </BoardsContainer>
  )
}

Boards.propTypes = {
  children: PropTypes.node,
}

export default Boards
