import { createBoard, getBoards } from "actions/boards"
import { getOptions } from "actions/Citizen/account"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { createContext, useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

import AppContext from "./App"

const BoardsContext = createContext()
export default BoardsContext

export const BoardsContainer = ({ children }) => {
  // for use in Filter by Cateogory component under boards list

  const { authUser, isClerk, isSuperAdmin } = useContext(AppContext)

  const createBoardMutation = useMutation(createBoard)

  // get boards citizen list ...
  const getBoardsMutation = useMutation(getBoards)

  // get boards list on Clerk side...
  const { data: boardsData, isLoading: boardsDataLoading } = useQuery(
    ["boardsData", authUser && isClerk],
    () => getBoards(authUser?.entity_id, isClerk),
    {
      retry: false,
      enabled: Boolean(authUser?.entity_id),
    }
  )

  return (
    <BoardsContext.Provider
      value={{
        createBoardMutation,
        boardsData,
        boardsDataLoading,
        getBoardsMutation,
      }}
    >
      {children}
    </BoardsContext.Provider>
  )
}

BoardsContainer.propTypes = {
  children: PropTypes.node,
}
