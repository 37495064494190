import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import classnames from "clsx"
import ButtonRounded from "components/Common/ButtonRounded"
import ListItemWithContent from "components/Common/ListItemWithContent"
import * as dayjs from "dayjs"
import PropTypes from "prop-types"
import { Fragment } from "react"
import getColor from "utils/getColor"

import useStyles from "./styles"

const Dot = () => {
  const classes = useStyles()
  return <Box className={classes.dot} />
}

const ReviewListItem = ({ data, expandedPanel, setExpandedPanel }) => {
  const classes = useStyles()
  const { applicants, review_sent, reviewId } = data

  //open the user default email provider and attach the subject and the email
  //to send
  const openEmail = (email) => {
    window.location = `mailto:${email}?subject= Review Inquiry`
  }

  // now denominator is responses received with a Yes or No
  // removed Need more info from calculation per client
  const percentage = (applicant) => {
    if (!applicant?.responses) {
      return []
    }
    const validResponses = applicant?.responses?.filter(
      (res) => res.review_complete && res.response_id !== 3
    )
    return (
      (validResponses?.filter((x) => x.response_id === 1).length * 100) /
      validResponses?.length
    ).toFixed(0)
  }

  // change handler makes one panel open at a time
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  return (
    <Accordion
      square
      expanded={reviewId === expandedPanel}
      onChange={handleChange(reviewId)}
      className={classes.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.arrowIcon} />}
        classes={{ content: classes.accordionSummary }}
      >
        <Typography>
          Review session sent on {dayjs(review_sent).format("M/D/YY")}
        </Typography>
        <Typography>
          {applicants?.length === 1
            ? applicants?.length + " applicant"
            : applicants?.length + " applicants"}{" "}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContent}>
        {applicants?.map((applicant, i) => (
          <Box key={i}>
            <ListItemWithContent
              avatar={applicant}
              ListItemTextProps={{
                disableTypography: true,
              }}
              primary={
                <Box display="flex">
                  <Typography variant="body2" className={classes.nameText}>
                    {applicant?.first_name} {applicant?.last_name}
                  </Typography>
                  <Typography
                    style={getColor(percentage(applicant))}
                    variant="body2"
                    className={classnames(
                      classes.nameText,
                      classes.percentText
                    )}
                  >
                    {isNaN(percentage(applicant)) ? "-" : percentage(applicant)}
                    %
                  </Typography>
                </Box>
              }
              secondary={
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 300,
                  }}
                >
                  <span>
                    {applicant?.county}, {applicant?.city}, SC{" "}
                    {applicant?.zip_code}
                  </span>
                </Typography>
              }
            >
              <ButtonRounded
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => openEmail(applicant?.email)}
              >
                Contact
              </ButtonRounded>
            </ListItemWithContent>
            <TableContainer className={classes.table}>
              <Table
                className={classes.table}
                size="small"
                aria-label="review-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Reviewer</TableCell>
                    <TableCell align="center" width="45px">
                      Yes
                    </TableCell>
                    <TableCell align="center" width="45px">
                      No
                    </TableCell>
                    <TableCell align="center" width="120px">
                      Need more info
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicant?.responses?.map?.((res, i) => {
                    // response_id key: 1 Yes, 2 No, 3 Need more info
                    return (
                      <Fragment key={i}>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <Typography variant="body1">{res.email}</Typography>
                          </TableCell>
                          <TableCell align="center" width="45px">
                            {Boolean(res?.review_complete) &&
                              Number(res?.response_id) === 1 && <Dot />}
                          </TableCell>
                          <TableCell align="center" width="45px">
                            {Boolean(res?.review_complete) &&
                              Number(res?.response_id) === 2 && <Dot />}
                          </TableCell>
                          <TableCell align="center" width="120px">
                            {Boolean(res?.review_complete) &&
                              res?.response_id === 3 && <Dot />}
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

ReviewListItem.propTypes = {
  data: PropTypes.object,
  expandedPanel: PropTypes.number,
  setExpandedPanel: PropTypes.func,
}

export default ReviewListItem
