import { Box } from "@material-ui/core"
import noReviews from "assets/no-reviews-icon.png"
import NoData from "components/Common/NoData"
import BoardContext from "contexts/Clerk/Board"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import ReviewListItem from "./ReviewListItem"
import useStyles from "./styles"

const ReviewsList = () => {
  const classes = useStyles()
  const location = useLocation()
  const [expandedPanel, setExpandedPanel] = useState(null)

  const { boardReviews, boardReviewsLoading } = useContext(BoardContext)

  // reformats from object of objects to
  // iterable array of objects with each review_id becoming a k/v pair
  const boardReviewsArr =
    boardReviews &&
    Object.entries(boardReviews).map((e, i) => ({
      ...e[1],
      reviewId: parseInt(e[0]),
    }))

  // if location obj contains state (passed from dashboard card)
  // used to default a certain review accordion to open (normally all closed)
  useEffect(() => {
    if (location?.state?.chosenReview) {
      setExpandedPanel(location?.state?.chosenReview)
    }
  }, [location?.state?.chosenReview])

  if (!isEmpty(boardReviews) && !boardReviewsLoading) {
    return boardReviewsArr?.map?.((rev, i) => (
      <Box key={rev?.reviewId}>
        <ReviewListItem
          data={rev}
          expandedPanel={expandedPanel}
          setExpandedPanel={setExpandedPanel}
        />
      </Box>
    ))
  } else {
    return (
      <Box textAlign="center" paddingY={2}>
        <NoData
          image={noReviews}
          title="No applicants are in the review process currently"
          subtitle="To initiate, please share applicants from the Applicants tab"
          props={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    )
  }
}

ReviewsList.propTypes = {
  children: PropTypes.node,
}

export default ReviewsList
