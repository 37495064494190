import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
    },
    form: {
      display: "flex",
    },
    inputBox: {
      flexGrow: 1,
    },
    composeBox: {
      borderTop: `1px solid ${theme.palette.primary.main}`,
    },
    messageInput: {
      padding: theme.spacing(0.5),
      marginRight: theme.spacing(1),
      width: "100%",
      minHeight: "100%",
      maxHeight: "150px",
      border: "none",
      outline: "none",
      overflowX: "hidden",
      overflowY: "auto",
      fontSize: "1.4rem",
      fontWeight: 400,
      "&:empty:before": {
        content: "attr(data-placeholder)",
        color: "rgba(0, 0, 0, .32)",
        cursor: "text",
      },
    },
    inputContainer: {
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
      },
    },
    sendButton: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
      },
    },
    formCover: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  }),
  {
    name: "Form-SendMessage",
  }
)

export default styles
