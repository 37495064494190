import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    height: "100%",
    overflow: "auto",
  },
}))

export default styles
