import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "80%",
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(8),
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: "#898989",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },

  buttonText: {
    fontSize: "1rem",
    color: theme.palette.primary.dark,
  },
  textButton: {
    alignSelf: "center",
  },
  topContainer: {
    height: "320px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      minHeight: "60%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
  },

  chanegImage: {
    // color: theme.palette.primary.dark,
  },
  avatar: {
    width: "108px",
    height: "108px",
    fontSize: "50px",
  },
  userName: {
    fontSize: "1.8rem",
    marginTop: theme.spacing(1),
  },
  districtCityText: {
    fontSize: "1.4rem",
    marginBottom: theme.spacing(1),
  },
  bottomContainer: {
    height: "140px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      minHeight: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
  },
  contactLine: {
    display: "flex",
    alignContent: "space-around",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  iconContainer: {
    width: "26px",
    height: "26px",
    borderRadius: "50px",
    backgroundColor: "#F3E2EB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.dark,
    fontSize: "10px",
  },
  text: {
    fontSize: "1.4rem",
  },
}))

export default styles
