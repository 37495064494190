import Record from "./base"

export class User extends Record {
  get initials() {
    return `${this.first_name?.[0] || ""}${this.last_name?.[0] || ""}`
  }
  constructor(...args) {
    super(...args)
    const [model, props] = args
    if (this.phone.includes("+")) {
      this.phone = this.phone.slice(2, this.phone.length)
    }
  }
}
