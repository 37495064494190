const getColor = (value) => {
  if (value < 20) {
    const red = { color: "#ae5c4c" }
    return red
  } else if (value > 20 && value < 80) {
    const yellow = { color: "#baba1c" }
    return yellow
  } else if (value > 80) {
    const green = { color: "#1FA04C" }
    return green
  }
}
export default getColor
