import api from "store/api"
import API from "store/api"
import serialize from "store/serialize"

export const getReviews = (token) => {
  return api.get(`/reviews/${token}`).then((res) => {
    return serialize("reviewResponse", res.data.data)
  })
}

export const sendReview = (data) => {
  return api.post(`/reviews/`, data).then((res) => res)
}

export const getResumeFromReviews = (resumeId) => {
  return api
    .get(`/reviews/file`, {
      params: {
        id: resumeId,
      },
    })
    .then((res) => res.data.data)
}
