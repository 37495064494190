import { Box, Card } from "@material-ui/core"
import EntityGrid from "components/Clerk/Entity/EntityGrid"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import EntityContext from "contexts/Clerk/Entity"
import { useContext } from "react"

import useStyles from "./styles"

const EntityRightContainer = () => {
  const classes = useStyles()

  const {
    entityBoards,
    entityBoardsLoading,
    entityData,
    entityDataLoading,
    reviewersData,
    reviewersDataLoading,
  } = useContext(EntityContext)

  const { openDialog } = useContext(AppContext)

  return (
    <Box height="100%">
      <Box height="48px" pb={1} display="flex" justifyContent="flex-end">
        <ButtonRounded
          variant="contained"
          className={classes.importButton}
          style={entityBoardsLoading ? { display: "none" } : null}
          onClick={() =>
            openDialog("uploadCsv", {
              fromSuper: true,
              entityId: entityData?.id,
            })
          }
        >
          Data Import
        </ButtonRounded>
      </Box>
      <Box display="flex" flexDirection="column" height="100%">
        <Box maxHeight="45%" overflow="hidden" marginBottom={2}>
          <Card className={classes.cardRoot}>
            <EntityGrid
              title="Boards"
              data={entityBoards}
              loading={entityBoardsLoading}
              entityId={entityData?.id}
            />
          </Card>
        </Box>
        <Box maxHeight="45%" overflow="hidden" marginBottom={2}>
          <Card className={classes.cardRoot}>
            <EntityGrid
              title="Files"
              data={entityData?.files}
              loading={entityDataLoading}
            />
          </Card>
        </Box>
        <Box maxHeight="45%" overflow="hidden" marginBottom={2}>
          <Card className={classes.cardRoot}>
            <EntityGrid
              title="Default Review Members"
              data={reviewersData}
              loading={reviewersDataLoading}
              entityId={entityData?.id}
            />
          </Card>
        </Box>
      </Box>
    </Box>
  )
}

export default EntityRightContainer
