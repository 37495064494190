import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import MemberEditCard from "components/Clerk/Boards/MembersList/MemberEditCard"
import ReplacementMemberCard from "components/Clerk/Boards/MembersList/ReplacementMemberCard"
import UpdateCreateMember from "components/Clerk/Boards/MembersList/UpdateCreateMember"
import ButtonRounded from "components/Common/ButtonRounded"
import App from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import PositionName from "dialogs/EditBoardMember/PositionName"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { useContext } from "react"

import useStyles from "./styles"

const EditBoardMemberDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { dialogs, closeDialog } = useContext(App)
  const {
    board,
    getApplicantsWithStatusMutation,
    appointApplicantMutation,
    updateApplicantStatusMutation,
    updateBoardMemberMutation,
    addBoardMemberMutation,
  } = useContext(BoardContext)
  const [approvedApplicants, setApprovedApplicants] = useState([])

  const thisDialog = dialogs?.["editBoardMember"] || {}
  const { open = false, data = {} } = thisDialog

  const currentMember = thisDialog?.data?.currentMember

  const [member, setMember] = useState({})
  //is vacant if the position dont have a member
  const [vacant, setVacant] = useState(false)
  //state to edit the member or create a member
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if (open) {
      setMember(currentMember || {})
      setVacant(thisDialog?.data?.vacant)
    }
  }, [open])

  const handleClose = () => {
    closeDialog("editBoardMember", data)
    setTimeout(() => {
      setMember({})
      setEditing(false)
    }, [200])
  }
  //get in consideration applicants (status===3)
  useEffect(() => {
    if (open) {
      getApplicantsWithStatusMutation
        .mutateAsync()
        .then((res) => {
          setApprovedApplicants(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [open])

  return (
    <Dialog
      open={open}
      // maxWidth={mobileView ? false : "sm"}
      classes={{ paperWidthSm: classes.dialog }}
      keepMounted={false}
    >
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title}>Edit Board Member</Typography>
        </Box>
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <PositionName
          member={member}
          boardId={board?.id}
          setMember={setMember}
        />
        {getApplicantsWithStatusMutation?.isLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={15} />
          </Box>
        ) : (
          <>
            {editing ? (
              <UpdateCreateMember
                member={member}
                vacant={vacant}
                setEditing={setEditing}
                updateMutation={updateBoardMemberMutation}
                addMutation={addBoardMemberMutation}
                boardId={board?.id}
                setVacant={setVacant}
                setMember={setMember}
              />
            ) : (
              <MemberEditCard
                member={member}
                vacant={vacant}
                updateMutation={updateBoardMemberMutation}
                boardId={board?.id}
                setVacant={setVacant}
                setEditing={setEditing}
                setMember={setMember}
                statusMutation={updateApplicantStatusMutation}
                setApprovedApplicants={setApprovedApplicants}
              />
            )}
            {vacant ? (
              <Box marginTop={4}>
                <Typography variant="body2" color="primary">
                  Replace Board Member
                </Typography>
                <Box marginTop={2}>
                  {!isEmpty(approvedApplicants) ? (
                    approvedApplicants?.map((x, i) => (
                      <ReplacementMemberCard
                        key={i}
                        applicant={x}
                        statusMutation={updateApplicantStatusMutation}
                        boardId={board.id}
                        setApprovedApplicants={setApprovedApplicants}
                        appointApplicantMutation={appointApplicantMutation}
                        setVacant={setVacant}
                        setMember={setMember}
                        vacant={vacant}
                        member={member}
                        setEditing={setEditing}
                      />
                    ))
                  ) : (
                    <Box>
                      <Typography>No applicants to show</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : null}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <ButtonRounded
          color="primary"
          variant="outlined"
          onClick={handleClose}
          type="button"
        >
          Cancel
        </ButtonRounded>
      </DialogActions>
    </Dialog>
  )
}

export default EditBoardMemberDialog
