import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: "468px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: "1.4rem",
  },

  saveButton: {
    marginLeft: theme.spacing(2),
  },
  buttonLabel: {
    minWidth: "36px",
  },
  textfield: {
    marginBottom: theme.spacing(1.5),
  },

  datePicker: {
    color: theme.palette.error.main,
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.error.main,
    },
    "& .MuiFormHelperText-root": {
      "& .MuiError": {
        marginLeft: "0px",
        position: "absolute",
        bottom: "-20px",
      },
    },
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
}))

export default styles
