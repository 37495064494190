import { Box, Typography, useMediaQuery } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import { useTheme } from "@material-ui/styles"
import ButtonRounded from "components/Common/ButtonRounded"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import RequestsContext from "contexts/Clerk/Requests"
import * as dayjs from "dayjs"
import MaterialTable from "material-table"
import Page from "pages/Page"
import { useContext } from "react"
import AutoSizer from "react-virtualized-auto-sizer"

import useStyles from "./styles"

const List = () => {
  const theme = useTheme()
  const tabletView = useMediaQuery(theme.breakpoints.down("sm"))
  const phoneView = useMediaQuery(theme.breakpoints.down("xs"))

  const { openDialog } = useContext(AppContext)
  const { requestsData, requestsDataLoading } = useContext(RequestsContext)

  const hideTablet = tabletView ? "none" : null
  const hidePhone = phoneView ? "none" : null

  const handleOpenDialog = (rowData) => {
    let data = rowData
    openDialog("requestReview", data)
  }

  const headCells = [
    {
      title: "State",
      field: "state",
      render: function stateText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.state}
              desktopSize={2}
              phoneSize={2}
            />
          </>
        )
      },

      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 30px",
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 30px",
      },
      sorting: false,
    },
    {
      title: "Entity",
      field: "entity",
      render: function entityType(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.entity_type}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        display: hidePhone,
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        display: hidePhone,
      },
      sorting: false,
    },
    {
      title: "Entity Name",
      field: "entity_name",
      headerStyle: {
        width: tabletView ? "50%" : "10%",
        maxWidth: tabletView ? "50%" : "10%",
        height: "25px",
        padding: "0px 10px",
      },
      cellStyle: {
        width: tabletView ? "50%" : "10%",
        maxWidth: tabletView ? "50%" : "10%",
        padding: "0px 10px",
      },
      sorting: false,

      render: function entityNameText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={rowData?.entity}
              desktopSize={25}
              phoneSize={20}
            />
          </Box>
        )
      },
    },
    {
      title: "Title",
      field: "title",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        display: hideTablet,
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        display: hideTablet,
      },
      sorting: false,

      render: function titleText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={rowData?.title}
              desktopSize={25}
              phoneSize={20}
            />
          </Box>
        )
      },
    },
    {
      title: "First Name",
      field: "first_name",
      render: function firstNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.first_name}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        // display: hideTablet,
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        // display: hideTablet,
      },
      sorting: false,
    },
    {
      title: "Last Name",
      field: "last_name",
      render: function lastNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.last_name}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        height: "25px",
        padding: "0px 10px",
        display: hideTablet,
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "0px 10px",
        display: hideTablet,
      },
      sorting: false,

      render: function emailText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={rowData?.email}
              desktopSize={25}
              phoneSize={20}
            />
          </Box>
        )
      },
    },
    {
      title: "Email Verified",
      field: "email_verified",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 10px",
        display: hideTablet,
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 10px",
        display: hideTablet,
      },
      sorting: false,

      render: function emailVerified(rowData) {
        return (
          <Box>{rowData?.email_verified ? <CheckIcon /> : <CloseIcon />}</Box>
        )
      },
    },
    {
      title: "Submitted",
      field: "submitted",
      sorting: false,

      render: function submittedCellData(rowData) {
        const diff = dayjs(rowData?.updated).diff(dayjs(), "day")
        const displayDiff = () => {
          if (diff === 0) {
            return "Today"
          } else if (diff >= 1) {
            return `${diff} days ago`
          } else {
            return null
          }
        }
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={dayjs(rowData?.updated).format("MM/DD/YYYY")}
              desktopSize={20}
              phoneSize={20}
            />
            <Typography variant="body1" style={{ color: "#898989" }}>
              {displayDiff()}
            </Typography>
          </>
        )
      },
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        display: hideTablet,
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
        display: hideTablet,
      },
      customSort: (a, b) => new Date(a.memberSince) - new Date(b.memberSince),
    },
    {
      title: "",
      field: "",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      sorting: false,
      render: function ViewDetailsButton(rowData) {
        return (
          <ButtonRounded
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog(rowData)}
            data-cy="open-review-dialog"
          >
            <Typography variant="body2">Review</Typography>
          </ButtonRounded>
        )
      },
    },
  ]

  const classes = useStyles()
  return (
    <Page>
      <Box className={classes.rigthContainer} height="100%">
        <Typography variant="h2" className={classes.title}>
          Access Request
        </Typography>
        <AutoSizer style={{ width: "100%", height: "100%" }}>
          {({ width, height }) => {
            return (
              <>
                <MaterialTable
                  // tableRef={tableRef}
                  isLoading={requestsDataLoading}
                  // loadingType="linear"
                  options={{
                    toolbar: false,
                    title: "",
                    headerStyle: {
                      backgroundColor: "#fff",
                      position: "sticky",
                      top: 0,
                      whiteSpace: "nowrap",
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "50px",
                      padding: "5px 10px",
                      color: "#A01F62",
                      borderTop: "0px",
                      paddingTop: "15px",
                    },

                    maxBodyHeight: height - 55,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100],
                    rowStyle: (rowData, i) => ({
                      backgroundColor: i % 2 !== 0 ? "#fff" : "#F8F8F8",
                      maxHeight: tabletView ? "70px" : "40px",
                      height: tabletView ? "70px" : "40px",
                      padding: "5px 10px",
                    }),
                  }}
                  classses={{ root: classes.root }}
                  columns={headCells}
                  data={requestsData}
                />
              </>
            )
          }}
        </AutoSizer>
      </Box>
    </Page>
  )
}

export default List
