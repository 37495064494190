import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    dialog: {
      maxWidth: "550px",
    },
    title: {
      fontSize: theme.typography.h1.fontSize,
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    contentRoot: {
      paddingTop: 0,
    },
  }),
  {
    name: "EditBoardMemberDialog",
  }
)

export default styles
