import { Box } from "@material-ui/core"
import FormsContainer from "components/Citizen/MyAccount/FormsContainer"
import PersonalInfoContainer from "components/Citizen/MyAccount/PersonalInfoContainer"
import UploadInformationContainer from "components/Citizen/MyAccount/UploadInformationContainer"
import WelcomeBanner from "components/Citizen/MyAccount/WelcomeBanner"
import { AccountContainer } from "contexts/Citizen/account"
import AddEducationDialog from "dialogs/AddEducation"
import AddOccupationDialog from "dialogs/AddOccupation"
import AddPersonalInfo from "dialogs/AddPersonalInfo"
import AddQualifiersDialog from "dialogs/AddQualifiers"
import AddReferencesDialog from "dialogs/AddReferences"
import ContactInformation from "dialogs/ContactInformation"
import UploadImage from "dialogs/UploadImage"
import UploadPdfDialog from "dialogs/UploadPdf"
import Page from "pages/Page"

import useStyles from "./styles"

const MyAccount = () => {
  const classes = useStyles()

  return (
    <Page>
      <AccountContainer>
        <WelcomeBanner />
        <Box className={classes.contentContainer}>
          <Box className={classes.leftContainer}>
            <PersonalInfoContainer />
          </Box>
          <Box className={classes.middleContainer}>
            <UploadInformationContainer />
          </Box>
          <Box className={classes.rightContainer}>
            <FormsContainer />
          </Box>
        </Box>
        {/* dialogs below */}
        <AddPersonalInfo />
        <ContactInformation />
        <AddQualifiersDialog />
        <UploadPdfDialog />
        <AddReferencesDialog />
        <AddEducationDialog />
        <AddOccupationDialog />
        <UploadImage />
      </AccountContainer>
    </Page>
  )
}

export default MyAccount
