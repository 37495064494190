import { DialogContent, DialogTitle, Typography } from "@material-ui/core"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import FileUpload from "components/FileUpload"
import AppContext from "contexts/App"
import { useContext } from "react"

import useStyles from "./styles"

const UploadPdfDialog = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["uploadPdfDialog"] || {}
  const { open = false, data = {} } = thisDialog

  let title = data?.title || ""
  let type = data?.type || ""

  const handleClose = () => {
    closeDialog("uploadPdfDialog")
  }

  return (
    <ConditionalDialogWrapper open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h2">{title}</Typography>
      </DialogTitle>

      <DialogContent style={{ display: "flex", flexDirection: "column" }}>
        <Typography className={classes.subtitle}>
          Please upload a PDF or Word file
        </Typography>

        <FileUpload type="resume" handleClose={handleClose} />
      </DialogContent>
    </ConditionalDialogWrapper>
  )
}

export default UploadPdfDialog
