import { Box, ListItem, ListItemText } from "@material-ui/core"
import classnames from "clsx"
import PropTypes from "prop-types"

import AvatarComponent from "../Avatar"
import useStyles from "./styles"

const ListItemWithContent = ({
  avatar,
  primary,
  secondary,
  children,
  selected,
  className = "",
  viewed,
  ListItemTextProps = {},
  ...props
}) => {
  const classes = useStyles()
  return (
    <ListItem className={classnames(classes.listItem, className)} {...props}>
      {avatar && (
        <AvatarComponent
          user={avatar}
          style={{ borderRadius: "50%", marginRight: "12px" }}
        />
      )}
      <Box className={classes.listItemTextBox} overflow="hidden">
        <ListItemText
          {...ListItemTextProps}
          primary={primary}
          secondary={secondary}
          classes={
            viewed
              ? { primary: classes.primaryViewed, secondary: classes.secondary }
              : { secondary: classes.secondary }
          }
        />
      </Box>
      {children}
    </ListItem>
  )
}

ListItemWithContent.propTypes = {
  avatar: PropTypes.object,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  ListItemTextProps: PropTypes.object,
  selected: PropTypes.bool,
  viewed: PropTypes.bool,
}

export default ListItemWithContent
