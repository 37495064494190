import { Box, Card, CircularProgress, Typography } from "@material-ui/core"
import ChipSquare from "components/Common/ChipSquare"
import * as dayjs from "dayjs"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"

import useStyles from "./styles"

const ApplicantHistory = ({ applicantHistory }) => {
  const classes = useStyles()
  const perPage = 15

  const [displayedData, setDisplayedData] = useState([])
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    setDisplayedData(applicantHistory?.slice(0, perPage))
    setHasMore(applicantHistory?.length > displayedData?.length)
  }, [applicantHistory])

  const loadData = () => {
    setDisplayedData(() =>
      applicantHistory?.slice(0, displayedData.length + perPage)
    )
    if (displayedData?.length === applicantHistory?.length) {
      setHasMore(false)
    }
  }

  return (
    <Card
      className={classes.container}
      style={{
        height: 300,
        overflow: "auto",
        display: "flex",
        width: "100%",
        padding: "20px",
      }}
      id="scrollable-applicant-history"
    >
      <InfiniteScroll
        style={{ width: "100%" }}
        dataLength={displayedData?.length || 15}
        scrollableTarget="scrollable-applicant-history"
        next={loadData}
        hasMore={hasMore}
        loader={
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        endMessage={
          <Box width="100%" textAlign="center">
            <Typography
              variant="body2"
              style={{
                opacity: 0.7,
                marginBottom: "5px",
              }}
            >
              End of Results
            </Typography>
          </Box>
        }
      >
        {displayedData?.map?.((x, i) => {
          return (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              width="100%"
              marginBottom="20px"
            >
              <Box width="100%">
                <Typography style={{ fontSize: "1.325rem" }}>
                  {x?.name}
                </Typography>
                {x.status_id === 1 ? (
                  <Typography style={{ color: "gray" }}>
                    Submitted:{" "}
                    {dayjs(x?.created).format("dddd, MMMM D, YYYY h:mm A")}
                  </Typography>
                ) : (
                  <Typography style={{ color: "gray" }}>
                    Updated:{" "}
                    {dayjs(x?.created).format("dddd, MMMM D, YYYY h:mm A")}
                  </Typography>
                )}
              </Box>
              <Box>
                <ChipSquare status={{ label: x?.status, id: x?.status_id }} />
              </Box>
            </Box>
          )
        })}
      </InfiniteScroll>
    </Card>
  )
}

ApplicantHistory.propTypes = {
  applicantHistory: PropTypes.array,
}

export default ApplicantHistory
