import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from "@material-ui/core"
import axios from "axios"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import { useCallback, useContext, useState } from "react"
import { useDropzone } from "react-dropzone"
import Cropper from "react-easy-crop"
import { useQueryClient } from "react-query"
import api from "store/api"
import serialize from "store/serialize"
import getCroppedImg from "utils/cropImage.js"

import useStyles from "./styles"

const UploadImage = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { setAuthUserState, setAuthData, token } = useContext(AppContext)
  const { updateProfileImageMutation } = useContext(AccountContext)

  const { dialogs, closeDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["uploadImage"] || {}
  const { open = false, data = {} } = thisDialog
  const image = data?.image
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1.1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("")
  const [imageToEdit, setImageToEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    getBase64(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, acceptedFiles, rejectedFiles } =
    useDropzone({
      onDrop,
      accept: "image/*",
    })

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleClose = () => {
    closeDialog("uploadImage")
    setTimeout(() => {
      setCrop({ x: 0, y: 0 })
      setZoom(1.1)
      setCroppedAreaPixels("")
      setImageToEdit(null)
    }, 300)
  }

  //convert file to base64
  function getBase64(file) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setImageToEdit(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true)
    try {
      const croppedImage = await getCroppedImg(imageToEdit, croppedAreaPixels)

      // Get cloudinary signature from API
      const {
        api_key,
        timestamp,
        signature,
        eager,
        folder,
        resource_type,
        cloudname,
      } = (await api.get("/app/users/cloud-signature")).data.data

      // Use cloudinary signature data to create formdata
      const url = `https://api.cloudinary.com/v1_1/${cloudname}/${resource_type}/upload`
      const formData = new FormData()

      formData.append("file", croppedImage)
      formData.append("api_key", api_key)
      formData.append("timestamp", timestamp)
      formData.append("signature", signature)
      formData.append("eager", eager)
      formData.append("folder", folder)

      let uploadResponse
      try {
        // Post image to cloudinary
        uploadResponse = await axios({
          method: "post",
          url,
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        })
      } catch (error) {
        throw new Error("Upload failed")
      }

      // Update profile image with mutation
      const mutateData = await updateProfileImageMutation.mutateAsync({
        profile_image: uploadResponse.data.public_id,
      })

      // Update user state
      const serializedUser = await serialize("user", mutateData.user)
      setAuthUserState(serializedUser)

      queryClient.setQueryData("user", (oldState) => {
        return serializedUser
      })

      setAuthData({ token, account: serializedUser })

      setIsLoading(false)
      handleClose()
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }, [croppedAreaPixels])

  return (
    <ConditionalDialogWrapper
      open={open}
      onClose={handleClose}
      className={classes.uploadDialog}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2">
          {!image ? "Upload an image" : "Crop Image"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {!imageToEdit ? (
          <Box className={classes.uploadFileContainer}>
            <div
              {...getRootProps()}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <input {...getInputProps()} />

              <Box className={classes.uploadFileSubcontainer}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                >
                  <Typography variant="h6" style={{ marginRight: "2px" }}>
                    Choose an IMAGE
                  </Typography>
                  <Typography variant="subtitle1">or drag it here</Typography>
                </Box>
              </Box>
            </div>
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box className={classes.cropContainer}>
                <Cropper
                  image={
                    imageToEdit || image
                    // cloudinary
                    //   .image(image)
                    //   .resize(Resize.scale().width(200).height(200))
                    //   .toURL()
                  }
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  minZoom={0.1}
                  zoomWithScroll={false}
                  onCropChange={setCrop}
                  restrictPosition={true}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>
            </Box>
            <Box marginTop={1} display="flex" justifyContent="center">
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                style={{ width: "100%" }}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <ButtonRounded
          color="primary"
          variant="outlined"
          onClick={handleClose}
          type="button"
          disabled={isLoading}
        >
          Cancel
        </ButtonRounded>
        <LoaderButton
          color="primary"
          variant="contained"
          onClick={showCroppedImage}
          classoverrides={{
            wrapper: classes.button,
            button: {
              root: classes.buttonRoot,
            },
          }}
          working={isLoading}
        >
          Save
        </LoaderButton>
      </DialogActions>
    </ConditionalDialogWrapper>
  )
}

export default UploadImage
