// created for use with drop down lists that have string options
// e.g., High School or Equivalent --> high-school-or-equivalent in Add or Update Board

const normalizeString = (string) => {
  if (string) {
    return string?.toLowerCase().trim()
  } else {
    return ""
  }
}

export default normalizeString
