import { Box, CircularProgress, Grid, Typography } from "@material-ui/core"
import BarChartCard from "components/Clerk/Analytics/BarChartCard"
import PieChartCard from "components/Clerk/Analytics/PieChartCard"
import AnalyticsContext, { AnalyticsContainer } from "contexts/Clerk/Analytics"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import Router from "router"

import useStyles from "./styles"

const BoardAnalytics = () => {
  const classes = useStyles()

  const {
    genderData,
    genderDataLoading,
    raceData,
    raceDataLoading,
    ageData,
    ageDataLoading,
    membershipData,
    membershipDataLoading,
    boardData,
    boardDataLoading,
  } = useContext(AnalyticsContext)

  const dataLoading =
    ageDataLoading ||
    genderDataLoading ||
    raceDataLoading ||
    membershipDataLoading
      ? true
      : false

  return dataLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      height="100%"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Page>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.headerContainer}
      >
        <Typography variant="h1">Analytics</Typography>
        <Typography variant="subtitle2" color="primary">
          Board Members
        </Typography>
      </Box>
      <Box
        display="flex"
        className={classes.headerContainer}
        style={{ width: "90%" }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ height: "100px" }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              className={classes.numberStats}
            >
              <Typography variant="h6" align="center">
                Total Boards: {membershipData?.total_boards}
              </Typography>
              <Typography variant="h6" align="center">
                Applications Submitted: {membershipData?.total_applications}
              </Typography>
              {/* <Typography variant="h6" align="center">
                Vacancies: {membershipData?.total_vacant_seats}/
                {membershipData?.total_seats}
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {boardDataLoading ? (
              <CircularProgress />
            ) : (
              <BarChartCard title="Boards" graphData={boardData} />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChartCard title="Gender" graphData={genderData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChartCard title="Race" graphData={raceData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PieChartCard title="Age" graphData={ageData} />
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}

const Analytics = ({ routes = [], ...rest }) => {
  return (
    <AnalyticsContainer>
      <BoardAnalytics />
      <Router routes={routes} {...rest} />
    </AnalyticsContainer>
  )
}

Analytics.propTypes = {
  routes: PropTypes.array,
}

export default Analytics
