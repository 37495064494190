import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  errorContainer: {
    maxWidth: "527px",
    backgroundColor: "#FFE5E5",
    padding: theme.spacing(1),
  },
  infoIcon: {
    color: "#E34A4A",
    marginRight: theme.spacing(1),
  },
  errorText: {
    color: "#E34A4A",
  },
}))

export default styles
