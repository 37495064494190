import {
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import CharacterCounter from "components/Common/CharacterCounter"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import { diff } from "deep-object-diff"
import { useEffect } from "react"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"

import useStyles from "./styles"

const AddQualifiersDialog = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const authContext = useContext(AppContext)

  const { dialogs, closeDialog, openSnackBar, authUser, token } =
    useContext(AppContext)
  const { options } = useContext(AccountContext)
  const thisDialog = dialogs?.["addQualifiersDialog"] || {}
  const { open = false, data = {} } = thisDialog

  let type = data?.type || ""
  let subtitle = data?.subtitle || ""
  let formPlaceholder = data?.formPlaceholder || ""
  let updateMutation = data?.updateMutation || ""

  const [checkedList, setCheckedList] = useState([])
  const [more, setMore] = useState("")
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)

  useEffect(() => {
    if (open) {
      let value = `other_${type?.toLowerCase()}`
      setCheckedList(authUser[type.toLowerCase()] || [])
      setMore(authUser[value] || "")
    }
  }, [open])

  const clearData = () => {
    setCheckedList([])
    setMore("")
    setClicked(false)
  }

  const handleClose = () => {
    closeDialog("addQualifiersDialog")
    setTimeout(() => {
      clearData()
    }, [200])
  }

  const handleChangeCheckbox = (element) => {
    if (checkedList?.filter((x) => x?.id === element?.id).length > 0) {
      setCheckedList((oldState) =>
        oldState.filter((x) => x?.id !== element?.id)
      )
    } else {
      setCheckedList((oldState) => [...oldState, element])
    }
  }

  const saveData = () => {
    setClicked(true)
    if (more?.length <= 2000) {
      let value = `other_${type?.toLowerCase()}`
      let initialValues = {
        [type.toLowerCase()]: authUser?.[type.toLowerCase()],
        value: authUser?.[value],
      }
      let currentValues = {
        [type.toLowerCase()]: checkedList,
        value: more,
      }
      //check if there is difference between initial values and state
      const unsavedData = diff(initialValues, currentValues)
      if (Object.keys(unsavedData)?.length > 0) {
        setLoading(true)
        let value = `other_${type?.toLowerCase()}`
        let data = {
          [type.toLowerCase()]: checkedList.map((x) => x.id),
          [value]: more,
        }
        updateMutation
          .mutateAsync(data)
          .then((res) =>
            serialize("user", res.user).then((serializedData) => {
              authContext.setAuthData({
                token: token,
                account: serializedData,
              })
              openSnackBar({ message: `${type} updated` })
              return queryClient.setQueryData("user", (oldState) => {
                return serializedData
              })
            })
          )
          .catch((err) => {
            openSnackBar({ message: `Error updating ${type} ` })
          })
          .finally(() => {
            handleClose()
            setLoading(false)
          })
      } else {
        handleClose()
        openSnackBar({ message: `${type} updated` })
        setLoading(false)
      }
    }
  }

  const error = more?.length > 2000 && clicked
  return (
    <ConditionalDialogWrapper
      open={open}
      onClose={handleClose}
      className={classes.qualifiersDialog}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2">Add {type}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} style={{ margin: "initial" }}>
          {options &&
            options[type?.toLowerCase() + "s"]?.map((element, i) => {
              return (
                <Grid item xs={4} key={i} style={{ padding: "3px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          checkedList?.filter((x) => x?.id === element.id)
                            .length > 0
                        }
                        data-cy={`${type?.toLowerCase()}-checkbox-${i}`}
                        onChange={() => handleChangeCheckbox(element)}
                      />
                    }
                    label={element.name}
                  />
                </Grid>
              )
            })}
        </Grid>
        <Box position="relative">
          <Box position="absolute" top="0" right="0">
            <CharacterCounter currentValue={more?.length} length={2000} />
          </Box>
          <TextField
            multiline
            rows={7}
            variant="outlined"
            data-cy={`${type?.toLowerCase()}-optional-text`}
            placeholder={formPlaceholder}
            fullWidth
            className={classes.texfield}
            onChange={(e) => setMore(e.target.value)}
            value={more}
          />
        </Box>
        {error && (
          <Typography style={{ color: "#F84738" }}>
            No more that 2000 characters.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <ButtonRounded
            color="primary"
            variant="outlined"
            onClick={handleClose}
            type="button"
            data-cy={`${type?.toLowerCase()}-close-button`}
          >
            Close
          </ButtonRounded>
          <ButtonRounded
            color="primary"
            variant="contained"
            onClick={saveData}
            type="button"
            className={classes.saveButton}
            data-cy={`${type?.toLowerCase()}-save-button`}
            classes={{ label: classes.btnLabel }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: "#fff" }} />
            ) : (
              "Save"
            )}
          </ButtonRounded>
        </Box>
      </DialogActions>
    </ConditionalDialogWrapper>
  )
}

export default AddQualifiersDialog
