import {
  Box,
  CircularProgress,
  Container,
  Icon,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core"
import { getEntities } from "actions/Clerk/entities"
import search from "assets/icon-feather-search.svg"
import EntitiesCard from "components/Clerk/EntitiesCard"
import App from "contexts/App"
import EntitiesContext from "contexts/Clerk/Entities"
import Page from "pages/Page"
import { useContext } from "react"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useMutation } from "react-query"

import useStyles from "./styles"

const List = () => {
  const classes = useStyles()
  const { statesList, statesListLoading } = useContext(EntitiesContext)
  const { debouncedSearchValue, searchValue, setSearchValue } = useContext(App)
  const [tabValue, setTabValue] = useState("all")
  const [stateValue, setStateValue] = useState("")
  const getEntitiesMutation = useMutation(getEntities)
  //state for data to show
  const [displayedData, setDisplayedData] = useState(null)
  //boolean to know if we need call more data
  const [hasMoreData, setHasMoreData] = useState(true)
  //pagination page
  const [page, setPage] = useState(1)
  //pagination size
  const size = 20

  const handleChangeTab = (tab) => {
    //change to page 1 if we change tab
    setPage(1)
    setHasMoreData(true)
    setTabValue(tab)
  }

  //call more data when we change the page
  const loadData = () => {
    setPage((oldState) => oldState + 1)
  }
  // when stateList loads from context, set the initial value of the dropdown state filter to list[0]
  useEffect(() => {
    if (!statesListLoading) {
      setStateValue(statesList?.[0])
    }
  }, [statesList, statesListLoading])

  //call the api for more data and populate displayed data array
  useEffect(() => {
    //first if statment prevents 2 calls
    if (stateValue !== "") {
      let query = `p=${page}&n=${size}&state=${stateValue?.toLowerCase()}`
      if (debouncedSearchValue !== "") {
        query = query + `&s=${debouncedSearchValue}`
      }
      if (tabValue !== "all") {
        query = query + `&${tabValue}=true`
      }
      getEntitiesMutation
        ?.mutateAsync(query)
        .then((res) => {
          if (res.length === 0) {
            setHasMoreData(false)
          }
          //if we are in page 1 clean data and add the response
          if (page === 1) {
            setDisplayedData((oldState) => [...res])
          } else {
            setDisplayedData((oldState) => [...oldState, ...res])
          }
        })
        .catch((err) => setDisplayedData([]))
    }
  }, [tabValue, stateValue, debouncedSearchValue, page])

  const sortOrSearchUsed =
    debouncedSearchValue?.length > 0 ||
    tabValue !== "all" ||
    stateValue !== "sc"

  if (!displayedData && statesListLoading) {
    return <LinearProgress />
  } else {
    return (
      <Page>
        <Container maxWidth={false} style={{ flexWrap: "wrap" }}>
          <Box className={classes.entityFilters}>
            {/* Tabs start here */}
            <Box className={classes.tabContainer}>
              <Typography variant="h2" className={classes.title}>
                Entities
              </Typography>
              <Tabs
                value={tabValue}
                variant="fullWidth"
                indicatorColor="secondary"
                textColor="secondary"
                aria-label="entity-type-tab"
                className={classes.tabs}
              >
                <Tab
                  classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
                  label="All"
                  onClick={() => handleChangeTab("all")}
                  value="all"
                  color="secondary"
                />

                <Tab
                  classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
                  label="Counties"
                  onClick={() => handleChangeTab("counties")}
                  value="counties"
                  color="secondary"
                />

                <Tab
                  classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
                  label="Municipalities"
                  onClick={() => handleChangeTab("municipalities")}
                  value="municipalities"
                  color="secondary"
                />
              </Tabs>
            </Box>
            <Box className={classes.inputWrapper}>
              <TextField
                select
                name="select_state"
                value={stateValue}
                onChange={(e) => setStateValue(e.target.value)}
                variant="outlined"
                fullWidth
                className={classes.input}
                style={{
                  width: "120px",
                  marginRight: "20px",
                  backgroundColor: "#F8F8F8",
                }}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              >
                {!statesListLoading &&
                  statesList?.map((state, i) => (
                    <MenuItem value={state} key={i}>
                      {state}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box className={classes.inputWrapper}>
              <TextField
                placeholder="Search entities..."
                className={classes.input}
                variant="filled"
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  if (e.target.value === "") {
                    setSearchValue("")
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value)
                  }
                }}
                value={searchValue}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                    focused: classes.inputFocused,
                  },
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginLeft: "10px", marginBottom: "10px" }}
                    >
                      <Icon classes={{ root: classes.iconRoot }}>
                        <img
                          className={classes.imageIcon}
                          src={search}
                          alt=""
                        />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          {page === 1 && getEntitiesMutation?.isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <InfiniteScroll
              dataLength={displayedData?.length || 20}
              next={loadData}
              hasMore={displayedData?.length === page * size && hasMoreData}
              scrollableTarget="scrollableDiv"
              loader={
                <Box width="100%" display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              }
              endMessage={
                displayedData?.length === 0 && (
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="500px"
                  >
                    <Typography variant="h2">
                      {sortOrSearchUsed ? "No matched data" : "No entities"}
                    </Typography>
                  </Box>
                )
              }
              style={{ overflow: "hidden" }}
            >
              <Box width="100%" display="flex" flexWrap="wrap">
                {displayedData?.map((e) => {
                  return <EntitiesCard key={e.id} entity={e} />
                })}
              </Box>
            </InfiniteScroll>
          )}
        </Container>
      </Page>
    )
  }
}

export default List
