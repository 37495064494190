import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        height: "100vh",
        overflow: "auto",
      },
    },
    gridItem: {
      height: "100%",
    },
    scrollBox: {
      overflow: "auto",
      height: "100%",
    },
  }),
  {
    name: "Layouts-Messages",
  }
)

export default styles
