import dayjs from "dayjs"

export default function checkBoardReviewPeriod(reviewPeriods) {
  //we are checking if we are within any review period for a board
  const activeReviewPeriods = reviewPeriods?.filter((period) => {
    return (
      dayjs().month() >= period.start_month_num &&
      dayjs().month() <= period.end_month_num
    )
  })

  return activeReviewPeriods?.length > 0
}
