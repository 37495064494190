import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import CreateIcon from "@material-ui/icons/Create"
import { approveRequest, declineRequest } from "actions/Clerk/requests"
import logo from "assets/logo-icon.svg"
import axios from "axios"
import ButtonRounded from "components/Common/ButtonRounded"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import { useEffect } from "react"
import { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import api from "store/api"
import formatPhoneNumber from "utils/formatPhone"

import useStyles from "./styles"

const RequestReview = () => {
  const classes = useStyles()

  const { dialogs, closeDialog, openSnackBar, openDialog } =
    useContext(AppContext)

  const thisDialog = dialogs?.["requestReview"] || {}
  const { open = false, data = {} } = thisDialog

  const [entityTitle, setEntityTitle] = useState("")
  const [triggerError, setTriggerError] = useState(false)
  const [croppedImage, setCroppedImage] = useState(null)
  const [approving, setApproving] = useState(false)

  const approveRequestMutation = useMutation((data) => {
    approveRequest(data?.id, data)
  })
  const declineRequestMutation = useMutation(declineRequest)

  const queryClient = useQueryClient()

  const clearData = () => {
    setTimeout(() => {
      setEntityTitle("")
      setTriggerError(false)
      setCroppedImage(null)
    }, 100)
  }
  const handleClose = () => {
    closeDialog("requestReview")
    clearData()
  }

  const leftList = [
    { name: "State", value: "state" },
    { name: "Entity", value: "entity" },
    { name: "Entity Name", value: "entity" },
    { name: "Title", value: "title" },
    { name: "First Name", value: "first_name" },
    { name: "Last Name", value: "last_name" },
    { name: "Phone", value: "phone" },
    { name: "Email", value: "email" },
    { name: "Email Verified", value: "email_verified" },
    { name: "Website", value: "entity_url" },
    { name: "# of Council Districts", value: "number_districts" },
    { name: "Application review", value: "application_review" },
    {
      name: "Which levels of leadership are in your entity?",
      value: "leadership",
    },
  ]

  // dialog open to upload an image
  const openUploadImage = (image) => {
    let data = { image: image, setFn: setCroppedImage }
    // let data = { image: image, request: data }
    openDialog("chooseRequestImage", data)
  }

  const handleCloudinary = async () => {
    setApproving(true)
    if (croppedImage) {
      try {
        // Get cloudinary signature from API
        const {
          api_key,
          timestamp,
          signature,
          eager,
          folder,
          resource_type,
          cloudname,
        } = (await api.get("/admin/entities/cloud-signature")).data.data

        // Use cloudinary signature data to create formdata
        const url = `https://api.cloudinary.com/v1_1/${cloudname}/${resource_type}/upload`
        const formData = new FormData()

        formData.append("file", croppedImage)
        formData.append("api_key", api_key)
        formData.append("timestamp", timestamp)
        formData.append("signature", signature)
        formData.append("eager", eager)
        formData.append("folder", folder)

        let uploadResponse
        try {
          // Post image to cloudinary
          uploadResponse = await axios({
            method: "post",
            url,
            headers: { "Content-Type": "multipart/form-data" },
            data: formData,
          })
          handleApprove(uploadResponse.data.public_id)
          return uploadResponse.data.public_id
        } catch (error) {
          throw new Error("Upload failed")
        }
      } catch (e) {
        console.error(e)
        openSnackBar({
          message: "Image upload failed",
        })
      }
    } else {
      handleApprove("")
    }
  }

  const handleApprove = (res) => {
    if (entityTitle.length) {
      // make request to submit to Cloudinary first, then run through the approve mutation.
      // req data to include entity_image: image
      let reqData = {
        id: data?.request_id,
        entity: entityTitle,
        entity_image: res || "",
      }
      approveRequestMutation
        .mutateAsync(reqData)
        .then((res) => {
          setApproving(false)

          setTriggerError(false)
          openSnackBar({ message: "Request Approved." })
          // removes approved req from list of outstanding reqs.
          queryClient.setQueryData("requestsData", (oldData) => {
            let newData = oldData.filter(
              (x) => x.request_id !== data.request_id
            )
            return newData
          })
          handleClose()
        })
        .catch((err) => {
          openSnackBar({ message: "Error approving request." })
          setApproving(false)
          handleClose()
          setApproving(false)
        })
    } else {
      setTriggerError(true)
      openSnackBar({ message: "Entity name required" })
      setApproving(false)
    }
  }

  const handleDecline = () => {
    declineRequestMutation
      .mutateAsync(data?.request_id)
      .then((res) => {
        openSnackBar({ message: "Request Deleted." })
        queryClient.setQueryData("requestsData", (oldData) => {
          let newData = oldData.filter((x) => x.request_id !== data.request_id)
          return newData
        })

        handleClose()
      })
      .catch((err) => {
        openSnackBar({ message: "Error declining request." })
      })
  }

  //set entity title on successfuly load of requests data
  useEffect(() => {
    setEntityTitle(data?.entity)
  }, [data])

  // show validation error if field is cleared out, remove otherwise
  useEffect(() => {
    if (entityTitle?.length) {
      setTriggerError(false)
    } else {
      setTriggerError(true)
    }
  }, [entityTitle])

  const renderData = (element, data) => {
    if (element === "email_verified") {
      if (data?.email_verified) {
        return <CheckIcon />
      } else {
        return <CloseIcon />
      }
    } else if (element === "application_review") {
      if (data?.review_period_rolling) {
        return <Typography variant="subtitle1">Rolling Basis</Typography>
      } else {
        return (
          <Typography variant="subtitle1">
            {data?.abbreviated_period_start +
              "-" +
              data?.abbreviated_period_end || "-"}
          </Typography>
        )
      }
    } else if (element === "leadership") {
      return data?.requested_leaderships?.map((level, i) => (
        <Typography
          className={classes.values}
          style={{ paddingRight: "2px" }}
          variant="subtitle1"
          key={i}
        >
          {level}
        </Typography>
      ))
    } else if (element === "email") {
      return (
        <HoverText
          variant="subtitle1"
          data={data?.email}
          desktopSize={30}
          phoneSize={30}
        />
      )
    } else if (element === "phone") {
      return (
        <Typography variant="subtitle1">
          {formatPhoneNumber(data?.phone)}
        </Typography>
      )
    } else {
      return <Typography variant="subtitle1">{data?.[element]}</Typography>
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paperWidthSm: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2">Access Review</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" width="100%" flexWrap="wrap" height="100%">
          <Box width="50%" height="100%" className={classes.leftContainer}>
            {/* if length of name string is less than 46 characters  */}
            {leftList?.map((element, i) => {
              if (element.name.length < 45) {
                const renderedData = renderData(element?.value, data)
                return (
                  <Box key={i} display="flex" width="100%">
                    <Box width="40%">
                      <Typography
                        className={classes.keys}
                        color="primary"
                        variant="subtitle1"
                      >
                        {element?.name}
                      </Typography>
                    </Box>
                    <Box width="60%" marginLeft="10px">
                      {renderedData ? renderedData : null}
                    </Box>
                  </Box>
                )
              }
              /*  add class longName string is greater than 45 characters */
              const renderedData = renderData(element?.value, data)
              return (
                <Box key={i} display="flex" width="100%">
                  <Box width="40%">
                    <Typography
                      className={(classes.keys, classes.longName)}
                      color="primary"
                      variant="subtitle1"
                    >
                      {element?.name}
                    </Typography>
                  </Box>
                  <Box width="60%" marginLeft="10px">
                    {renderedData ? renderedData : null}
                  </Box>
                </Box>
              )
            })}
          </Box>
          <Box width="50%" className={classes.rightContainer}>
            <Typography className={classes.label} variant="subtitle1">
              {data?.entity_type} Image
            </Typography>
            <Box display="flex" marginBottom="50px" alignItems="center">
              <Box className={classes.uploadImageContainer}>
                <Avatar
                  className={classes.avatar}
                  alt="current user profile picture"
                  src={croppedImage ? URL.createObjectURL(croppedImage) : logo}
                  // src={croppedImage ? scaledImage(croppedImage) : logo}
                />
                <IconButton
                  className={classes.pencilIcon}
                  onClick={() => openUploadImage(data?.entity_image)}
                >
                  <CreateIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography>
                  Add {data?.entity_type?.toLowerCase()} logo to this
                  organization. This logo will be used as all
                  clerks/administrators profile picture and on branded emails to{" "}
                  {data?.entity_type?.toLowerCase()} members
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.label} variant="subtitle1">
              {data?.entity_type} Title
            </Typography>
            <TextField
              type="text"
              onChange={(e) => setEntityTitle(e.target.value)}
              value={entityTitle}
              variant="outlined"
              fullWidth
              style={{
                backgroundColor: "#F8F8F8",
                marginBottom: "25px",
              }}
              error={triggerError}
            />
            {triggerError && (
              <Typography variant="subtitle1" style={{ color: "red" }}>
                {data?.entity_type} Title is a required field
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <ButtonRounded
            color="primary"
            variant="outlined"
            onClick={handleClose}
            type="button"
            style={{ marginRight: "10px" }}
            disabled={
              approveRequestMutation?.isLoading ||
              declineRequestMutation?.isLoading
            }
          >
            Close
          </ButtonRounded>
          <ButtonRounded
            color="primary"
            variant="contained"
            type="button"
            className={classes.declineButton}
            style={{ marginRight: "10px" }}
            onClick={handleDecline}
            disabled={
              approveRequestMutation?.isLoading ||
              declineRequestMutation?.isLoading ||
              triggerError
            }
          >
            {declineRequestMutation?.isLoading ? (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={20} style={{ color: "#fff" }} />
              </Box>
            ) : (
              "Decline"
            )}
          </ButtonRounded>
          <ButtonRounded
            color="primary"
            variant="contained"
            type="button"
            className={classes.approveButton}
            style={{ marginRight: "10px" }}
            onClick={handleCloudinary}
            disabled={
              approveRequestMutation?.isLoading ||
              declineRequestMutation?.isLoading ||
              triggerError ||
              approving
            }
            data-cy="approve-request"
          >
            {approving ? (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress size={20} style={{ color: "#fff" }} />
              </Box>
            ) : (
              "Approve"
            )}
          </ButtonRounded>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default RequestReview
