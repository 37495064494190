import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    listItem: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      "&:first-child": {
        paddingTop: "none",
      },
      // "&:last-child": {
      //   paddingBottom: "none",
      // },
    },
    secondary: {
      lineHeight: `${theme.spacing(2)}px`,
    },
    listItemTextBox: {
      flexGrow: 1,
      paddingRight: theme.spacing(2),
    },
    primaryViewed: {
      fontWeight: 500,
    },
  }),
  {
    name: "ListItemWithContent",
  }
)

export default styles
