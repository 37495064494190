import { Dialog, useMediaQuery, useTheme } from "@material-ui/core"
import BottomDrawer from "components/Common/BottomDrawer"
import PropTypes from "prop-types"

import useStyles from "./styles"

const ConditionalDialogWrapper = ({
  open = false,
  onClose,
  children,
  className = "",
  newClasses,
  drawerColor,
}) => {
  // in implementation, takes from a dialog both the open and the show prop
  // open prop only present if !mobileView, while show prop exists only if mobileView
  // open -> dialog state, while show -> drawer state
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  if (mobileView) {
    return (
      <BottomDrawer
        show={open}
        componentToRender={children}
        onClose={onClose}
        drawerColor={drawerColor}
      />
    )
  } else {
    return (
      <Dialog
        open={open}
        className={className}
        classes={newClasses}
        scroll="paper"
      >
        {children}
      </Dialog>
    )
  }
}

ConditionalDialogWrapper.propTypes = {
  children: PropTypes.node,
}

export default ConditionalDialogWrapper
