import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import App from "contexts/App"
import BoardContext from "contexts/Citizen/Board"
import dayjs from "dayjs"
import { isEmpty } from "lodash"
import { useContext, useEffect, useRef, useState } from "react"
import { useQueryClient } from "react-query"

import CoverLetter from "./components/CoverLetter"
import Questions from "./components/Questions"
import Submitted from "./components/Submitted"
import useStyles from "./styles"

const QuickApplyDialog = () => {
  const classes = useStyles()
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { dialogs, closeDialog, openSnackBar, authUser } = useContext(App)
  const thisDialog = dialogs?.["quickApply"] || {}
  const { open = false, data = {} } = thisDialog
  //state used to change between the 3 child's components
  const [step, setStep] = useState(0)
  const { board, applyBoardMutation } = useContext(BoardContext)
  const [defaultQuestionsArray, setDefaultQuestionsArray] = useState([])
  const [additionalQuestionsArray, setAdditionalQuestionsArray] = useState([])
  const [file, setFile] = useState({})
  const [text, setText] = useState("")
  const [errorState, setErrorState] = useState(false)
  const [clicked, setClicked] = useState(false)
  const childRef = useRef()
  const [uploadFile, setUploadFile] = useState(true)
  //state to check if the user has preferred position
  const [preferredPosition, setPreferredPosition] = useState(null)
  //state to populate role/district positions select
  const [positions, setPositions] = useState([])
  //populate the select in questions component for positions
  //the use could prefered a position if the is a vacant or there
  // is a member with a expiration date less than 90 dayjs
  useEffect(() => {
    setPositions(
      data?.board_positions
        ?.map((x) => {
          if (
            (x.position && !x.first_name) ||
            (x.position &&
              board?.term?.includes("year") &&
              x?.term_expiration &&
              dayjs(x?.term_expiration).diff(dayjs(), "day") <= 90)
          ) {
            return x
          }
        })
        .filter((x) => x)
    )
  }, [data])

  const steps = [
    <Questions
      key={1}
      data={data}
      defaultQuestionsArray={defaultQuestionsArray}
      setDefaultQuestionsArray={setDefaultQuestionsArray}
      clicked={clicked}
      additionalQuestionsArray={additionalQuestionsArray}
      setAdditionalQuestionsArray={setAdditionalQuestionsArray}
      setPreferredPosition={setPreferredPosition}
      positions={positions}
      setErrorState={setErrorState}
      board={board}
    />,

    <CoverLetter
      key={3}
      file={file}
      setFile={setFile}
      clicked={clicked}
      setText={setText}
      text={text}
      fileUploadRef={childRef}
      uploadFile={uploadFile}
      setUploadFile={setUploadFile}
    />,
    <Submitted key={4} data={data} />,
  ]

  const handleClose = () => {
    if (step === 0 || step === 2) {
      closeDialog("quickApply")
      setTimeout(() => {
        setStep(0)
        setClicked(false)
        setDefaultQuestionsArray([])
        setAdditionalQuestionsArray([])
        setFile({})
        setText("")
      }, 200)
    } else {
      setStep((oldState) => oldState - 1)
    }
  }
  const step0Disabled =
    defaultQuestionsArray?.length !== board?.default_questions?.length ||
    additionalQuestionsArray?.length !== board?.additional_questions?.length

  const step1Disabled =
    (isEmpty(file) || (!isEmpty(file) && file.name === "")) && text === ""

  const handleApply = () => {
    setClicked(true)
    if (step === 0 && !step0Disabled) {
      setStep((oldState) => oldState + 1)
      setClicked(false)
    } else if (step === 1 && !step1Disabled) {
      let data = {
        default_answers: defaultQuestionsArray,
        additional_answers: additionalQuestionsArray,
        cover_letter_id: file?.file_id,
        cover_letter: file?.file_name,
        cover_letter_copy: text,
        preferred_position_id: preferredPosition?.position_id || null,
      }

      applyBoardMutation
        .mutateAsync(data)
        .then((res) => {
          openSnackBar({ message: "Board application submitted" })
          //update my applications query
          queryClient.setQueryData(["applied", authUser?.id], (oldState) => {
            let newData = { ...res[0], entity: board?.entity }
            return [...oldState, newData]
          })
          //update board query
          queryClient.setQueryData(["board", board?.id], (oldState) => {
            let newBoard = oldState
            newBoard.applied = 1
            return newBoard
          })

          setClicked(false)
          setStep((oldState) => oldState + 1)
        })
        .catch((err) => {
          if (
            err?.response?.data?.message === "User already applied to board"
          ) {
            openSnackBar({ message: "User already applied to board" })
          } else {
            openSnackBar({ message: "Error applying board " })
          }
        })
    } else {
      childRef?.current?.callOnSubmit()
    }
  }

  const buttonText = () => {
    if (step === 2 && !step0Disabled) {
      return "Apply"
    } else if (step === 1 && step1Disabled && uploadFile) {
      return "Upload"
    } else {
      return "Next"
    }
  }

  const closeText = () => {
    if (step === 0) {
      return "Close"
    } else {
      return "Back"
    }
  }
  return (
    <ConditionalDialogWrapper
      open={open}
      onClose={handleClose}
      newClasses={step === 2 ? { paper: classes.dialog } : null}
      drawerColor={step === 2 ? theme.palette.primary.dark : null}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2">{step !== 2 ? "QuickApply" : ""}</Typography>
      </DialogTitle>
      <DialogContent>{steps[step]}</DialogContent>
      {step !== 2 ? (
        <DialogActions className={classes.buttonContainer}>
          <ButtonRounded
            color="primary"
            variant="outlined"
            onClick={handleClose}
            type="button"
          >
            {closeText()}
          </ButtonRounded>

          <ButtonRounded
            color="primary"
            variant="contained"
            onClick={handleApply}
            type="button"
            disabled={errorState}
          >
            {applyBoardMutation?.isLoading ? (
              <CircularProgress size={30} style={{ color: "#fff" }} />
            ) : (
              buttonText()
            )}
          </ButtonRounded>
        </DialogActions>
      ) : (
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "5%",
          }}
        >
          <ButtonRounded
            variant="contained"
            onClick={handleClose}
            type="button"
            classes={{ label: classes.label }}
          >
            Close
          </ButtonRounded>
        </DialogActions>
      )}
    </ConditionalDialogWrapper>
  )
}

export default QuickApplyDialog
