import { Container } from "@material-ui/core"
import EntityLeftContainer from "components/Clerk/Entity/EntityLeftContainer"
import EntityRightContainer from "components/Clerk/Entity/EntityRightContainer"
import { EntityContainer } from "contexts/Clerk/Entity"
import UploadEntityImage from "dialogs/UploadEntityImage"
import FiftyFifty from "Layouts/FiftyFifty"
import Page from "pages/Page"
import { useRouteMatch } from "react-router-dom"

import useStyles from "./styles"

const Entity = () => {
  const classes = useStyles()
  const match = useRouteMatch("/entities/:entity_id")

  return (
    <Page>
      <EntityContainer entityId={match?.params?.entity_id}>
        <FiftyFifty
          left={
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                // height: "100%",
              }}
              className={classes.leftContainer}
            >
              <EntityLeftContainer />
            </Container>
          }
          right={
            <Container
            // style={{ height: "100%" }}
            >
              <EntityRightContainer />
            </Container>
          }
        />
        {/* dialog space below */}
        <UploadEntityImage />
      </EntityContainer>
    </Page>
  )
}

export default Entity
