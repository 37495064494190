import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  listItem: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  listItemText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  notificationKeywords: {
    fontWeight: 700,
    justifyContent: "flex-start",
  },
  notificationDetails: {
    fontWeight: 300,
    justifyContent: "flex-start",
  },
  timestampText: {
    fontWeight: 300,
    filter: "opacity(0.5)",
  },
}))

export default styles
