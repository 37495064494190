import { Box } from "@material-ui/core"
import ApplicantsList from "components/Clerk/Boards/ApplicantsList"
import { ApplicantContainer } from "contexts/Clerk/Applicant"
import ShareForReview from "dialogs/ShareForReview"
import Page from "pages/Page"

import useStyles from "./styles"

const Index = () => {
  const classes = useStyles()

  return (
    <Page>
      <Box className={classes.container}>
        <ApplicantContainer>
          <ApplicantsList />
          {/* dialog for share reviews*/}
          <ShareForReview />
        </ApplicantContainer>
      </Box>
    </Page>
  )
}

export default Index
