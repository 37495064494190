import { compact } from "lodash"
import api from "store/api"
import serialize from "store/serialize"

export const getBoards = (queryOrId, isClerk = false) => {
  if (isClerk) {
    return api.get(`/admin/boards/${queryOrId}`).then((res) => {
      return serialize("board", res.data.data)
    })
  } else {
    return api.get(`/app/boards?${queryOrId}`).then((res) => {
      return serialize("board", res.data.data.boards)
    })
  }
}

export const createBoard = (data) => {
  return api.post("/admin/boards", data).then((res) => res.data.data)
}

export const getInterests = (data) => {
  return api.get(`/app/boards/interests`).then((res) => res.data.data.list)
}

export const importBoards = (mutationData) => {
  let { entityId, ...data } = mutationData
  return api
    .post(`/admin/entities/${entityId}/imports`, data)
    .then((res) => res)
}
