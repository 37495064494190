import { Box, Card, IconButton, Typography } from "@material-ui/core"
import AccountBalanceIcon from "@material-ui/icons/AccountBalance"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail"
import CakeIcon from "@material-ui/icons/Cake"
import HomeIcon from "@material-ui/icons/Home"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import PhoneIcon from "@material-ui/icons/Phone"
import AvatarComponent from "components/Common/Avatar"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import dayjs from "dayjs"
import { useContext } from "react"
import formatPhoneNumber from "utils/formatPhone"

import ApplyError from "../ApplyError"
import useStyles from "./styles"

const PersonalInfoContainer = () => {
  const classes = useStyles()

  const { authUser, openDialog } = useContext(AppContext)
  const { applied } = useContext(CitizenAppContext)

  const errors = {
    details:
      !authUser?.first_name ||
      !authUser?.last_name ||
      !authUser?.address ||
      !authUser?.state ||
      !authUser?.county ||
      !authUser?.city ||
      !authUser?.zip_code,
    contactInformation: !authUser?.phone || !authUser?.email,
  }

  const handleOpenDialog = (name, data) => {
    openDialog([name], data)
  }

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.title} variant="subtitle1">
          My Details
        </Typography>
        <ButtonRounded
          className={classes.textButton}
          onClick={() => handleOpenDialog("addPersonalInfo")}
          data-cy="details-button"
        >
          <Typography className={classes.buttonText}>EDIT</Typography>
        </ButtonRounded>
      </Box>
      <>
        <Card className={classes.topContainer}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Box position="relative">
              <AvatarComponent user={authUser} className={classes.avatar} />
              <IconButton
                onClick={() => handleOpenDialog("uploadImage")}
                style={{ position: "absolute", bottom: -10, right: -15 }}
              >
                <AddCircleIcon className={classes.chanegImage} />
              </IconButton>
            </Box>
            <Typography className={classes.userName}>
              {authUser?.first_name + " " + authUser?.last_name || ""}
            </Typography>
          </Box>
          <Box display="flex" overflow="visible">
            <Box className={classes.iconContainer}>
              <HomeIcon className={classes.icon} />
            </Box>
            <Typography
              className={classes.districtCityText}
              style={{ maxWidth: "60%" }}
            >
              {authUser?.address ? `${authUser?.address}` : "-"}
              {<br></br>}
              {authUser?.city ? `${authUser?.city}, ` : "-"}{" "}
              {authUser?.state ? `${authUser?.state}` : "-"}{" "}
              {authUser?.zip_code ? `${authUser?.zip_code}` : "-"}
            </Typography>
          </Box>
          <Box display="flex">
            <Box className={classes.iconContainer}>
              <AccountBalanceIcon className={classes.icon} />
            </Box>
            <Typography className={classes.districtCityText}>
              {authUser?.county ? `${authUser?.county}` : "-"}
            </Typography>
          </Box>
          <Box display="flex" marginTop={1}>
            <Box className={classes.iconContainer}>
              <CakeIcon className={classes.icon} />
            </Box>
            <Typography className={classes.districtCityText}>
              {authUser?.dob
                ? `${dayjs(authUser?.dob).format("MMM DD, YYYY")}`
                : "-"}
            </Typography>
          </Box>
        </Card>
        {errors?.details && applied && <ApplyError />}
      </>
      <Box display="flex" justifyContent="space-between">
        <Typography className={classes.title} variant="subtitle1">
          Contact Information
        </Typography>
        <ButtonRounded
          className={classes.textButton}
          onClick={() => handleOpenDialog("contactInformation")}
          data-cy="contact-button"
        >
          <Typography className={classes.buttonText}>EDIT</Typography>
        </ButtonRounded>
      </Box>
      <>
        <Card className={classes.bottomContainer}>
          <Box display="flex">
            <Box className={classes.iconContainer}>
              <PhoneIcon className={classes.icon} />
            </Box>
            <Typography className={classes.text}>
              {authUser?.phone ? formatPhoneNumber(authUser?.phone) : "-"}
            </Typography>
          </Box>
          <Box display="flex">
            <Box className={classes.iconContainer}>
              <AlternateEmailIcon className={classes.icon} />
            </Box>
            <Typography className={classes.text}>
              {authUser?.email || "-"}
            </Typography>
          </Box>
          <Box display="flex">
            <Box className={classes.iconContainer}>
              <LinkedInIcon className={classes.icon} />
            </Box>
            <Typography className={classes.text}>
              {authUser?.linkedin_url || "-"}
            </Typography>
          </Box>
        </Card>
        {errors?.contactInformation && applied && <ApplyError />}
      </>
    </Box>
  )
}

PersonalInfoContainer.propTypes = {}

export default PersonalInfoContainer
