import {
  AppBar,
  Badge,
  Box,
  Button,
  Divider,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import LinkTab from "components/Common/LinkTab"
import AppContext from "contexts/App"
import MessagesContext from "contexts/Messages"
import { set } from "lodash"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useMemo } from "react"
import { useIndexedDB } from "react-indexed-db"
import { useHistory, useLocation } from "react-router-dom"

import useStyles from "./styles"

const Sidebar = () => {
  const classes = useStyles()
  const { clear } = useIndexedDB("auth")
  const { threads, selectedThread, setSelectedThread } =
    useContext(MessagesContext)

  const location = useLocation()
  const history = useHistory()

  const tabsValue = useMemo(() => {
    return location.pathname.split("/")[1]
  }, [location.pathname])

  const {
    token,
    setAuthData,
    openDialog,
    isSuperAdmin,
    setIsSuperAdmin,
    isAdmin,
    setIsAdmin,
    setSearchValue,
    authUser,
  } = useContext(AppContext)

  const handleLogout = () => {
    if (token) {
      setAuthData({ token: "", account: "" })
      setIsSuperAdmin(false)
      setIsAdmin(false)
      clear().finally(() => {
        if (process.env.REACT_APP_NODE_ENV === "development") {
          window.location.href = "/clerk"
        } else {
          window.location.href = "/"
        }
      })
    }
  }

  const goToInitialState = () => {
    if (isAdmin && !history?.location?.pathname?.includes("dashboard")) {
      history.push("/dashboard")
    } else if (
      isSuperAdmin &&
      !isAdmin &&
      !history?.location?.pathname?.includes("requests")
    ) {
      history.push("/requests")
    }
  }

  // Check for unread messages
  const unread = useMemo(() => {
    return threads?.some(
      (thread) => thread?.direction === "received" && !thread?.viewed
    )
  }, [threads])

  return (
    token && (
      <AppBar className={classes.root} position="static" color="default">
        <Box className={classes.logoBox} onClick={goToInitialState}>
          <Logo style={{ width: 150 }} />
        </Box>
        <>
          <Tabs
            indicatorColor="secondary"
            textColor="secondary"
            orientation="vertical"
            value={tabsValue}
            aria-label="simple tabs example"
            style={{ flexGrow: 1 }}
          >
            {isAdmin && isSuperAdmin && (
              <Tab
                label={
                  <Typography className={classes.title} variant="h5">
                    Clerk
                  </Typography>
                }
                disabled
                style={{
                  paddingTop: "10px",
                }}
                classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
              />
            )}
            {isAdmin && (
              <LinkTab
                label="DASHBOARD"
                to="/dashboard"
                onClick={() => setSearchValue("")}
                value="dashboard"
                icon={<span />}
              />
            )}
            {isAdmin && (
              <LinkTab
                label="APPLICANTS"
                to="/applicants"
                value="applicants"
                onClick={() => setSearchValue("")}
                icon={
                  <Badge badgeContent={0} color="primary" invisible={true} />
                }
              />
            )}
            {isAdmin && (
              <LinkTab
                label="BOARDS"
                to="/boards"
                value="boards"
                onClick={() => setSearchValue("")}
                icon={
                  <Badge badgeContent={0} color="primary" invisible={true} />
                }
              />
            )}
            {isAdmin && (
              <LinkTab
                label="MESSAGES"
                to="/messages"
                value="messages"
                onClick={() => {
                  setSearchValue("")
                  setSelectedThread(null)
                }}
                icon={
                  <Badge variant="dot" color="primary" invisible={!unread} />
                }
              />
            )}
            {isAdmin && (
              <LinkTab
                label="ANALYTICS"
                to="/analytics"
                value="analytics"
                onClick={() => setSearchValue("")}
                icon={
                  <Badge badgeContent={0} color="primary" invisible={true} />
                }
              />
            )}
            {isSuperAdmin && isAdmin && (
              <Tab
                label={
                  <Typography className={classes.title} variant="h5">
                    Admin
                  </Typography>
                }
                disabled
                style={{
                  borderTop: "1px solid silver",
                  marginTop: "10px",
                  paddingTop: "10px",
                }}
                classes={{ root: classes.tab, wrapper: classes.tabWrapper }}
              />
            )}
            {isSuperAdmin && (
              <LinkTab
                label="REQUESTS"
                to="/requests"
                value="requests"
                icon={
                  <Badge badgeContent={0} color="primary" invisible={true} />
                }
              />
            )}
            {isSuperAdmin && (
              <LinkTab
                label="ENTITIES"
                to="/entities"
                value="entities"
                icon={
                  <Badge badgeContent={0} color="primary" invisible={true} />
                }
              />
            )}
            {isSuperAdmin && (
              <LinkTab
                label="ADMINS"
                to="/admins"
                value="admins"
                icon={
                  <Badge badgeContent={0} color="primary" invisible={true} />
                }
              />
            )}
          </Tabs>
          <Box
            paddingLeft="12px"
            paddingRight="18px"
            marginBottom="10px"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {isAdmin && (
              <Button
                className={classes.bottomButton}
                onClick={() => openDialog("widgetEmbedTool")}
              >
                Widget Embed Tool
              </Button>
            )}
            <Button
              className={classes.bottomButton}
              onClick={() => openDialog("clerkMyAccount")}
            >
              My Account
            </Button>
            <Button className={classes.bottomButton} onClick={handleLogout}>
              Logout
            </Button>
          </Box>
        </>
      </AppBar>
    )
  )
}

Sidebar.propTypes = {
  children: PropTypes.node,
}

export default Sidebar
