import { Box, Card } from "@material-ui/core"
import { verifyEmail } from "actions/Clerk/users"
import loginBackgroundImage from "assets/login-background-image.jpg"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import LoginForm from "components/Forms/LoginForm"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import Page from "pages/Page"
import { useContext, useEffect } from "react"
import { useMutation } from "react-query"
import { useLocation } from "react-router"

import useStyles from "./styles"

const Index = () => {
  const classes = useStyles()

  const location = useLocation()
  const { openDialog, setAuthData, openSnackBar } = useContext(AppContext)
  const verifyEmailMutation = useMutation(verifyEmail)

  //check verify email code
  useEffect(() => {
    if (!isEmpty(location.search)) {
      const params = new URLSearchParams(location.search)
      const obj = {}

      // iterate over all keys
      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          obj[key] = params.getAll(key)
        } else {
          obj[key] = params.get(key)
        }
      }
      setAuthData({ token: "", account: {} })
      verifyEmailMutation
        ?.mutateAsync(obj.verify)
        .then((res) => {
          let dialogData = { subtitle: "Thank you for verifying your email." }
          openDialog("emailVerification", dialogData)
        })
        .catch((err) => {
          if (err.response?.data?.error === "User has already verified email") {
            let dialogData = { subtitle: "Your email has already verified." }
            openDialog("emailVerification", dialogData)
          } else {
            openSnackBar({ message: "Error verifying email" })
          }
        })
    }
  }, [])

  return (
    <Page>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.container}
        style={{ backgroundImage: `url("${loginBackgroundImage}")` }}
      >
        <Card className={classes.formContainer}>
          <Logo className={classes.logo} />

          <LoginForm />
        </Card>
      </Box>
    </Page>
  )
}

export default Index
