import Page from "pages/Page"
import PropTypes from "prop-types"
import React from "react"
import Router from "router"

import useStyles from "./styles"

const Messages = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  return (
    <Page>
      <Router routes={routes} {...rest} />
    </Page>
  )
}

Messages.propTypes = {
  routes: PropTypes.array,
}

export default Messages
