import { Box, Menu, MenuItem, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { getResumeFromReviews } from "actions/Clerk/reviews"
import ApplicantData from "components/Clerk/ApplicantData"
import AvatarComponent from "components/Common/Avatar"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useMutation } from "react-query"

import useStyles from "./styles"

const RevieweeInfo = ({
  selected,
  answer,
  setAnswers,
  token,
  options,
  allCategory,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { openSnackBar } = useContext(AppContext)
  const [moreData, setMoreData] = useState([])
  const [hide, setHide] = useState(false)

  const openResponseChoices = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseChoices = (value) => {
    setAnswers((oldState) =>
      oldState.map((x) => {
        if (x.application_id === selected?.application_id) {
          x.answer = value
          return x
        } else {
          return x
        }
      })
    )
    setAnchorEl(null)
  }

  const openPdfMutation = useMutation(getResumeFromReviews)

  const downloadPdf = () => {
    openPdfMutation
      ?.mutateAsync(selected?.resume_id)
      .then((res) => {
        window.location.href = res?.url
      })
      .catch((err) => {
        openSnackBar({ message: "Error opening resume" })
      })
  }

  return (
    <Box padding="20px" overflow="auto">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <AvatarComponent
            user={selected}
            style={{ height: "60px", width: "60px" }}
          />
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            marginLeft="10px"
            justifyContent="flex-start"
          >
            <Typography className={classes.name} variant="h5">
              {selected?.first_name + " " + selected?.last_name}
            </Typography>
            <Typography>
              {selected?.county}, {selected?.city}, {selected?.zip_code}
            </Typography>
          </Box>
        </Box>

        <ButtonRounded
          className={classes.optionsButton}
          onClick={openResponseChoices}
          // aria-controls={open ? "simple-menu" : undefined}
          aria-haspopup="true"
          variant="contained"
        >
          <Typography style={{ fontSize: "1.325rem", fontWeight: 600 }}>
            {answer?.[0]?.answer?.response
              ? answer?.[0]?.answer?.response
              : "Review Needed"}
          </Typography>
          <ExpandMoreIcon />
        </ButtonRounded>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={(event, reason) => {
            if (reason === "backdropClick") {
              setAnchorEl(null)
            }
          }}
        >
          {options?.map((s) => {
            return (
              <MenuItem key={s.id} onClick={() => handleCloseChoices(s)}>
                {s.response}
              </MenuItem>
            )
          })}
        </Menu>
      </Box>
      <ApplicantData
        applicantData={{ applicant: { ...selected, ...allCategory } }}
        openPdfMutation={openPdfMutation}
        downloadPdf={downloadPdf}
        board={true}
        setMoreData={setMoreData}
        moreData={moreData}
        hide={hide}
        setHide={setHide}
      />
    </Box>
  )
}

RevieweeInfo.propTypes = {
  selected: PropTypes.object,
  answer: PropTypes.array,
  setAnswers: PropTypes.func,
  token: PropTypes.string,
  options: PropTypes.array,
  allCategory: PropTypes.object,
}

export default RevieweeInfo
