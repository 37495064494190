import {
  getMe,
  login,
  updatePassword,
  updateUserProfile,
} from "actions/authentication"
import { isEmpty } from "lodash"
import Deferred from "promise-deferred"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { createContext } from "react"
import { useIndexedDB } from "react-indexed-db"
import { useMutation, useQuery, useQueryClient } from "react-query"
import API from "store/api"
import { useDebounce } from "use-lodash-debounce"

const AppContext = createContext()
export default AppContext

export const AppContainer = ({ children, ...props }) => {
  const { isClerk, isIframe } = props
  const queryClient = useQueryClient()

  const { add, clear, getAll } = useIndexedDB("auth")

  const [authUser, setAuthUserState] = useState(null)
  const [token, setToken] = useState(null)
  const [shouldRender, setShouldRender] = useState(false)
  const [dialogs, setDialogsState] = useState({})
  const dialogPromises = useRef({})
  const [isSuperAdmin, setIsSuperAdmin] = useState(false)
  //a approved clerk is an admin
  const [isAdmin, setIsAdmin] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const debouncedSearchValue = useDebounce(searchValue, 500)
  //know if search button is clicked in citizen side
  const [searchButtonClicked, setSearchButtonClicked] = useState(false)
  //know if search button is clicked in citizen side from the index page
  const [searchButtonClickedFromIndex, setSearchButtonClickedFromIndex] =
    useState(false)
  //how if clear button ins clicked in citizen side
  const [clearSearch, setClearSearch] = useState(false)

  const [snackBar, setSnackbar] = useState({
    open: false,
    message: "There was an error",
    autoHideDuration: 3000,
  })

  /**
   * Set data and open dialog
   * @param {string} name
   * @param {Object} data
   */
  const openDialog = (name, data) => {
    setDialogsState({
      ...dialogs,
      [name]: {
        data,
        open: false,
      },
    })
    setTimeout(() => {
      setDialogsState((newDialogs) => ({
        ...newDialogs,
        [name]: {
          ...newDialogs[name],
          open: true,
        },
      }))
    }, 150)
    dialogPromises.current = {
      ...dialogPromises.current,
      [name]: new Deferred(),
    }
    return dialogPromises.current[name].promise
  }

  const closeDialog = (name, confirm = false, data) => {
    // confirm is false by default so dialogs will always reject
    if (confirm) {
      dialogPromises?.current?.[name]?.resolve?.(data)
    } else {
      dialogPromises?.current?.[name]?.reject?.(data)
    }
    setDialogsState({
      ...dialogs,
      [name]: {
        ...dialogs[name],
        open: false,
      },
    })
    setTimeout(() => {
      setDialogsState({
        ...dialogs,
        [name]: {
          ...dialogs[name],
          data: null,
          open: false,
        },
      })
      delete dialogPromises.current[name]
    }, 300)
  }

  const openSnackBar = ({ open = true, ...rest }) => {
    setSnackbar({
      ...snackBar,
      ...rest,
      open,
    })
  }

  const setAuthData = ({ token, account }) => {
    return clear().then(() => {
      return add({ name: "token", token, account }).then(
        () => {
          API.defaults.headers.common["Authorization"] = token
          setAuthUserState(account)
          setToken(token)

          if (isClerk) {
            setIsSuperAdmin(account?.super_admin === 1 ? true : false)
            setIsAdmin(account?.approved === 1 ? true : false)
          }
        },
        (error) => {
          console.log(error)
        }
      )
    })
  }

  useEffect(() => {
    try {
      // indexdb
      getAll()
        .then((res) => {
          if (!isEmpty(res)) {
            API.defaults.headers.common["Authorization"] = res[0].token
            setAuthUserState(res[0].account)
            setToken(res[0].token)
            if (isClerk) {
              setIsSuperAdmin(res[0].account.super_admin === 1 ? true : false)
              setIsAdmin(res[0].account?.approved === 1 ? true : false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          removeAuthToken()
        })
        .finally(() => {
          setShouldRender(true)
        })
    } catch (err) {
      console.log(err)
      removeAuthToken()
    }
  }, [])

  // Watch token updates and run getMe call
  const getMeQuery = useQuery(["getMe", token], () => getMe(isClerk), {
    enabled: Boolean(token),
    onSuccess: (res) => {
      setAuthUserState(res)
      setAuthData({ token, account: res })
    },
  })

  const removeAuthToken = () => {
    return new Promise((resolve, reject) => {
      clear().then(() => {
        setToken("")
        resolve()
        // remove queries from cache (helps cleaning up when logging back in)
        if (!isClerk) {
          queryClient.removeQueries("board", "boards", "getMe", "applied")
        }
      })
    })
  }

  const updateUserProfileMutation = useMutation(updateUserProfile)
  const updatePasswordMutation = useMutation(updatePassword)
  const loginMutation = useMutation(login)

  return (
    <AppContext.Provider
      value={{
        setAuthData,
        setToken,
        token,
        authUser,
        dialogs,
        openDialog,
        closeDialog,
        snackBar,
        openSnackBar,
        isSuperAdmin,
        setIsSuperAdmin,
        isAdmin,
        setIsAdmin,
        searchValue,
        setSearchValue,
        updateUserProfileMutation,
        updatePasswordMutation,
        setAuthUserState,
        isClerk,
        isIframe,
        loginMutation,
        debouncedSearchValue,
        searchButtonClicked,
        setSearchButtonClicked,
        clearSearch,
        setClearSearch,
        removeAuthToken,
        searchButtonClickedFromIndex,
        setSearchButtonClickedFromIndex,
      }}
    >
      {shouldRender && children}
      {!shouldRender && null}
    </AppContext.Provider>
  )
}

AppContainer.propTypes = {
  children: PropTypes.node,
  isClerk: PropTypes.bool,
  isIframe: PropTypes.bool,
}
