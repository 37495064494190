import {
  AppBar,
  Box,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"
import AvatarComponent from "components/Common/Avatar"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const Searchbar = () => {
  const classes = useStyles()
  const history = useHistory()

  const { authUser, token, searchValue, setSearchValue } =
    useContext(AppContext)

  let whatTitle = () => {
    if (history?.location?.pathname === "/dashboard") {
      return (
        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={classes.welcomeText}>
            {`Welcome back, ${authUser?.first_name}`}
          </Typography>
          <AvatarComponent user={authUser} />
        </Box>
      )
    } else if (
      history?.location?.pathname === "/entities" ||
      history?.location?.pathname?.includes("entities") ||
      history?.location?.pathname === "/requests" ||
      history?.location?.pathname === "/admins"
    ) {
      return (
        <Typography className={classes.welcomeText}>
          Matchboard Admin Portal
        </Typography>
      )
    } else {
      return (
        <TextField
          placeholder="Search"
          className={classes.search}
          variant="filled"
          // style={{ backgroundColor: "#898989" }}
          onChange={(e) => {
            setSearchValue(e.target.value)
            if (e.target.value === "") {
              setSearchValue("")
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setSearchValue(e.target.value)
            }
          }}
          value={searchValue}
          InputProps={{
            classes: {
              root: classes.inputBackgroundColor,
            },
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ marginLeft: "10px", marginBottom: "10px" }}
              >
                <Search classes={{ root: classes.icon }} />
              </InputAdornment>
            ),
          }}
        />
      )
    }
  }
  return (
    <AppBar
      position="static"
      color="secondary"
      elevation={0}
      className={classes.root}
    >
      {token && <Toolbar>{whatTitle()}</Toolbar>}
    </AppBar>
  )
}

Searchbar.propTypes = {
  children: PropTypes.node,
}

export default Searchbar
