import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      color: "#FFFFFF",
      marginBottom: theme.spacing(4),
    },

    button: {
      width: "200px",
    },
  }),
  {
    name: "RequestClerkBox",
  }
)

export default styles
