import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
} from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import PropTypes from "prop-types"
import { useContext, useState } from "react"

import useStyles from "./styles"

const FilterByCategory = ({ children }) => {
  const classes = useStyles()

  const {
    interests,
    interestsLoading,
    selectedCategories,
    setSelectedCategories,
  } = useContext(CitizenAppContext)
  const [showAll, setShowAll] = useState(false)

  const handleToggleCollapse = () => {
    setShowAll((oldState) => !oldState)
  }

  const handleChange = (event) => {
    if (event.target.checked) {
      const checkedInterest = interests?.find(
        (x) => x.name === event.target.name
      )
      setSelectedCategories([...selectedCategories, checkedInterest])
    } else {
      setSelectedCategories(
        selectedCategories.filter((x) => x.name !== event.target.name)
      )
    }
  }

  return (
    <Box style={{ overflowY: "auto" }}>
      <FormControl component="fieldset" className={classes.formControl}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginBottom={1}
          justifyContent="space-between"
          marginRight={2}
        >
          <FormLabel component="legend" classes={{ root: classes.legend }}>
            Filter by category
          </FormLabel>
          <IconButton onClick={handleToggleCollapse}>
            {!showAll ? (
              <KeyboardArrowDownIcon color="primary" />
            ) : (
              <KeyboardArrowUpIcon color="primary" />
            )}
          </IconButton>
        </Box>
        <FormGroup>
          <Collapse in={showAll}>
            {!interestsLoading &&
              interests?.length > 0 &&
              interests?.map((interest, i) => {
                return (
                  <Box key={interest?.id} pr={1} pl={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCategories
                            ?.map((x) => x.id)
                            ?.includes(interest?.id)}
                          onChange={handleChange}
                          name={interest?.name}
                        />
                      }
                      label={interest?.name}
                      labelPlacement="start"
                      classes={{ labelPlacementStart: classes.checkboxLabel }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    />
                    <Divider />
                  </Box>
                )
              })}
          </Collapse>
        </FormGroup>
      </FormControl>
    </Box>
  )
}

FilterByCategory.propTypes = {
  children: PropTypes.node,
}

export default FilterByCategory
