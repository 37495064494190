import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core"
import classnames from "clsx"
import BottomDrawer from "components/Common/BottomDrawer"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import useStyles from "./styles"

const FiftyFifty = ({ left, right, lockLeft = false }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))
  const location = useLocation()
  const history = useHistory()

  const [showDrawer, toggleShowDrawer] = useState(false)

  // Logic specific to mobile board drawer showing, dependent on:
  // 1) FiftyFifty layout also used in entity, limits this logic to the boards usage
  // 2) if boards/:board_id (board selected), triggers to show bottom drawer
  const boardChosen =
    location?.pathname?.split("/")[1] === "boards" &&
    location?.pathname?.split("/")[2]

  useEffect(() => {
    if (boardChosen) {
      toggleShowDrawer(true)
    }
  }, [boardChosen])

  return (
    <Grid
      container
      className={classnames(classes.root, {
        [classes.entityRoot]: location.pathname.includes("entities"),
      })}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        className={classnames(classes.gridItem, {
          [classes.entityGridItem]: location.pathname.includes("entities"),
        })}
      >
        <Box
          className={classnames(classes.scrollBox, {
            [classes.frozenBox]: lockLeft,
          })}
        >
          {left}
        </Box>
      </Grid>
      {mobile && !location.pathname.includes("entities") ? (
        <BottomDrawer
          componentToRender={right}
          show={showDrawer}
          onClose={() => {
            toggleShowDrawer(false)
            setTimeout(() => history.push("/boards"), 500)
          }}
        />
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className={classnames(classes.gridItem, {
            [classes.entityGridItem]: location.pathname.includes("entities"),
          })}
        >
          {right}
        </Grid>
      )}
    </Grid>
  )
}

FiftyFifty.propTypes = {
  left: PropTypes.node,
  right: PropTypes.node,
  lockLeft: PropTypes.bool,
}

export default FiftyFifty
