import {
  getAge,
  getBoardStats,
  getGender,
  getMembership,
  getRace,
} from "actions/Clerk/analytics"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useContext } from "react"
import { useQuery } from "react-query"

import AppContext from "../App"

const AnalyticsContext = createContext()
export default AnalyticsContext

export const AnalyticsContainer = ({ children, ...props }) => {
  const { authUser, isAdmin } = useContext(AppContext)

  const { data: genderData, isLoading: genderDataLoading } = useQuery(
    ["genderData", authUser?.entity_id],
    () => getGender(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  const { data: raceData, isLoading: raceDataLoading } = useQuery(
    ["raceData", authUser?.entity_id],
    () => getRace(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  const { data: ageData, isLoading: ageDataLoading } = useQuery(
    ["ageData", authUser?.entity_id],
    () => getAge(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  const { data: membershipData, isLoading: membershipDataLoading } = useQuery(
    ["membershipData", authUser?.entity_id],
    () => getMembership(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  const { data: boardData, isLoading: boardDataLoading } = useQuery(
    ["boardData", authUser?.entity_id],
    () => getBoardStats(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  return (
    <AnalyticsContext.Provider
      value={{
        genderData,
        genderDataLoading,
        raceData,
        raceDataLoading,
        ageData,
        ageDataLoading,
        membershipData,
        membershipDataLoading,
        boardData,
        boardDataLoading,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

AnalyticsContainer.propTypes = {
  children: PropTypes.node,
}
