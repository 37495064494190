import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import RequestClerkContext from "contexts/Clerk/RequestClerk"
import { Field, FieldArray, getIn, useFormikContext } from "formik"
import { CheckboxWithLabel } from "formik-material-ui"
import { useContext, useEffect } from "react"

import useStyles from "./styles"

const Step3 = () => {
  const classes = useStyles()
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext()

  const { leadershipData } = useContext(RequestClerkContext)

  const months = [
    {
      shorthand: "Jan.",
      value: "january",
    },
    {
      shorthand: "Feb.",
      value: "february",
    },
    {
      shorthand: "Mar.",
      value: "march",
    },
    {
      shorthand: "Apr.",
      value: "april",
    },
    {
      shorthand: "May",
      value: "may",
    },
    {
      shorthand: "Jun.",
      value: "june",
    },
    {
      shorthand: "Jul.",
      value: "july",
    },
    {
      shorthand: "Aug.",
      value: "august ",
    },
    {
      shorthand: "Sep.",
      value: "september",
    },
    {
      shorthand: "Oct.",
      value: "october",
    },
    {
      shorthand: "Nov.",
      value: "November",
    },
    {
      shorthand: "Dec.",
      value: "December",
    },
  ]

  const emptyPeriod = {
    review_period_start: "",
    review_period_end: "",
  }

  useEffect(() => {
    if (values?.review_period_rolling) {
      setFieldValue("review_periods", [emptyPeriod])
    }
  }, [values?.review_period_rolling])

  return (
    <Box>
      <TextField
        type="text"
        name="entity_url"
        label={
          values?.entity_url ? "Entity website (URL)" : "Entity website (URL)*"
        }
        data-cy="clerk-request-entity-url"
        id="entity_url"
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.entity_url && errors.entity_url}
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        value={values.entity_url}
        variant="outlined"
        fullWidth
        InputProps={{
          classes: {
            root: classes.inputBackgroundColor,
          },
        }}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        error={touched.entity_url && errors.entity_url}
      />
      <TextField
        label={
          values?.number_districts
            ? "Number of Councils Districts"
            : "Number of Councils Districts*"
        }
        type="number"
        data-cy="clerk-request-number-districts"
        name="number_districts"
        value={values?.number_districts}
        onChange={handleChange}
        variant="outlined"
        fullWidth
        style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
        helperText={touched.number_districts && errors.number_districts}
        error={touched.number_districts && errors.number_districts}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        InputProps={{
          inputProps: {
            min: 1,
          },
        }}
      />

      <Typography className={classes.subtitle}>
        What is the application review period?
      </Typography>
      <Box
        className={classes.dateContainer}
        display="flex"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <FieldArray
            name="review_periods"
            disabled={values?.review_period_rolling}
          >
            {({ push, remove }) => (
              <Box className={classes.dateContainer}>
                {values?.review_periods.map((p, index) => {
                  const start = `review_periods[${index}].review_period_start`
                  const touchedStart = getIn(touched, start)
                  const errorStart = getIn(errors, start)
                  const end = `review_periods[${index}].review_period_end`
                  const touchedEnd = getIn(touched, end)
                  const errorEnd = getIn(errors, end)

                  return (
                    <Box
                      key={index}
                      display="flex"
                      width="100%"
                      alignItems="center"
                      marginBottom="12px"
                    >
                      <TextField
                        select
                        disabled={values?.review_period_rolling}
                        value={p.review_period_start}
                        onChange={(e) =>
                          setFieldValue(
                            "review_periods",
                            values.review_periods?.map((x, periodsIndex) => {
                              if (periodsIndex === index) {
                                x.review_period_start = e.target.value
                                return x
                              } else {
                                return x
                              }
                            })
                          )
                        }
                        variant="outlined"
                        fullWidth
                        style={{
                          backgroundColor: "#F8F8F8",
                          width: "120px",
                          marginRight: "20px",
                        }}
                        helperText={
                          touchedStart && errorStart ? errorStart : ""
                        }
                        error={Boolean(touchedStart && errorStart)}
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                      >
                        {months?.map((month, i) => {
                          return (
                            <MenuItem key={i} value={month?.value}>
                              {month?.shorthand}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                      <Typography>to</Typography>
                      <TextField
                        select
                        disabled={values?.review_period_rolling}
                        value={p.review_period_end}
                        onChange={(e) =>
                          setFieldValue(
                            "review_periods",
                            values?.review_periods?.map((x, periodsIndex) => {
                              if (periodsIndex === index) {
                                x.review_period_end = e.target.value
                                return x
                              } else {
                                return x
                              }
                            })
                          )
                        }
                        onBlur={handleBlur}
                        variant="outlined"
                        fullWidth
                        style={{
                          backgroundColor: "#F8F8F8",
                          width: "120px",
                          marginRight: "20px",
                          marginLeft: "20px",
                        }}
                        error={Boolean(touchedEnd && errorEnd)}
                        helperText={touchedEnd && errorEnd ? errorEnd : ""}
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                      >
                        {months?.map((month, i) => {
                          return (
                            <MenuItem key={i} value={month?.value}>
                              {month?.shorthand}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                      <IconButton
                        color="primary"
                        onClick={() => remove(index)}
                        disabled={values?.review_period_rolling}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )
                })}
                <Box display="flex">
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="review_period_rolling"
                    Label={{ label: "Rolling" }}
                    data-cy="clerk-request-rolling"
                  />
                  <IconButton
                    style={{ padding: 10, borderRadius: 0 }}
                    onClick={() => push(emptyPeriod)}
                    disabled={values?.review_period_rolling}
                  >
                    <AddCircleOutlineIcon />
                    <Typography className={classes.saveText}>
                      Add another review period
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
            )}
          </FieldArray>
        </Box>
      </Box>

      <Typography className={classes.subtitle}>
        Which levels of leadership are in your entity?{" "}
      </Typography>
      <Typography className={classes.selectText}>
        Select all that apply
      </Typography>
      {leadershipData?.map((l) => {
        return (
          <Field
            key={l.id}
            component={CheckboxWithLabel}
            type="checkbox"
            value={l.id}
            Label={{ label: l.level }}
            checked={values?.leadership_ids?.includes(l.id)}
            onChange={(e) => {
              if (values?.leadership_ids?.includes(l.id)) {
                setFieldValue(
                  "leadership_ids",
                  values?.leadership_ids?.filter((x) => x !== l.id)
                )
              } else {
                setFieldValue("leadership_ids", [
                  ...values?.leadership_ids,
                  l.id,
                ])
              }
            }}
          />
        )
      })}
    </Box>
  )
}

Step3.propTypes = {}

export default Step3
