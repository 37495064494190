import { Box, Typography } from "@material-ui/core"
import classnames from "clsx"
import * as dayjs from "dayjs"
import PropTypes from "prop-types"

import useStyles from "./styles"

const MessageItem = ({ data, user }) => {
  const classes = useStyles()

  return (
    <Box
      className={classnames(classes.root, {
        [classes.received]: data.direction === "received",
        [classes.sent]: data.direction === "sent",
      })}
    >
      <Box className={classes.messageBox}>
        <Typography className={classes.messageText}>{data.message}</Typography>
      </Box>
      <Box className={classes.metaText}>
        <Typography color="primary" style={{ fontWeight: 500, marginRight: 4 }}>
          {data.direction === "sent" ? "You" : user?.name}
        </Typography>
        <Typography color="primary">{`${dayjs(data?.created)
          .utc(true)
          .format("M/DD/YY")} at
          ${dayjs(data?.created).utc(true).format("h:mm A")}`}</Typography>
      </Box>
    </Box>
  )
}

MessageItem.propTypes = {
  data: PropTypes.object,
  user: PropTypes.object,
}

export default MessageItem
