import LoaderButton from "@bit/c_t.components.loader-button"
import { Box, TextField, Typography } from "@material-ui/core"
import { forgotPassword } from "actions/Citizen/users"
import App from "contexts/App"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation } from "react-query"
import * as yup from "yup"

import useStyles from "./styles"

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
})

const ForgotPasswordForm = ({ resetClick, setEmail }) => {
  const classes = useStyles()
  const mutation = useMutation((data) => {
    return forgotPassword(data)
  })
  const { openSnackBar } = useContext(App)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Send forgot password email
      try {
        const emailResponse = await mutation.mutateAsync(values)
        if (emailResponse.status === 200) {
          //this it the email address we need to get to the code input
          const resetEmail = JSON.parse(emailResponse.config.data)
          setEmail(resetEmail)
          //this will put login dialog into reset password state
          resetClick()
          openSnackBar({
            message: `Forgot password email sent!`,
          })
        } else {
          openSnackBar({
            message: "Invalid email",
            open: true,
            error: true,
          })
        }
      } catch (error) {
        openSnackBar({
          message: `No Account Found with that Email Address`,
          open: true,
          error: true,
        })
      }
    },
  })

  // Set formik field to touched onblur
  const handleTouched = (e) => {
    formik.setFieldTouched(e.target.id, true)
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box>
        <Box>
          <Typography>
            Instructions to reset your password will be sent to your email
            address.
          </Typography>
        </Box>
        <Box marginTop={4}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              formik.submitForm()
            }}
          >
            <TextField
              className={classes.textField}
              id="email"
              label="Email Address"
              variant="outlined"
              type="text"
              name="email"
              onChange={formik.handleChange}
              onBlur={handleTouched}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : " "
              }
              fullWidth
            />
            <LoaderButton
              color="primary"
              classoverrides={{
                wrapper: classes.button,
                button: {
                  root: classes.buttonRoot,
                },
              }}
              variant="contained"
              fullWidth
              type="submit"
              working={mutation.isLoading}
            >
              Reset Password
            </LoaderButton>
          </form>
        </Box>
      </Box>
    </Box>
  )
}

ForgotPasswordForm.propTypes = {
  setEmail: PropTypes.func,
  handleClose: PropTypes.func,
  forgotClick: PropTypes.func,
  resetClick: PropTypes.func,
}

export default ForgotPasswordForm
