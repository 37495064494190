import DateFnsUtils from "@date-io/date-fns"
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import { getPronouns } from "actions/authentication"
import Avatar from "components/Common/Avatar"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useQuery, useQueryClient } from "react-query"
import serialize from "store/serialize"
import getScaledImage from "utils/getScaledImage"
import * as Yup from "yup"

import useStyles from "./styles"

let statesList = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
]

const personalInfoSchema = Yup.object({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  dob: Yup.date().nullable(true),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  county: Yup.string().required("Required"),
  zip_code: Yup.string().required("Required"),
  pronoun_id: Yup.number().nullable(true),
})

const AddPersonalInfo = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const authContext = useContext(AppContext)
  const {
    dialogs,
    closeDialog,
    authUser,
    token,
    openSnackBar,
    openDialog,
    isClerk,
  } = useContext(AppContext)

  const { data: pronounsList, isLoading: pronounsListLoading } = useQuery(
    "pronouns",
    () => getPronouns(isClerk)
  )

  const { updateDetailsMutation } = useContext(AccountContext)
  const thisDialog = dialogs?.["addPersonalInfo"] || {}
  const { open = false, data = {} } = thisDialog

  const handleClose = () => {
    closeDialog("addPersonalInfo")
  }

  const onSubmit = (values, actions) => {
    updateDetailsMutation
      .mutateAsync(values)
      .then((res) => {
        serialize("user", res.user).then((serializedData) => {
          authContext.setAuthData({
            token: token,
            account: serializedData,
          })
          return queryClient.setQueryData("user", (oldState) => {
            return serializedData
          })
        })
        openSnackBar({ message: "Details updated" })
        handleClose()
      })
      .catch((err) => {
        openSnackBar({ message: "Error updating details" })
      })
  }

  // dialog open to upload an image
  const openUploadImage = (image) => {
    let data = { image: image }
    openDialog("uploadImage", data)
  }

  const scaledImage = (img) => {
    return getScaledImage(img, 200, 200)
  }

  return (
    <ConditionalDialogWrapper open={open} onClose={handleClose}>
      <DialogTitle style={{ paddingBottom: 0 }} disableTypography>
        <Typography variant="h2">My Details</Typography>
        <Typography className={classes.subtitle}>
          This must be completed
        </Typography>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: authUser?.first_name || "",
          last_name: authUser?.last_name || "",
          dob: authUser?.dob || null,
          address: authUser?.address || "",
          city: authUser?.city || "",
          state: authUser?.state || "",
          county: authUser?.county || "",
          zip_code: authUser?.zip_code || "",
          pronoun_id: authUser?.pronoun_id || null,
        }}
        onSubmit={onSubmit}
        validationSchema={personalInfoSchema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setErrors,
          dirty,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {pronounsListLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={15} />
              </Box>
            ) : (
              <DialogContent>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  marginBottom={3}
                >
                  <Box
                    width="48%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop={1}
                  >
                    <Box
                      className={classes.avatarContainer}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="6px"
                    >
                      <IconButton
                        className={classes.pencilIcon}
                        onClick={() => openUploadImage(authUser?.profile_image)}
                      >
                        <CreateIcon />
                      </IconButton>
                      <Avatar className={classes.avatar} user={authUser} />
                    </Box>
                  </Box>
                  <Box width="48%" marginTop={2}>
                    <TextField
                      type="text"
                      name="first_name"
                      label="First Name"
                      id="first_name"
                      data-cy="details-first-name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.first_name && errors.first_name}
                      style={{ marginBottom: "25px" }}
                      value={values.first_name}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      error={touched.first_name && errors.first_name}
                    />
                    <TextField
                      type="text"
                      name="last_name"
                      label="Last Name"
                      data-cy="details-last-name"
                      id="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={touched.last_name && errors.last_name}
                      style={{ marginBottom: "25px" }}
                      value={values.last_name}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      error={touched.last_name && errors.last_name}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        openTo="year"
                        views={["year", "month", "date"]}
                        id="date-picker-user-dob"
                        label="DOB"
                        value={values?.dob}
                        classes={
                          errors?.dob && touched?.dob
                            ? { root: classes.dobDatePicker }
                            : null
                        }
                        data-cy="details-dob"
                        onChange={(e) => {
                          setFieldValue("dob", e)
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Box>
                </Box>
                <Box>
                  <TextField
                    type="text"
                    name="address"
                    label="Address"
                    id="county"
                    data-cy="details-address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.address && errors.address}
                    style={{ marginBottom: "25px" }}
                    value={values.address}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    error={touched.address && errors.address}
                  />
                  <TextField
                    label="State"
                    select
                    name="state"
                    data-cy="details-state"
                    value={values?.state}
                    onChange={(e) => setFieldValue("state", e.target.value)}
                    variant="outlined"
                    fullWidth
                    style={{
                      marginBottom: "25px",
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    className={classes.textField}
                    error={touched.state && errors.state}
                    helperText={touched.state && errors.state}
                  >
                    {statesList?.map((s, i) => {
                      return (
                        <MenuItem
                          key={s}
                          value={s}
                          data-cy={`details-state-${i}`}
                        >
                          {s}
                        </MenuItem>
                      )
                    })}
                  </TextField>

                  <TextField
                    type="text"
                    name="city"
                    label="City"
                    data-cy="details-city"
                    id="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.city && errors.city}
                    style={{ marginBottom: "25px" }}
                    value={values.city}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    error={touched.city && errors.city}
                  />
                  <TextField
                    type="text"
                    name="county"
                    label="County"
                    id="county"
                    data-cy="details-county"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.county && errors.county}
                    style={{ marginBottom: "25px" }}
                    value={values.county}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    error={touched.county && errors.county}
                  />
                  <TextField
                    type="text"
                    name="zip_code"
                    label="ZIP code"
                    data-cy="details-zip"
                    id="zip_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.zip_code && errors.zip_code}
                    style={{ marginBottom: "25px" }}
                    value={values.zip_code}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    error={touched.zip_code && errors.zip_code}
                  />
                  <TextField
                    label="Pronouns"
                    select
                    name="pronoun_id"
                    data-cy="details-pronouns"
                    value={values?.pronoun_id}
                    onChange={(e) =>
                      setFieldValue("pronoun_id", e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    style={{
                      backgroundColor: "#fff",
                    }}
                    className={classes.textField}
                  >
                    {pronounsList?.data?.pronouns?.map((p, i) => {
                      return (
                        <MenuItem
                          key={p?.id}
                          value={p?.id}
                          data-cy={`details-pronouns-${i}`}
                        >
                          {p?.pronoun}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Box>
              </DialogContent>
            )}
            <DialogActions>
              <Box display="flex" justifyContent="center" width="100%">
                <ButtonRounded
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  type="button"
                  data-cy="details-close-button"
                >
                  Close
                </ButtonRounded>
                <ButtonRounded
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.saveButton}
                  disabled={!dirty || updateDetailsMutation.isLoading}
                  data-cy="details-save-button"
                  classes={{ label: classes.buttonLabel }}
                >
                  {updateDetailsMutation.isLoading ? (
                    <CircularProgress
                      style={{
                        color: "#fff",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                      size={20}
                    />
                  ) : (
                    "Save"
                  )}
                </ButtonRounded>
              </Box>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ConditionalDialogWrapper>
  )
}

export default AddPersonalInfo
