import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@material-ui/core"
import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js"
import PropTypes from "prop-types"
import { Doughnut } from "react-chartjs-2"

import useStyles from "./styles"

ChartJS.register(ArcElement, Legend, Title, LinearScale, Tooltip)
const PieChartCard = ({ title, graphData, board = false }) => {
  const totals = Object.values(graphData).reduce(
    (totals, current) => totals + current
  )
  const theme = useTheme()
  const classes = useStyles()
  const data = {
    labels: Object.keys(graphData),
    datasets: [
      {
        label: title,
        data: Object.values(graphData),
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.primary.light,
          theme.palette.secondary.dark,
          theme.palette.secondary.light,
          "#00ACA3",
          "#48817C",
          "#877179",
          "#AE6DAB",
          "#005B56",
        ],
        borderColor: [
          theme.palette.primary.main,
          theme.palette.primary.light,
          theme.palette.secondary.dark,
          theme.palette.secondary.light,
          "#00ACA3",
          "#48817C",
          "#877179",
          "#AE6DAB",
          "#005B56",
        ],
        hoverOffset: 10,
        borderWidth: 1,
      },
    ],
  }

  return (
    <Card style={{ width: "100%", height: board ? "370px" : "320px" }}>
      <CardHeader title={title} />
      <CardContent>
        <Box
          display="flex"
          className={classes.graphContainer}
          style={{ height: board ? "240px" : "220px" }}
        >
          {totals === 0 ? (
            <Box width="100%" display="flex" justifyContent="center">
              <Typography variant="h1">NO DATA AVAILABLE</Typography>
            </Box>
          ) : (
            <Doughnut
              data={data}
              options={{
                responsive: true,
                width: "100%",
                height: board ? "230px" : "200px",
                maintainAspectRatio: false,
                cutoutPercentage: 40,
                plugins: {
                  legend: {
                    position: "right",
                    maxHeight: "220px",
                    labels: {
                      boxWidth: 20,
                      usePointStyle: true,
                    },
                  },
                },
                tooltips: {
                  mode: "nearest",
                  intersect: false,
                },
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

PieChartCard.propTypes = {
  title: PropTypes.string,
  graphData: PropTypes.object,
  board: PropTypes.bool,
}

export default PieChartCard
