import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    marginBottom: theme.spacing(4),
  },
  textBox: {
    width: "30%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 100px",
    paddingBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "65%",
      overflow: "auto",
      margin: "0 15%",
    },
  },
}))

export default styles
