import {
  Box,
  Container,
  LinearProgress,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import BoardsList from "components/Clerk/Boards/List"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import BoardsContext from "contexts/Boards"
import FiftyFifty from "Layouts/FiftyFifty"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import Router from "router"
import sortHelper from "utils/sortHelper"
import sortTypeUtil from "utils/sortTypeUtil"

import useStyles from "./styles"

const List = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { boardsData, boardsDataLoading } = useContext(BoardsContext)
  const { openDialog, searchValue } = useContext(AppContext)
  const theme = useTheme()

  const ipadView = useMediaQuery(theme.breakpoints.down("md"))

  const [sortedData, setSortedData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [sortState, setSortState] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (value) => {
    //prevent click outside of the menu
    if (typeof value === "string") {
      setSortState(value)
    }
    setAnchorEl(null)
  }

  const handleOpenDialog = () => {
    openDialog("addBoard")
  }

  // set boardsData to filteredData on first load
  useEffect(() => {
    if (boardsData?.length > 0 && !sortedData) {
      setSortedData(boardsData)
    }
  }, [boardsData])

  // handle search and filter conditionals
  useEffect(() => {
    if (boardsData) {
      let newArray = [...boardsData]
      if (searchValue?.length > 0 || sortState) {
        // if a search val is applied
        if (searchValue?.length > 0) {
          newArray = newArray?.filter((x) => {
            return (
              x.summary.toLowerCase().includes(searchValue.toLowerCase()) ||
              x.name.toLowerCase().includes(searchValue.toLowerCase())
            )
          })
        }
        //checks if a sort has been applied
        if (sortState) {
          //util function to sort the data
          let sortType = sortTypeUtil(sortState)
          newArray = sortHelper(newArray, sortType)
        }
      } else if (!searchValue) {
        // handles no search value
        newArray = boardsData
      }
      setSortedData(newArray)
    }
  }, [searchValue, sortState, boardsData])

  const sortText = () => {
    if (ipadView) {
      return "Sort"
    } else {
      return "Sort Options"
    }
  }

  if (boardsDataLoading) {
    return <LinearProgress />
  } else {
    return (
      <Page>
        <FiftyFifty
          lockLeft
          left={
            <Container className={classes.container}>
              <Box className={classes.buttonsContainer}>
                <Box className={classes.leftButtons}>
                  <ButtonRounded
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                  >
                    Add New Board
                  </ButtonRounded>
                  <ButtonRounded
                    variant="contained"
                    color="primary"
                    className={classes.dataImportButton}
                    onClick={() => openDialog("uploadCsv")}
                  >
                    Data Import
                  </ButtonRounded>
                </Box>
                <ButtonRounded
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  className={classes.sortButton}
                >
                  {sortState ? sortState : sortText()} <ArrowDropDownIcon />
                </ButtonRounded>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {/* sort on open positions (3/20 higher than 3/8), term length, most recent */}
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={(e) => handleClose(e.target.textContent)}
                  >
                    Most Recent
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={(e) => handleClose(e.target.textContent)}
                  >
                    Open Seats
                  </MenuItem>
                  <MenuItem
                    classes={{ root: classes.menuItem }}
                    onClick={(e) => handleClose(e.target.textContent)}
                  >
                    Applicant Count
                  </MenuItem>
                </Menu>
              </Box>
              <Box className={classes.scrollArea} id="board-scroll-clerk">
                <BoardsList data={sortedData} loading={boardsDataLoading} />
              </Box>
            </Container>
          }
          right={<Router routes={routes} {...rest} />}
        />
      </Page>
    )
  }
}

List.propTypes = {
  routes: PropTypes.array,
}

export default List
