import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import { populateEntityList, updateEntityInfo } from "actions/Clerk/entities"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import EntityContext from "contexts/Clerk/Entity"
import { Field, FieldArray, Form, Formik, getIn } from "formik"
import { CheckboxWithLabel } from "formik-material-ui"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useEffect, useRef, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import capitalize from "utils/capitalize"
import * as Yup from "yup"

import useStyles from "./styles"

const EntityGeneralInfo = () => {
  const classes = useStyles()
  const formRef = useRef()

  const { openSnackBar } = useContext(AppContext)
  const { entityData, entityDataLoading } = useContext(EntityContext)
  const entityId = entityData?.id
  const queryClient = useQueryClient()

  const months = [
    {
      shorthand: "-",
      value: null,
    },
    {
      shorthand: "Jan.",
      value: "january",
    },
    {
      shorthand: "Feb.",
      value: "february",
    },
    {
      shorthand: "Mar.",
      value: "march",
    },
    {
      shorthand: "Apr.",
      value: "april",
    },
    {
      shorthand: "May",
      value: "may",
    },
    {
      shorthand: "Jun.",
      value: "june",
    },
    {
      shorthand: "Jul.",
      value: "july",
    },
    {
      shorthand: "Aug.",
      value: "august ",
    },
    {
      shorthand: "Sep.",
      value: "september",
    },
    {
      shorthand: "Oct.",
      value: "october",
    },
    {
      shorthand: "Nov.",
      value: "November",
    },
    {
      shorthand: "Dec.",
      value: "December",
    },
  ]

  const schema = Yup.object().shape({
    entity: Yup.string().required("Entity Title is required"),
    entity_url: Yup.string().required("Entity URL is required"),
    review_periods: Yup.array().of(
      Yup.object().shape({
        review_period_start: Yup.string()
          .nullable(true)
          .test(
            "start",
            "Required",
            (val) =>
              val !== undefined ||
              formRef?.current?.values?.review_period_rolling
          ),
        review_period_end: Yup.string()
          .nullable(true)
          .test(
            "end",
            "Required",
            (val) =>
              val !== undefined ||
              formRef?.current?.values?.review_period_rolling
          ),
      })
    ),
    review_period_rolling: Yup.bool(),
    number_districts: Yup.number()
      .min(1)
      .typeError("Needs to be a number")
      .required("Number of districts is required")
      .nullable(true),
    leadership_ids: Yup.array().required(`Required`),
  })

  // call to get leadership levels options
  const { data: entityPopulateData, isLoading: entityPopulateDataLoading } =
    useQuery(["entityPopulateData", entityId], populateEntityList)

  // mutation to update entity
  const updateEntityInfoMutation = useMutation((data) =>
    updateEntityInfo(data, entityId)
  )

  // onsubmit call for the form calling mutation to update entity
  const onSubmit = (values, actions) => {
    let data = { ...values }
    if (data?.review_period_rolling) {
      data = {
        ...data,
        review_periods: [],
      }
    }
    updateEntityInfoMutation
      .mutateAsync(data)
      .then((res) => {
        let updatedVals
        queryClient.setQueryData(["entityData", entityId], (oldState) => {
          updatedVals = {
            ...oldState,
            entity: res?.entity,
            entity_url: res?.entity_url,
            review_period_rolling: res?.review_period_rolling,
            number_districts: Number(res?.number_districts),
            leadership_ids: data?.leadership_ids,
            review_periods: data?.review_periods,
          }
          return updatedVals
        })
        openSnackBar({
          message: "Successfully updated entity information",
        })
        actions.resetForm({ values: { ...updatedVals } })
      })
      .catch((err) => {
        console.log(err)
        openSnackBar({
          message: "Error updating entity",
        })
      })
  }
  const emptyPeriod = {
    review_period_start: "",
    review_period_end: "",
  }

  return (
    <div>
      <Formik
        initialValues={{
          entity: entityData?.entity || "",
          entity_url: entityData?.entity_url || "",
          review_periods: entityData?.review_periods || [emptyPeriod],
          review_period_rolling:
            Boolean(entityData?.review_period_rolling) || false,
          number_districts: Number(entityData?.number_districts),
          leadership_ids: entityData?.leadership_ids || [],
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        validateOnBlur={true}
        innerRef={formRef}
      >
        {(formik) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <Typography
                variant="body2"
                style={{ fontSize: "1.4rem", marginBottom: "12px" }}
              >
                General
              </Typography>
              <Box>
                <Typography color="primary" variant="body2">
                  {capitalize(entityData?.entity_type)} Title
                </Typography>
                <TextField
                  fullWidth
                  id="entity"
                  name="entity"
                  variant="filled"
                  type="text"
                  onChange={formik.handleChange}
                  helperText={formik.touched.entity && formik.errors.entity}
                  value={formik.values.entity}
                  className={classes.input}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  error={formik.touched.entity && formik.errors.entity}
                />
                <Typography color="primary" variant="body2">
                  Entity URL
                </Typography>
                <TextField
                  fullWidth
                  id="entity_url"
                  name="entity_url"
                  variant="filled"
                  type="text"
                  onChange={formik.handleChange}
                  helperText={
                    formik.touched.entity_url && formik.errors.entity_url
                  }
                  value={formik.values.entity_url}
                  className={classes.input}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  error={formik.touched.entity_url && formik.errors.entity_url}
                />
                <Typography color="primary" variant="body2">
                  Application Review
                </Typography>
                <Box display="flex" alignItems="center" marginTop="8px">
                  <FieldArray
                    name="review_periods"
                    disabled={Boolean(formik?.values?.review_period_rolling)}
                  >
                    {({ push, remove }) => (
                      <Box className={classes.dateContainer}>
                        {formik?.values?.review_periods.map((p, index) => {
                          const start = `review_periods[${index}].review_period_start`
                          const touchedStart = getIn(formik?.touched, start)
                          const errorStart = getIn(formik?.errors, start)
                          const end = `review_periods[${index}].review_period_end`
                          const touchedEnd = getIn(formik?.touched, end)
                          const errorEnd = getIn(formik?.errors, end)
                          return (
                            <Box
                              key={index}
                              display="flex"
                              width="100%"
                              alignItems="center"
                            >
                              <TextField
                                select
                                disabled={Boolean(
                                  formik?.values?.review_period_rolling
                                )}
                                value={p.review_period_start}
                                onChange={(e) => {
                                  formik?.setFieldValue(
                                    "review_periods",
                                    formik?.values?.review_periods?.map(
                                      (x, periodsIndex) => {
                                        if (periodsIndex === index) {
                                          x.review_period_start = e.target.value
                                          return x
                                        } else {
                                          return x
                                        }
                                      }
                                    )
                                  )
                                }}
                                variant="outlined"
                                fullWidth
                                style={{
                                  backgroundColor: "#F8F8F8",
                                  width: "120px",
                                  marginRight: "20px",
                                }}
                                helperText={
                                  touchedStart && errorStart ? errorStart : ""
                                }
                                error={Boolean(touchedStart && errorStart)}
                                FormHelperTextProps={{
                                  className: classes.formHelperText,
                                }}
                              >
                                {months?.map((month, i) => {
                                  return (
                                    <MenuItem key={i} value={month?.value}>
                                      {month?.shorthand}
                                    </MenuItem>
                                  )
                                })}
                              </TextField>
                              <Typography>to</Typography>
                              <TextField
                                select
                                disabled={Boolean(
                                  formik?.values?.review_period_rolling
                                )}
                                value={p.review_period_end}
                                onChange={(e) =>
                                  formik?.setFieldValue(
                                    "review_periods",
                                    formik?.values?.review_periods?.map(
                                      (x, periodsIndex) => {
                                        if (periodsIndex === index) {
                                          x.review_period_end = e.target.value
                                          return x
                                        } else {
                                          return x
                                        }
                                      }
                                    )
                                  )
                                }
                                onBlur={formik?.handleBlur}
                                variant="outlined"
                                fullWidth
                                style={{
                                  backgroundColor: "#F8F8F8",
                                  width: "120px",
                                  marginRight: "20px",
                                  marginLeft: "20px",
                                }}
                                error={Boolean(touchedEnd && errorEnd)}
                                helperText={
                                  touchedEnd && errorEnd ? errorEnd : ""
                                }
                                FormHelperTextProps={{
                                  className: classes.formHelperText,
                                }}
                              >
                                {months?.map((month, i) => {
                                  return (
                                    <MenuItem key={i} value={month?.value}>
                                      {month?.shorthand}
                                    </MenuItem>
                                  )
                                })}
                              </TextField>
                              <IconButton
                                color="primary"
                                onClick={() => remove(index)}
                                disabled={Boolean(
                                  formik?.values?.review_period_rolling
                                )}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          )
                        })}
                        <Box display="flex">
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="review_period_rolling"
                            Label={{ label: "Rolling" }}
                            onChange={(e) => {
                              formik?.setFieldValue(
                                "review_period_rolling",
                                !formik?.values?.review_period_rolling
                              )
                              if (
                                formik?.values?.review_period_rolling &&
                                isEmpty(formik?.values?.review_periods)
                              ) {
                                push(emptyPeriod)
                              }
                            }}
                          />
                          <Box display="flex" flexDirection="column">
                            <IconButton
                              style={{ padding: 10, borderRadius: 0 }}
                              onClick={() => push(emptyPeriod)}
                              disabled={Boolean(
                                formik?.values?.review_period_rolling
                              )}
                            >
                              <AddCircleOutlineIcon />
                              <Typography
                                style={{ marginLeft: "4px", wrap: "wrap" }}
                              >
                                Add another review period
                              </Typography>
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </FieldArray>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Typography color="primary" variant="body2">
                    # of Council Districts
                  </Typography>
                  <TextField
                    id="number_districts"
                    name="number_districts"
                    variant="outlined"
                    type="number"
                    helperText={
                      formik.touched.number_districts &&
                      formik.errors.number_districts
                    }
                    style={{ maxWidth: "80px", marginBottom: 0 }}
                    onChange={formik.handleChange}
                    value={formik.values.number_districts}
                    className={classes.input}
                    FormHelperTextProps={{
                      classes: {
                        root: classes.formHelperText,
                      },
                    }}
                    error={
                      formik.touched.number_districts &&
                      formik.errors.number_districts
                    }
                  />
                </Box>
                <Box mt={1}>
                  <Typography color="primary" variant="body2">
                    Levels of Leadership
                  </Typography>
                  {!entityPopulateDataLoading &&
                    entityPopulateData?.leadership_levels?.map((l) => {
                      return (
                        <Field
                          key={l.id}
                          component={CheckboxWithLabel}
                          type="checkbox"
                          value={l.id}
                          Label={{ label: l.level }}
                          checked={formik?.values?.leadership_ids?.includes(
                            l.id
                          )}
                          onChange={(e) => {
                            if (
                              formik?.values?.leadership_ids?.includes(l.id)
                            ) {
                              formik?.setFieldValue(
                                "leadership_ids",
                                formik?.values?.leadership_ids?.filter(
                                  (x) => x !== l.id
                                )
                              )
                            } else {
                              formik?.setFieldValue("leadership_ids", [
                                ...formik?.values?.leadership_ids,
                                l.id,
                              ])
                            }
                          }}
                        />
                      )
                    })}
                </Box>
              </Box>
              <Box minHeight="40px" pt={1}>
                <ButtonRounded
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.save}
                  disabled={updateEntityInfoMutation.isLoading}
                >
                  {updateEntityInfoMutation.isLoading ? (
                    <Box
                      width="98px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress style={{ color: "#fff" }} size={20} />
                    </Box>
                  ) : (
                    "Save Changes"
                  )}
                </ButtonRounded>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default EntityGeneralInfo
