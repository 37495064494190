import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@material-ui/core"
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js"
import PropTypes from "prop-types"
import { Bar } from "react-chartjs-2"

ChartJS.register(Legend, Title, LinearScale, Tooltip, CategoryScale, BarElement)
const BarChartCard = ({ title, graphData }) => {
  const theme = useTheme()
  const data = {
    labels: graphData.map((board) => board.name),
    datasets: [
      {
        label: "active",
        data: graphData.map((board) => board.active),
        backgroundColor: [theme.palette.success.main],
        borderColor: [theme.palette.success.main],
        borderWidth: 1,
        stack: "0",
      },
      {
        label: "expired",
        data: graphData.map((board) => board.expired),
        backgroundColor: [theme.palette.primary.main],
        borderColor: [theme.palette.primary.main],
        borderWidth: 1,
        stack: "0",
      },
      {
        label: "vacant",
        data: graphData.map((board) => board.vacant),
        backgroundColor: [theme.palette.warning.main],
        borderColor: [theme.palette.warning.main],
        borderWidth: 1,
        stack: "0",
      },
    ],
  }

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader title={title} />
      <CardContent>
        {graphData.length === 0 ? (
          <Box width="100%" display="flex" justifyContent="center">
            <Typography variant="h1">NO DATA AVAILABLE</Typography>
          </Box>
        ) : (
          <Box display="flex">
            <Bar data={data} />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

BarChartCard.propTypes = {
  title: PropTypes.string,
  graphData: PropTypes.arrayOf(PropTypes.object),
}

export default BarChartCard
