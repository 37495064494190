import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #0000001A",
    zIndex: 20,
  },
  drawerPaper: {
    marginTop: "50px",
  },
  drawerItem: {
    backgroundColor: "#F1E1E9",
    color: "#190D27",
  },
  loginButtonBox: {
    width: "100%",
  },
  login: {
    color: "#412A5D",
    width: "100%",
  },
}))

export default styles
