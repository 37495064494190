import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: "400px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  dialogTitle: {
    textAlign: "center",
    paddingTop: theme.spacing(6),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  closeButton: {
    paddingTop: 10,
    position: "absolute",
    top: 10,
    right: 10,
  },
  loginHeader: {
    paddingBottom: theme.spacing(1),
  },
}))

export default styles
