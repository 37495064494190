import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import { useCallback, useContext, useState } from "react"
import { useDropzone } from "react-dropzone"
import Cropper from "react-easy-crop"
import getCroppedImg from "utils/cropImage.js"

import useStyles from "./styles"

const ChooseRequestImage = () => {
  const classes = useStyles()
  // const { updateEntityImageMutation, data: entity } = useContext(EntityContext)
  const { dialogs, closeDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["chooseRequestImage"] || {}
  const { open = false, data = {} } = thisDialog

  const image = data?.image
  const setCroppedImage = data?.setFn

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1.1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState("")
  const [imageToEdit, setImageToEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    getBase64(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  })

  const onCropComplete = useCallback((croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const handleClose = () => {
    closeDialog("chooseRequestImage")
    setTimeout(() => {
      setCrop({ x: 0, y: 0 })
      setZoom(1.1)
      setCroppedAreaPixels("")
      setImageToEdit(null)
    }, 300)
  }

  //convert file to base64
  function getBase64(file) {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setImageToEdit(reader.result)
    }
    reader.onerror = function (error) {
      console.log("Error: ", error)
    }
  }

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true)
    try {
      const croppedImage = await getCroppedImg(imageToEdit, croppedAreaPixels)
      // send up to parent component via ...
      setCroppedImage(croppedImage)
      setIsLoading(false)
      handleClose()
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }, [croppedAreaPixels])

  return (
    <ConditionalDialogWrapper
      open={open}
      onClose={handleClose}
      className={classes.uploadDialog}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2">
          {!image ? "Upload an image" : "Crop Image"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {!imageToEdit ? (
          <Box
            className={classes.uploadFileContainer}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <Box
                className={classes.uploadFileSubcontainer}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="100%"
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography variant="h6" style={{ marginRight: "2px" }}>
                    Choose an IMAGE
                  </Typography>
                  <Typography variant="subtitle1">or drag it here</Typography>
                </Box>
              </Box>
            </div>
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box className={classes.cropContainer}>
                <Cropper
                  image={imageToEdit || image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1 / 1}
                  minZoom={0.1}
                  zoomWithScroll={false}
                  onCropChange={setCrop}
                  restrictPosition={true}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </Box>
            </Box>
            <Box marginTop={1} display="flex" justifyContent="center">
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                style={{ width: "100%" }}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        <ButtonRounded
          color="primary"
          variant="outlined"
          onClick={handleClose}
          type="button"
          disabled={isLoading}
        >
          Cancel
        </ButtonRounded>
        <LoaderButton
          color="primary"
          variant="contained"
          onClick={showCroppedImage}
          classoverrides={{
            wrapper: classes.button,
            button: {
              root: classes.buttonRoot,
            },
          }}
          working={isLoading}
        >
          Save
        </LoaderButton>
      </DialogActions>
    </ConditionalDialogWrapper>
  )
}

export default ChooseRequestImage
