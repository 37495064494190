import { Box, Container, useMediaQuery, useTheme } from "@material-ui/core"
import MessageSidebar from "components/Common/Messages/MessageSidebar"
import ThreadList from "components/Common/Messages/ThreadList"
import MessagesContext from "contexts/Messages"
import Layout from "Layouts/Messages"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useParams } from "react-router"
import Router from "router"

import useStyles from "./styles"

const Messages = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const ipadView = useMediaQuery(theme.breakpoints.down("md"))
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))

  const { selectedThread } = useContext(MessagesContext)

  return (
    <Page>
      {!ipadView ? (
        <Layout
          left={
            <Box className={classes.threadsBox}>
              <ThreadList />
            </Box>
          }
          middle={<Router routes={routes} {...rest} />}
          right={!ipadView ? <MessageSidebar /> : null}
        />
      ) : mobileView ? (
        <>
          {!selectedThread ? (
            <Box className={classes.threadsBox}>
              <ThreadList />
            </Box>
          ) : (
            <Box height="100%">
              <Router routes={routes} {...rest} />
            </Box>
          )}
        </>
      ) : (
        <>
          {!selectedThread ? (
            <Box className={classes.threadsBox}>
              <ThreadList />
            </Box>
          ) : (
            <>
              <Box display="flex" height="100%" flexDirection="row">
                <Box width="70%">
                  <Router routes={routes} {...rest} />
                </Box>
                <Box width="30%">
                  <MessageSidebar />
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </Page>
  )
}

Messages.propTypes = {
  routes: PropTypes.array,
}

export default Messages
