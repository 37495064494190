import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    entityFilters: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(2),
        flexWrap: "wrap",
        justifyContent: "space-between",
      },
    },
    tabContainer: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
        maxWidth: "100%",
        padding: "initial",
      },
    },
    tabs: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        marginLeft: "initial",
        width: "100%",
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
      },
    },
    tab: {
      minWidth: "20px",
    },
    tabWrapper: {
      width: "auto",
      display: "flex",
      justifyContent: "center",
    },
    inputWrapper: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "50%",
        marginBottom: theme.spacing(1),
      },
    },
    input: {
      width: "200px",
      marginRight: "20px",
      backgroundColor: "#F8F8F8",
      [theme.breakpoints.down("sm")]: {
        width: "275px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    imageIcon: {
      display: "flex",
      height: "inherit",
      width: "inherit",
    },
    icon: {
      color: "#898989",
      "&:hover": {
        cursor: "pointer",
      },
    },
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
      },
    },
  }),
  {
    name: "EntitiesCardList",
  }
)

export default styles
