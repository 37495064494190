import IframeContent from "components/Iframe/IframeContent"
import { IframeContainer } from "contexts/Citizen/IframeContext"
import PropTypes from "prop-types"
import Router from "router"

import useStyles from "./styles"

const Iframe = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <IframeContainer>
      <IframeContent />
      <Router routes={routes} {...rest} />
    </IframeContainer>
  )
}

Iframe.propTypes = {
  routes: PropTypes.array,
}

export default Iframe
