import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  loaderButtonwrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2rem",
  },

  buttonRoot: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: "1.2rem",
    minHeight: 45,
    marginBottom: "2.2rem",
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  textField: {
    marginBottom: "24px",
  },
}))

export default styles
