import { Box, Typography } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import InfoIcon from "@material-ui/icons/Info"
import PropTypes from "prop-types"

import useStyles from "./styles"

const ApplyError = ({
  title = "This must be completed in order to submit applications",
}) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" className={classes.errorContainer}>
      <InfoIcon className={classes.infoIcon} />
      <Typography className={classes.errorText}>{title}</Typography>
    </Box>
  )
}

ApplyError.propTypes = {
  title: PropTypes.string,
}

export default ApplyError
