import { EntitiesContainer } from "contexts/Clerk/Entities"
import Page from "pages/Page"
import PropTypes from "prop-types"
import Router from "router"

import useStyles from "./styles"

const Entities = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  return (
    <Page>
      <EntitiesContainer>
        <Router routes={routes} {...rest} />
      </EntitiesContainer>
    </Page>
  )
}

Entities.propTypes = {
  routes: PropTypes.array,
}

export default Entities
