import { AppBar as A, Box, Toolbar } from "@material-ui/core"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useHistory } from "react-router-dom"

import Menu from "./Menu"
import useStyles from "./styles"

const AppBar = () => {
  const classes = useStyles()
  const history = useHistory()
  const { isIframe } = useContext(AppContext)

  const handleLogoClick = () => {
    if (!isIframe) {
      if (!history?.location?.pathname?.includes("/boards")) {
        history?.push("/boards")
      }
    } else {
      if (process.env.REACT_APP_NODE_ENV === "development") {
        window.open("http://localhost:3000/citizen/")
      } else if (process.env.REACT_APP_NODE_ENV === "staging") {
        window.open("https://app.matchboard.dev/")
      } else {
        window.open("https://app.matchboard.tech/")
      }
    }
  }

  // Disable appbar if iframe
  if (window.location.pathname.includes("/pub")) {
    return null
  }

  return (
    <A
      position="static"
      color="secondary"
      elevation={0}
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoBox} onClick={handleLogoClick}>
          <Logo style={{ width: 150 }} />
        </Box>
        <Box flexGrow="1" />
        <Menu />
      </Toolbar>
    </A>
  )
}

AppBar.propTypes = {
  children: PropTypes.node,
}

export default AppBar
