import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    formContainer: {
      height: "500px",
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    logo: {
      width: "auto",
      marginBottom: theme.spacing(8),
    },
  }),
  {
    name: "IndexPage-Clerk",
  }
)

export default styles
