import { getReviews } from "actions/Clerk/reviews"
import InternalReviewContent from "components/Clerk/InternalReviewContent"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { useLocation } from "react-router"
import Router from "router"

import useStyles from "./styles"

const InternalReview = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const location = useLocation()
  const { token, removeAuthToken } = useContext(AppContext)

  useEffect(() => {
    removeAuthToken()
  }, [token])

  const params = useMemo(() => {
    if (!isEmpty(location.search)) {
      const params = new URLSearchParams(location.search)
      const obj = {}

      // iterate over all keys
      for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
          obj[key] = params.getAll(key)
        } else {
          obj[key] = params.get(key)
        }
      }
      return obj
    } else {
      return {}
    }
  }, [location.search])

  const { data: reviewData, isLoading: reviewDataLoading } = useQuery(
    "reviewData",
    () => getReviews(params?.token)
  )

  return (
    <Page>
      <InternalReviewContent
        data={reviewData}
        loading={reviewDataLoading}
        token={params?.token}
      />
      <Router routes={routes} {...rest} />
    </Page>
  )
}

InternalReview.propTypes = {
  routes: PropTypes.array,
}

export default InternalReview
