import { Box, Card, Typography } from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import PropTypes from "prop-types"
import formatPhoneNumber from "utils/formatPhone"

import useStyles from "./styles"

const References = ({
  myAccountAcctions = false,
  references,
  addReferences,
  handleOpenDialog,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Box width="100%">
        {references?.map((reference, i) => {
          return (
            <Box
              display="flex"
              width="100%"
              key={i}
              className={classes.referenceContainer}
            >
              <Box flexGrow={1} width="100%">
                <Typography className={classes.nameText}>
                  {reference?.full_name}
                </Typography>
                <Box
                  className={classes.textGray}
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Typography variant="body1">
                    {reference?.relationship}
                  </Typography>
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography variant="body1">
                    {reference?.phone
                      ? formatPhoneNumber(reference?.phone)
                      : ""}
                  </Typography>
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography variant="body1">{reference?.email}</Typography>
                </Box>
              </Box>
            </Box>
          )
        })}
        {addReferences}
      </Box>
    </Card>
  )
}

References.propTypes = {
  myAccountAcctions: PropTypes.bool,
  references: PropTypes.array,
  addReferences: PropTypes.node,
  handleOpenDialog: PropTypes.node,
}

export default References
