import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  list: {
    height: "100%",
    overflow: "auto",
    paddingRight: theme.spacing(1),
    // "& .infinite-scroll-component__outerdiv": {
    //   paddingRight: theme.spacing(0.5),
    // },
    // "& .infinite-scroll-component": {
    //   paddingRight: theme.spacing(0.5),
    // },
  },
}))

export default styles
