import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  expiresText: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontWeight: 400,
    fontStyle: "italic",
  },
  expiredText: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontWeight: 400,
    fontStyle: "italic",
    marginRight: theme.spacing(3),
    color: theme.palette.error.main,
  },
  reminderIcon: {
    color: theme.palette.error.main,
    fontSize: "1.6rem",
  },
}))

export default styles
