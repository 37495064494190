import { Container, Typography } from "@material-ui/core"
import Page from "pages/Page"

import useStyles from "./styles"

const Index = () => {
  const classes = useStyles()
  return (
    <Page>
      <Container style={{ marginTop: 30 }}>
        <Typography>Consumer Index</Typography>
      </Container>
    </Page>
  )
}

export default Index
