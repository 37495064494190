import { Avatar as MuiAvatar } from "@material-ui/core"
import logo from "assets/logo-icon.svg"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import getScaledImage from "utils/getScaledImage"

// avatar components for rendering profile_image or initials
const AvatarComponent = ({ user, ...props }) => {
  // gets public id and transforms as needed
  const scaledImage = (img) => {
    return getScaledImage(img, 200, 200)
  }

  const avatarInitials = () => {
    if (user?.initials) {
      // for data run thru user serializer
      return user.initials
    } else {
      if (user?.name) {
        // name is combined field - e.g., message threads
        let splitName = user?.name?.split?.(" ")
        if (splitName.length > 1) {
          return splitName?.[0]?.charAt(0) + splitName?.[1]?.charAt(0)
        } else {
          return splitName?.[0]?.charAt(0)
        }
      } else if (user?.first_name && user?.last_name) {
        // vacant user only
        return user?.first_name.charAt(0) + user?.last_name.charAt(0)
      }
    }
  }

  const avatarProps = {}
  if (user?.profile_image) {
    // only attempt to add a src attribute if there is a profile_image
    // this prevents from showing a broken image indicator and defaults to the users initials
    avatarProps["src"] = scaledImage(user?.profile_image)
  } else if (user?.entity_image) {
    avatarProps["src"] = scaledImage(user.entity_image)
  } else if (!user?.first_name || isEmpty(user)) {
    avatarProps["src"] = logo
  }

  return (
    <MuiAvatar {...avatarProps} {...props}>
      {/* renders the users initials from the user model in the circle in the instance there is no profile_image */}
      {avatarInitials()}
    </MuiAvatar>
  )
}

AvatarComponent.propTypes = {
  user: PropTypes.object.isRequired,
}

export default AvatarComponent
