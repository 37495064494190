import { Box, ListItem, ListItemText, Typography } from "@material-ui/core"
import * as dayjs from "dayjs"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import cleanDateTime from "utils/cleanDateTime"

const Applied = ({ notification, classes }) => {
  const history = useHistory()
  return (
    <ListItem
      key={notification?.id}
      className={classes.listItem}
      onClick={() => history.push(`boards/${notification?.board_id}`)}
    >
      <ListItemText
        classes={{
          multiline: classes.listItemText,
        }}
        disableTypography
        primary={
          <Box marginLeft={1}>
            <Typography variant="body1">
              <span className={classes.notificationKeywords}>
                {notification?.board_name}
              </span>
            </Typography>
            <Typography>
              <span className={classes.notificationDetails}>
                {`${notification?.first_name} ${notification?.last_name} applied`}
              </span>
            </Typography>
          </Box>
        }
        secondary={
          <Box>
            <span className={classes.timestampText}>
              {cleanDateTime(notification?.created)}
            </span>
          </Box>
        }
      />
    </ListItem>
  )
}

Applied.propTypes = {
  notification: PropTypes.object,
  classes: PropTypes.object,
}

export default Applied
