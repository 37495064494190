import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import { Form, Formik } from "formik"
import { useContext } from "react"
import InputMask from "react-input-mask"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import formatPhoneNumber from "utils/formatPhone"
import * as Yup from "yup"

import useStyles from "./styles"

const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
const contactSchema = Yup.object({
  phone: Yup.string()
    .required("Required")
    .matches(phoneRegex, "Phone number is not valid")
    .min(14, "Phone number is not valid")
    .max(14, "Phone number is not valid"),
  //14 char - 9 numbers, 3 special chars ()-, 1 space
  email: Yup.string().email("Please enter a valid email").required(`Required`),
  linkedin_url: Yup.string(),
})

const ContactInformation = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const authContext = useContext(AppContext)

  const { dialogs, closeDialog, authUser, openSnackBar, token } =
    useContext(AppContext)
  const { updateContactMutation } = useContext(AccountContext)
  const thisDialog = dialogs?.["contactInformation"] || {}
  const { open = false, data = {} } = thisDialog

  const handleClose = () => {
    closeDialog("contactInformation")
  }

  const onSubmit = (values, actions) => {
    // replace regex takes out all special characters from masked value, leaving only numbers
    const formattedValues = {
      ...values,
      phone: values.phone.replace(/[^0-9]/g, ""),
    }
    updateContactMutation
      .mutateAsync(formattedValues)
      .then((res) => {
        serialize("user", res.user).then((serializedData) => {
          authContext.setAuthData({
            token: token,
            account: serializedData,
          })
          return queryClient.setQueryData("user", (oldState) => {
            return serializedData
          })
        })
        openSnackBar({ message: "Contact information updated" })
        handleClose()
      })
      .catch((err) => {
        openSnackBar({ message: "Error updating contact information" })
      })
  }

  return (
    <ConditionalDialogWrapper open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Contact Information</Typography>
        <Typography className={classes.subtitle}>
          This must be completed
        </Typography>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          phone: formatPhoneNumber(authUser?.phone) || "",
          email: authUser?.email || "",
          linkedin_url: authUser?.linkedin_url || "",
        }}
        onSubmit={onSubmit}
        validationSchema={contactSchema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setErrors,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <>
              <DialogContent>
                <InputMask
                  id="phone-mask"
                  mask="(999) 999-9999"
                  maskChar="_"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {() => (
                    <TextField
                      type="text"
                      name="phone"
                      label="Phone"
                      data-cy="contact-phone"
                      id="phone"
                      helperText={touched.phone && errors.phone}
                      style={{ marginBottom: "25px" }}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      error={Boolean(touched.phone && errors.phone)}
                    />
                  )}
                </InputMask>
                <TextField
                  type="text"
                  name="email"
                  label="Email"
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.email && errors.email}
                  style={{ marginBottom: "25px" }}
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  disabled
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  error={Boolean(touched.email && errors.email)}
                />
                <TextField
                  type="text"
                  name="linkedin_url"
                  label="Linkedin URL"
                  data-cy="contact-linkedin"
                  id="linkedin_url"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.linkedin_url && errors.linkedin_url}
                  style={{ marginBottom: "25px" }}
                  value={values.linkedin_url}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  error={Boolean(touched.linkedin_url && errors.linkedin_url)}
                />
              </DialogContent>
              <DialogActions>
                <Box display="flex" justifyContent="center" width="100%">
                  <ButtonRounded
                    color="primary"
                    variant="outlined"
                    onClick={handleClose}
                    type="button"
                    data-cy="contact-close-button"
                  >
                    Close
                  </ButtonRounded>
                  <ButtonRounded
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.saveButton}
                    disabled={!dirty || updateContactMutation?.isLoading}
                    data-cy="contact-save-button"
                    classes={{ label: classes.btnLabel }}
                  >
                    {updateContactMutation?.isLoading ? (
                      <CircularProgress size={20} style={{ color: "#fff" }} />
                    ) : (
                      "Save"
                    )}
                  </ButtonRounded>
                </Box>
              </DialogActions>
            </>
          </Form>
        )}
      </Formik>
    </ConditionalDialogWrapper>
  )
}

export default ContactInformation
