import {
  getApplicantApplications,
  getApplicants,
} from "actions/Clerk/applicants"
import { getResume } from "actions/Clerk/board"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { createContext, useContext } from "react"
import { useMutation, useQuery } from "react-query"

const ApplicantsContext = createContext()
export default ApplicantsContext

export const ApplicantsContainer = ({ children }) => {
  const { authUser, isAdmin } = useContext(AppContext)
  const { data: applicantsData, isLoading: applicantsDataLoading } = useQuery(
    "applicantsData",
    () => getApplicants(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  const getApplicantApplicationsMutation = useMutation((userId) =>
    getApplicantApplications(authUser?.entity_id, userId)
  )
  const getResumeMutation = useMutation(getResume)

  return (
    <ApplicantsContext.Provider
      value={{
        applicantsData,
        applicantsDataLoading,
        getApplicantApplicationsMutation,
        getResumeMutation,
      }}
    >
      {children}
    </ApplicantsContext.Provider>
  )
}

ApplicantsContainer.propTypes = {
  children: PropTypes.node,
}
