import { getAdmins } from "actions/Clerk/users"
import PropTypes from "prop-types"
import { createContext, useState } from "react"
import { useQuery } from "react-query"

const AdminsContext = createContext()
export default AdminsContext

export const AdminsContainer = ({ children }) => {
  return <AdminsContext.Provider value={{}}>{children}</AdminsContext.Provider>
}

AdminsContainer.propTypes = {
  children: PropTypes.node,
}
