import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      backgroundColor: "#F1E1E9",
      padding: theme.spacing(8),
      margin: theme.spacing(2),
      borderRadius: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
        flexDirection: "column",
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1.5),
        flexDirection: "column",
      },
    },
    leftContainer: {
      width: "50%",
      marginRight: theme.spacing(5),
      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(2),
        width: "100%",
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    title: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(3),
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(4),
        textAlign: "center",
      },
    },
    subtitle: {
      fontSize: "2.2rem",
    },
    optionsBox: {
      width: "40%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    optionsTitle: {
      marginBottom: theme.spacing(2),
    },
    optionsText: {
      marginBottom: theme.spacing(2),
      fontSize: "1.6rem",
      [theme.breakpoints.down("xs")]: {},
    },
    button: {
      position: "absolute",
      right: 25,
      top: 25,
      zIndex: 1,
      [theme.breakpoints.down("sm")]: {
        right: 5,
        top: 5,
      },
    },
    hereText: {
      marginBottom: theme.spacing(2),
      fontSize: "1.6rem",
      marginLeft: "3px",
      textDecoration: "underline",
      cursor: "pointer",
    },
  }),
  {
    name: "WelcomeBanner-Clerk",
  }
)

export default styles
