import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: "1003px",
    minHeight: "500px",
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
    },
  },
  leftContainer: {
    borderRight: "1px solid #dcdcdc",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titles: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  data: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  approveButton: {
    backgroundColor: "#27B247",
    "&:hover": {
      backgroundColor: "#27B247",
    },
  },
  keys: {
    marginBottom: theme.spacing(1),
  },
  values: {
    marginBottom: theme.spacing(1),
    lineHeight: "12px !important",
  },
  uploadImageContainer: {
    backgroundColor: "#E2E7EF",
    minWidth: "130px",
    height: "130px",
    borderRadius: "10px",
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    width: "90px",
    height: "90px",
    border: "none",
    backgroundColor: "white",
  },
  pencilIcon: {
    color: theme.palette.primary.dark,
    fontSize: "10px",
    padding: "6px",
    position: "absolute",
    left: "0px",
    bottom: "0px",
  },
  label: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },

  longName: {
    lineHeight: "15px !important",
  },
}))

export default styles
