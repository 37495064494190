import {
  AppBar,
  Box,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import pin from "assets/icon-feather-map-pin.svg"
import search from "assets/icon-feather-search.svg"
import IframeContext from "contexts/Citizen/IframeContext"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { Link, useHistory } from "react-router-dom"

import useStyles from "./styles"

const IframeSearch = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { searchValue, setSearchValue } = useContext(IframeContext)

  const [currentSearch, setCurrentSearch] = useState("")

  return (
    <TextField
      placeholder="Search for board"
      className={classes.search}
      variant="filled"
      onChange={(e) => {
        setCurrentSearch(e.target.value)
        if (e.target.value === "") {
          setSearchValue("")
        }
      }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          setSearchValue(currentSearch)
        }
      }}
      value={currentSearch}
      InputProps={{
        classes: {
          root: classes.inputBackgroundColor,
        },
        disableUnderline: true,
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginLeft: "10px", marginBottom: "10px" }}
          >
            <Icon classes={{ root: classes.iconRoot }}>
              <img className={classes.imageIcon} src={search} alt="" />
            </Icon>
          </InputAdornment>
        ),
      }}
    />
  )
}

IframeSearch.propTypes = {
  children: PropTypes.node,
}

export default IframeSearch
