import {
  Backdrop,
  Box,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import MessageContext from "contexts/Message"
import PropTypes from "prop-types"
import { useContext, useRef, useState } from "react"
import * as Yup from "yup"

import useStyles from "./styles"

const validationSchema = Yup.object({
  message: Yup.string().required("A message is required"),
})

const SendMessage = ({ data, messageContainer }) => {
  const classes = useStyles()
  const messageInput = useRef(null)
  const [loading, setLoading] = useState(false)
  const { createMessageMutation, refetchMessages } = useContext(MessageContext)
  const { isClerk } = useContext(AppContext)

  const send = async (event) => {
    // If message already sending or if message is empty stop
    if (loading || messageInput.current.innerText === "") {
      return
    }

    setLoading(true)
    const text = messageInput.current.innerText

    if (!text) {
      return
    }

    try {
      await createMessageMutation.mutateAsync({
        entity_id: data.entity_id,
        user_id: data.user_id,
        thread_id: data.thread_id,
        message: text,
        admin: isClerk,
      })

      // Refetch new messages
      await refetchMessages()

      // Scroll to bottom
      const scrollHeight = messageContainer.current.scrollHeight
      messageContainer.current.scrollTo(0, scrollHeight)
    } catch (error) {
      setLoading(false)
      throw new Error(error)
    }

    messageInput.current.innerText = ""
    setLoading(false)
  }

  return (
    <Box className={classes.composeBox}>
      {loading && <LinearProgress />}
      <Box className={classes.root}>
        <Box
          display="flex"
          alignItems="center"
          padding={(2, 2)}
          className={classes.inputContainer}
        >
          <div
            ref={messageInput}
            className={classes.messageInput}
            contentEditable
            role="textbox"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault()
                send()
              }
            }}
            tabIndex="-1"
            data-placeholder="Write a message..."
          ></div>
          <Box className={classes.sendButton}>
            <ButtonRounded onClick={send} variant="outlined" disabled={loading}>
              Send
            </ButtonRounded>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

SendMessage.propTypes = {
  data: PropTypes.object,
  messageContainer: PropTypes.object,
}

export default SendMessage
