import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    input: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    formHelperText: {
      marginLeft: 0,
      backgroundColor: "#fff",
    },
    save: {
      height: theme.spacing(4),
      alignSelf: "flex-end",
    },
  }),
  { name: "EntityGeneralForm" }
)

export default styles
