import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      overflow: "auto",
    },
    graphContainer: {
      justifyContent: "left",
      alignItems: "left",
      marginRight: theme.spacing(4),
    },
  }),
  {
    name: "PieChartCard",
  }
)

export default styles
