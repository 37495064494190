import { Box, Tooltip, Typography, useTheme } from "@material-ui/core"
import { useMediaQuery } from "@material-ui/core"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

const HoverText = ({ data, desktopSize, phoneSize, variant, className }) => {
  const theme = useTheme()
  const phone = useMediaQuery(theme.breakpoints.up("sm"))

  const size = useMemo(() => {
    if (phone) {
      return desktopSize
    } else {
      return phoneSize
    }
  }, [phone])

  return (
    <Box>
      {data && data.length > size && typeof data === "string" ? (
        <Tooltip
          style={{ cursor: "pointer" }}
          title={<Typography variant="h6">{data}</Typography>}
          placement="bottom-start"
          enterDelay={700}
          interactive
          transitioncomponent="zoom"
        >
          <Box>
            <Typography
              className={className}
              variant={variant}
              style={{ wordBreak: "break-word" }}
            >
              {data.slice(0, size) + "..."}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <Typography
          variant={variant}
          className={className}
          style={{ wordBreak: "break-word" }}
        >
          {data}
        </Typography>
      )}
    </Box>
  )
}

HoverText.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  desktopSize: PropTypes.number,
  phoneSize: PropTypes.number,
  variant: PropTypes.string,
  className: PropTypes.node,
  props: PropTypes.object,
}

export default HoverText
