import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  cardWrapper: {
    paddingTop: "20px",
    paddingRight: theme.spacing(1),
    "&:last-child": {
      paddingBottom: "20px",
    },
  },
  container: {
    borderRadius: theme.spacing(1),
    transition: theme.transitions.create("border", {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.sharp,
    }),
  },
  selected: {
    border: `2.5px solid ${theme.palette.primary.main}`,
  },
  actionsContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTop: "1px solid #E5E5E5",
    width: "100%",
  },
  bottomDataContainer: {
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(1),
    borderRight: "1px solid #E5E5E5",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  bottomTitle: {
    fontSize: "1rem",
    color: "#898989",
  },
  bottomData: {
    color: theme.palette.secondary.dark,
  },
  chipRoot: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "6px",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipLabel: {
    color: theme.palette.primary.dark,
    textTransform: "capitalize",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontWeight: 300,
  },
  entityName: {
    fontWeight: 500,
    marginRight: "6px",
    fontSize: "1.075em",
  },
  entityType: {
    opacity: 0.5,
    fontSize: "1.075em",
  },
  buttonWrapper: {
    // [theme.breakpoints.down("xs")]: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  appliedText: {
    fontSize: "2rem",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.625rem",
    },
  },
  // },
}))

export default styles
