import { Box, Typography, Zoom } from "@material-ui/core"
import checkBoxIcon from "assets/check-box-image.svg"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Submitted = ({ data }) => {
  const classes = useStyles()

  return (
    <Zoom in>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom="20px"
        >
          <img src={checkBoxIcon} alt="" height="50px" width="auto" />
        </Box>
        <Typography
          variant="h4"
          className={classes.text}
          style={{ marginBottom: "20px" }}
        >
          Your application has been submitted!
        </Typography>
        <Typography variant="subtitle1" className={classes.text}>
          {"Thank you for your interest in joining "}
          <span style={{ fontWeight: "700" }}>{data?.name}</span>
          {
            '. You can track the progress of your application under the "Applied" Tab.'
          }
        </Typography>
      </Box>
    </Zoom>
  )
}

Submitted.propTypes = {
  data: PropTypes.object,
}

export default Submitted
