import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { getBoards } from "actions/boards"
import FilterByCategory from "components/Citizen/Boards/FilterByCategory"
import BoardsList from "components/Citizen/Boards/List"
import AppContext from "contexts/App"
import BoardsContext from "contexts/Boards"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import FiftyFifty from "Layouts/FiftyFifty"
import { isEmpty } from "lodash-es"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useHistory } from "react-router-dom"
import Router from "router"

import useStyles from "./styles"

const List = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("xs"))
  const tablet = useMediaQuery(theme.breakpoints.down("sm"))

  const { interestsLoading, selectedCategories } = useContext(CitizenAppContext)

  const {
    searchValue,
    searchButtonClicked,
    setSearchButtonClicked,
    clearSearch,
    setSearchButtonClickedFromIndex,
    searchButtonClickedFromIndex,
    authUser,
  } = useContext(AppContext)
  const { locationSearch, setLocationSearch } = useContext(CitizenAppContext)

  const { getBoardsMutation } = useContext(BoardsContext)

  //state for data to show
  const [displayedData, setDisplayedData] = useState([])
  //boolean to know if we need call more data
  const [hasMoreData, setHasMoreData] = useState(true)
  //pagination page
  const [page, setPage] = useState(1)
  // flag to determine if boardId sent as query param from iframe
  const [boardSelected, setBoardSelected] = useState(false)
  //pagination size
  const size = 30

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  // when displayed boards populates from api call,
  // push to first board on desktop / boards list on tablet or phone
  // UNLESS query params from iframe board click
  useEffect(() => {
    if (displayedData?.[0]?.id) {
      if (!tablet) {
        if (!boardSelected) {
          history.push(`/boards/${displayedData?.[0]?.id}`)
        }
      }
    }
  }, [displayedData, selectedCategories, boardSelected])

  // resets to 1st in list if search cleared, categories changed, or search button clicked
  useEffect(() => {
    // if (searchButtonClicked || clearSearch) {
    const parentEl = document?.getElementById?.("board-scroll-citizen")
    const y = parentEl?.getBoundingClientRect().y
    parentEl?.scrollTo(0, -y)
    setBoardSelected(false)
    // }
  }, [clearSearch, searchButtonClicked, setLocationSearch, selectedCategories])

  //handle categories filter and pages changes
  useEffect(() => {
    if (isEmpty(params)) {
      history.push("/boards")
      if (
        page !== 1 &&
        (selectedCategories?.length > 0 || searchButtonClicked)
      ) {
        setPage(1)
      }
      let query = `p=${page}&n=${size}`
      if (searchValue !== "") {
        query = query + `&s=${searchValue}`
      }
      if (!isEmpty(locationSearch)) {
        query =
          query +
          `&l=${locationSearch?.label?.split(",")[0]}&t=${locationSearch?.type}`
      }
      if (selectedCategories?.length > 0) {
        query =
          query + `&cat=${selectedCategories?.map((x) => x?.id).join(",")}`
      }
      if (page === 1) {
        setDisplayedData([])
        setHasMoreData(true)
      }
      getBoardsMutation
        ?.mutateAsync(query)
        .then((res) => {
          if (res.length === 0) {
            setHasMoreData(false)
          }
          setSearchButtonClickedFromIndex(false)
          setSearchButtonClicked(false)
          //if we are in page 1 clean data and add the response
          if (page === 1) {
            setDisplayedData((oldState) => [...res])
          } else {
            setDisplayedData((oldState) => [...oldState, ...res])
          }
        })
        .catch((err) => {
          setDisplayedData([])
          setSearchButtonClickedFromIndex(false)
          setSearchButtonClicked(false)
        })
    }
  }, [page, selectedCategories, authUser?.id])

  //handle searching and clear searching
  useEffect(() => {
    if (isEmpty(params) && (clearSearch || searchButtonClicked)) {
      history.push("/boards")
      if (
        page !== 1 &&
        (selectedCategories?.length > 0 || searchButtonClicked)
      ) {
        setPage(1)
      }
      let query = `p=${page}&n=${size}`
      if (searchValue !== "") {
        query = query + `&s=${searchValue}`
      }
      if (!isEmpty(locationSearch)) {
        query =
          query +
          `&l=${locationSearch?.label?.split(",")[0]}&t=${locationSearch?.type}`
      }
      if (selectedCategories?.length > 0) {
        query =
          query + `&cat=${selectedCategories?.map((x) => x?.id).join(",")}`
      }
      if (page === 1) {
        setDisplayedData([])
        setHasMoreData(true)
      }
      getBoardsMutation
        ?.mutateAsync(query)
        .then((res) => {
          if (res.length === 0) {
            setHasMoreData(false)
          }
          setSearchButtonClickedFromIndex(false)
          setSearchButtonClicked(false)
          //if we are in page 1 clean data and add the response
          if (page === 1) {
            setDisplayedData((oldState) => [...res])
          } else {
            setDisplayedData((oldState) => [...oldState, ...res])
          }
        })
        .catch((err) => {
          setDisplayedData([])
          setSearchButtonClickedFromIndex(false)
          setSearchButtonClicked(false)
        })
    }
  }, [searchButtonClicked, clearSearch])

  // only runs when a Learn More button clicked from the iframe
  // handle query params sent
  useEffect(() => {
    if (!isEmpty(params)) {
      setLocationSearch({
        id: parseInt(params?.entityId),
        label: `${params?.name}, ${params?.entityState}`,
        type: params?.type,
      })

      let query = `p=1&n=${size}&l=${params.name}&t=${params.type}`
      if (params.categories) {
        query = query + `&cat=${params.categories}`
      }
      setBoardSelected(Boolean(params.boardId))
      getBoardsMutation
        ?.mutateAsync(query)
        .then((res) => {
          setDisplayedData((oldState) => [...res])
          setSearchButtonClickedFromIndex(false)
          history.push(`boards/${params.boardId}`)
          const el = document.getElementById(`board-card-${params.boardId}`)
        })
        .catch((err) => {
          setDisplayedData([])
          setSearchButtonClickedFromIndex(false)
        })
    }
  }, [])

  const sortOrSearchUsed =
    searchValue?.length > 0 ||
    selectedCategories?.length > 0 ||
    locationSearch?.length > 0

  if (interestsLoading) {
    return <LinearProgress />
  } else {
    return (
      <Page>
        <FiftyFifty
          lockLeft //special case prop to FiftyFifty - don't want overflow auto here
          left={
            <Container classes={{ root: classes.container }}>
              {/* citizen boards list goes here */}
              <Box display="flex" flexDirection="row" height="100%">
                {/* filter container hides under 600px screenwidth */}
                <Box className={classes.filterContainer}>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ fontWeight: 300, marginBottom: "30px" }}
                  >
                    Showing {displayedData?.length} results
                  </Typography>
                  <FilterByCategory />
                </Box>
                <Box className={classes.listContainer}>
                  <Box
                    className={classes.header}
                    style={mobile ? { marginRight: "15px" } : null}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: "#898989",
                        fontWeight: 300,
                        marginBottom: "10px",
                      }}
                    >
                      Boards
                    </Typography>
                    {/* if mobile view, # of # moved down from filter container to here */}
                    {mobile && (
                      <Typography
                        variant="h6"
                        color="primary"
                        style={{ fontWeight: 300, marginBottom: "10px" }}
                      >
                        Showing {displayedData?.length} results
                      </Typography>
                    )}
                  </Box>
                  {page === 1 && getBoardsMutation?.isLoading ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      height="100%"
                      alignItems="center"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <BoardsList
                      data={displayedData}
                      page={page}
                      size={size}
                      setPage={setPage}
                      hasMoreData={hasMoreData}
                      sortOrSearchUsed={sortOrSearchUsed}
                    />
                  )}
                </Box>
              </Box>
            </Container>
          }
          right={<Router routes={routes} {...rest} />}
        />
      </Page>
    )
  }
}

List.propTypes = {
  routes: PropTypes.array,
}

export default List
