// for displaying 9 digit number as (###)###-####
const formatPhoneNumber = (string) => {
  var cleaned = ("" + string).replace(/\D/g, "")
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3]
  }
  return null
}

export default formatPhoneNumber
