import { getInterests } from "actions/boards"
import { getAutocompleteOptions, updateResume } from "actions/Citizen/account"
import {
  checkRegisterEmail,
  checkRegisterPhone,
  getUserApplications,
} from "actions/Citizen/users"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { createContext, useContext, useState } from "react"
import { useMutation, useQuery } from "react-query"

const CitizenAppContext = createContext()
export default CitizenAppContext

export const CitizenAppContainer = ({ children, ...props }) => {
  const { authUser } = useContext(AppContext)
  const [applied, setApplied] = useState(false)
  const [locationSearch, setLocationSearch] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])

  const { data: interests, isLoading: interestsLoading } = useQuery(
    "interests",
    () => getInterests(),
    { retry: false }
  )
  const { data: appliedData, isLoading: appliedLoading } = useQuery(
    ["applied", authUser?.id],
    getUserApplications,
    { enabled: !isEmpty(authUser) }
  )

  const { data: autocompleteOptions, isLoading: autocompleteOptionsLoading } =
    useQuery("autocompleteOptions", () => getAutocompleteOptions(), {
      retry: false,
    })

  const checkRegisterPhoneMutation = useMutation(checkRegisterPhone)
  const checkEmailRegisterMutation = useMutation(checkRegisterEmail)
  const updateResumeMutation = useMutation(updateResume)

  return (
    <CitizenAppContext.Provider
      value={{
        interests,
        interestsLoading,
        applied,
        setApplied,
        autocompleteOptions,
        autocompleteOptionsLoading,
        locationSearch,
        setLocationSearch,
        appliedData,
        appliedLoading,
        checkRegisterPhoneMutation,
        checkEmailRegisterMutation,
        updateResumeMutation,
        selectedCategories,
        setSelectedCategories,
      }}
    >
      {children}
    </CitizenAppContext.Provider>
  )
}

CitizenAppContainer.propTypes = {
  children: PropTypes.node,
}
