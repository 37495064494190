//? Cloudinary url-gen docs: https://www.npmjs.com/package/@cloudinary/url-gen
import { Cloudinary } from "@cloudinary/url-gen"

function cloudinaryConfig() {
  return new Cloudinary({
    cloud: {
      cloudName: process.env.REACT_APP_CLOUD_NAME,
    },
    url: {
      secure: true, // force https, set to false to force http
    },
  })
}

export default cloudinaryConfig()
