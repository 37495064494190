import { Box } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"
import ReactCodeInput from "react-code-input"

import useStyles from "./styles"

const styleProps = {}

const CodeInput = ({ onChange = null, ...props }) => {
  const classes = useStyles()

  // Handle change and pass to onChange prop if defined and typeof function
  const handleChange = (value) => {
    if (onChange !== null && typeof onChange === "function") {
      onChange(value)
    }
  }

  /**
   * TODO: Add error handling
   */

  return (
    <Box className={classes.root}>
      <ReactCodeInput
        fields={6}
        placeholder="-"
        onChange={handleChange}
        {...styleProps}
        className={classes.codeInput}
        {...props}
      />
    </Box>
  )
}

CodeInput.propTypes = {
  onChange: PropTypes.func,
}

export default CodeInput
