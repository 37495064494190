import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      boxShadow: "0px -3px 6px #0000001A",
    },
    container: {
      height: "100%",
      overflow: "hidden",
    },
  }),
  {
    name: "BoardPage-Clerk",
  }
)

export default styles
