import { Box, ListItem, ListItemText, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import cleanDateTime from "utils/cleanDateTime"

const MemberExpiration = ({ notification, classes }) => {
  const history = useHistory()

  const message = useCallback(
    (type) => {
      switch (type) {
        case `expiring_member_30`:
          return `${notification?.first_name} ${notification?.last_name}'s seat expires in 30 days`
        case `expiring_member_60`:
          return `${notification?.first_name} ${notification?.last_name}'s seat expires in 60 days`
        case `expiring_member_90`:
          return `${notification?.first_name} ${notification?.last_name}'s seat expires in 90 days`
        default:
          return ``
      }
    },
    [notification.first_name, notification.last_name]
  )

  return (
    <ListItem
      key={notification?.id}
      className={classes.listItem}
      onClick={() => history.push(`boards/${notification?.board_id}/members`)}
    >
      {/* <Badge color="primary" variant="dot" /> */}
      <ListItemText
        classes={{
          multiline: classes.listItemText,
        }}
        disableTypography
        primary={
          <Box marginLeft={1}>
            <Typography variant="body1">
              <span className={classes.notificationKeywords}>
                {notification?.board_name}
              </span>{" "}
            </Typography>
            <Typography>
              <span className={classes.notificationDetails}>
                {message(notification?.type)}
              </span>
            </Typography>
          </Box>
        }
        secondary={
          <Box>
            <span className={classes.timestampText}>
              {cleanDateTime(notification?.created)}
            </span>
          </Box>
        }
      />
    </ListItem>
  )
}

MemberExpiration.propTypes = {
  notification: PropTypes.object,
  classes: PropTypes.object,
}

export default MemberExpiration
