import { Tab, Tabs, Typography } from "@material-ui/core"
import App from "contexts/App"
import MessagesContext from "contexts/Messages"
import { findIndex, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useMemo } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

import useStyles from "./styles"

const BottomNavMobile = ({ children }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const { authUser } = useContext(App)
  const { selectedThread, setSelectedThread } = useContext(MessagesContext)

  const tabs = [
    {
      link: "/boards",
      label: "BOARDS",
      value: 0,
    },
    {
      link: "/messages",
      label: "MESSAGES",
      value: 1,
    },
    {
      link: "/my-account",
      label: "PROFILE",
      value: 2,
    },
  ]

  const currentTab = useMemo(() => {
    if (location.pathname.includes("boards")) {
      // keeps tab highlighted if board is selected (e.g., boards/:board.id)
      return 0
    } else if (location.pathname.includes("messages")) {
      return 1
    } else if (location.pathname === "/applied") {
      return false
    } else {
      return findIndex(tabs, { link: location.pathname })
    }
  }, [location.pathname])

  return (
    !isEmpty(authUser) && (
      <Tabs
        value={currentTab}
        indicatorColor="secondary"
        textColor="secondary"
        variant="fullWidth"
        className={classes.tabsWrapper}
        classes={{ flexContainer: classes.flexContainer }}
      >
        {tabs.map((tab, i) => {
          return (
            <Tab
              key={i}
              fullWidth
              label={
                tab?.label && (
                  <Typography variant="h6" className={classes.label}>
                    {tab.label}
                  </Typography>
                )
              }
              aria-label={tab.label}
              onClick={() => {
                history.push(`${tab.link}`)
                if (tab.value === 1) {
                  setSelectedThread(null)
                }
              }}
              value={tab.value}
              style={currentTab !== tab.value ? { color: "silver" } : null}
              classes={{ root: classes.tabRoot, wrapper: classes.labelWrapper }}
            />
          )
        })}
      </Tabs>
    )
  )
}

BottomNavMobile.propTypes = {
  children: PropTypes.node,
}

export default BottomNavMobile
