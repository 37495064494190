import { Box, Button } from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import useStyles from "./styles"

const Menu = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Button
        component={Link}
        to="/boards"
        className={classes.link}
        disableRipple
      >
        View Boards
      </Button>
      <Button
        component={Link}
        to="/admin"
        className={classes.link}
        disableRipple
      >
        Administrators
      </Button>
      <Box className={classes.loginButtonBox}>
        <ButtonRounded variant="outlined">Login</ButtonRounded>
      </Box>
    </Box>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
}

export default Menu
