import { Box } from "@material-ui/core"
import ReviewsList from "components/Clerk/Boards/ReviewsList"
import Page from "pages/Page"

import useStyles from "./styles"

const Reviews = () => {
  const classes = useStyles()

  return (
    <Page>
      <Box className={classes.container}>
        <ReviewsList />
      </Box>
    </Page>
  )
}

export default Reviews
