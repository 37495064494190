const sortTypeUtil = (sortName) => {
  //return sort type that will be use in sortHelper
  if (sortName === "Open Seats") {
    return "seat_number"
  } else if (sortName === "Applicant Count") {
    return "applicant_number"
  } else {
    return "date"
  }
}

export default sortTypeUtil
