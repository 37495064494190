import { AppBar as A, Box, Button, Drawer, Toolbar } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import MenuIcon from "@material-ui/icons/Menu"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import TopMenuMobile from "components/Citizen/AppBarMobile/TopMenuMobile"
import App from "contexts/App"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const AppBarMobile = () => {
  const classes = useStyles()
  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false)
  const [origin, setOrigin] = useState("")

  const { appliedLoading, isIframe } = useContext(App)

  const handleShowDrawer = () => {
    setIsOpen(!isOpen)
  }

  const handleLogoClick = () => {
    if (!isIframe) {
      if (!history?.location?.pathname?.includes("/boards")) {
        history?.push("/boards")
      }
    } else {
      if (process.env.REACT_APP_NODE_ENV === "development") {
        window.open("http://localhost:3000/citizen/")
      } else if (process.env.REACT_APP_NODE_ENV === "staging") {
        window.open("https://app.matchboard.dev/")
      } else {
        window.open("https://app.matchboard.tech/")
      }
    }
    setIsOpen(false)
  }

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  // sets origin entity id if coming from iFrame, only for use in Go Back button
  useEffect(() => {
    if (params) {
      const entityId = parseInt(params?.e)
      setOrigin(entityId)
    }
  }, [])

  // Disable appbar if iframe
  if (window.location.pathname.includes("/pub")) {
    return null
  }

  return (
    <>
      <A
        position="static"
        color="secondary"
        elevation={0}
        className={classes.root}
      >
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logoBox} onClick={handleLogoClick}>
            <Logo style={{ width: 150 }} />
          </Box>
          <Box flexGrow="1" />
          {Boolean(origin) && (
            <Button
              color="primary"
              startIcon={<KeyboardBackspaceIcon />}
              style={{ backgroundColor: "transparent" }}
              classes={{
                text: classes.textBtn,
                startIcon: classes.startIcon,
              }}
              onClick={() => {
                history.push(`/pub?e=${origin}`)
              }}
            >
              Go Back
            </Button>
          )}
          {!appliedLoading && (
            <Button onClick={handleShowDrawer}>
              {!isOpen ? <MenuIcon /> : <CloseIcon />}
            </Button>
          )}
        </Toolbar>
      </A>
      <Drawer
        variant="temporary"
        anchor="top"
        open={isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            setIsOpen(false)
          }
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
        style={{ position: "relative", top: "-200px", zIndex: 10 }}
      >
        <TopMenuMobile setIsOpen={setIsOpen} />
      </Drawer>
    </>
  )
}

AppBarMobile.propTypes = {
  children: PropTypes.node,
}

export default AppBarMobile
