import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: "518px",
    minHeight: "657px",
    padding: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  changeStepButton: {
    minWidth: "15px",
    height: "15px",
  },
  stepsText: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: 0,
    color: theme.palette.primary.dark,
  },
  outlined: {
    padding: 0,
  },
  tab: {
    minWidth: "50px",
  },
  actionsContainer: {
    position: "absolute",
    bottom: "0px",
    width: "90%",
  },

  contentContainer: {
    height: "450px",
    overflowX: "auto",
  },
}))

export default styles
