import {
  AppBar as A,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import Searchbar from "components/Clerk/Searchbar"
import Sidebar from "components/Clerk/Sidebar"
import AppBarPhone from "components/Common/AppBarPhone"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useIndexedDB } from "react-indexed-db"
import Router from "router"

import useStyles from "./styles"

const Clerk = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { clear } = useIndexedDB("auth")

  const {
    openDialog,
    token,
    setAuthData,
    authUser,
    setIsSuperAdmin,
    setIsAdmin,
  } = useContext(AppContext)

  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleShowDrawer = (e) => {
    setIsOpen((prev) => !prev)
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setIsOpen(!isOpen)
    setAnchorEl(null)
  }

  const handleLogout = () => {
    if (token) {
      setAuthData({ token: "", account: "" })
      setIsSuperAdmin(false)
      setIsAdmin(false)
      clear().finally(() => {
        if (process.env.REACT_APP_NODE_ENV === "development") {
          window.location.href = "/clerk"
        } else {
          window.location.href = "/"
        }
      })
    }
  }

  return (
    <Box className={classes.pageContainer}>
      {!mobileView && !isEmpty(authUser) && <Sidebar />}
      {mobileView && !isEmpty(authUser) && (
        <>
          <Box display="flex" alignItems="center" flexDirection="column">
            <A
              position="static"
              color="transparent"
              className={classes.mobileRoot}
            >
              <Toolbar className={classes.toolbar}>
                <Box className={classes.logoBox}>
                  <Logo style={{ width: 150 }} />
                </Box>
                <Box flexGrow="1" />
                <Button onClick={(e) => handleShowDrawer(e)}>
                  {!isOpen ? <MenuIcon /> : <CloseIcon />}
                </Button>
              </Toolbar>
            </A>
            <Menu
              open={isOpen}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              onClose={(event, reason) => {
                if (reason === "backdropClick") {
                  handleClose()
                }
              }}
            >
              <MenuItem>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleClose()
                    openDialog("clerkMyAccount")
                  }}
                  classes={{ label: classes.label }}
                >
                  My Account
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleClose()
                    handleLogout()
                  }}
                  classes={{ label: classes.label }}
                >
                  Log Out
                </Button>
              </MenuItem>
            </Menu>
            <AppBarPhone />
          </Box>
        </>
      )}
      <Box className={classes.routerContainer}>
        {!mobileView && <Searchbar />}
        <Box className={classes.routes} id="scrollableDiv">
          <Router routes={routes} {...rest} />
        </Box>
      </Box>
    </Box>
  )
}

Clerk.propTypes = {
  routes: PropTypes.array,
}

export default Clerk
