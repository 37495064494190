import Record from "./base"

export class Request extends Record {
  get abbreviated_period_start() {
    return `${
      this.review_period_start?.charAt(0)?.toUpperCase() +
        this.review_period_start.slice(1, 3) || ""
    }`
  }
  get abbreviated_period_end() {
    return `${
      this.review_period_end?.charAt(0)?.toUpperCase() +
        this.review_period_end.slice(1, 3) || ""
    }`
  }
  constructor(...args) {
    super(...args)
    const [model, props] = args
    this.entity_type =
      this.entity_type.charAt(0)?.toUpperCase() + this.entity_type.slice(1)
    if (this.phone.includes("+")) {
      this.phone = this.phone.slice(2, this.phone.length)
    }
  }
}
