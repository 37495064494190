import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
    },
    link: {
      fontWeight: 500,
      marginLeft: theme.spacing(2),
    },
    loginButtonBox: {
      marginLeft: theme.spacing(2),
    },
  }),
  {
    name: "AppBarMenu",
  }
)

export default styles
