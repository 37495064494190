import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("md")]: {
        overflowY: "auto",
      },
    },
    messageSenderBox: {
      height: 70,
      display: "flex",
      alignItems: "center",
      flexShrink: 0,
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    messagesListBox: {
      flexGrow: 1,
      overflow: "auto",
    },
    pronoun: {
      opacity: 0.3,
      marginLeft: theme.spacing(1),
    },
  }),
  {
    name: "MessageComposer",
  }
)

export default styles
