import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      flexGrow: 1,
      overflow: "auto",
    },
  }),
  {
    name: "DashboardCard",
  }
)

export default styles
