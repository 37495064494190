import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    optionsButton: {
      backgroundColor: theme.palette.secondary.dark,
      color: "#fff",
      display: "flex",
      minWidth: "155px",
      justifyContent: "space-between",
      maxHeight: "40px",
      alignSelf: "center",
    },
  }),
  {
    name: "RevieweeInfo",
  }
)

export default styles
