import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      boxShadow: "0px -3px 6px #0000001A",
    },
    container: {
      height: "100%",
      overflow: "auto",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        overflow: "hidden",
        padding: 0,
      },
    },
    questionBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "50%",
    },
    answerBox: {
      display: "flex",
      width: "30%",
      justifyContent: "space-between",
      padding: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }),
  {
    name: "BoardPage-Citizen",
  }
)

export default styles
