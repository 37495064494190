import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    // overflow: "auto",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
  // styles for id="scrollableDiv" below
  routes: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      // paddingBottom: "80px",
    },
  },
  // special case for id="scrollableDiv" below (created for my-account)
  noScroll: {
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
}))

export default styles
