import DateFnsUtils from "@date-io/date-fns"
import {
  Box,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import ButtonRounded from "components/Common/ButtonRounded"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"
import * as Yup from "yup"

import useStyles from "./styles"

const occupationSchema = Yup.object({
  title: Yup.string().required("Required"),
  company_name: Yup.string().required("Required"),
  start_date: Yup.date()
    .nullable(true)
    .when("current", {
      is: false,
      then: Yup.date().nullable(true).required("Required"),
    }),
  end_date: Yup.date()
    .nullable(true)
    .when("current", {
      is: false,
      then: Yup.date().nullable(true).required("Required"),
    }),
  current: Yup.bool(),
})

const AddOccupationDialog = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const authContext = useContext(AppContext)

  const { dialogs, closeDialog, token, openSnackBar, authUser } =
    useContext(AppContext)
  const { updatePositionMutation } = useContext(AccountContext)

  const thisDialog = dialogs?.["addOccupation"] || {}
  const { open = false, data = {} } = thisDialog

  const handleClose = () => {
    closeDialog("addOccupation")
  }

  const onSubmit = (values) => {
    let data = values
    if (data.current === true) {
      data.end_date = null
    }

    if (values?.start_date) {
      values.start_date = values?.start_date?.toISOString()
    }
    if (values?.end_date) {
      values.end_date = values?.end_date?.toISOString()
    }
    updatePositionMutation
      .mutateAsync(data)
      .then((res) => {
        serialize("user", res.user)
          .then((serializedData) => {
            authContext.setAuthData({
              token: token,
              account: serializedData,
            })
            openSnackBar({ message: "Position updated" })
            handleClose()
          })
          .catch((err) => {
            openSnackBar({ message: "Error updating position" })
          })
      })
      .catch((err) => {
        openSnackBar({ message: "Error updating position" })
      })
  }

  return (
    <ConditionalDialogWrapper open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Add Occupation/ Current Position</Typography>
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={{
          title: authUser?.position?.[0]?.title || "",
          company_name: authUser?.position?.[0]?.company_name || "",
          start_date: authUser?.position?.[0]?.start_date
            ? dayjs(authUser?.position?.[0]?.start_date)
            : null,
          end_date: authUser?.position?.[0]?.end_date
            ? dayjs(authUser?.position?.[0]?.end_date)
            : null,
          current: authUser?.position?.[0]?.current || false,
        }}
        onSubmit={onSubmit}
        validationSchema={occupationSchema}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setErrors,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <>
                <DialogContent>
                  <TextField
                    type="text"
                    name="title"
                    data-cy="position-title"
                    label="Title"
                    id="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.title && errors.title}
                    style={{ marginBottom: "25px" }}
                    value={values.title}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    error={touched.title && errors.title}
                  />
                  <TextField
                    type="text"
                    name="company_name"
                    label="Company Name"
                    data-cy="position-company-name"
                    id="company_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.company_name && errors.company_name}
                    style={{ marginBottom: "25px" }}
                    value={values.company_name}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    error={touched.company_name && errors.company_name}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      className={classes.dateContainer}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="date-picker-occ-start"
                          data-cy="position-start-date"
                          label="Start Date"
                          openTo="year"
                          views={["year", "month", "date"]}
                          value={values?.start_date}
                          style={{ marginRight: "10px" }}
                          classes={
                            errors?.start_date && touched?.start_date
                              ? { root: classes.datePicker }
                              : null
                          }
                          onChange={(e) => {
                            setFieldValue("start_date", e)
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Box>
                      <Box>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="date-picker-occ-end"
                          data-cy="position-end-date"
                          label="End Date"
                          openTo="year"
                          views={["year", "month", "date"]}
                          value={values?.end_date}
                          style={{ marginLeft: "10px" }}
                          classes={
                            errors?.end_date && touched?.end_date
                              ? { root: classes.datePicker }
                              : null
                          }
                          onChange={(e) => {
                            setFieldValue("end_date", e)
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={Boolean(values?.current)}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box width="50%" />
                      <FormControlLabel
                        style={{ marginLeft: "5px" }}
                        control={
                          <Checkbox
                            data-cy="position-current-checkbox"
                            checked={Boolean(values?.current)}
                            onChange={() => {
                              setFieldValue("current", !values?.current)
                            }}
                            name="current"
                          />
                        }
                        label="I currently work here"
                        labelPlacement="end"
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                  <Box display="flex" justifyContent="center" width="100%">
                    <ButtonRounded
                      color="primary"
                      variant="outlined"
                      onClick={handleClose}
                      type="button"
                      data-cy="position-close-button"
                    >
                      Close
                    </ButtonRounded>
                    <ButtonRounded
                      color="primary"
                      variant="contained"
                      type="submit"
                      data-cy="position-save-button"
                      className={classes.saveButton}
                      disabled={!dirty}
                      classes={{ label: classes.buttonLabel }}
                    >
                      {updatePositionMutation?.isLoading ? (
                        <CircularProgress size={20} style={{ color: "#fff" }} />
                      ) : (
                        "Save"
                      )}
                    </ButtonRounded>
                  </Box>
                </DialogActions>
              </>
            </Form>
          )
        }}
      </Formik>
    </ConditionalDialogWrapper>
  )
}

export default AddOccupationDialog
