import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    "& input::selection": {
      background: "transparent",
    },
    "& input::placeholder": {
      color: "#fff",
    },
  },
  codeInput: {
    display: ["flex", "!important"],
    justifyContent: "center",
    "& > input": {
      marginTop: `-20px`,
      fontFamily: "'Roboto Mono', monospace",
      borderRadius: "6px",
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: "rgb(0 0 0 / 10%) 0px 0px 10px 0px",
      margin: "4px",
      padding: "0",
      width: "36px",
      height: "42px",
      fontSize: "2rem",
      boxSizing: "border-box",
      color: "#black",
      backgroundColor: "white",
      textAlign: "center",
      outline: "none",
      "&:disabled": {
        backgroundColor: ["grey", "!important"],
        border: "1px solid #FFFFFF80",
        opacity: 0.5,
      },
    },
  },
}))

export default styles
