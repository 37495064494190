import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    marginBottom: theme.spacing(1.5),
  },
  buttonRoot: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: "1.2rem",
    minHeight: 45,
  },
}))

export default styles
