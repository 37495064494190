import { Container, Typography } from "@material-ui/core"
import { BoardsContainer } from "contexts/Boards"
import Page from "pages/Page"
import PropTypes from "prop-types"
import React from "react"
import Router from "router"

import useStyles from "./styles"

const Boards = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <Page>
      <BoardsContainer>
        <Router routes={routes} {...rest} />
      </BoardsContainer>
    </Page>
  )
}

Boards.propTypes = {
  routes: PropTypes.array,
}

export default Boards
