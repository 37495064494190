import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      paddingTop: theme.spacing(5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: "relative",
    },
    closeEditButton: {
      position: "absolute",
      top: 0,
      right: -10,
    },
    formHelperText: {
      marginLeft: "0px",
      position: "absolute",
      bottom: "-20px",
      color: theme.palette.error.main,
    },

    datePickerError: {
      color: theme.palette.error.main,
      "& .MuiInputBase-root": {
        backgroundColor: "#F8F8F8",
        borderRadius: "5px",
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.error.main}`,
        },
      },
      "& .MuiInputLabel-root": {
        color: theme.palette.error.main,
      },
      "& > .MuiPickerDTToolbar-toolbar.MuiToolbar-root": {
        backgroundColor: "#F8F8F8",
        borderRadius: "5px",
      },
    },

    datePicker: {
      "& .MuiInputBase-root": {
        backgroundColor: "#F8F8F8",
        borderRadius: "5px",
      },
      "& > .MuiPickerDTToolbar-toolbar.MuiToolbar-root": {
        backgroundColor: "#F8F8F8",
        borderRadius: "5px",
      },
    },
  }),

  {
    name: "UpdateMember",
  }
)

export default styles
