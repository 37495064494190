import { Box, Card, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Education = ({ actions, educationAttained, degree }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Box display="flex" width="100%">
        <Box flexGrow={1}>
          <Typography className={classes.educationText}>
            {educationAttained}
          </Typography>
          <Typography variant="body1" className={classes.textGray}>
            {degree}
          </Typography>
        </Box>
        <Box>{actions}</Box>
      </Box>
    </Card>
  )
}

Education.propTypes = {
  actions: PropTypes.node,
  educationAttained: PropTypes.string,
  degree: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default Education
