import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(2),
      paddingRight: "initial",
      paddingBottom: "initial",
      overflow: "hidden",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexShrink: "0",
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(3),
      flexWrap: "wrap",
    },
    leftButtons: {
      display: "flex",
      flexWrap: "no-wrap",
      [theme.breakpoints.down(1100)]: {
        flexDirection: "column",
      },
      [theme.breakpoints.only("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.down(670)]: {
        flexDirection: "column",
      },
    },
    scrollArea: {
      overflow: "auto",
      height: "100%",
    },
    menuItem: {
      width: "150px",
    },
    dataImportButton: {
      backgroundColor: "#0A8158",
      marginLeft: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#0A8158",
      },
      [theme.breakpoints.down(1100)]: {
        marginTop: theme.spacing(1),
        marginLeft: "initial",
      },
      [theme.breakpoints.only("sm")]: {
        marginLeft: theme.spacing(1),
        marginTop: "initial",
      },
      [theme.breakpoints.down(670)]: {
        marginTop: theme.spacing(1),
        marginLeft: "initial",
      },
    },
    sortButton: {
      maxHeight: "40px",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
      },
    },
  }),
  {
    name: "Clerk-BoardsListPage",
  }
)

export default styles
