import dayjs from "dayjs"

import Record from "./base"
export class Board extends Record {
  constructor(...args) {
    super(...args)
    // const [model, props] = args
    this.vacants = this?.board_positions?.filter((x) => !x.active)?.length
    this.membersExpiring = this?.board_positions.filter(
      (x) =>
        x?.term_expiration &&
        dayjs(x.term_expiration).diff(dayjs(), "day") <= 90 &&
        (this?.term?.includes("year") || this?.term?.includes("other"))
    )?.length
  }
}
