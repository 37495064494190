const BoardModel = {
  board_positions: "array",
  default_questions: "array",
  additional_questions: "array",
  interest: "array",
  expertise: "array",
  experience: "array",
  admin_emails: "array",
  review_periods: "array",
  leaderships: "array",
}

export default BoardModel
