import { Container, Paper, Typography } from "@material-ui/core"
import { BoardContainer } from "contexts/Clerk/Board"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"

import useStyles from "./styles"

const Board = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const params = useParams()

  return (
    <Paper square className={classes.root}>
      <Container className={classes.container}>
        <Page>
          <BoardContainer boardId={params?.board_id}>
            <Typography>Board details here</Typography>
          </BoardContainer>
        </Page>
      </Container>
    </Paper>
  )
}

Board.propTypes = {
  routes: PropTypes.array,
}

export default Board
