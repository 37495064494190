import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      width: 225,
      flexShrink: 0,
      height: "100%",
      zIndex: 50,
    },
    tab: {},
    tabWrapper: {
      flexDirection: "row",
    },
    logoBox: {
      height: 64,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    bottomButton: {
      fontSize: "1.4rem",
      color: "#898989",
      fontWeight: 500,
    },
    title: {
      color: "black",
    },
  }),
  {
    name: "Sidebar",
  }
)

export default styles
