import { Box, List, ListItem, Typography } from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import App from "contexts/App"
import CitizenApp from "contexts/Citizen/CitizenApp"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useMemo } from "react"
import { Link, useHistory } from "react-router-dom"

import useStyles from "./styles"

const TopMenuMobile = ({ setIsOpen }) => {
  const classes = useStyles()
  const history = useHistory()

  const { openDialog, authUser, setAuthData, openSnackBar } = useContext(App)
  const { appliedData, appliedLoading } = useContext(CitizenApp)

  const goToBoards = () => {
    if (!history?.location?.pathname?.includes("/boards")) {
      history?.push("/boards")
    }
    setIsOpen(false)
  }

  const handleOpenLogin = () => {
    openDialog("login")
    setIsOpen(false)
  }

  const onHelp = () => {
    try {
      window.$zopim.livechat.window.show()
      setIsOpen(false)
    } catch (error) {
      console.log("%cError opening Zendesk.", "color: red")
      setIsOpen(false)
    }
  }

  const openClerkSide = () => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      window.location.href = "http://localhost:3000/clerk/"
    } else if (process.env.REACT_APP_NODE_ENV === "staging") {
      window.location.href = "https://admin.matchboard.dev/"
    } else {
      window.location.href = "https://admin.matchboard.tech/"
    }
  }

  const loggedInListItems = useMemo(() => {
    return [
      <ListItem
        key="applications"
        button
        onClick={() => {
          setIsOpen(false)
          history.push("/applied")
        }}
      >
        <Typography variant="overline">
          My Applications ({`${appliedData?.length}`})
        </Typography>
      </ListItem>,
      <ListItem key="help" button onClick={onHelp}>
        <Typography variant="overline">Help</Typography>
      </ListItem>,
    ]
  }, [appliedData])

  const loggedOutListItems = useMemo(() => {
    return [
      <ListItem key="boards" button onClick={goToBoards}>
        <Typography variant="overline">View Boards</Typography>
      </ListItem>,
      <ListItem key="admins" button onClick={() => openClerkSide()}>
        <Typography variant="overline">Administrators</Typography>
      </ListItem>,
      <ListItem key="help" button onClick={onHelp}>
        <Typography variant="overline">Help</Typography>
      </ListItem>,
    ]
  }, [appliedData])

  return (
    <Box style={{ backgroundColor: "#F1E1E9" }}>
      <List>
        {isEmpty(authUser) ? (
          <>{loggedOutListItems}</>
        ) : (
          <>{loggedInListItems}</>
        )}
      </List>
      {isEmpty(authUser) ? (
        <Box className={classes.loginLogoutBox}>
          <ButtonRounded
            variant="outlined"
            className={classes.loginLogout}
            onClick={handleOpenLogin}
          >
            <Typography variant="overline">Login</Typography>
          </ButtonRounded>
        </Box>
      ) : (
        <Box className={classes.loginLogoutBox}>
          <ButtonRounded
            variant="outlined"
            className={classes.loginLogout}
            onClick={() => {
              setAuthData({ token: "", account: {} })
              setIsOpen(false)
              history.push("/")
              openSnackBar({
                message: "You have logged out successfully!",
              })
            }}
          >
            <Typography variant="overline">Logout</Typography>
          </ButtonRounded>
        </Box>
      )}
    </Box>
  )
}

TopMenuMobile.propTypes = {
  children: PropTypes.node,
  setIsOpen: PropTypes.func,
}

export default TopMenuMobile
