import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.primary.dark,
    fontSize: "1.4rem",
  },

  saveButton: {
    marginLeft: theme.spacing(2),
  },
  textfield: {
    marginTop: theme.spacing(2),
  },
}))

export default styles
