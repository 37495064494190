import * as dayjs from "dayjs"

// formatting of date display used in Message Thread, Notification on dashboard

export default function cleanDateTime(date) {
  const cleanTime = dayjs(date).format("h:mm a")
  const cleanDay = dayjs(date).format("dddd")
  const cleanDate = dayjs(date).format("M/D/YY")
  if (!date) {
    return ""
  }

  if (dayjs().isAfter(dayjs(date), "day")) {
    // msg rec'd yesterday or earlier
    if (dayjs().diff(date, "day") >= 6) {
      // outside previous week...
      return cleanDate
    } else {
      //within the previous week...
      return cleanDay
    }
  } else {
    // msg rec'd today
    return cleanTime
  }
}
