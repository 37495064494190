import { Box, Card, IconButton, Typography } from "@material-ui/core"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"

import useStyles from "./styles"

const Resume = ({ name, actions, resumeId, openMutation }) => {
  const classes = useStyles()
  const { openSnackBar } = useContext(AppContext)

  const downloadResume = () => {
    openMutation
      ?.mutateAsync(resumeId)
      .then((res) => {
        window.location.href = res?.url
      })
      .catch((err) => {
        openSnackBar({ message: "Error opening resume" })
      })
  }
  return (
    <Card className={classes.card}>
      <Box display="flex" width="100%" alignItems="center">
        {name ? (
          <Box>
            <IconButton onClick={downloadResume}>
              <PictureAsPdfIcon className={classes.resumeIcon} />
            </IconButton>
          </Box>
        ) : null}
        <Box flexGrow={1}>
          <Typography variant="h6" className={classes.resumeName}>
            {name ? name : "No document attached"}
          </Typography>
        </Box>
        {name ? <Box>{actions}</Box> : null}
      </Box>
    </Card>
  )
}

Resume.propTypes = {
  element: PropTypes.object,
  actions: PropTypes.node,
  name: PropTypes.string,
  resumeId: PropTypes.string,
  marginBottom: PropTypes.string,
  openMutation: PropTypes.object,
}

export default Resume
