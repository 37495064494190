import { Backdrop, Box, Slide, useMediaQuery, Button } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"

import useStyles from "./styles"

const BottomDrawer = ({ componentToRender, show, onClose, drawerColor }) => {
  const classes = useStyles()
  const location = useLocation()
  const theme = useTheme()
  const tabletView = useMediaQuery(theme.breakpoints.only("sm"))
  const fixedHeight = location.pathname.includes("/boards") ? "90%" : null

  return (
    <Backdrop open={show} style={{ zIndex: 50 }}>
      <Slide
        direction="up"
        in={show}
        style={{
          zIndex: 1200,
          boxShadow: "0px -8px 30px rgb(50 50 50 / 75%)",
          backgroundColor: drawerColor || null,
        }}
      >
        <Box className={classes.drawer} style={{ height: fixedHeight }}>
          <Button
            variant="contained"
            className={classes.collapseButton}
            onClick={onClose}
          >
            Close
          </Button>
          {/* <Box className={classes.collapseLine} onClick={onClose} /> */}

          <Box className={classes.scrollContainer}>
            {/* component passed in renders below inside this scroll container */}
            {componentToRender}
          </Box>
        </Box>
      </Slide>
    </Backdrop>
  )
}

BottomDrawer.propTypes = {
  componentToRender: PropTypes.node,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  drawerColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default BottomDrawer
