import {
  Box,
  Collapse,
  Container,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import Autocomplete from "@material-ui/lab/Autocomplete"
import pin from "assets/icon-feather-map-pin.svg"
import search from "assets/icon-feather-search.svg"
import ButtonRounded from "components/Common/ButtonRounded"
import App from "contexts/App"
import CitizenApp from "contexts/Citizen/CitizenApp"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useEffect } from "react"
import capitalize from "utils/capitalize"

import MobileInterestCheckboxes from "./MobileInterestCheckboxes"
import useStyles from "./styles"

const MobileSearchFilter = ({
  searchValue,
  setSearchValue,
  noLocation = false, // only turned true for IFrame (loc not needed)
}) => {
  const classes = useStyles()
  const [showOptions, setShowOptions] = useState(false)

  const { interestsLoading, setSearchButtonClicked, setClearSearch } =
    useContext(App)
  const {
    autocompleteOptions,
    autocompleteOptionsLoading,
    locationSearch,
    setLocationSearch,
    selectedCategories,
  } = useContext(CitizenApp)

  const handleToggleCollapse = () => {
    setShowOptions((oldState) => !oldState)
  }

  // if on iframe page, hides the search location "chip" piece (location/entity will come from url params)
  useEffect(() => {
    if (noLocation) {
      setLocationSearch(null)
    }
  }, [])

  const showClear = searchValue !== "" || !isEmpty(locationSearch)

  if (interestsLoading) {
    return <LinearProgress />
  } else {
    return (
      <Container className={classes.root}>
        <FormControl
          fullWidth
          component="fieldset"
          className={classes.formControl}
        >
          <Box className={classes.titleWrapper} onClick={handleToggleCollapse}>
            <Typography
              variant="h6"
              color="primary"
              classes={{ root: classes.legend }}
            >
              Filter/Search Options
            </Typography>
            <IconButton id="collapse-search">
              {!showOptions ? (
                <KeyboardArrowDownIcon
                  id="search-options-show"
                  color="primary"
                />
              ) : (
                <KeyboardArrowUpIcon id="search-options-hide" color="primary" />
              )}
            </IconButton>
          </Box>
          <FormGroup>
            <Collapse in={showOptions}>
              <TextField
                fullWidth
                placeholder="Search by Keyword"
                className={classes.search}
                value={searchValue}
                variant="filled"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value)
                  }
                }}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                }}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                    focused: classes.inputFocused,
                  },
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginLeft: "10px", marginBottom: "10px" }}
                    >
                      <Icon classes={{ root: classes.iconRoot }}>
                        <img
                          className={classes.imageIcon}
                          src={search}
                          alt=""
                        />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
              />
              {!noLocation && (
                <Autocomplete
                  fullWidth
                  id="home-loc-autocomplete"
                  getOptionLabel={(option) => `${option.label}`}
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={autocompleteOptions || []}
                  loading={autocompleteOptionsLoading}
                  value={locationSearch}
                  popupIcon={null}
                  onChange={(event, newValue) => {
                    setLocationSearch(newValue)
                  }}
                  renderOption={(option) => {
                    return (
                      <Box
                        key={option?.id}
                        display="flex"
                        flexDirection="row"
                        alignItems="flex-end"
                      >
                        <Typography
                          style={{
                            fontWeight: 500,
                            marginRight: "6px",
                          }}
                          variant="body2"
                        >
                          {option?.label}
                        </Typography>
                        <Typography style={{ opacity: 0.4, fontSize: "1rem" }}>
                          {capitalize(option?.type)}
                        </Typography>
                      </Box>
                    )
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        placeholder={
                          !locationSearch ? "Search by Location" : ""
                        }
                        className={classes.search}
                        style={{
                          paddingTop: "10px !important",
                          paddingBottom: "12px !important",
                        }}
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            root: classes.inputBackgroundColor,
                          },
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                marginLeft: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <Icon classes={{ root: classes.iconRoot }}>
                                <img
                                  className={classes.imageIcon}
                                  src={pin}
                                  alt=""
                                />
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )
                  }}
                  classes={{
                    root: classes.autocomplete,
                    inputRoot: classes.inputRoot,
                  }}
                />
              )}
              <Box className={classes.buttonBox}>
                <ButtonRounded
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSearchButtonClicked(true)
                    setTimeout(() => {
                      setSearchButtonClicked(false)
                    }, 300)
                    setShowOptions(false)
                  }}
                >
                  Search
                </ButtonRounded>
                <ButtonRounded
                  disabled={!showClear}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setSearchValue("")
                    setLocationSearch(null)
                    setClearSearch(true)
                    setTimeout(() => {
                      setClearSearch(false)
                    }, 300)
                    setShowOptions(false)
                  }}
                  style={{ marginLeft: "30px" }}
                >
                  Clear Search
                </ButtonRounded>
              </Box>
              <MobileInterestCheckboxes />
            </Collapse>
          </FormGroup>
        </FormControl>
        {(searchValue || locationSearch || selectedCategories?.length > 0) && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="no-wrap"
            marginRight="8px"
          >
            <Box paddingBottom={1} width="85%">
              <Typography color="primary">Filters Applied:</Typography>
              {searchValue && (
                <Typography style={{ marginLeft: "8px" }}>
                  Keyword:{" "}
                  <span
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    {searchValue}
                  </span>
                </Typography>
              )}
              {locationSearch && (
                <Typography style={{ marginLeft: "8px" }}>
                  Location:{" "}
                  <span
                    style={{
                      marginLeft: "12px",
                    }}
                  >
                    {locationSearch?.label}
                  </span>
                  <span
                    style={{
                      marginLeft: "12px",
                      color: "#898989",
                    }}
                  >
                    {capitalize(locationSearch?.type)}
                  </span>
                </Typography>
              )}
              {selectedCategories?.length > 0 && (
                <Typography style={{ marginLeft: "8px" }}>
                  Categories:
                  {selectedCategories?.map((x, i) => (
                    <span
                      key={i}
                      style={{
                        marginLeft: "12px",
                      }}
                    >
                      {x?.name}
                    </span>
                  ))}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              {showClear && !showOptions && (
                <IconButton
                  onClick={() => {
                    setSearchValue("")
                    setLocationSearch(null)
                    setClearSearch(true)
                    setTimeout(() => {
                      setClearSearch(false)
                    }, 300)
                  }}
                >
                  <ClearIcon className={classes.clearIcon} />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Container>
    )
  }
}

MobileSearchFilter.propTypes = {
  noLocation: PropTypes.bool,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
}

export default MobileSearchFilter
