import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    bodyText: {
      fontSize: "1.4rem",
    },
    container: {
      marginBottom: theme.spacing(2),
      border: "1px solid #70707043",
      overflow: "hidden",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("xs")]: {
        height: "auto",
      },
    },
    titleBox: {
      width: "100%",
      flexWrap: "wrap",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
      },
    },
    scrollArea: {
      height: "100%",
      overflowY: "auto",
      padding: "0 30px",
      [theme.breakpoints.down("xs")]: {
        height: "auto",
        marginBottom: "50px",
        overflowY: "hidden",
        overflowX: "hidden",
        padding: "8px",
      },
    },
    dataContainer: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    infoBoxTitle: {
      fontSize: "1.4rem",
      color: "#898989",
      fontWeight: 500,
    },
    infoBoxData: {
      fontSize: "1.4rem",
      color: theme.palette.secondary.dark,
    },
    bottomBox: {
      flexShrink: "0",
      paddingTop: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    chipContainer: {
      width: "70%",
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: theme.spacing(1),
      },
    },
    chipRoot: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: "6px",
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipLabel: {
      fontSize: "1.4rem",
      color: theme.palette.primary.dark,
      textTransform: "capitalize",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontWeight: 300,
    },
    buttonLabel: {
      fontSize: "1.4rem",
    },
  }),
  {
    name: "IframeBoardCard",
  }
)

export default styles
