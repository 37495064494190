import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 11,
    paddingBottom: 11,
    // "&:last-child": {
    //   borderBottom: "none",
    // },
    "&:hover": {
      cursor: "pointer",
    },
  },
  listItemText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondaryText: {
    fontWeight: 500,

    textAlign: "right",
  },
  noDataImage: {
    width: "212px",
    marginTop: "40px",
  },
  noDataTitle: {
    marginBottom: theme.spacing(2),
    color: "#190D27",
    opacity: "0.48",
  },
}))

export default styles
