import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
    },
    search: {
      marginRight: theme.spacing(3),
    },
    autocomplete: {
      marginRight: theme.spacing(2),
    },
    inputRoot: {
      width: "230px",
      paddingTop: "0 !important",
      paddingRight: "20px !important",
    },
    imageIcon: {
      display: "flex",
      height: "inherit",
      width: "inherit",
    },
    icon: {
      color: "#898989",
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
  {
    name: "IframeSearch",
  }
)

export default styles
