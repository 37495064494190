import PropTypes from "prop-types"
import { createContext } from "react"

const ApplicantContext = createContext()
export default ApplicantContext

export const ApplicantContainer = ({ children, ...props }) => {
  return (
    <ApplicantContext.Provider value={{}}>{children}</ApplicantContext.Provider>
  )
}

ApplicantContainer.propTypes = {
  children: PropTypes.node,
  applicantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
