import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import ButtonRounded from "components/Common/ButtonRounded"
import App from "contexts/App"
import RequestClerkContext from "contexts/Clerk/RequestClerk"
import { Form, Formik } from "formik"
import { useRef } from "react"
import { useState } from "react"
import { useContext } from "react"
import * as Yup from "yup"

import Step1 from "./Components/Step1"
import Step2 from "./Components/Step2"
import Step3 from "./Components/Step3"
import Step4 from "./Components/Step4"
import useStyles from "./styles"

const ClerkRequest = () => {
  const classes = useStyles()
  const formRef = useRef()

  const { dialogs, closeDialog, openSnackBar } = useContext(App)
  const { requestClerkMutation, checkPhoneMutation, checkEmailMutation } =
    useContext(RequestClerkContext)
  const thisDialog = dialogs?.["clerkRequest"] || {}
  const { open = false, data = {} } = thisDialog
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [entityUsed, setEntityUsed] = useState(false)
  const [entityType, setEntityType] = useState("")

  const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

  const schema = Yup.object().shape({
    state: Yup.string().required(`Required`),
    entity_type: Yup.string().required(`Required`),
    entity_id: Yup.object().required(`Required`).typeError("Required"),
    title: Yup.string().required(`Required`),
    first_name: Yup.string().required(`Required`),
    last_name: Yup.string().required(`Required`),
    email: Yup.string()
      .email("Please enter a valid email")
      .required(`Required`),
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegex, "Phone number is not valid")
      .min(14, "Phone number is not valid")
      .max(14, "Phone number is not valid"),
    //14 char - 9 numbers, 3 special chars ()-, 1 space
    entity_url: Yup.string().required(`Required`),
    pronoun_id: Yup.number().nullable(true),
    number_districts: Yup.number()
      .min(1, "Mininum 1 seat")
      .integer()
      .required("Required")
      .nullable(true),
    review_periods: Yup.array().of(
      Yup.object().shape({
        review_period_start: Yup.string()
          .nullable(true)
          .test(
            "end",
            "Required",
            (val) =>
              val !== undefined ||
              formRef?.current?.values?.review_period_rolling
          ),
        review_period_end: Yup.string()
          .nullable(true)
          .test(
            "end",
            "Required",
            (val) =>
              val !== undefined ||
              formRef?.current?.values?.review_period_rolling
          ),
      })
    ),

    review_period_rolling: Yup.bool(),
    leadership_ids: Yup.array().required(`Required`),
  })

  const handleClose = () => {
    closeDialog("clerkRequest")
    setTimeout(() => {
      setStep(1)
      setEntityUsed(false)
      setEntityType("")
    }, 100)
  }

  const handleChangeStep = (operation) => {
    setStep((oldStep) => {
      if (Number(oldStep) < 3) {
        return operation === "+" ? oldStep + 1 : oldStep - 1
      } else if (Number(oldStep) === 3 && operation === "-") {
        return 3 - 1
      }
    })
  }

  const onSubmit = () => {
    if (
      step === 3 &&
      ((formRef?.current.values.review_periods?.[0]?.review_period_start ===
        "" &&
        formRef?.current.values.review_periods?.[0]?.review_period_end === "" &&
        formRef?.current.values.review_period_rolling === false) ||
        formRef?.current.values.entity_url === "" ||
        formRef?.current.values.number_districts === null)
    ) {
      formRef.current.handleSubmit()
    } else {
      let data = {
        ...formRef?.current?.values,
        phone: formRef?.current?.values?.phone?.replace(/[^0-9]/g, ""),
        entity_id: formRef?.current?.values?.entity_id?.id,
      }
      setEntityType(formRef.current?.values?.entity_type)
      setLoading(true)
      requestClerkMutation
        .mutateAsync(data)
        .then((res) => {
          openSnackBar({ message: "Entity created" })
          setLoading(false)
          setStep(4)
        })
        .catch((err) => {
          openSnackBar({ message: "Error creating entity" })
          setLoading(false)
        })
    }
  }

  const steps = [
    <Step1 key={1} setEntityUsed={setEntityUsed} openSnackBar={openSnackBar} />,
    <Step2 key={2} />,
    <Step3 key={3} />,
    <Step4 key={4} setStep={setStep} entityType={entityType} />,
  ]

  const stepsTitle = [
    "Thank you for your interest in Matchboard. Let’s get you started.",
    `Please tell us a little more about your role in ${formRef?.current?.values?.entity_id?.entity}.`,
    `Thank you, ${formRef?.current?.values?.first_name}. Last information we need to get you started`,
  ]
  //function to pass to the next step and check the validation.
  //In step2 is doing calls to see if phone or email are used before
  const nexStepFunc = (setErrors, errors) => {
    if (
      step === 1 &&
      (formRef?.current?.values?.state === "" ||
        formRef?.current?.values?.entity_id === null ||
        formRef?.current?.values?.entity_type === "") &&
      !entityUsed
    ) {
      formRef.current.handleSubmit()
    } else if (
      step === 2 &&
      (formRef?.current?.values?.title === "" ||
        formRef?.current?.values?.first_name === "" ||
        formRef?.current?.values?.last_name === "" ||
        formRef?.current?.values?.email === "" ||
        formRef?.current?.values?.phone === "" ||
        errors.email ||
        errors.phone)
    ) {
      formRef.current.handleSubmit()
    } else if (
      step === 2 &&
      formRef?.current.values.phone !== "" &&
      formRef?.current.values.email !== ""
    ) {
      let emailPromise = new Promise((resolve, reject) => {
        checkEmailMutation
          .mutateAsync({ email: formRef?.current.values.email })
          .then((res) => {
            if (res.email_found === true) {
              setErrors({
                ...formRef.current.errors,
                email: "Email is already in use",
              })
              openSnackBar({ message: "Email is already in use" })

              reject()
            } else {
              resolve()
            }
          })
      })
      let phonePromise = new Promise((resolve, reject) => {
        checkPhoneMutation
          .mutateAsync({
            phone: formRef?.current.values.phone.replace(/[^0-9]/g, ""),
          })
          .then((res) => {
            if (res.phone_found === true) {
              setErrors({
                ...formRef.current.errors,
                phone: "Phone number is already in use",
              })
              openSnackBar({ message: "Phone number is already in use" })
              reject()
            } else {
              resolve()
            }
          })
      })
      return Promise.all([emailPromise, phonePromise])
        .then((val) => {
          setErrors({})
          handleChangeStep("+")
        })
        .catch((err) => {
          console.log(err)
          // openSnackBar({ message: "Error checking email and phone" })
        })
    } else {
      handleChangeStep("+")
      setErrors({})
    }
  }

  const emptyPeriod = {
    review_period_start: "",
    review_period_end: "",
  }

  return (
    <Dialog open={open} classes={{ paperWidthSm: classes.paper }}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5" color="primary">
          {stepsTitle[step - 1]}
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          state: "sc",
          entity_type: "",
          entity_id: null,
          title: "",
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          pronoun: null,
          entity_url: "",
          number_districts: null,
          review_periods: [emptyPeriod],
          review_period_rolling: false,
          leadership_ids: [],
        }}
        onSubmit={onSubmit}
        validationSchema={schema}
        innerRef={formRef}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          setErrors,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent className={classes.contentContainer}>
                {steps[step - 1]}
              </DialogContent>

              {step !== 4 && (
                <DialogActions className={classes.actionsContainer}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box display="flex" alignItems="center">
                      <ButtonRounded
                        variant="outlined"
                        color="primary"
                        className={classes.changeStepButton}
                        classes={{ outlined: classes.outlined }}
                        onClick={() => {
                          if (step !== 1) {
                            handleChangeStep("-")
                          }
                        }}
                        disabled={step === 1}
                      >
                        <KeyboardArrowLeftIcon style={{ fontSize: "12px" }} />
                      </ButtonRounded>
                      <Typography className={classes.stepsText} variant="h5">
                        {step}/3
                      </Typography>
                      <ButtonRounded
                        variant="outlined"
                        color="primary"
                        className={classes.changeStepButton}
                        classes={{ outlined: classes.outlined }}
                        onClick={() => {
                          nexStepFunc(setErrors, errors)
                        }}
                        disabled={step === 3 || entityUsed}
                      >
                        <KeyboardArrowRightIcon style={{ fontSize: "12px" }} />
                      </ButtonRounded>
                    </Box>
                    <Box>
                      <ButtonRounded
                        className={classes.cancelButton}
                        onClick={handleClose}
                      >
                        Cancel
                      </ButtonRounded>
                      {step === 3 ? (
                        <ButtonRounded
                          variant="contained"
                          color="primary"
                          onClick={onSubmit}
                          disabled={loading}
                          data-cy="clerk-request-save-button"
                        >
                          {loading ? <CircularProgress size={22} /> : "Save"}
                        </ButtonRounded>
                      ) : (
                        <ButtonRounded
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            nexStepFunc(setErrors, errors)
                          }}
                          disabled={entityUsed}
                          data-cy="clerk-request-next-button"
                        >
                          Next
                        </ButtonRounded>
                      )}
                    </Box>
                  </Box>
                </DialogActions>
              )}
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default ClerkRequest
