import { Box, CircularProgress, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import InfiniteScroll from "react-infinite-scroll-component"

import BoardCard from "../BoardCard"
import useStyles from "./styles"

const List = ({ data, page, size, setPage, hasMoreData, sortOrSearchUsed }) => {
  const classes = useStyles()
  //call more data when we change the page

  const loadData = () => {
    setPage((oldState) => oldState + 1)
  }

  return (
    <Box id="boards-scroll-citizen" className={classes.list}>
      <InfiniteScroll
        dataLength={data?.length || 30}
        next={loadData}
        hasMore={
          Number(data?.length) === Number(page) * Number(size) && hasMoreData
        }
        scrollableTarget="boards-scroll-citizen"
        loader={
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        endMessage={
          data?.length === 0 && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="500px"
            >
              <Typography variant="h2">
                {sortOrSearchUsed ? "No matched data" : "No Boards"}
              </Typography>
            </Box>
          )
        }
        style={{ overflow: "hidden" }}
      >
        {data?.map?.((board) => {
          const firstCard = board?.id === data?.[0]?.id
          // bool prop passed to handle scrolling action -
          // first card in the list doesn't get smooth scroll)
          return (
            <BoardCard key={board?.id} data={board} firstCard={firstCard} />
          )
        })}
      </InfiniteScroll>
    </Box>
  )
}

List.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  data: PropTypes.array,
  size: PropTypes.number,
  hasMoreData: PropTypes.bool,
  sortOrSearchUsed: PropTypes.bool,
}

export default List
