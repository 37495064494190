import { Box } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import PropTypes from "prop-types"

import useStyles from "./styles"

const AddButton = ({
  onClick,
  width = "48px",
  height = "48px",
  buttonSize = "30px",
  type,
}) => {
  const classes = useStyles()
  return (
    <Box
      className={classes.buttonContainer}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      style={{ width: width, height: height }}
      data-cy={`${type}-button`}
    >
      <AddIcon style={{ color: "#A01F62", fontSize: buttonSize }} />
    </Box>
  )
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  buttonSize: PropTypes.string,
  type: PropTypes.string,
}

export default AddButton
