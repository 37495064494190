import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: theme.spacing(1.5),
    },
    sent: {
      alignItems: "flex-end",
    },
    messageBox: {
      backgroundColor: "#F0F0F0",
      borderRadius: 2,
      padding: theme.spacing(1),
      maxWidth: 400,
      marginBottom: theme.spacing(0.5),
    },
    messageText: {
      fontWeight: 500,
      fontSize: "1.2rem",
      whiteSpace: "break-spaces",
    },
    metaText: {
      display: "flex",
      fontSize: ["1.1rem", "!important"],
    },
  }),
  {
    name: "MessageItem",
  }
)

export default styles
