import { createMessage, getMessages, viewedMessage } from "actions/messages"
import AppContext from "contexts/App"
import MessagesContext from "contexts/Messages"
import PropTypes from "prop-types"
import { createContext, useContext } from "react"
import { useMutation, useQuery } from "react-query"

const MessageContext = createContext()
export default MessageContext

export const MessageContainer = ({ children, ...props }) => {
  // const messageId = parseInt(props?.messageId || "0")

  const { authUser, isClerk } = useContext(AppContext)
  const { selectedThread } = useContext(MessagesContext)

  // Create message mutation
  const createMessageMutation = useMutation(createMessage)

  // Set message as seen
  const viewedMessageMutation = useMutation(viewedMessage)

  // Get messages -- refetchInterval is active to receieve new messages
  const {
    data: messages,
    isLoading: messageDataLoading,
    refetch: refetchMessages,
  } = useQuery(
    ["messages", selectedThread],
    () => getMessages(selectedThread.thread_id, isClerk),
    { refetchInterval: 3000 }
  )

  return (
    <MessageContext.Provider
      value={{
        messages,
        messageDataLoading,
        createMessageMutation,
        refetchMessages,
        viewedMessageMutation,
      }}
    >
      {children}
    </MessageContext.Provider>
  )
}

MessageContainer.propTypes = {
  children: PropTypes.node,
  messageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
