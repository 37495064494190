import api from "store/api"

export const getGender = (entityId) => {
  return api
    .get(`/admin/analytics/gender/${entityId}`)
    .then((res) => res.data.data)
}

export const getRace = (entityId) => {
  return api
    .get(`/admin/analytics/race/${entityId}`)
    .then((res) => res.data.data)
}

export const getAge = (entityId) => {
  return api
    .get(`/admin/analytics/ages/${entityId}`)
    .then((res) => res.data.data)
}

export const getMembership = (entityId) => {
  return api
    .get(`/admin/analytics/membership/${entityId}`)
    .then((res) => res.data.data)
}

export const getBoardStats = (entityId) => {
  return api
    .get(`/admin/analytics/boards/${entityId}`)
    .then((res) => res.data.data)
}
