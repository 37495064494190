import { Box, ListItem, ListItemText, Typography } from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import pinkCheckmark from "assets/pink-checkmark.svg"
import PropTypes from "prop-types"

import useStyles from "./styles"

const CategoryAreaListItem = ({ value, board = false }) => {
  const classes = useStyles()

  // if no board passed e.g., Applicants tab on Clerk (set as default in props) OR
  // if value is a string rather than an object e.g., Citizens BoardView
  // we don't want to see differently colored items/green check mark

  // show green - if applicant item matches board item, give green check mark
  const showGreen =
    (value?.matched !== null && value?.matched === 1) || value?.matched === true

  const whatColor = () => {
    if (showGreen) {
      return { color: "#23B12E" }
    } else {
      return null
    }
  }

  if (!board) {
    return (
      // Display this (colors, percentages) only if coming from board/applicants tab
      <ListItem disableGutters classes={{ root: classes.root }}>
        <ListItemText
          primary={
            <Box display="flex" flexDirection="row" alignItems="center">
              <img src={pinkCheckmark} alt="" style={{ marginRight: "8px" }} />
              <Typography>{value?.name ? value?.name : value}</Typography>
            </Box>
          }
          classes={{ primary: classes.primary }}
        />
      </ListItem>
    )
  } else {
    // Display this (all same with pink checks) only if coming from Applicants sidebar
    return (
      <ListItem disableGutters classes={{ root: classes.root }}>
        <ListItemText
          primary={
            <Box display="flex" flexDirection="row" alignItems="center">
              <FiberManualRecordIcon
                style={{ height: "5px", width: "auto", marginRight: "5px" }}
              />
              <Typography style={whatColor()}>
                {value?.name ? value?.name : value}
              </Typography>
            </Box>
          }
          classes={{ primary: classes.primary }}
        />
      </ListItem>
    )
  }
}

CategoryAreaListItem.propTypes = {
  children: PropTypes.node,
  board: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default CategoryAreaListItem
