import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  nameText: {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    fontWeight: 500,
    marginBottom: theme.spacing(0.75),
  },
  percentText: {
    paddingLeft: 12,
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    display: "inline-block",
  },
  table: {
    marginBottom: 10,
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1.5px solid #E5E5E5",
  },
  accordionSummary: {
    justifyContent: "space-between",
  },
  accordionContent: {
    flexDirection: "column",
    padding: 0,
    backgroundColor: "#FAFAFA",
  },
  arrowIcon: {
    fill: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    borderRadius: "50%",
  },
}))

export default styles
