import { Box, CircularProgress, List } from "@material-ui/core"
import { addPosition } from "actions/Clerk/board"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useQueryClient } from "react-query"

import MemberListItem from "./MemberListItem"
import useStyles from "./styles"

const MembersList = () => {
  const queryClient = useQueryClient()

  const classes = useStyles()
  const { openSnackBar, isClerk, authUser } = useContext(AppContext)
  const { board } = useContext(BoardContext)
  const [memberArr, setMemberArr] = useState([])
  const addPositionMutation = useMutation(addPosition)

  const membersList = board?.board_positions
  // creates arr with length data.positions
  // true passed as vacant prop into SeatMemberListItem for conditional render during map
  // set the memberArr on each initial page load
  useEffect(() => {
    if (board?.board_positions) {
      setMemberArr(board?.board_positions)
    }
  }, [JSON.stringify(board?.board_positions)])

  const addPositionFunc = () => {
    let data = { boardId: board?.id, position: "" }
    addPositionMutation
      .mutateAsync(data)
      .then((res) => {
        //update get boards query
        queryClient.setQueryData(
          ["boardsData", authUser && isClerk],
          (oldData) => {
            let newData = oldData.map((x) => {
              if (x.id === board?.id) {
                let data = x
                x.board_positions = [
                  ...x.board_positions,
                  { position_id: res?.position_id, board_id: board?.id },
                ]
                return data
              } else {
                return x
              }
            })
            return newData
          }
        )
        openSnackBar({ message: "Role/district added" })
      })
      .catch((err) => {
        openSnackBar({ message: "Error adding role/district" })
      })
  }

  if (!membersList) {
    return (
      <Box justifyContent="center" display="flex" flexGrow={1} height="100%">
        <CircularProgress size={30} />
      </Box>
    )
  } else {
    return (
      <Box height="100%" display="flex" flexDirection="column">
        <Box overflow="auto" flexGrow={1}>
          <List>
            {memberArr.map((mem, i) => {
              return (
                <MemberListItem
                  key={i}
                  member={mem}
                  vacant={mem.first_name ? false : true}
                />
              )
            })}
          </List>
        </Box>
        <Box display="flex" justifyContent="center" margin="10px">
          <ButtonRounded
            variant="outlined"
            color="primary"
            onClick={addPositionFunc}
            disabled={addPositionMutation?.isLoading}
          >
            {addPositionMutation?.isLoading ? (
              <Box width="90px">
                <CircularProgress color="primary" size={15} />
              </Box>
            ) : (
              "Add Position"
            )}
          </ButtonRounded>
        </Box>
      </Box>
    )
  }
}

MembersList.propTypes = {
  children: PropTypes.node,
}

export default MembersList
