import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  noDataImage: {
    width: "212px",
  },
  noDataTitle: {
    marginBottom: theme.spacing(2),
    opacity: "0.48",
  },
}))

export default styles
