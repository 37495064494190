import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import { deletePosition } from "actions/Clerk/board"
import ButtonRounded from "components/Common/ButtonRounded"
import ListItemWithContent from "components/Common/ListItemWithContent"
import AppContext from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext, useMemo } from "react"
import { useMutation, useQueryClient } from "react-query"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import TermRenewChip from "../TermRenewChip"
import useStyles from "./styles"

const MemberListItem = ({ member, noButton = false, vacant = false }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { openDialog, openSnackBar, isClerk, authUser } = useContext(AppContext)
  const deletePositionMutation = useMutation(deletePosition)
  const { updateApplicantStatusMutation, board } = useContext(BoardContext)
  useContext(BoardContext)

  //show expiration date in the member
  //checks if the expiration is in 90 days and if the board has a term with years
  const expiresInDays = useMemo(() => {
    let daysToExpire = dayjs(member.term_expiration).diff(dayjs(), "day")

    if (member?.term_expiration && daysToExpire <= 90 && daysToExpire >= 0) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="right"
          alignItems="inherit"
          style={{ marginRight: "16px", width: "125px" }}
        >
          <Typography color="primary" className={classes.expiresText}>
            Expires in {daysToExpire} days
          </Typography>
          {member?.renew_magic_link ? <TermRenewChip member={member} /> : ""}
        </Box>
      )
    } else if (
      member?.term_expiration &&
      daysToExpire < 0 &&
      (board?.term?.includes("year") || board?.term?.includes("other"))
    ) {
      return <Typography className={classes.expiredText}>Expired</Typography>
    } else {
      return ""
    }
  }, [member?.term_expiration, board?.term])

  //show expiration date when the member has expiration date and the board has term with years
  let memberExpirationDate = () => {
    if (board?.term?.includes("year")) {
      return dayjs(member?.term_expiration).isValid()
        ? dayjs(member?.term_expiration).format("MMMM DD YYYY")
        : "-"
    } else if (board?.term?.toLowerCase() === "other") {
      // check if a member has a valid expiration date to display in case their member term is 1-6 years
      // if not, show a "-"
      const expirationDate = dayjs(member?.term_expiration).isValid()
        ? dayjs(member?.term_expiration).format("MMMM DD YYYY")
        : "-"
      // display the expiration date if 1-6 year OR coterminous/no limit if those
      return member?.member_term_length?.includes("year")
        ? expirationDate
        : transformCaseAndSpaces(member?.member_term_length)
    } else if (!board?.term?.includes("year")) {
      return transformCaseAndSpaces(board?.term)
    } else {
      return ""
    }
  }

  const displayName = useMemo(() => {
    if (vacant) {
      return `Vacant Seat`
    } else {
      return `${member?.first_name} ${member?.last_name}`
    }
  }, [vacant, member?.first_name, member?.last_name])

  const displayEmail = useMemo(() => {
    if (!vacant) {
      return member?.email
    }
  }, [vacant, member?.email])

  //when wedisplay role and expiration date
  const displayRoleDate = useMemo(() => {
    //only display position if is vacant
    if (vacant) {
      return member?.position ? member?.position : null
    } else {
      if (member?.position && member?.term_expiration) {
        return member?.position + " • " + memberExpirationDate()
      } else if (
        member?.position &&
        !member?.term_expiration &&
        board?.term?.includes("year")
      ) {
        return member?.position
      } else {
        return member?.position
          ? member?.position + " • " + memberExpirationDate()
          : memberExpirationDate()
      }
    }
  }, [vacant, member?.position, member?.term_expiration, board?.term])

  const openEditMemberDialog = () => {
    const data = {
      currentMember: member,
      vacant: vacant,
      boardId: board?.id,
    }
    openDialog("editBoardMember", data)
  }

  const deletePositionFunc = () => {
    let data = {
      action: "delete this position?",
      confirmText: "Delete",
    }
    openDialog("clickConfirmation", data).then((res) => {
      let mutationData = {
        boardId: board?.id,
        positionId: member?.position_id,
      }
      deletePositionMutation
        ?.mutateAsync(mutationData)
        .then((res) => {
          //update get boards query
          queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldData) => {
              let newData = oldData.map((x) => {
                if (x.id === board?.id) {
                  let data = x
                  x.board_positions = x.board_positions.filter(
                    (x) => x.position_id !== member?.position_id
                  )
                  return data
                } else {
                  return x
                }
              })
              return newData
            }
          )
          let statusData = {
            boardId: member?.board_id,
            applicationId: member?.applicant_id,
            status_id: 3,
          }
          //if the member is an applicant change his status to in consideration
          if (member?.applicant_id) {
            updateApplicantStatusMutation
              .mutateAsync(statusData)
              .then((res) => {
                return queryClient.setQueryData(
                  ["boardApplicants", board?.id],
                  (oldState) => {
                    let newApplicants = oldState.map((x) => {
                      if (
                        x.board_application_id === member?.applicant_id ||
                        member?.board_application_id
                      ) {
                        let data = x
                        x.status_id = 3
                        x.status = "In Consideration"
                        return data
                      } else {
                        return x
                      }
                    })
                    return newApplicants
                  }
                )
              })
          }
        })

        .catch((err) => {
          openSnackBar({ message: "Error deliting position" })
        })
    })
  }

  return (
    <ListItemWithContent
      key={member?.id}
      avatar={member}
      ListItemTextProps={{
        disableTypography: true,
      }}
      primary={<Typography variant="body2">{displayName}</Typography>}
      secondary={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="body2"
            style={{
              fontSize: "1.2rem",
              fontWeight: 300,
            }}
          >
            {displayEmail}
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontSize: "1.2rem",
              fontWeight: 300,
            }}
          >
            {displayRoleDate}
          </Typography>
        </Box>
      }
    >
      <Box display="flex" flexShrink={0} alignItems="center">
        {expiresInDays}
        {!noButton && (
          <>
            <ButtonRounded
              color="primary"
              variant="outlined"
              size="small"
              onClick={openEditMemberDialog}
            >
              Edit
            </ButtonRounded>
            <IconButton
              style={{ marginLeft: "5px" }}
              onClick={deletePositionFunc}
              disabled={deletePositionMutation?.isLoading}
            >
              {deletePositionMutation?.isLoading ? (
                <Box width="25px">
                  <CircularProgress size={9} />
                </Box>
              ) : (
                <CancelIcon color="primary" />
              )}
            </IconButton>
          </>
        )}
      </Box>
    </ListItemWithContent>
  )
}

MemberListItem.propTypes = {
  member: PropTypes.object,
  applicants: PropTypes.array,
  vacant: PropTypes.bool,
  noButton: PropTypes.bool,
  newMember: PropTypes.object,
}

export default MemberListItem
