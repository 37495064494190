import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import StarIcon from "@material-ui/icons/Star"
import { getStatusList } from "actions/Clerk/applicants"
import noApplicants from "assets/no-applicants.svg"
import classnames from "clsx"
import ButtonRounded from "components/Common/ButtonRounded"
import ChipSquare from "components/Common/ChipSquare"
import ListItemWithContent from "components/Common/ListItemWithContent"
import NoData from "components/Common/NoData"
import AppContext from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import * as dayjs from "dayjs"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useContext, useState } from "react"
import { useQuery } from "react-query"

import useStyles from "./styles"

const ApplicantsList = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { openDialog } = useContext(AppContext)
  const {
    favoriteApplicantMutation,
    updateApplicantStatusMutation,
    getResumeMutation,
    shareApplicantsForReviewMutation,
  } = useContext(BoardContext)
  const { boardApplicants, getBoardApplicantByIdMutation, board } =
    useContext(BoardContext)
  const [selected, setSelected] = useState([])
  const [selectedStatus, setSelectedStatus] = useState({ label: "All", id: 0 })
  const [anchorEl, setAnchorEl] = useState(null)
  const [filteredApplicants, setFilteredApplicants] = useState([])
  const [previousApplicants, setPreviousApplicants] = useState([])

  // when checkbox clicked, add to array & apply conditional formatting
  const handleSelectedApplicant = (applicant) => {
    if (
      selected.find(
        (x) => x.board_application_id === applicant.board_application_id
      )
    ) {
      setSelected(
        selected.filter(
          (x) => x.board_application_id !== applicant.board_application_id
        )
      )
    } else {
      setSelected([...selected, applicant])
    }
  }

  // open applicant dialog on click of everything but the checkbox
  const handleApplicantDialog = (applicant) => {
    let dialogData = {
      selected: applicant,
      mutation: getBoardApplicantByIdMutation,
      favoriteMutation: favoriteApplicantMutation,
      statusMutation: updateApplicantStatusMutation,
      openPdfMutation: getResumeMutation,
      boardId: board.id,
      board: true,
    }
    openDialog("applicantDialog", dialogData)
    setSelectedStatus({ id: 0, label: "All" })
  }

  const handleAddApplicant = () => {
    openDialog("addApplicantDialog")
  }

  const handleShareDialog = () => {
    if (selected?.length) {
      let dialogData = {
        selected: selected,
        members: board?.board_positions.filter((x) => x.email),
        shareFn: shareApplicantsForReviewMutation,
        boardId: board?.id,
      }
      openDialog("shareForReview", dialogData)
    }
  }

  // singular or plural unit from date difference (e.g., weeks v. week)
  const singularOrPlural = (diff, unit) => {
    if (diff >= 1 && diff < 2) {
      return `${diff} ${unit} ago`
    }
    return `${diff} ${unit}s ago`
  }

  // calculate & display weeks ago, days ago, months ago
  // runs return through singular or plural if !today
  const dateDifference = (date) => {
    let difference
    if (dayjs().isAfter(dayjs(date), "day")) {
      //date yesterday or earlier
      if (dayjs().diff(date, "week") >= 1) {
        // 1 week plus ago
        if (dayjs().diff(date, "month") >= 1) {
          // 1 plus month ago
          difference = dayjs().diff(dayjs(date), "month")
          return singularOrPlural(difference, "month")
        }
        difference = dayjs().diff(dayjs(date), "week")
        return singularOrPlural(difference, "week")
      }
      difference = dayjs().diff(dayjs(date), "day")
      return singularOrPlural(difference, "day")
    } else {
      return "Today"
    }
  }

  const openStatus = (event) => {
    setAnchorEl(event.currentTarget)
  }
  //populate status dropdown
  const { data: statusOptions, isLoading: statusOptionsLoading } = useQuery(
    "status",
    getStatusList
  )
  useEffect(() => {
    if (selectedStatus.id) {
      setFilteredApplicants(
        boardApplicants.active_applications.filter(
          (x) => x.status_id === selectedStatus?.id
        )
      )
    } else {
      if (boardApplicants?.active_applications?.length) {
        setFilteredApplicants(boardApplicants.active_applications)
      }
    }
    if (boardApplicants?.previous_applications?.length) {
      setPreviousApplicants(boardApplicants.previous_applications)
    }
  }, [selectedStatus, boardApplicants])

  return (
    <Box display="flex" flexDirection="column">
      <Box
        marginBottom={1}
        marginTop={1}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        className={classes.buttonContainer}
      >
        <Box className={classes.buttonLeftContainer}>
          <ButtonRounded
            size="medium"
            variant="outlined"
            color="primary"
            disabled={!selected?.length}
            onClick={() => handleShareDialog()}
          >
            Share for Review
          </ButtonRounded>

          {/* <ButtonRounded
            variant="outlined"
            color="primary"
            className={classes.applicationButton}
            onClick={() => handleAddApplicant()}
          >
            <AddIcon color="primary" />
            Add Applicant
          </ButtonRounded> */}
        </Box>
        <ButtonRounded
          onClick={openStatus}
          aria-haspopup="true"
          variant="outlined"
          color="primary"
          aria-controls={"simple-menu2"}
        >
          {selectedStatus?.label} <ExpandMoreIcon />
        </ButtonRounded>

        <Menu
          id="simple-menu2"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
        >
          <MenuItem
            key={0}
            onClick={() => {
              setAnchorEl(null)
              setSelectedStatus({ label: "All", id: 0 })
            }}
          >
            All
          </MenuItem>

          {statusOptions?.map((s, i) => {
            return (
              <MenuItem
                key={i}
                onClick={() => {
                  setSelectedStatus({ label: s?.status, id: s?.id })
                  setAnchorEl(null)
                }}
              >
                {s.status}
              </MenuItem>
            )
          })}
        </Menu>
      </Box>
      <Box style={{ paddingTop: 2 }}>
        <Typography variant="h3">Current Applications</Typography>
      </Box>
      {filteredApplicants?.length > 0 ? (
        <>
          {filteredApplicants?.map((applicant, i) => (
            <Box
              key={i}
              className={classnames(classes.applicantContainer, {
                [classes.selected]: selected.find(
                  (x) =>
                    x.board_application_id === applicant.board_application_id
                ),
              })}
            >
              <Box className={classes.checkboxContainer}>
                <Checkbox
                  disableRipple
                  classes={{ root: classes.checkbox }}
                  onChange={() => handleSelectedApplicant(applicant)}
                />
              </Box>
              <Box className={classes.middleContainer}>
                <ListItemWithContent
                  disableGutters
                  avatar={applicant}
                  ListItemTextProps={{
                    disableTypography: true,
                  }}
                  primary={
                    <Typography variant="body2" className={classes.underline}>
                      {applicant?.first_name + " " + applicant?.last_name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" className={classes.underline}>
                      {applicant?.city}
                      {", "}
                      {applicant?.state}
                      {", "}
                      {applicant?.zip_code}
                    </Typography>
                  }
                  onClick={() => handleApplicantDialog(applicant)}
                />
                {!mobileView && (
                  <Box className={classes.dateContainer}>
                    <Typography variant="body1">
                      {dayjs(applicant?.submitted).format("MMMM DD, YYYY")}
                    </Typography>
                    <Typography style={{ fontSize: "10px", color: "#898989" }}>
                      {dateDifference(applicant?.submitted)}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.rightContainer}>
                <Box marginRight={1}>
                  <ChipSquare
                    status={{
                      id: applicant.status_id,
                      label: applicant.status,
                    }}
                  />
                </Box>
                <Box display="inline-block" align="center" width="20%">
                  {(applicant?.favorited === 1 ||
                    applicant?.favorited === true) && (
                    <StarIcon className={classes.starIcon} color="primary" />
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <NoData
          image={noApplicants}
          title={
            filteredApplicants?.length === 0
              ? "No current applications"
              : `No ${selectedStatus?.label} applicants`
          }
          subtitle=""
          marginTop="100px"
          props={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            borderRadius: "20px",
            marginTop: "20px",
          }}
        />
      )}
      <Box style={{ paddingTop: 4 }}>
        <Typography variant="h3" style={{ marginTop: 4 }}>
          Previous Applications
        </Typography>
      </Box>
      {previousApplicants.length > 0 ? (
        <>
          {previousApplicants.map((prevApp, i) => (
            <Box
              key={i}
              className={classnames(classes.applicantContainer, {
                [classes.selected]: selected.find(
                  (x) => x.board_application_id === prevApp.board_application_id
                ),
              })}
            >
              <Box className={classes.checkboxContainer}>
                <Checkbox
                  disableRipple
                  classes={{ root: classes.checkbox }}
                  onChange={() => handleSelectedApplicant(prevApp)}
                />
              </Box>
              <Box className={classes.middleContainer}>
                <ListItemWithContent
                  disableGutters
                  avatar={prevApp}
                  ListItemTextProps={{
                    disableTypography: true,
                  }}
                  primary={
                    <Typography variant="body2" className={classes.underline}>
                      {prevApp?.first_name + " " + prevApp?.last_name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1" className={classes.underline}>
                      {prevApp?.city}
                      {", "}
                      {prevApp?.state}
                      {", "}
                      {prevApp?.zip_code}
                    </Typography>
                  }
                  onClick={() => handleApplicantDialog(prevApp)}
                />
                {!mobileView && (
                  <Box className={classes.dateContainer}>
                    <Typography variant="body1">
                      {dayjs(prevApp?.submitted).format("MMMM DD, YYYY")}
                    </Typography>
                    <Typography style={{ fontSize: "10px", color: "#898989" }}>
                      {dateDifference(prevApp?.submitted)}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.rightContainer}>
                <Box marginRight={1}>
                  <ChipSquare
                    status={{
                      id: prevApp.status_id,
                      label: prevApp.status,
                    }}
                  />
                </Box>
                <Box display="inline-block" align="center" width="20%">
                  {(prevApp?.favorited === 1 ||
                    prevApp?.favorited === true) && (
                    <StarIcon className={classes.starIcon} color="primary" />
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </>
      ) : (
        <NoData
          image={noApplicants}
          title={
            previousApplicants.length === 0
              ? "No previous applications"
              : `No ${selectedStatus?.label} applicants`
          }
          subtitle=""
          marginTop="100px"
          props={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            borderRadius: "20px",
            marginTop: "20px",
          }}
        />
      )}
    </Box>
  )
}

ApplicantsList.propTypes = {
  children: PropTypes.node,
}

export default ApplicantsList
