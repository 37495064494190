import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import { deleteReviewer, getFile } from "actions/Clerk/entities"
import AppContext from "contexts/App"
import { BoardsContainer } from "contexts/Boards"
import { BoardContainer } from "contexts/Clerk/Board"
import EntityContext from "contexts/Clerk/Entity"
import dayjs from "dayjs"
import UpdateBoardFromEntity from "dialogs/UpdateBoardFromEntity"
import MaterialTable from "material-table"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"

import useStyles from "./styles"

const EntityGrid = ({ title, data, loading = false, entityId = null }) => {
  const classes = useStyles()
  const { openDialog, openSnackBar } = useContext(AppContext)
  const { entityData } = useContext(EntityContext)
  const getFileMutation = useMutation(getFile)
  const deleteReviewerMutation = useMutation(deleteReviewer)
  const rowData = data
  const queryClient = useQueryClient()

  const openAction = (rowData) => {
    if (title === "Files") {
      getFileMutation
        ?.mutateAsync(rowData?.file_id)
        .then((res) => {
          window.location.href = res?.url
        })
        .catch((err) => {
          openSnackBar({ message: "Error downloading file" })
        })
    } else if (title === "Boards") {
      let data = { board: rowData }
      openDialog("updateBoardFromEntity", data)
    } else {
      let confirmationData = {
        action: "delete this reviewer?",
        confirmText: "Delete",
      }
      openDialog("clickConfirmation", confirmationData).then((res) => {
        let data = { entityId: rowData?.entity_id, reviewerId: rowData?.id }
        deleteReviewerMutation
          ?.mutateAsync(data)
          .then((res) => {
            //update reviewers query
            queryClient.setQueryData(["reviewersData", entityId], (oldData) => {
              return [...oldData.filter((x) => x.id !== rowData?.id)]
            })
            openSnackBar({ message: "Reviewer deleted" })
          })
          .catch((err) => {
            console.log(err)
            openSnackBar({ message: "Error deleting reviewer" })
          })
      })
    }
  }

  const openCreate = () => {
    if (title === "Boards") {
      let data = {
        from: "entity",
        entity: {
          entityId: entityId,
          entity: entityData?.entity,
          entity_type: entityData?.entity_type,
          state: entityData?.state,
        },
      }
      openDialog("addBoard", data)
    } else {
      let data = { from: "entity", entityId: entityId }
      openDialog("createNewReviewer", data)
    }
  }

  const boardsFilesColumns = [
    {
      title: "",
      field: "name",
      cellStyle: {
        width: "90%",
      },
      headerStyle: { padding: 0 },
      render: function rowName(rowData) {
        return (
          <>
            <Typography variant="body1" style={{ fontSize: "1.4rem" }}>
              {title === "Files" ? rowData?.file_id : rowData?.name}
            </Typography>
            {title === "Files" && (
              <Typography variant="body1" style={{ color: "#898989" }}>
                Uploaded by {rowData?.name} on{" "}
                {dayjs(rowData?.created).format("MMMM DD YYYY")}
              </Typography>
            )}
          </>
        )
      },
    },
    {
      title: "",
      field: "",
      cellStyle: {
        width: "10%",
      },
      headerStyle: { padding: 0 },
      render: function rowButton(rowData) {
        return (
          <Button color="primary" onClick={() => openAction(rowData)}>
            <Typography variant="body2">
              {title === "Files" ? "Download" : "Edit"}
            </Typography>
          </Button>
        )
      },
    },
  ]

  const reviewersColumns = [
    {
      title: "Last Name",
      field: "last_name",
      cellStyle: {
        width: "20%",
      },
      headerStyle: { maxHeight: "0%" },
    },
    {
      title: "First Name",
      field: "first_name",
      cellStyle: {
        width: "20%",
      },
      headerStyle: {},
    },
    {
      title: "Email ",
      field: "email",
      cellStyle: {
        width: "20%",
      },
      headerStyle: {},
    },
    {
      title: "Role / District ",
      field: "role_district",
      cellStyle: {
        width: "20%",
      },
      headerStyle: {},
    },
    {
      title: "",
      field: "",
      headerStyle: {},
      cellStyle: {
        width: "20%",
      },
      render: function rowButton(rowData) {
        return (
          <IconButton
            onClick={() => openAction(rowData)}
            disabled={deleteReviewerMutation?.idLoading}
          >
            {deleteReviewerMutation?.isLoading ? (
              <CircularProgress size={15} />
            ) : (
              <DeleteIcon color="primary" />
            )}
          </IconButton>
        )
      },
    },
  ]

  const whatColor = () => {
    if (title === "Boards") {
      return "#A01F62"
    } else if (title === "Files") {
      return "#898989"
    } else {
      return "#412A5D"
    }
  }

  return (
    <BoardsContainer>
      <BoardContainer boardId={rowData?.id}>
        <div>
          <MaterialTable
            isLoading={loading}
            columns={
              title !== "Default Review Members"
                ? boardsFilesColumns
                : reviewersColumns
            }
            data={data}
            title=""
            options={{
              showTitle: false,
              toolbar: true,
              search: false,
              paging: false,
              sorting: false,
              maxBodyHeight: "300px",
              rowStyle: (rowData, i) => ({
                backgroundColor: i % 2 !== 0 ? "#fff" : "#F8F8F8",
                maxHeight: "25px",
                height: "25px",
                padding: "5px 10px",
                borderBottom: "0",
              }),
              headerStyle: {},
            }}
            components={{
              Toolbar: function rowButton(rowData) {
                return (
                  <Box
                    style={{
                      backgroundColor: whatColor(),
                      maxHeight: "47.5px",
                      height: "47.5px",
                      padding: "5px 10px",
                      borderBottom: "0",
                    }}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1" style={{ color: "white" }}>
                      {title}
                    </Typography>
                    {title !== "Files" && (
                      <Button
                        onClick={() => openCreate()}
                        style={{ color: "#fff" }}
                        startIcon={<AddIcon />}
                      >
                        Add New {title === "Boards" ? "Board" : "Reviewer"}
                      </Button>
                    )}
                  </Box>
                )
              },
            }}
            classes={{ root: classes.root }}
          />
        </div>
        <UpdateBoardFromEntity />
      </BoardContainer>
    </BoardsContainer>
  )
}

EntityGrid.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  entityId: PropTypes.number,
}

export default EntityGrid
