import LoaderButton from "@bit/c_t.components.loader-button"
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core"
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"
import { getPronouns } from "actions/authentication"
import { citizenSignUp } from "actions/Citizen/users"
import AppContext from "contexts/App"
import App from "contexts/App"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import { Field, Form, Formik } from "formik"
import { TextField as TextFieldFormik } from "formik-material-ui"
import PropTypes from "prop-types"
import { useRef } from "react"
import { useContext, useState } from "react"
import InputMask from "react-input-mask"
import { useMutation, useQuery } from "react-query"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"

import useStyles from "./styles"

const SignupForm = ({ loginClick }) => {
  const classes = useStyles()

  const authContext = useContext(App)
  const formRef = useRef()
  const { closeDialog, isClerk } = useContext(AppContext)
  const { checkEmailRegisterMutation, checkRegisterPhoneMutation } =
    useContext(CitizenAppContext)
  const history = useHistory()
  const citizenSignUpMutation = useMutation(citizenSignUp)
  const [visibility, setVisibility] = useState(true)
  const [visibility2, setVisibility2] = useState(true)

  const { data: pronounsList, isLoading: pronounsListLoading } = useQuery(
    "pronouns",
    () => getPronouns(isClerk)
  )
  const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/

  const loginSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .matches(phoneRegex, "Phone number is not valid")
      .min(14, "Phone number is not valid")
      .max(14, "Phone number is not valid"),
    zip: Yup.number().required("Required").nullable(true),
    email: Yup.string()
      .email("Please enter a valid email")
      .required(`Required`),
    pronoun_id: Yup.number().nullable(true),
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[\d])(?=.*[!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,}$/,
        "Requirements: 8 characters, 1 Upper Case & 1 special character"
      ),
    confirmed_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required(`Required`),
  })

  const onSubmit = (values, actions) => {
    let cleanPhone = values?.phone?.replace(/[^0-9]/g, "")
    let emailPromise = new Promise((resolve, reject) => {
      checkEmailRegisterMutation
        .mutateAsync({ email: formRef?.current.values.email })
        .then((res) => {
          if (res.email_found === true) {
            formRef?.current?.setErrors({
              ...formRef.current.errors,
              email: "Email is already in use",
            })
            reject()
          } else {
            resolve()
          }
        })
    })
    let phonePromise = new Promise((resolve, reject) => {
      checkRegisterPhoneMutation
        .mutateAsync({
          phone: cleanPhone,
        })
        .then((res) => {
          if (res.phone_found === true) {
            formRef?.current?.setErrors({
              ...formRef.current.errors,
              phone: "Phone number is already in use",
            })
            reject()
          } else {
            resolve()
          }
        })
    })
    return Promise.all([emailPromise, phonePromise])
      .then((val) => {
        formRef?.current?.setErrors({})
        //if the user didnt select a pronoun send null to the api
        if (values?.pronoun_id === "") {
          values.pronoun_id = null
        }
        let data = { ...values, phone: cleanPhone }
        citizenSignUpMutation.mutate(data, {
          onSuccess: (res) => {
            authContext.setAuthData({ token: res.token, account: res.user })
            authContext.openSnackBar({
              message: "Signed up successfully",
            })
            closeDialog("login")
            setTimeout(() => {
              history.push("/my-account")
            }, 300)
          },
          onError: (err) => {
            console.error(err)
            authContext.openSnackBar({
              message: "There was an error during sign up.",
            })
          },
          onSettled: () => {
            actions.setSubmitting(false)
          },
        })
      })
      .catch((err) => {
        console.log(err)
        // openSnackBar({ message: "Error checking email and phone" })
      })
  }

  return (
    <>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          zip: "",
          pronoun_id: "",
          email: "",
          password: "",
          confirmed_password: "",
          phone: "",
        }}
        onSubmit={onSubmit}
        validationSchema={loginSchema}
        innerRef={formRef}
      >
        {({
          isSubmitting,
          handleSubmit,
          values,
          setFieldValue,
          handleChange,
          handleBlur,
        }) =>
          pronounsListLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={15} />
            </Box>
          ) : (
            <div className={classes.inputContainer}>
              <Form onSubmit={handleSubmit}>
                <Field
                  data-cy="register-first-name"
                  component={TextFieldFormik}
                  name="first_name"
                  placeholder="First Name"
                  label="First Name"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
                <Field
                  data-cy="register-last-name"
                  component={TextFieldFormik}
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
                <InputMask
                  mask="(999) 999-9999"
                  maskChar="_"
                  onChange={(e) => setFieldValue("phone", e.target.value)}
                  value={values?.phone}
                  onBlur={handleBlur}
                >
                  {() => (
                    <Field
                      component={TextFieldFormik}
                      data-cy="register-phone"
                      name="phone"
                      placeholder="Phone"
                      label="Phone"
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                    />
                  )}
                </InputMask>
                <Field
                  component={TextFieldFormik}
                  name="zip"
                  placeholder="Zipcode"
                  data-cy="register-zip"
                  label="Zipcode"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
                <Field
                  component={TextFieldFormik}
                  data-cy="register-email"
                  name="email"
                  placeholder="Email"
                  label="Email"
                  type="text"
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
                <TextField
                  data-cy="register-pronouns"
                  label="Pronouns"
                  select
                  name="pronoun_id"
                  value={values?.pronoun_id}
                  onChange={(e) => setFieldValue("pronoun_id", e.target.value)}
                  variant="outlined"
                  fullWidth
                  style={{
                    backgroundColor: "#fff",
                  }}
                  className={classes.textField}
                >
                  {pronounsList?.data?.pronouns?.map((p) => {
                    return (
                      <MenuItem key={p?.id} value={p?.id}>
                        {p?.pronoun}
                      </MenuItem>
                    )
                  })}
                </TextField>
                <Field
                  component={TextFieldFormik}
                  data-cy="register-password"
                  type={visibility ? "password" : "text"}
                  name="password"
                  placeholder="Password"
                  label="Password"
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  fullWidth
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setVisibility(!visibility)}
                        >
                          {visibility ? (
                            <VisibilityOutlined
                              color="primary"
                              fontSize="small"
                            />
                          ) : (
                            <VisibilityOffOutlined
                              color="primary"
                              fontSize="small"
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  component={TextFieldFormik}
                  data-cy="register-confirm-password"
                  type={visibility2 ? "password" : "text"}
                  name="confirmed_password"
                  placeholder="Confirm New Password"
                  label="Confirm New Password"
                  className={classes.textField}
                  variant="outlined"
                  size="small"
                  fullWidth
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          onClick={() => setVisibility2(!visibility2)}
                        >
                          {visibility2 ? (
                            <VisibilityOutlined
                              color="primary"
                              fontSize="small"
                            />
                          ) : (
                            <VisibilityOffOutlined
                              color="primary"
                              fontSize="small"
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <LoaderButton
                  type="submit"
                  classoverrides={{
                    wrapper: classes.button,
                    button: {
                      root: classes.buttonRoot,
                    },
                  }}
                  variant="contained"
                  color="primary"
                  working={isSubmitting}
                  fullWidth
                  data-cy="register-create-account"
                >
                  Create account
                </LoaderButton>
              </Form>
            </div>
          )
        }
      </Formik>
      {/* <Box className={classes.orContainer}>
        <Typography>OR</Typography>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.googleButton}
        startIcon={
          <Box
            bgcolor="white"
            height="15px"
            width="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
          >
            <GoogleLogo
              style={{ height: 11.5, position: "relative", top: -0.5 }}
            />
          </Box>
        }
      >
        Sign up with google
      </Button> */}
      <Box
        display="flex"
        justifyContent="center"
        marginTop="30px"
        marginBottom={2}
      >
        <Button
          size="small"
          onClick={loginClick}
          disableRipple
          className={classes.signupButton}
        >
          Already have an account? Login Here
        </Button>
      </Box>
    </>
  )
}

SignupForm.propTypes = {
  loginClick: PropTypes.func,
}

export default SignupForm
