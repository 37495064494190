import { applyBoard } from "actions/Citizen/board"
import { getBoardsById } from "actions/Citizen/board"
import PropTypes from "prop-types"
import { createContext } from "react"
import { useMutation, useQuery } from "react-query"

const BoardContext = createContext()
export default BoardContext

export const BoardContainer = ({ children, ...props }) => {
  const boardId = parseInt(props?.boardId || "0")

  const {
    data: board,
    isLoading: boardLoading,
    refetch: refetchBoard,
  } = useQuery(["board", boardId], () => getBoardsById(boardId))
  const applyBoardMutation = useMutation((data) => applyBoard(boardId, data))

  return (
    <BoardContext.Provider
      value={{
        board,
        boardLoading,
        refetchBoard,
        applyBoardMutation,
      }}
    >
      {children}
    </BoardContext.Provider>
  )
}

BoardContainer.propTypes = {
  children: PropTypes.node,
  boardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
