import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import ApplicantsContext from "contexts/Clerk/Applicants"
import MaterialTable from "material-table"
import Page from "pages/Page"
import { useContext, useEffect, useState } from "react"
import AutoSizer from "react-virtualized-auto-sizer"

import useStyles from "./styles"

const List = () => {
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles()

  const [displayedApplicants, setDisplayedApplicants] = useState([])

  const { openDialog, searchValue } = useContext(AppContext)
  const {
    applicantsData,
    applicantsDataLoading,
    getApplicantApplicationsMutation,
    getResumeMutation,
  } = useContext(ApplicantsContext)

  // handle search
  useEffect(() => {
    if (!applicantsDataLoading && applicantsData) {
      let newArray = [...applicantsData]
      // if a search val is applied
      if (searchValue?.length > 0) {
        newArray = newArray?.filter(
          (x) =>
            x.first_name.toLowerCase().includes(searchValue.toLowerCase()) ||
            x.last_name.toLowerCase().includes(searchValue.toLowerCase())
        )
        setDisplayedApplicants(newArray)
      } else {
        setDisplayedApplicants(newArray)
      }
    }
  }, [searchValue, applicantsData])

  const headCells = [
    {
      title: "First Name",
      field: "first_name",
      render: function firstNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.first_name}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: mobileView ? "40%" : "20%",
        maxWidth: mobileView ? "40%" : "20%",
        padding: "0px 30px",
      },
      cellStyle: {
        width: mobileView ? "40%" : "20%",
        maxWidth: mobileView ? "40%" : "20%",
        padding: "0px 30px",
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      render: function lastNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.last_name}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: mobileView ? "40%" : "20%",
        maxWidth: mobileView ? "40%" : "20%",
        padding: "0px 10px",
      },
      cellStyle: {
        width: mobileView ? "40%" : "20%",
        maxWidth: mobileView ? "40%" : "20%",
        padding: "0px 10px",
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "40%",
        maxWidth: "40%",
        height: "25px",
        padding: "0px 10px",
        display: mobileView && "none",
      },
      cellStyle: {
        width: "40%",
        maxWidth: "40%",
        padding: "0px 10px",
        display: mobileView && "none",
      },
      render: function emailText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={rowData?.email}
              desktopSize={35}
              phoneSize={20}
            />
          </Box>
        )
      },
    },

    {
      title: "",
      field: "",
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
      },
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
      },
      sorting: false,
      render: function ViewDetailsButton(rowData) {
        return (
          <Box display="flex" justifyContent="flex-end" marginRight="20px">
            <ButtonRounded
              key={rowData.id}
              variant="contained"
              color="primary"
              onClick={() => {
                let data = {
                  selected: rowData,
                  getDataMutation: getApplicantApplicationsMutation,
                  openMutation: getResumeMutation,
                }
                openDialog("applicantDetails", data)
              }}
            >
              <Typography variant="body2">
                {mobileView ? "View" : "View Details"}
              </Typography>
            </ButtonRounded>
          </Box>
        )
      },
    },
  ]

  return (
    <Page>
      <Box className={classes.rigthContainer} height="100%">
        <Typography variant="h2" className={classes.title}>
          Applicants
        </Typography>
        <AutoSizer style={{ width: "100%", height: "100%" }}>
          {({ width, height }) => {
            return (
              <>
                <MaterialTable
                  // tableRef={tableRef}
                  isLoading={applicantsDataLoading}
                  // loadingType="linear"
                  options={{
                    toolbar: false,
                    title: "",
                    headerStyle: {
                      backgroundColor: "#fff",
                      position: "sticky",
                      top: 0,
                      whiteSpace: "nowrap",
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "40px",
                      color: "#A01F62",
                      borderTop: "0px",
                    },

                    maxBodyHeight: height - 55,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100],
                    rowStyle: (rowData, i) => ({
                      backgroundColor: i % 2 !== 0 ? "#fff" : "#F8F8F8",
                      maxHeight: "70px",
                      height: "40px",
                    }),
                  }}
                  classses={{ root: classes.root }}
                  columns={headCells}
                  data={displayedApplicants}
                />
              </>
            )
          }}
        </AutoSizer>
      </Box>
    </Page>
  )
}

export default List
