import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  areasContainer: {
    minHeight: "100px",
    //   maxHeight: "200px",
    overflow: "auto",
  },
}))

export default styles
