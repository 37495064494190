import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  multiline: {
    padding: 0,
    margin: 0,
  },
  primary: {
    marginBottom: theme.spacing(0.5),
    padding: 0,
  },
}))

export default styles
