import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  uploadDialog: {
    "& .MuiDialog-paperWidthSm": {
      width: "auto",
      maxWidth: "unset",
    },
  },
  paper: {
    minWidth: "468px",
    minHeight: "500px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  uploadFileContainer: {
    backgroundColor: "#E2E7EF",
    width: "425px",
    height: "424px",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  uploadFileSubcontainer: {
    width: "410px",
    height: "100px",
    border: "1px #BCBFC3 dashed",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },

  cropContainer: {
    position: "relative",
    height: "400px",
    width: "400px",
  },
  controlsContainer: {
    position: "absolute",
    bottom: -70,
    left: "50%",
    width: "200px",
    transform: "translateX(-50%)",
    height: "80px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      bottom: -50,
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonRoot: {
    borderRadius: "99em",
  },
}))

export default styles
