import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    minWidth: "480px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  texfield: {
    marginTop: theme.spacing(4),
  },
  uploadFileContainer: {
    backgroundColor: "#E2E7EF",
    width: "425px",
    height: "124px",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
  },
  uploadFileSubcontainer: {
    width: "410px",
    height: "100px",
    border: "1px #BCBFC3 dashed",
    borderRadius: "5px",
  },
  uploadIcon: {
    marginBottom: theme.spacing(1),
  },
  textfield: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  errorText: {
    color: "#f44336",
  },
}))

export default styles
