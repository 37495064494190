import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    responseText: {
      color: theme.palette.secondary.dark,
      fontWeight: 600,
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `5px solid ${theme.palette.primary.dark}`,
    },
    nameText: {
      fontSize: "1.8rem",
    },
  }),
  {
    name: "RevieweesList",
  }
)

export default styles
