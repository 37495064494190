import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  subtitle: {
    fontSize: "1.8rem",
    marginBottom: theme.spacing(2),
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  entityTypeText: {
    textTransform: "capitalize",
  },
}))

export default styles
