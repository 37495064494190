import DateFnsUtils from "@date-io/date-fns"
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core"
import CancelIcon from "@material-ui/icons/Cancel"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import { Form, Formik } from "formik"
import PropTypes from "prop-types"
import { useContext } from "react"
import InputMask from "react-input-mask"
import { useQueryClient } from "react-query"
import formatPhoneNumber from "utils/formatPhone"
import normalizeString from "utils/normalizeString"
import usStatesList from "utils/UsStateList"
import * as Yup from "yup"

import useStyles from "./styles"

//update create member is the form that appears if we click
//the edit button in member edit card

const UpdateCreateMember = ({
  updateMutation,
  setEditing,
  member,
  addMutation,
  boardId,
  setMember,
  setVacant,
}) => {
  const classes = useStyles()
  const { openSnackBar, authUser, isClerk } = useContext(AppContext)
  const { board } = useContext(BoardContext)

  /* eslint-disable */

  // this is injected into the schema based on board term
  const memberTermSchema =
    board?.term?.toLowerCase() === "other"
      ? // if a boardterm is other,require the member term length field;
        // then if member term length field is 1-6 years, require the expirationdate.
        {
          member_term_length: Yup.string().nullable(true).required("Required"),
          term_expiration: Yup.date()
            .nullable(true)
            .when("member_term_length", {
              is: (value) => value?.includes("year"),
              then: Yup.date().nullable(true).required("Required"),
            }),
        }
      : // but if board term is 1-6 year, makes member term length not req'd
      // while it does require a term expiration
      board?.term?.includes("year")
      ? {
          member_term_length: Yup.string().nullable(true),
          term_expiration: Yup.date().nullable(true).required("Required"),
        }
      : {
          // finally, no requirement for either board term of Cotermionus or No Limit
          member_term_length: Yup.string().nullable(true),
          term_expiration: Yup.date().nullable(true),
        }

  /* eslint-enable */
  const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
  const memberSchema = Yup.object().shape({
    member_id: Yup.number().nullable(true),
    board_position: Yup.number().nullable(true),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Please enter a valid email"),
    // .required(`Required`),
    phone: Yup.string()
      .matches(phoneRegex, "Phone number not valid")
      .min(14, "Phone number is not valid")
      .max(14, "Phone number is not valid"),
    position: Yup.string(),
    // injects conditional schema for term expiration & member term length
    ...memberTermSchema,
    terms_served: Yup.number().min(0, "Mininum 0 ").integer(),
    race: Yup.string(),
    gender: Yup.string(),
    dob: Yup.date().nullable(true),
    active: Yup.bool(),
    address: Yup.string(),
    zip_code: Yup.string()
      .notRequired()
      .nullable()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    state: Yup.string(),
    city: Yup.string(),
  })

  const queryClient = useQueryClient()
  const closeEditMember = () => {
    setEditing(false)
  }

  //update member
  const onSubmit = (values, actions) => {
    let data = values

    // set zip_code to null if not entered
    if (data.zip_code === "") {
      data.zip_code = null
    }
    //if it doesnt have an id we are creating a member
    if (!data?.member_id) {
      //create member if it doesnt have id
      addMutation
        .mutateAsync(data)
        .then((res) => {
          setMember(res.board_member)
          setVacant(false)
          setEditing(false)
          openSnackBar({ message: "Member Created" })

          //update get boards query
          queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldData) => {
              let newData = oldData.map((x) => {
                if (x.id === boardId) {
                  let data = x
                  x.board_positions = x.board_positions.map((x) => {
                    if (
                      (x.board_position_id || x.position_id) ===
                      values.board_position
                    ) {
                      return res.board_member
                    } else {
                      return x
                    }
                  })
                  return data
                } else {
                  return x
                }
              })
              return newData
            }
          )
        })
        .catch((err) => {
          openSnackBar({ message: "Error creating member" })
        })
      //if it has an id we are updating a member
    } else {
      updateMutation
        ?.mutateAsync(data)
        .then((res) => {
          setMember(res.board_member)
          setVacant(false)
          setEditing(false)
          openSnackBar({ message: "Member updated" })
          //update get boards query
          queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldData) => {
              let newData = oldData.map((x) => {
                if (x.id === boardId) {
                  let data = x
                  x.board_positions = x.board_positions.map((x) => {
                    if (
                      (x.board_position_id || x.position_id) ===
                      values.board_position
                    ) {
                      return res.board_member
                    } else {
                      return x
                    }
                  })
                  return data
                } else {
                  return x
                }
              })
              return newData
            }
          )
        })
        .catch((err) => {
          openSnackBar({ message: "Error editing member" })
        })
    }
  }

  if (member?.phone) {
    if (member?.phone?.includes("+")) {
      member.phone = member?.phone?.slice(2, member.phone.length)
    }
  }

  return (
    <Card className={classes.container}>
      <IconButton onClick={closeEditMember} className={classes.closeEditButton}>
        <CancelIcon />
      </IconButton>
      <Formik
        initialValues={{
          member_id: member?.id || member?.member_id || "",
          board_position: member?.position_id || member?.board_position_id,
          first_name: member?.first_name || "",
          last_name: member?.last_name || "",
          member_term_length: member?.member_term_length || "",
          email: member?.email || "",
          phone: formatPhoneNumber(member?.phone) || "",
          term_expiration: member?.term_expiration
            ? member?.term_expiration
            : null,
          terms_served: member?.terms_served || "",
          race: normalizeString(member?.race) || "",
          gender: normalizeString(member?.gender) || "",
          dob: member?.dob ? member?.dob : null,
          active: true,
          address: member?.address || "",
          zip_code: member?.zip_code || "",
          state: member?.state || "",
          city: member?.city || "",
        }}
        validationSchema={memberSchema}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          setErrors,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex">
              <TextField
                type="text"
                name="first_name"
                label="First Name"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.first_name}
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginRight: "5px",
                }}
                variant="outlined"
                fullWidth
                helperText={touched.first_name && errors.first_name}
                error={Boolean(touched.first_name && errors.first_name)}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
              <TextField
                type="text"
                name="last_name"
                label="Last Name"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.last_name}
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginLeft: "5px",
                }}
                variant="outlined"
                fullWidth
                helperText={touched.last_name && errors.last_name}
                error={Boolean(touched.last_name && errors.last_name)}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              />
            </Box>
            <TextField
              type="text"
              name="email"
              label="Email"
              id="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.email}
              style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
              variant="outlined"
              fullWidth
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
            />
            <InputMask
              id="phone-mask"
              mask="(999) 999-9999"
              maskChar="_"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {() => (
                <TextField
                  type="text"
                  name="phone"
                  label="Phone"
                  data-cy="member-phone"
                  id="phone"
                  helperText={touched.phone && errors.phone}
                  style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                  }}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  error={Boolean(touched.phone && errors.phone)}
                />
              )}
            </InputMask>
            <Box display="flex">
              <TextField
                type="text"
                name="address"
                label="Address"
                value={values?.address}
                id="address"
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                fullWidth
                style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
                helperText={touched.address && errors.address}
                error={Boolean(touched.address && errors.address)}
              />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <TextField
                type="text"
                name="city"
                label="City"
                id="name"
                value={values?.city}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                width="45%"
                style={{ backgroundColor: "#F8F8F8", marginBottom: "25px" }}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
                helperText={touched.city && errors.city}
                error={Boolean(touched.city && errors.city)}
              />
              <TextField
                type="number"
                name="zip_code"
                label="Zip Code"
                value={values.zip_code}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                width="35%"
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginLeft: 4,
                }}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
                helperText={touched.zip_code && errors.zip_code}
                error={Boolean(touched.zip_code && errors.zip_code)}
              />
              <TextField
                select
                name="state"
                label="ST"
                value={values?.state}
                onChange={handleChange}
                onBlur={handleBlur}
                variant="outlined"
                width="35%"
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginLeft: 8,
                }}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                }}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
                helperText={touched.state && errors.state}
                error={Boolean(touched.state && errors.state)}
              >
                {usStatesList.map((state, idx) => (
                  <MenuItem key={idx} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box display="flex">
              <Box marginBottom="25px" marginRight="5px" width="50%">
                <TextField
                  select
                  id="member_term_length"
                  name="member_term_length"
                  label="Member Term Length"
                  value={values?.member_term_length}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  fullWidth
                  helperText={
                    touched.member_term_length && errors.member_term_length
                  }
                  error={Boolean(
                    touched.member_term_length && errors.member_term_length
                  )}
                  style={{
                    backgroundColor: "#F8F8F8",
                  }}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  disabled={board?.term?.toLowerCase() !== "other"} // only enabled if other is the selected Board.term
                >
                  <MenuItem value={"1 year"}>1 year</MenuItem>
                  <MenuItem value={"2 years"}>2 years</MenuItem>
                  <MenuItem value={"3 years"}>3 years</MenuItem>
                  <MenuItem value={"4 years"}>4 years</MenuItem>
                  <MenuItem value={"5 years"}>5 years</MenuItem>
                  <MenuItem value={"6 years"}>6 years</MenuItem>
                  <MenuItem value={"coterminous"}>Coterminous</MenuItem>
                  <MenuItem value={"no term limit"}>No term limit</MenuItem>
                </TextField>
              </Box>
              <Box width="50%" marginLeft="5px" marginBottom="25px">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    classes={
                      errors?.term_expiration && touched?.term_expiration
                        ? { root: classes.datePickerError }
                        : { root: classes.datePicker }
                    }
                    id="date-picker-exp"
                    label="Term Expiration"
                    style={{
                      width: "100%",
                    }}
                    value={values?.term_expiration}
                    openTo="year"
                    views={["year", "month", "date"]}
                    onChange={(e) => {
                      setFieldValue("term_expiration", e)
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    helperText={
                      touched.term_expiration && errors.term_expiration
                    }
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    disabled={
                      !board?.term?.includes("year") &&
                      board?.term?.toLowerCase() !== "other"
                    } //disabled if anything other than 1-6 years is selected
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <Box display="flex">
              <TextField
                type="number"
                name="terms_served"
                label="Terms Served"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  handleChange(e)
                }}
                onBlur={handleBlur}
                helperText={touched.terms_served && errors.terms_served}
                value={values.terms_served}
                error={Boolean(touched.terms_served && errors.terms_served)}
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginRight: "5px",
                  width: "50%",
                }}
                InputProps={{
                  classes: {
                    root: classes.inputBackgroundColor,
                  },
                  inputProps: {
                    min: 0,
                    // max:
                    //   board?.term === "coterminous" || "no-term-limit"
                    //     ? 1
                    //     : null,
                  },
                }}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
                disabled={
                  !board?.term?.includes("year") &&
                  board?.term?.toLowerCase() !== "other"
                } //disabled if anything other than 1-6 years is selected
              />
              <TextField
                select
                name="gender"
                label="Gender"
                id="name"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.gender}
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginLeft: "5px",
                  width: "50%",
                }}
                // fullWidth
                helperText={touched.gender && errors.gender}
                error={Boolean(touched.gender && errors.gender)}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"non binary"}>Non binary</MenuItem>
                <MenuItem value={"prefer not to say"}>
                  Prefer not to say
                </MenuItem>
              </TextField>
            </Box>
            <Box display="flex">
              <TextField
                select
                name="race"
                label="Race"
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.race}
                style={{
                  backgroundColor: "#F8F8F8",
                  marginBottom: "25px",
                  marginRight: "5px",
                  width: "50%",
                }}
                variant="outlined"
                helperText={touched.race && errors.race}
                error={touched.race && errors.race}
                FormHelperTextProps={{
                  className: classes.formHelperText,
                }}
              >
                <MenuItem value={"asian/pacific islander"}>
                  Asian/Pacific Islander
                </MenuItem>
                <MenuItem value={"black/african american"}>
                  Black/African American
                </MenuItem>
                <MenuItem value={"hispanic/latin"}>Hispanic/Latin</MenuItem>
                <MenuItem value={"native american"}>Native American</MenuItem>
                <MenuItem value={"white"}>White</MenuItem>
                <MenuItem value={"two or more races"}>
                  Two or more races
                </MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
                <MenuItem value={"prefer not to say"}>
                  Prefer not to say
                </MenuItem>
              </TextField>{" "}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  style={{ width: "50%", marginLeft: "5px" }}
                  id="date-picker-dob"
                  label="DOB"
                  value={values?.dob}
                  openTo="year"
                  views={["year", "month", "date"]}
                  classes={{ root: classes.datePicker }}
                  onChange={(e) => {
                    setFieldValue("dob", e)
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
            <Box display="flex" justifyContent="center">
              <ButtonRounded
                variant="contained"
                color="primary"
                type="submit"
                disabled={updateMutation?.isLoading || addMutation?.isLoading}
              >
                {updateMutation?.isLoading || addMutation?.isLoading ? (
                  <CircularProgress style={{ color: "#fff" }} size={20} />
                ) : (
                  "Save"
                )}
              </ButtonRounded>
            </Box>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

UpdateCreateMember.propTypes = {
  updateMutation: PropTypes.object,
  addMutation: PropTypes.object,
  setEditing: PropTypes.func,
  setMember: PropTypes.func,
  setVacant: PropTypes.func,
  member: PropTypes.object,
  vacant: PropTypes.bool,
  boardId: PropTypes.number,
}

export default UpdateCreateMember
