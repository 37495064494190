import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      height: "100%",
      paddingRight: "5px",
      paddingLeft: "5px",
    },
  }),
  {
    name: "MessagesList",
  }
)

export default styles
