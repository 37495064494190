import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 2,
    },

    appliedChip: {
      backgroundColor: "#FFCCA7",
      color: "#F88938",
    },
    viewedChip: {
      backgroundColor: "#33A3B139",
      color: "#33A3B1",
    },
    appointedChip: {
      backgroundColor: "#B5E5B9",
      color: "#23B12E",
    },
    deferredChip: {
      backgroundColor: "#D6D2DC",
      color: "#412A5D",
    },
    noLongerConsiderationChip: {
      backgroundColor: "#F84738",
      color: "#FFC7C3",
    },
    otherChip: {
      backgroundColor: "#F1E1E9",
      color: "#A01F62",
    },
  }),
  {
    name: "ChipSquare",
  }
)

export default styles
