import dayjs from "dayjs"

const sortHelper = (array, type, value) => {
  //function to sort the data
  //data=>array
  //type=> type of sorting
  //value=> what value in data is sorted

  if (type === "seat_number" && array?.length > 0) {
    // board members / total seats = percentage of seats that are NOT available
    // so sort from lower to higher
    value = "seats"
    let filteredArr = array?.filter(
      (x) => x?.board_members?.length / x[value] !== 1
    )
    // filter out those who have same # of board members as total seats
    return filteredArr?.sort(
      (a, b) =>
        a?.board_members?.length / a[value] -
        b?.board_members?.length / b[value]
    )
    // if (type === "applicant_number" && array?.length > 0) {
    //     value = "seats"
    //     return array?.sort((a, b) => b[value] - a[value])
  } else if (type === "date" && array?.length > 0) {
    value = "updated"
    return array.sort((a, b) => dayjs(b[value]).unix() - dayjs(a[value]).unix())
  } else if (type === "applicant_number" && array?.length > 0) {
    return array?.sort(function (a, b) {
      return b?.applicants - a?.applicants
    })
  }
}

export default sortHelper
