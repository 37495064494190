import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import Autocomplete from "@material-ui/lab/Autocomplete"
import pin from "assets/icon-feather-map-pin.svg"
import search from "assets/icon-feather-search.svg"
import App from "contexts/App"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import CitizenApp from "contexts/Citizen/CitizenApp"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import capitalize from "utils/capitalize"

import useStyles from "./styles"

const Searchbar = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))
  const tabletView = useMediaQuery(theme.breakpoints.down("sm"))

  const [origin, setOrigin] = useState("")

  const {
    searchValue,
    setSearchValue,
    authUser,
    setSearchButtonClicked,
    setClearSearch,
  } = useContext(App)

  const { appliedData, appliedLoading } = useContext(CitizenAppContext)

  const {
    autocompleteOptions,
    autocompleteOptionsLoading,
    locationSearch,
    setLocationSearch,
  } = useContext(CitizenApp)

  const showClear = searchValue !== "" || !isEmpty(locationSearch)

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  // sets origin entity id if coming from iFrame, only for use in Go Back button
  useEffect(() => {
    if (params) {
      const entityId = parseInt(params?.entityId)
      setOrigin(entityId)
    }
  }, [])

  return (
    <AppBar
      position="static"
      color="secondary"
      elevation={0}
      className={classes.root}
    >
      <Toolbar
        className={classes.toolbar}
        style={
          !isEmpty(authUser) && !mobileView && tabletView && !appliedLoading
            ? { height: "120px" }
            : null
        }
      >
        <Box className={classes.searchInputsBox}>
          <TextField
            placeholder="All Positions"
            className={classes.search}
            variant="filled"
            value={searchValue}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchValue(e.target.value)
                setSearchButtonClicked(true)
              }
            }}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            InputProps={{
              classes: {
                root: classes.inputBackgroundColor,
                focused: classes.inputFocused,
              },
              disableUnderline: true,
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  <Icon classes={{ root: classes.iconRoot }}>
                    <img className={classes.imageIcon} src={search} alt="" />
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            id="home-loc-autocomplete"
            getOptionLabel={(option) => `${option.label}`}
            getOptionSelected={(option, value) => option.id === value.id}
            options={autocompleteOptions || []}
            loading={autocompleteOptionsLoading}
            value={locationSearch}
            popupIcon={null}
            disableClearable
            autoHighlight
            onChange={(event, newValue) => {
              setLocationSearch(newValue)
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setSearchButtonClicked(true)
              }
            }}
            renderOption={(option) => {
              return (
                <Box
                  key={option?.id}
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-end"
                >
                  <Typography
                    style={{
                      fontWeight: 500,
                      marginRight: "6px",
                    }}
                    variant="body2"
                  >
                    {option?.label}
                  </Typography>
                  <Typography style={{ opacity: 0.4, fontSize: "1rem" }}>
                    {capitalize(option?.type)}
                  </Typography>
                </Box>
              )
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder={!locationSearch ? "Search by location" : ""}
                  className={classes.search}
                  style={{
                    paddingTop: "10px !important",
                    paddingBottom: "12px !important",
                  }}
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.inputBackgroundColor,
                    },
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginLeft: "10px", marginBottom: "10px" }}
                      >
                        <Icon classes={{ root: classes.iconRoot }}>
                          <img className={classes.imageIcon} src={pin} alt="" />
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              )
            }}
            classes={{
              root: classes.autocomplete,
              inputRoot: classes.inputRoot,
            }}
          />
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              setSearchButtonClicked(true)
            }}
          >
            Search
          </Button>
          {/* The applied link moves to top tab bar if logged in & on mobile device */}
          {showClear && (
            <IconButton
              style={{ height: "40px" }}
              onClick={() => {
                setSearchValue("")
                setLocationSearch(null)
                setClearSearch(true)
                setTimeout(() => {
                  setClearSearch(false)
                }, 300)
              }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
        <Box display="flex" width="100%" justifyContent="flex-end">
          {Boolean(origin) && (
            <Box className={classes.myApplicationsBox}>
              <Button
                color="primary"
                startIcon={<KeyboardBackspaceIcon />}
                style={{ backgroundColor: "transparent" }}
                classes={{
                  text: classes.textBtn,
                  startIcon: classes.startIcon,
                }}
                onClick={() => {
                  history.push(`/pub?e=${origin}`)
                }}
              >
                Go Back
              </Button>
            </Box>
          )}
          {!isEmpty(authUser) && !mobileView && !appliedLoading && (
            <Box className={classes.myApplicationsBox}>
              <Button
                component={Link}
                to="/applied"
                style={{
                  position: "relative",
                  right: 0,
                }}
              >
                My Applications ({`${appliedData?.length}`})
              </Button>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  )
}

Searchbar.propTypes = {
  children: PropTypes.node,
}

export default Searchbar
