import api from "store/api"

export const citizenSignUp = (data) => {
  return api.post("/app/users", data).then((res) => res.data)
}

export const updateNewUser = () => {
  return api.post("app/users/new-user/").then((res) => res.data)
}

export const getUserApplications = () => {
  return api.get("/app/users/applications").then((res) => res.data.data)
}

export const checkRegisterPhone = ({ phone }) => {
  return api.get(`/app/users/phone/${phone}`).then((res) => res.data)
}

export const checkRegisterEmail = ({ email }) => {
  return api.get(`/app/users/email/${email}`).then((res) => res.data)
}

export const updatePassword = (data) => {
  return api.put("/app/users/forgot", data)
}

export const forgotPassword = (data) => {
  return api.post("/app/users/forgot", data)
}

export const getPin = (data) => {
  return api.post("/app/users/pin", data)
}
