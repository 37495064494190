import api from "store/api"
import serialize from "store/serialize"

export const getEntities = (query) => {
  return api
    .get(`/admin/super/entities?${query}`)
    .then((res) => serialize("entity", res.data.data))
}

export const getEntityById = (entityId) => {
  return api
    .get(`/admin/super/entities/${entityId}`)
    .then((res) => serialize("entity", res.data.data.entity))
}

export const updateEntityInfo = (data, entityId) => {
  return api
    .put(`/admin/super/entities/${entityId}`, data)
    .then((res) => serialize("entity", res.data.data.entity[0]))
}

export const listStates = () => {
  return api.get("/admin/entities/states").then((res) => res.data.data)
}

export const updateEntityImage = (data, entityId) => {
  return api
    .put(`/admin/entities/${entityId}/image`, data)
    .then((res) => res.status)
  // res.config.data?
}

export const getReviewsByEntity = (entityId) => {
  return api
    .get(`/admin/entities/${entityId}/reviews`)
    .then((res) => res.data.data.reviews)
}

export const populateEntityList = (data) => {
  return api.get("/admin/users/register").then((res) => res.data.data)
}

export const getFile = (id) => {
  return api.get(`/admin/super/imports?id=${id}`).then((res) => res.data.data)
}

export const getReviewersList = (entityId) => {
  return api
    .get(`/admin/entities/${entityId}/council`)
    .then((res) => res.data.members)
}

export const createReviewer = (dataMutation) => {
  const { entityId, ...data } = dataMutation
  return api
    .post(`/admin/entities/${entityId}/council`, data)
    .then((res) => res.data.data.new_member)
}

export const deleteReviewer = (dataMutation) => {
  const { entityId, reviewerId } = dataMutation
  return api
    .delete(`/admin/entities/${entityId}/council/${reviewerId}`)
    .then((res) => res)
}

export const superBoardImport = (mutationData) => {
  let { entityId, file_id } = mutationData
  return api
    .post(`admin/super/entities/${entityId}/import`, { file_id })
    .then((res) => res)
}
