import LoaderButton from "@bit/c_t.components.loader-button"
import { Box, IconButton, InputAdornment } from "@material-ui/core"
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"
import { updatePassword } from "actions/Citizen/users"
import App from "contexts/App"
import { Field, Form, Formik, useFormikContext } from "formik"
import { TextField } from "formik-material-ui"
import PropTypes from "prop-types"
import { useContext } from "react"
import { useState } from "react"
import { useMutation } from "react-query"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"

import useStyles from "./styles"

const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[\d])(?=.*[!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{8,}$/,
      "Requirements: 8 characters, 1 Upper Case & 1 special character"
    ),
  updatePassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(`Required`),
  pin: Yup.string(),
})
export const Fields = () => {
  const classes = useStyles()

  const [visibility, setVisibility] = useState(true)
  return (
    <>
      <Field
        component={TextField}
        type={visibility ? "password" : "text"}
        name="password"
        fullWidth
        label="New Password"
        variant="outlined"
        className={classes.textField}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                size="small"
                onClick={() => setVisibility(!visibility)}
              >
                {visibility ? (
                  <VisibilityOutlined color="primary" fontSize="small" />
                ) : (
                  <VisibilityOffOutlined color="primary" fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Field
        component={TextField}
        type={visibility ? "password" : "text"}
        name="updatePassword"
        fullWidth
        label="Confirm New Password"
        variant="outlined"
        className={classes.textField}
        FormHelperTextProps={{
          className: classes.formHelperText,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                size="small"
                onClick={() => setVisibility(!visibility)}
              >
                {visibility ? (
                  <VisibilityOutlined color="primary" fontSize="small" />
                ) : (
                  <VisibilityOffOutlined color="primary" fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export const Buttons = () => {
  // LoaderButton grabs formik context and spins automatically
  /* If `submitForm` from `formikContext` returns a Promise, you can pass it
          to `onClick` to automatically show success or error animation */

  const { submitForm } = useFormikContext()
  const classes = useStyles()

  return (
    <Box className={classes.loaderButtonwrapper}>
      <LoaderButton
        type="button"
        variant="contained"
        color="primary"
        fullWidth
        onClick={submitForm}
      >
        Submit
      </LoaderButton>
    </Box>
  )
}

const UpdatePasswordForm = ({
  resetPin,
  FieldsComponent,
  ButtonsComponent,
  handleClose,
}) => {
  const { openSnackBar } = useContext(App)

  const history = useHistory()
  const resetPassMutation = useMutation(updatePassword)

  const handleSubmit = (values) => {
    if (values.password === values.updatePassword) {
      resetPassMutation.mutate({
        password: values.password,
        pin: `${resetPin}`,
      })
      openSnackBar({
        message: "Password Reset!",
      })
      setTimeout(() => {
        history.push("/boards")
      }, 300)
      handleClose()
    }
  }

  return (
    <Formik
      initialValues={{ password: "", updatePassword: "", pin: `${resetPin}` }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <>
        {FieldsComponent && (
          <FieldsComponent>
            <Form>
              <Fields />
              <button
                type="submit"
                style={{ opacity: "0", visibility: "hidden" }}
                U
              />
            </Form>
          </FieldsComponent>
        )}
        {!FieldsComponent && (
          <Form>
            <Fields />
          </Form>
        )}
        {ButtonsComponent && (
          <ButtonsComponent>
            <Buttons />
          </ButtonsComponent>
        )}
        {!ButtonsComponent && <Buttons />}
      </>
    </Formik>
  )
}

UpdatePasswordForm.propTypes = {
  resetPin: PropTypes.object,
  FieldsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  ButtonsComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  handleClose: PropTypes.func,
}

export default UpdatePasswordForm
