import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      zIndex: 5,
    },
    toolbar: {
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    startIcon: {
      marginRight: "2px",
    },
    search: {
      marginRight: theme.spacing(3),
      minWidth: "200px",
    },
    autocomplete: {
      marginRight: theme.spacing(2),
    },
    inputRoot: {
      width: "200px",
      paddingTop: "0 !important",
      paddingRight: "20px !important",
    },
    imageIcon: {
      display: "flex",
      height: "inherit",
      width: "inherit",
    },
    icon: {
      color: "#898989",
      "&:hover": {
        cursor: "pointer",
      },
    },
    searchInputsBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    rightBox: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        justifyContent: "flex-end",
      },
    },
    myApplicationsBox: {
      display: "flex",
      marginRght: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        marginTop: "10px",
      },
    },
    inputBackgroundColor: {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "white !important",
      },
    },
  }),
  {
    name: "Searchbar",
  }
)

export default styles
