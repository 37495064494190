import { Box, Grid, Typography, IconButton } from "@material-ui/core"
import Boards from "components/Clerk/Dashboard/Boards"
import Messages from "components/Clerk/Dashboard/Messages"
import Notifications from "components/Clerk/Dashboard/Notifications"
import Review from "components/Clerk/Dashboard/Review"
import WelcomeBanner from "components/Clerk/Dashboard/WelcomeBanner"
import Page from "pages/Page"
import PropTypes from "prop-types"
import { Router } from "react-router-dom"
import GetAppIcon from "@material-ui/icons/GetApp"
import { useContext } from "react"
import App from "contexts/App"

import useStyles from "./styles"

const Dashboard = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const { authUser } = useContext(App)

  const handleDownload = () => {
    try {
      window.location.href = `${process.env.REACT_APP_API_ENDPOINT}admin/boards/pdfs/${authUser.id}/${authUser.entity_id}`
    } catch {
      console.log("Error downloading")
    }
  }

  return (
    <Page>
      <WelcomeBanner />
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
          <Box className={classes.headingBox}>
            <Typography variant="h5" color="primary" className={classes.title}>
              Boards
            </Typography>
            <IconButton
              color="primary"
              variant="contained"
              onClick={handleDownload}
            >
              <GetAppIcon />
            </IconButton>
          </Box>
          <Boards />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
          <Box className={classes.headingBox}>
            <Typography variant="h5" color="primary" className={classes.title}>
              In Review
            </Typography>
          </Box>
          <Review />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
          <Box className={classes.headingBox}>
            <Typography variant="h5" color="primary" className={classes.title}>
              Messages
            </Typography>
          </Box>
          <Messages />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.gridItem}>
          <Box className={classes.headingBox}>
            <Typography variant="h5" color="primary" className={classes.title}>
              Notifications
            </Typography>
          </Box>
          <Notifications />
        </Grid>
      </Grid>
      <Router routes={routes} {...rest} />
    </Page>
  )
}
Dashboard.propTypes = {
  routes: PropTypes.array,
}

export default Dashboard
