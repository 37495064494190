import { Box, Typography } from "@material-ui/core"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"

import useStyles from "./styles"

const Step4 = ({ setStep, entityType }) => {
  const classes = useStyles()

  const { closeDialog } = useContext(AppContext)

  const handleClose = () => {
    closeDialog("clerkRequest")
    setTimeout(() => {
      setStep(1)
    }, 100)
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100%"
    >
      <Logo className={classes.logo} />
      <Typography color="primary" variant="h2" className={classes.title}>
        {`Thank you for submitting your ${entityType} to join Matchboard. Please check
        your email for verification.`}
      </Typography>
      <ButtonRounded
        variant="contained"
        color="primary"
        onClick={handleClose}
        data-cy="clerk-request-close-dialog"
      >
        Close
      </ButtonRounded>
    </Box>
  )
}

Step4.propTypes = {
  setStep: PropTypes.func,
  entityType: PropTypes.string,
}

export default Step4
