import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import ButtonRounded from "components/Common/ButtonRounded"
import ChipSquare from "components/Common/ChipSquare"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import AppliedContext from "contexts/Citizen/Applied"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import dayjs from "dayjs"
import MaterialTable from "material-table"
import Page from "pages/Page"
import { useContext, useState } from "react"
import { useQueryClient } from "react-query"
import { Link, useHistory } from "react-router-dom"
import AutoSizer from "react-virtualized-auto-sizer"

import useStyles from "./styles"

const List = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"))
  const { authUser } = useContext(AppContext)
  // const maxBodyHeight = mobileView ? "100%" : "600px"
  const maxBodyHeight = "100%"

  const hideColumn = mobileView ? "none" : null

  const { appliedData, appliedLoading } = useContext(CitizenAppContext)
  const { openSnackBar, openDialog } = useContext(AppContext)
  const { withdrawApplicationMutation } = useContext(AppliedContext)

  const updateApplication = (application, id) => {
    const boardId = application.board_id
    let data = {
      boardId: boardId,
      applicationId: application.application_id,
      status_id: id,
    }
    withdrawApplicationMutation
      .mutateAsync(data)
      .then((res) => {
        openSnackBar({ message: "Application status updated" })
        return queryClient.setQueryData(
          ["applied", authUser?.id],
          (oldState) => {
            let updatedApplications = oldState.map((x) => {
              if (x.application_id === application.application_id) {
                let updated = {
                  ...x,
                  status_id: res.status_id,
                  status: res?.status_id === 7 ? "Withdrawn" : "Applied",
                }
                return updated
              } else {
                return x
              }
            })
            return updatedApplications
          }
        )
      })
      .catch((err) => {
        console.log(err)
        openSnackBar({ message: "Error updating application status" })
      })
  }

  const handleConfirmWithdraw = (application) => {
    let data = {
      action: `to withdraw your application for ${application.name}?`,
      confirmText: "Withdraw",
    }
    openDialog("clickConfirmation", data).then(() => {
      updateApplication(application, 7)
    })
  }

  const handleConfirmReapply = (application) => {
    let data = {
      action: `to reapply for ${application.name}?`,
      confirmText: "Reapply",
    }
    openDialog("clickConfirmation", data).then(() => {
      updateApplication(application, 1)
    })
  }

  const headCells = [
    {
      title: "Board",
      field: "name",
      render: function boardNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.name}
              desktopSize={50}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: mobileView ? "70%" : "35%",
        maxWidth: mobileView ? "70%" : "35%",
        padding: "5px 30px",
      },
      cellStyle: {
        width: mobileView ? "70%" : "35%",
        maxWidth: mobileView ? "70%" : "35%",
        padding: "5px 30px",
      },
    },
    {
      title: "Location",
      field: "location",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        height: "25px",
        padding: "5px 10px",
        display: hideColumn,
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
        display: hideColumn,
      },
      render: function locationText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={rowData?.entity}
              desktopSize={25}
              phoneSize={10}
            />
          </Box>
        )
      },
    },
    {
      title: "Submission Date",
      field: "submissionDate",
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
        display: hideColumn,
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "5px 10px",
        display: hideColumn,
      },
      render: function dateText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={dayjs(rowData?.created).format("M/D/YYYY")}
              desktopSize={25}
              phoneSize={10}
            />
          </Box>
        )
      },
    },
    {
      title: "Status",
      field: "applicationStatus",
      headerStyle: {
        width: mobileView ? "10%" : "20%",
        maxWidth: mobileView ? "10%" : "20%",
        padding: "5px 10px",
      },
      cellStyle: {
        width: mobileView ? "10%" : "20%",
        maxWidth: mobileView ? "10%" : "20%",
        padding: "5px 10px",
      },
      render: function statusText(rowData) {
        return (
          <Box>
            <ChipSquare
              status={{
                label: rowData?.status,
                id: rowData?.status_id,
              }}
              className={classes.viewedChip}
            />
          </Box>
        )
      },
    },
    {
      title: "",
      field: "",
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        padding: "5px 10px",
        textAlign: "center",
      },
      render: function withdrawBtn(rowData) {
        if (rowData.status_id !== 4 && rowData.status_id !== 6)
          if (!mobileView) {
            return (
              <ButtonRounded
                variant="outlined"
                color="primary"
                onClick={() =>
                  rowData?.status_id === 7
                    ? handleConfirmReapply(rowData)
                    : handleConfirmWithdraw(rowData)
                }
              >
                {rowData?.status_id === 7 ? "Reapply" : "Withdraw"}
              </ButtonRounded>
            )
          } else {
            return (
              <IconButton
                onClick={() =>
                  rowData?.status_id === 7
                    ? handleConfirmReapply(rowData)
                    : handleConfirmWithdraw(rowData)
                }
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
      },
    },
  ]

  const classes = useStyles()
  return (
    <Page>
      <Box height="100%" display="flex" alignItems="flex-start" width="100%">
        <AutoSizer style={{ width: "100%", height: "100%" }}>
          {({ width, height }) => {
            return (
              <MaterialTable
                // tableRef={tableRef}
                isLoading={appliedLoading}
                loadingType="linear"
                options={{
                  toolbar: false,
                  title: "",
                  headerStyle: {
                    backgroundColor: "#E5E5E5",
                    position: "sticky",
                    top: 0,
                    whiteSpace: "nowrap",
                    width: "100%",
                    fontSize: "16px",
                    height: "50px",
                    padding: "5px 10px",
                    color: "#190D27",
                    borderTop: "0px",
                    zIndex: 1,
                  },
                  maxBodyHeight: maxBodyHeight,
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  rowStyle: (rowData, i) => ({
                    backgroundColor: "#fff",
                    padding: "5px 10px",
                    height: "65px",
                  }),
                }}
                classes={{ root: classes.root }}
                columns={headCells}
                data={appliedData}
              />
            )
          }}
        </AutoSizer>
        {!mobileView && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            width="20%"
            marginRight="30px"
          >
            <ButtonRounded
              onClick={() => history.push("/boards")}
              variant="contained"
              color="primary"
            >
              Go back
            </ButtonRounded>
          </Box>
        )}
      </Box>
    </Page>
  )
}

export default List
