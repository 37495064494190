import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import classnames from "clsx"
import HoverText from "components/Common/HoverText"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import useStyles from "./styles"

const BoardCard = ({ data }) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const match = useRouteMatch("/boards/:board_id")

  // shows right-side board container & triggers conditional border styling
  const displayBoardView = () => {
    history.push(`/boards/${data.id}`)
  }

  const selected = useMemo(() => {
    return parseInt(match?.params?.board_id) === data.id
  }, [match?.params?.board_id, data.id])

  return (
    <Card
      className={classnames(classes.container, {
        [classes.selected]: selected && !mobileView,
      })}
      onClick={() => {
        displayBoardView()
      }}
    >
      <CardContent style={{ padding: "12px" }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <HoverText
              variant="h5"
              desktopSize={100}
              phoneSize={100}
              data={data?.name}
            />
            <Typography variant="body1">{data?.entity}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography className={classes.applicantsNumber}>
              {data?.applicants || 0}
            </Typography>
            <Typography>
              {data?.applicants === 1 ? "Applicant" : "Applicants"}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions className={classes.actionsContainer}>
        <Box display="flex" width="100%">
          <Box className={classes.bottomDataContainer}>
            <Typography className={classes.bottomTitle}>POSITIONS</Typography>
            <Typography className={classes.bottomData} variant="body1">
              <span style={{ fontWeight: "bold" }}>{data?.vacants}</span> of{" "}
              <span style={{ fontWeight: "bold" }}>
                {data?.board_positions?.length}
              </span>{" "}
              available
            </Typography>
          </Box>
          <Box className={classes.bottomDataContainer}>
            <Typography className={classes.bottomTitle}>TERM</Typography>
            <Typography className={classes.bottomData} variant="body1">
              {transformCaseAndSpaces(data?.term)}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.bottomTitle}>AUTHORITY</Typography>
            <Typography className={classes.bottomData} variant="body1">
              {transformCaseAndSpaces(data?.appointing_authority)}
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  )
}

BoardCard.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
}

export default BoardCard
