import { Box, Grow, Icon, IconButton, Typography } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import AppContext from "contexts/App"
import AccountContext from "contexts/Citizen/account"
import PropTypes from "prop-types"
import { useState } from "react"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import serialize from "store/serialize"

import useStyles from "./styles"

const WelcomeBanner = ({ children }) => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { authUser, token, setAuthData } = useContext(AppContext)
  const authContext = useContext(AppContext)
  const { updateNewUserMutation } = useContext(AccountContext)
  const [isNew, setIsNew] = useState(authUser.new_user)

  const cancelWelcomeMessage = () => {
    setIsNew(!isNew)
    updateNewUserMutation
      ?.mutateAsync()
      .then((res) => {
        serialize("user", res.data).then((serializedData) => {
          authContext.setAuthData({
            token: token,
            account: serializedData,
          })
          return queryClient.setQueryData("user", (oldState) => {
            return serializedData
          })
        })
      })
      .catch((err) => console.log(err))
  }
  return isNew ? (
    <Grow in={Boolean(authUser?.new_user)}>
      <Box>
        <Box className={classes.buttonWrapper}>
          <IconButton
            onClick={cancelWelcomeMessage}
            className={classes.closeButton}
          >
            <ClearIcon style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Box
          className={classes.container}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box width="50%">
            <Typography variant="subtitle1" style={{ color: "#fff" }}>
              {`Welcome to Matchboard, ${authUser?.first_name}! Please complete your profile in order
        to apply for positions!`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grow>
  ) : null
}

WelcomeBanner.propTypes = {
  children: PropTypes.node,
}

export default WelcomeBanner
