import { Box, Card, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Address = ({ address, city, state, county, zipCode }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Box display="flex" width="100%">
        <Box flexGrow={1}>
          <Typography className={classes.educationText}>{address}</Typography>
          <Typography variant="body1" className={classes.textGray}>
            {city}
            {", "}
            {county}
            {", "}
            {state}
            {", "}
            {zipCode}
          </Typography>
        </Box>
      </Box>
    </Card>
  )
}

Address.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.number,
}

export default Address
