import { Box, ListItem, ListItemText, Typography } from "@material-ui/core"
import PersonIcon from "@material-ui/icons/Person"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import useStyles from "./styles"

const SeatMemberListItem = ({
  member = {},
  vacant = false,
  boardTerm = "",
}) => {
  // vacant prop flipped to true on BoardView for an unfilled seat, passed as a prop
  const classes = useStyles()

  const memberTerm = () => {
    if (boardTerm?.includes("year")) {
      return dayjs(member?.term_expiration).isValid()
        ? dayjs(member?.term_expiration).format("MMMM DD YYYY")
        : "-"
    } else if (boardTerm?.toLowerCase() === "other") {
      // check if a member has a valid expiration date to display in case their member term is 1-6 years
      // if not, show a "-"
      const expirationDate = dayjs(member?.term_expiration).isValid()
        ? dayjs(member?.term_expiration).format("MMMM DD YYYY")
        : "-"
      // display the expiration date if 1-6 year OR coterminous/no limit if those
      return member?.member_term_length?.includes("year")
        ? expirationDate
        : transformCaseAndSpaces(member?.member_term_length)
    } else {
      // if board is coterminous or no limit, don't display anything
      return ""
    }
  }

  const today = dayjs(new Date())
  const memberExpiration = dayjs(memberTerm())
  const expired = memberExpiration.isBefore(today)

  return (
    <ListItem disableGutters classes={{ root: classes.root }}>
      <Box display="flex" flexDirection="row" height="64px" alignItems="center">
        {!expired ? (
          <Box
            color="white"
            style={
              !vacant &&
              dayjs(member?.term_expiration).isValid &&
              !(dayjs(member?.term_expiration) < dayjs().add(90, "days"))
                ? { backgroundColor: "#1FA073" }
                : { backgroundColor: "#CBD058" }
            }
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginRight="4px"
            height="100%"
          >
            <PersonIcon size="xs" />
          </Box>
        ) : (
          <Box
            color="white"
            style={{ backgroundColor: "#ba1f03" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginRight="4px"
            height="100%"
          >
            <PersonIcon size="xs" />
          </Box>
        )}
        {!vacant ? (
          <ListItemText
            disableTypography
            classes={{ primary: classes.primary, multiline: classes.multiline }}
            primary={
              <Box display="flex" flexDirection="row">
                <Typography variant="body1">
                  {member?.member?.first_name || member?.first_name}{" "}
                  {member?.last_name || member?.member?.last_name}
                </Typography>
              </Box>
            }
            secondary={
              <>
                <Box display="flex" flexDirection="row">
                  <Typography variant="caption" style={{ opacity: "0.5" }}>
                    {member?.position}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography variant="caption" style={{ opacity: "0.5" }}>
                    {memberTerm()}
                  </Typography>
                </Box>
              </>
            }
          />
        ) : (
          <ListItemText
            disableTypography
            classes={{ primary: classes.primary, multiline: classes.multiline }}
            primary={
              <Box display="flex" flexDirection="row">
                <Typography variant="body1">Vacant</Typography>
              </Box>
            }
            secondary={
              <>
                <Box display="flex" flexDirection="row">
                  <Typography variant="caption" style={{ opacity: "0.5" }}>
                    {member?.position}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography variant="caption" style={{ opacity: "0.5" }}>
                    Apply today!
                  </Typography>
                </Box>
              </>
            }
          />
        )}
      </Box>
    </ListItem>
  )
}

SeatMemberListItem.propTypes = {
  children: PropTypes.node,
  member: PropTypes.object,
  vacant: PropTypes.bool,
  boardTerm: PropTypes.string,
}

export default SeatMemberListItem
