import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  tabsWrapper: {
    backgroundColor: "white",
    width: "100%",
    zIndex: 10,
    boxShadow: "0px -3px 6px #0000001A",
    flex: "0 1 auto",
    minHeight: "64px",
  },
  labelWrapper: {
    justifyContent: "center",
  },
  tabRoot: {
    minHeight: "64px",
  },
}))

export default styles
