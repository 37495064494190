import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  drawerItem: {
    backgroundColor: "#F1E1E9",
    color: "#190D27",
  },
  loginLogoutBox: {
    width: "100%",
    padding: theme.spacing(2),
  },
  loginLogout: {
    color: "#412A5D",
    width: "100%",
  },
}))

export default styles
