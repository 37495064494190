import {
  checkEmail,
  checkEntityAvailability,
  checkPhone,
  clerkRequest,
  requestClerkData,
} from "actions/Clerk/requestClerk"
import PropTypes from "prop-types"
import { createContext, useState } from "react"
import { useMutation } from "react-query"

const RequestClerkContext = createContext()
export default RequestClerkContext

export const RequestClerkContainer = ({ children }) => {
  const [entityData, setEntityData] = useState([])
  const [leadershipData, setLeadershipData] = useState([])
  const requestClerkDataMutation = useMutation(requestClerkData)
  const requestClerkMutation = useMutation(clerkRequest)

  const checkEntityAvailabilityMutation = useMutation(checkEntityAvailability)
  const checkPhoneMutation = useMutation(checkPhone)
  const checkEmailMutation = useMutation(checkEmail)

  const [entitiyUsed, setEntityUsed] = useState(false)

  return (
    <RequestClerkContext.Provider
      value={{
        requestClerkDataMutation,
        entityData,
        setEntityData,
        leadershipData,
        setLeadershipData,
        checkEntityAvailabilityMutation,
        entitiyUsed,
        setEntityUsed,
        requestClerkMutation,
        checkEmailMutation,
        checkPhoneMutation,
      }}
    >
      {children}
    </RequestClerkContext.Provider>
  )
}

RequestClerkContainer.propTypes = {
  children: PropTypes.node,
}
