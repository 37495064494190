import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core"
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn"
import { getReviewersList } from "actions/Clerk/entities"
import AvatarComponent from "components/Common/Avatar"
import ButtonRounded from "components/Common/ButtonRounded"
import App from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import dayjs from "dayjs"
import { difference, isEmpty } from "lodash"
import { union } from "lodash"
import { useContext, useEffect, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const ShareForReview = () => {
  const classes = useStyles()
  const history = useHistory()
  const queryClient = useQueryClient()

  const { dialogs, closeDialog, openSnackBar, authUser } = useContext(App)
  const { boardReviewRefetch } = useContext(BoardContext)
  const thisDialog = dialogs?.["shareForReview"] || {}
  const { open = false, data = [] } = thisDialog
  const applicants = data?.selected
  const shareFnMutation = data?.shareFn
  const boardId = data?.boardId
  const members = data?.members
  // in prod, to pass boardId instead of members, make api call to load board info incl. members
  const initialValues = { message: "", emailList: [] }
  const [formValues, setFormValues] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [entered, setEntered] = useState(false)
  //state vals for inputs
  const [councilChecked, setCouncilChecked] = useState(false)
  const [membersChecked, setMembersChecked] = useState(false)

  // clear out fields and local state values on close
  const handleClose = () => {
    setTimeout(() => {
      closeDialog("shareForReview")
      setFormValues(initialValues)
      setCouncilChecked(false)
      setMembersChecked(false)
      setClicked(false)
      setEntered(false)
    }, 100)
  }
  // universal change handler
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const { data: reviewersData, isLoading: reviewersDataLoading } = useQuery(
    ["reviewersData", authUser?.entity_id],
    () => getReviewersList(authUser?.entity_id)
  )

  // checkbox toggle injects or removes the member emails from recipient list
  useEffect(() => {
    const councilEmails = reviewersData?.map((x) => x?.email)

    if (councilChecked && reviewersData) {
      setFormValues({
        ...formValues,
        emailList: union([...formValues?.emailList, councilEmails].flat()),
      })
    } else if (!councilChecked) {
      // unchecking removes members address from email list, leaving the difference
      const leftovers = difference(formValues?.emailList, councilEmails)
      setFormValues({ ...formValues, emailList: union(leftovers) })
    }
  }, [councilChecked, reviewersData])

  useEffect(() => {
    const membersEmails = members?.map((x) => x?.email)

    if (membersChecked && members) {
      setFormValues({
        ...formValues,
        emailList: union([...formValues?.emailList, membersEmails].flat()),
      })
    } else if (!membersChecked) {
      // unchecking removes members address from email list, leaving the difference
      const leftovers = difference(formValues?.emailList, membersEmails)
      setFormValues({
        ...formValues,
        emailList: union(leftovers),
      })
    }
  }, [members, membersChecked])
  //called when clicking Share for Review button
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!emailListError && !messageError) {
      setLoading(true)
      setClicked(false)
      const applicantIds = applicants?.map((a) => a.board_application_id)
      const data = {
        message: formValues.message,
        emails: formValues.emailList,
        applicant_ids: applicantIds,
      }
      shareFnMutation
        .mutateAsync(data)
        .then(() => {
          queryClient.setQueryData(["boardReviews", boardId], (oldData) => {
            // new is temporary id - this gives more than enough info to inject into context
            const newData = {
              ...oldData,
              new: {
                applicants: applicants.map((x) => ({
                  ...x,
                  responses: data?.emails?.map((e) => ({
                    email: e,
                  })),
                })),
                review_sent: dayjs().toISOString(),
              },
            }
            return newData
          })
          openSnackBar({ message: "Applicant(s) sent for review" })
          handleClose()
          history.push(`/boards/${boardId}/reviews`)
          boardReviewRefetch()
          setLoading(false)
        })
        .catch((err) => {
          openSnackBar({ message: "Error sending applicant(s) for review" })
          setLoading(false)
        })
    } else {
      setClicked(true)
    }
  }
  // format validation & error handling specifically for email
  const isEmailValid = (email) => {
    let error = null
    const regex = /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/
    if (!regex.test(email)) {
      error = `${email} is not a valid email address.`
    }
    if (error) {
      return false
    }
    return true
  }

  const emailListError = isEmpty(formValues?.emailList)
  const messageError = !formValues?.message

  const emailValidationError = useMemo(() => {
    return !isEmailValid(formValues?.email) && formValues?.email !== ""
  }, [formValues?.email])

  // certain keystrokes (e.g, enter) submit into the emailList array
  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault()
      let email = formValues?.email?.trim()
      if (email && isEmailValid(email)) {
        // if emailVal has content and is a valid email address
        setFormValues({
          ...formValues,
          emailList: [...formValues?.emailList, email],
          email: "",
        })
        setEntered(false)
      }
      setEntered(true)
    }
  }

  const deleteRecipient = (recipient) => {
    const remaining = formValues?.emailList?.filter((x) => x !== recipient)
    setFormValues({ ...formValues, emailList: remaining })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paperWidthSm: classes.paper }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h1">Share For Review</Typography>
      </DialogTitle>
      {reviewersDataLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={15} />
        </Box>
      ) : (
        <DialogContent>
          <Typography color="primary" className={classes.labels}>
            Selected Applicants ({applicants?.length})
          </Typography>
          <Box className={classes.chipContainer}>
            {applicants?.map?.((x, i) => (
              <Box key={i}>
                <Chip
                  label={`${x?.first_name} ${x?.last_name}`}
                  variant="outlined"
                  size="small"
                  avatar={<AvatarComponent user={x} />}
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box className={classes.inputWrapper}>
            <Box className={classes.checkboxWrapper}>
              <Typography color="primary" className={classes.labels}>
                Add Emails
              </Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  disableRipple
                  disabled={!members?.length}
                  onChange={() => setMembersChecked(!membersChecked)}
                />
                <FormLabel component="legend">
                  <Typography variant="caption">
                    {members?.length > 0
                      ? `Board Members (${members?.length})`
                      : "No board members"}
                  </Typography>
                </FormLabel>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  disableRipple
                  disabled={!reviewersData?.length}
                  onChange={() => setCouncilChecked(!councilChecked)}
                />
                <FormLabel component="legend">
                  <Typography variant="caption">
                    {reviewersData?.length > 0
                      ? `Council Members (${reviewersData?.length})`
                      : "No council members"}
                  </Typography>
                </FormLabel>
              </Box>
            </Box>
            <Box
              className={classes.scrollWrapper}
              style={
                isEmpty(formValues?.emailList) ? { display: "none" } : null
              }
            >
              <Box
                className={classes.emailChipContainer}
                style={
                  isEmpty(formValues?.emailList) ? { display: "none" } : null
                }
              >
                {formValues?.emailList?.map((email, i) => (
                  <Chip
                    key={i}
                    label={email}
                    variant="outlined"
                    size="small"
                    onDelete={() => deleteRecipient(email)}
                    classes={{
                      root: classes.chipRoot,
                      label: classes.emailChipLabel,
                    }}
                  />
                ))}
              </Box>
            </Box>
            <Box position="relative">
              <TextField
                name="email"
                multiline
                variant="outlined"
                fullWidth
                onChange={handleChange}
                onKeyPress={handleKeyDown}
                value={formValues?.email}
                /* eslint-disable */
            InputProps={
              
              emailListError &&clicked? {
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              } : null,
              {endAdornment: (
                formValues?.email&&<InputAdornment position="start">
                  <KeyboardReturnIcon style={{fontSize:"15px"}}/>
                </InputAdornment>
              ),}
            }
            /* eslint-enable */
              />
              {emailValidationError && entered && (
                <Typography
                  className={classes.errorText}
                  style={{ position: "absolute", bottom: -16 }}
                >
                  Email is not valid
                </Typography>
              )}
              {emailListError && clicked && !emailValidationError && (
                <Typography
                  className={classes.errorText}
                  style={{ position: "absolute", bottom: -16 }}
                >
                  No recipients are listed
                </Typography>
              )}
            </Box>
          </Box>
          <Box className={classes.inputWrapper} position="relative">
            <Typography
              color="primary"
              style={{ marginBottom: "12px" }}
              className={classes.labels}
            >
              Custom Message
            </Typography>
            <TextField
              name="message"
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              onChange={handleChange}
              value={formValues?.message}
              /* eslint-disable */
            InputProps={
              messageError&&clicked ? {
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              } : null
            }
              /* eslint-enable */
            />
            {messageError && clicked ? (
              <Typography
                className={classes.errorText}
                style={{ position: "absolute", bottom: -16 }}
              >
                Your message cannot be blank
              </Typography>
            ) : null}
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <ButtonRounded
          color="primary"
          variant="outlined"
          onClick={handleClose}
          type="button"
          size="small"
        >
          Cancel
        </ButtonRounded>
        <ButtonRounded
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          type="button"
          size="small"
          disabled={loading}
        >
          {loading ? (
            <Box width="100px">
              <CircularProgress size={15} />{" "}
            </Box>
          ) : (
            "Share For Review"
          )}
        </ButtonRounded>
      </DialogActions>
    </Dialog>
  )
}

export default ShareForReview
