import { getBoardApplicantById, getOptions } from "actions/Clerk/board"
import { getReviewsByEntity } from "actions/Clerk/entities"
import {
  changePassword,
  checkApprovalToken,
  getNotifications,
  updateNewUser,
} from "actions/Clerk/users"
import AppContext from "contexts/App"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { createContext, useContext } from "react"
import { useMutation, useQuery } from "react-query"
import API from "store/api"
import serialize from "store/serialize"

const ClerkAppContext = createContext()
export default ClerkAppContext

export const ClerkAppContainer = ({ children, ...props }) => {
  const checkApprovalTokenMutation = useMutation(checkApprovalToken)
  const changePasswordMutation = useMutation(changePassword)
  const updateNewUserMutation = useMutation(updateNewUser)

  const { authUser, isAdmin } = useContext(AppContext)
  const { data: options, isLoading: optionsLoading } = useQuery(
    "options",
    () => getOptions(),
    { enabled: !isEmpty(authUser) }
  )
  const getBoardApplicantByIdMutaiton = useMutation(getBoardApplicantById)

  // Get notifications
  const { data: notifications, isLoading: notificationsLoading } = useQuery(
    ["notifications", authUser?.entity_id],
    () => getNotifications(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  const { data: entityReviews, isLoading: entityReviewsLoading } = useQuery(
    ["entityReviews", authUser?.entityId],
    () => getReviewsByEntity(authUser?.entity_id),
    { enabled: !isEmpty(authUser) && isAdmin }
  )

  return (
    <ClerkAppContext.Provider
      value={{
        checkApprovalTokenMutation,
        changePasswordMutation,
        options,
        optionsLoading,
        updateNewUserMutation,
        getBoardApplicantByIdMutaiton,
        notifications,
        notificationsLoading,
        entityReviews,
        entityReviewsLoading,
      }}
    >
      {children}
    </ClerkAppContext.Provider>
  )
}

ClerkAppContainer.propTypes = {
  children: PropTypes.node,
}
