import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  buttonText: {
    fontSize: "1rem",
    color: theme.palette.primary.dark,
  },
  favorite: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "50px",
    color: "#fff",
    padding: "2px",
  },
  unfavorite: {
    backgroundColor: "#898989",
    borderRadius: "50px",
    color: "#fff",
    padding: "2px",
  },
  downloadButton: {
    marginRight: theme.spacing(1),
  },
  starButton: {
    marginRight: theme.spacing(1),
  },
  textGray: {
    color: "#898989",
  },
  dot: {
    fontSize: "8px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  referenceContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  nameText: {
    fontSize: "1.4rem",
  },
  card: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  appliedChip: {
    backgroundColor: "#FFCCA7",
    "&:hover": {
      backgroundColor: "#FFCCA7",
    },
    color: "#F88938",
  },
  viewedChip: {
    backgroundColor: "#33A3B139",
    "&:hover": {
      backgroundColor: "#33A3B139",
    },
    color: "#33A3B1",
  },
  appointedChip: {
    backgroundColor: "#B5E5B9",
    "&:hover": {
      backgroundColor: "#B5E5B9",
    },
    color: "#23B12E",
  },
  deferredChip: {
    backgroundColor: "#D6D2DC",
    color: "#412A5D",
  },
  noLongerConsiderationChip: {
    backgroundColor: "#F84738",
    "&:hover": {
      backgroundColor: "#F84738",
    },
    color: "#FFC7C3",
  },
  otherChip: {
    backgroundColor: "#F1E1E9",
    "&:hover": {
      backgroundColor: "#F1E1E9",
    },
    color: "#A01F62",
  },
  percentage: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    color: "#23B12E",
  },
}))

export default styles
