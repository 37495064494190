import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      backgroundColor: "#F1E1E9",
      padding: `0 ${theme.spacing(2)}px`,
      // boxShadow: "0 4px 30px -2px grey",
      // paddingBottom: theme.spacing(2),
      // borderBottomRightRadius: "5px",
      // borderBottomLeftRaadius: "5px",
    },
    drawerPaper: {
      position: "relative",
    },
    titleWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    formControl: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
    },
    search: {
      marginRight: theme.spacing(3),
      [theme.breakpoints.down("xs")]: {
        margin: 0,
      },
    },
    autocomplete: {
      marginRight: theme.spacing(2),
    },
    inputRoot: {
      paddingTop: "0 !important",
      paddingRight: "20px !important",
      marginTop: theme.spacing(1),
    },
    imageIcon: {
      display: "flex",
      height: "inherit",
      width: "inherit",
    },
    icon: {
      color: "#898989",
      "&:hover": {
        cursor: "pointer",
      },
    },
    inputBackgroundColor: {
      backgroundColor: "white !important",
      "&:hover": {
        backgroundColor: "white !important",
      },
    },
    buttonBox: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    clearIcon: {
      color: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
    },
  }),
  {
    name: "MobileSearchFilter",
  }
)

export default styles
