import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  logo: {
    width: 150,
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
}))

export default styles
