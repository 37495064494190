import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import classnames from "clsx"
import AppContext from "contexts/App"
import App from "contexts/App"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext, useEffect, useMemo, useRef } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import capitalize from "utils/capitalize"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import useStyles from "./styles"

const BoardCard = ({ data, firstCard }) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const location = useLocation()
  const ref = useRef()
  const { appliedData } = useContext(CitizenAppContext)
  const { authUser } = useContext(App)

  const { clearSearch, isIframe } = useContext(AppContext)

  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const match = useRouteMatch("/boards/:board_id")

  const scrollToEl = () => {
    if (ref) {
      ref.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  // compares query string for board Id and scrolls to that div if not 1st
  useEffect(() => {
    const urlId =
      location.pathname.split("/")[location.pathname.split("/").length - 1]
    const cardId = ref.current.id.split("-")[2]
    if (cardId === urlId) {
      if (!firstCard) {
        // if not first in list, scroll to the chosen element
        scrollToEl()
      } else {
        // if 1st in list, scroll to very top of parent container
        const parentEl = document?.getElementById?.("board-scroll-citizen")
        const y = parentEl?.getBoundingClientRect().y
        parentEl?.scrollTo({ top: -y, behavior: "smooth" })
      }
    }
  }, [location.pathname, clearSearch, firstCard])

  // shows right-side board container & triggers conditional border styling
  const displayBoardView = () => {
    history.push(`/boards/${data?.id}`)
  }

  const selected = useMemo(() => {
    return parseInt(match?.params?.board_id) === data.id
  }, [match?.params?.board_id, data.id])

  const findOpenPositions = data?.board_positions.filter(
    (x) =>
      !x.active ||
      dayjs(x.term_expiration).isBefore(dayjs(new Date()).add(90, "days"))
  )
  const findfilledPositions = data?.board_positions.filter(
    (x) =>
      x.active &&
      !dayjs(x.term_expiration).isBefore(dayjs(new Date()).add(90, "days"))
  )
  const openPositions = findOpenPositions?.length
  const filledPositions = findfilledPositions?.length
  const totalPositions = filledPositions + openPositions

  const validApp = authUser?.id
    ? appliedData.filter((app) => app.board_id === data?.id && app.active === 1)
    : []

  console.log(validApp)

  const showReapply =
    validApp.length > 0 &&
    !validApp[0].active &&
    openPositions > 0 &&
    data.app_type === "invite only"

  return (
    <Box className={classes.cardWrapper} ref={ref} id={"board-card-" + data.id}>
      <Card
        className={classnames(classes.container, {
          [classes.selected]: selected && !mobileView,
        })}
      >
        <CardContent style={{ padding: "12px" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom={1}
            marginTop={1}
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                marginTop={1}
              >
                <Box display="flex">
                  <Typography
                    className={classes.entityName}
                    style={isIframe ? { fontSize: "1.4rem" } : null}
                    color="primary"
                    variant="body1"
                  >
                    {data?.entity}, {data?.state}
                  </Typography>
                  <Typography
                    className={classes.entityType}
                    style={isIframe ? { fontSize: "1.4rem" } : null}
                  >
                    {capitalize(data?.entity_type)}
                  </Typography>
                </Box>
                {/* if applied in last year "Applied" else if applied over a year ago "Re-apply" else nothin' */}
                {validApp.length > 0 && validApp[0].active ? (
                  <Typography className={classes.appliedText}>
                    Applied
                  </Typography>
                ) : showReapply ? (
                  <Typography className={classes.appliedText}>
                    Re-Apply
                  </Typography>
                ) : (
                  <Box />
                )}
              </Box>
              <Typography
                variant="h6"
                style={{ marginTop: "8px", marginBottom: "16px" }}
              >
                {data?.name}
              </Typography>

              <Typography
                variant="body1"
                style={
                  isIframe
                    ? { fontSize: "1.4rem", whiteSpace: "pre-wrap" }
                    : { whiteSpace: "pre-wrap" }
                }
              >
                {data?.summary}
              </Typography>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            alignItems="space-between"
            marginTop="12px"
          >
            {data?.interest?.map?.((x, i) => (
              <Chip
                key={i}
                label={x?.name}
                color="primary"
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              />
            ))}
          </Box>
        </CardContent>
        <CardActions className={classes.actionsContainer}>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            flexDirection="column"
            flexWrap="wrap"
          >
            <Grid
              container
              spacing={3}
              wrap="nowrap"
              style={{ marginTop: "5px" }}
            >
              <Grid item xs={4} style={{ paddingTop: 0 }}>
                <Typography
                  className={classes.bottomTitle}
                  style={isIframe ? { fontSize: "1.2rem" } : null}
                >
                  POSITIONS
                </Typography>
                <Typography
                  className={classes.bottomData}
                  style={isIframe ? { fontSize: "1.4rem" } : null}
                  variant="body1"
                >
                  <span style={{ fontWeight: "bold" }}>{openPositions}</span> of{" "}
                  <span style={{ fontWeight: "bold" }}>{totalPositions}</span>{" "}
                  available
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={4} style={{ paddingTop: 0 }}>
                <Typography
                  className={classes.bottomTitle}
                  style={isIframe ? { fontSize: "1.2rem" } : null}
                >
                  TERM
                </Typography>
                <Typography
                  className={classes.bottomData}
                  variant="body1"
                  style={isIframe ? { fontSize: "1.4rem" } : null}
                >
                  {transformCaseAndSpaces(data?.term)}
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={4} style={{ paddingTop: 0 }}>
                <Typography
                  className={classes.bottomTitle}
                  style={isIframe ? { fontSize: "1.2rem" } : null}
                >
                  AUTHORITY
                </Typography>
                <Typography
                  className={classes.bottomData}
                  variant="body1"
                  style={isIframe ? { fontSize: "1.4rem" } : null}
                >
                  {transformCaseAndSpaces(data?.appointing_authority)}
                </Typography>
              </Grid>
            </Grid>
            <Box className={classes.buttonWrapper}>
              <Button
                color="secondary"
                size="small"
                onClick={() => {
                  displayBoardView()
                  scrollToEl()
                }}
              >
                VIEW DETAILS
              </Button>
            </Box>
          </Box>
        </CardActions>
      </Card>
    </Box>
  )
}

BoardCard.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  firstCard: PropTypes.bool,
}

export default BoardCard
