import DateFnsUtils from "@date-io/date-fns"
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import BoardContext from "contexts/Clerk/Board"
import Questions from "dialogs/QuickApply/components/Questions"
import { Form, Formik } from "formik"
import { useRef } from "react"
import { useContext, useState } from "react"
import InputMask from "react-input-mask"
import usStatesList from "utils/UsStateList"
import * as Yup from "yup"

import AppUpload from "./AppUpload/Index"
import useStyles from "./styles"

const schema = Yup.object().shape({
  first_name: Yup.string().required("Please enter a first name"),
  last_name: Yup.string().required("Please enter a last name"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Please enter an email"),
  phone: Yup.string().required("Please enter a phone number"),
  address: Yup.string().required("Please enter an address"),
  county: Yup.string().required("Please enter a county"),
  city: Yup.string().required("Please enter a city"),
  state: Yup.string().required("Please enter a state"),
  zip_code: Yup.string().required("Please enter a zip code"),
  highest_education: Yup.string(),
  degree: Yup.string(),
  occupation_title: Yup.string(),
  company_name: Yup.string(),
  start_date: Yup.date().nullable(),
  end_date: Yup.date().nullable(),
  file_name: Yup.string().required(),
  file: Yup.string().required(),
})

const AddApplicantDialog = () => {
  const classes = useStyles()
  const childRef = useRef()
  const steps = ["general", "additional", "resume"]
  const [activeStep, setActiveStep] = useState(0)
  const [currentJob, setCurrentJob] = useState(false)

  const { dialogs, closeDialog, openSnackBar, openDialog } =
    useContext(AppContext)
  const thisDialog = dialogs?.["addApplicantDialog"] || {}
  const { open = false, data = {} } = thisDialog
  const { board, createApplication } = useContext(BoardContext)

  const [defaultQuestionsArray, setDefaultQuestionsArray] = useState([])
  const [additionalQuestionsArray, setAdditionalQuestionsArray] = useState([])
  const [clicked, setClicked] = useState(false)
  const [preferredPosition, setPreferredPosition] = useState(null)
  const [positions, setPositions] = useState([])
  const [file, setFile] = useState({})

  const handleClose = () => {
    closeDialog("addApplicantDialog")
    setActiveStep(0)
    setDefaultQuestionsArray([])
    setAdditionalQuestionsArray([])
    setFile({})
  }
  const handleSubmit = (values) => {
    let data = {
      ...values,
      default_answers: defaultQuestionsArray,
      additional_answers: additionalQuestionsArray,
      cover_letter_id: file?.file_id,
      cover_letter: file?.file_name,
    }
    if (activeStep === steps.length - 1) {
      childRef?.current?.callOnSubmit()
      createApplication.mutateAsync(data).then((res) => {
        openSnackBar({ message: "Application Added to Board" })
      })
      handleClose()
    } else {
      setActiveStep(activeStep + 1)
    }
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography>
        <Typography variant="h2">Add Application</Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            address: "",
            county: "",
            city: "",
            state: "",
            zip_code: "",
            highest_education: "",
            degree: "",
            occupation_title: "",
            company_name: "",
            start_date: null,
            end_date: null,
          }}
          validationSchema={schema}
        >
          {({
            values,
            actions,
            errors,
            touched,
            isSubmitting,
            isValid,
            handleChange,
            handleBlur,
            setErrors,
            setFieldValue,
          }) => (
            <Form>
              {/* {formSteps[activeStep]} */}
              {activeStep === 0 && (
                <DialogContent>
                  <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
                    Applicant Information
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <TextField
                      type="text"
                      name="first_name"
                      label="First Name"
                      id="name"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={values?.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                    />
                    <TextField
                      type="text"
                      name="last_name"
                      label="Last Name"
                      id="name"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={values?.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />
                    <TextField
                      type="text"
                      name="email"
                      label="Email"
                      id="email"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={values?.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <InputMask
                      id="phone-mask"
                      mask="(999) 999-9999"
                      maskChar="_"
                      value={values?.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    >
                      {() => (
                        <TextField
                          type="text"
                          name="phone"
                          id="phone"
                          label="Phone"
                          variant="outlined"
                          fullWidth
                          style={{
                            marginBottom: "20px",
                          }}
                          FormHelperTextProps={{
                            className: classes.TextField,
                          }}
                        />
                      )}
                    </InputMask>
                    <TextField
                      type="text"
                      name="address"
                      label="Address"
                      id="address"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={values?.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                    <TextField
                      type="text"
                      name="county"
                      label="County"
                      id="county"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={values?.county}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.county && errors.county)}
                      helperText={touched.county && errors.county}
                    />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        id="city"
                        variant="outlined"
                        fullWidth
                        style={{ width: "60%" }}
                        className={classes.textField}
                        value={values?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                      <TextField
                        label="State"
                        select
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-cy="details-state"
                        variant="outlined"
                        fullWidth
                        style={{
                          marginBottom: "16px",
                          width: "35%",
                        }}
                        InputProps={{
                          classes: {
                            root: classes.inputBackgroundColor,
                          },
                        }}
                        FormHelperTextProps={{
                          className: classes.formHelperText,
                        }}
                        className={classes.textField}
                      >
                        {usStatesList?.map((s, i) => {
                          return (
                            <MenuItem
                              key={s}
                              value={s}
                              data-cy={`details-state-${i}`}
                            >
                              {s}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Box>
                    <TextField
                      type="text"
                      name="zip_code"
                      label="Zip Code"
                      id="zip_code"
                      variant="outlined"
                      fullWidth
                      className={classes.textField}
                      value={values?.zip_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.zip_code && errors.zip_code)}
                      helperText={touched.zip_code && errors.zip_code}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ marginTop: 20 }}
                  ></Box>
                </DialogContent>
              )}
              {activeStep === 1 && (
                <DialogContent>
                  <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
                    Additional Information
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
                    Add Education
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <TextField
                      select
                      data-cy="education-highest-education"
                      name="highest_education"
                      label="Highest Education Attained"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "25px" }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      value={values?.highest_education}
                      onChange={handleChange}
                      error={
                        touched.highest_education && errors.highest_education
                      }
                      helperText={
                        touched.highest_education && errors.highest_education
                      }
                    >
                      <MenuItem value={"High School or Equivalent "}>
                        High School or Equivalent{" "}
                      </MenuItem>
                      <MenuItem value={"Professional Certificate"}>
                        Professional Certificate
                      </MenuItem>
                      <MenuItem value={"Associates"}>Associates</MenuItem>
                      <MenuItem value={"Bachelor’s"}>{"Bachelor’s"}</MenuItem>
                      <MenuItem value={"Master's"}>{"Master's"}</MenuItem>
                      <MenuItem value={"Ph.D"}>Ph.D </MenuItem>
                      <MenuItem value={"MD"}>MD</MenuItem>
                      <MenuItem
                        value={"JD"}
                        data-cy={"education-highest-education-jd"}
                      >
                        JD
                      </MenuItem>
                    </TextField>

                    <TextField
                      type="text"
                      name="degree"
                      label="Degree"
                      id="degree-user"
                      data-cy={"education-degree"}
                      style={{ marginBottom: "25px" }}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        classes: {
                          root: classes.inputBackgroundColor,
                        },
                      }}
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      value={values?.degree}
                      onChange={handleChange}
                      error={touched.degree && errors.degree}
                      helperText={touched.degree && errors.degree}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ marginTop: 15 }}
                  ></Box>
                  <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
                    Add Occupation/ Current Position
                  </Typography>
                  <TextField
                    type="text"
                    name="occupation_title"
                    data-cy="position-title"
                    label="Title"
                    id="occupation_title"
                    style={{ marginBottom: "25px" }}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    value={values?.occupation_title}
                    onChange={handleChange}
                    error={touched.occupation_title && errors.occupation_title}
                    helperText={
                      touched.occupation_title && errors.occupation_title
                    }
                  />
                  <TextField
                    type="text"
                    name="company_name"
                    label="Company Name"
                    data-cy="position-company-name"
                    id="company_name"
                    style={{ marginBottom: "25px" }}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      classes: {
                        root: classes.inputBackgroundColor,
                      },
                    }}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    value={values?.company_name}
                    onChange={handleChange}
                    error={touched.company_name && errors.company_name}
                    helperText={touched.company_name && errors.company_name}
                  />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      className={classes.dateContainer}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Box>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="date-picker-occ-start"
                          data-cy="position-start-date"
                          label="Start Date"
                          openTo="year"
                          name="start_date"
                          value={values?.start_date}
                          onChange={(e) => {
                            setFieldValue("start_date", e)
                          }}
                          views={["year", "month", "date"]}
                          style={{ marginRight: "10px" }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Box>
                      <Box>
                        <KeyboardDatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          id="date-picker-occ-end"
                          data-cy="position-end-date"
                          label="End Date"
                          openTo="year"
                          name="end_date"
                          value={values?.end_date}
                          onChange={(e) => {
                            setFieldValue("end_date", e)
                          }}
                          views={["year", "month", "date"]}
                          style={{ marginLeft: "10px" }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disabled={currentJob}
                        />
                      </Box>
                    </Box>
                    <Box display="flex">
                      <Box width="50%" />
                      <FormControlLabel
                        value={currentJob}
                        style={{ marginLeft: "5px" }}
                        control={
                          <Checkbox
                            data-cy="position-current-checkbox"
                            name="current"
                            onChange={() =>
                              currentJob === false
                                ? setCurrentJob(true)
                                : setCurrentJob(false)
                            }
                          />
                        }
                        label="I currently work here"
                        labelPlacement="end"
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                  <Questions
                    key={1}
                    data={data}
                    defaultQuestionsArray={defaultQuestionsArray}
                    setDefaultQuestionsArray={setDefaultQuestionsArray}
                    clicked={clicked}
                    additionalQuestionsArray={additionalQuestionsArray}
                    setAdditionalQuestionsArray={setAdditionalQuestionsArray}
                    setPreferredPosition={setPreferredPosition}
                    positions={positions}
                    board={board}
                    disableText={true}
                  />
                </DialogContent>
              )}
              {activeStep === 2 && (
                <DialogContent>
                  <AppUpload
                    file={file}
                    setFile={setFile}
                    type={"pdf"}
                    ref={childRef}
                    fromApply={true}
                  />
                </DialogContent>
              )}
              <DialogActions>
                <Box display="flex" justifyContent="center" width="100%">
                  <ButtonRounded
                    color="primary"
                    variant="outlined"
                    type="reset"
                    onClick={handleClose}
                    className={classes.actionButton}
                  >
                    Close
                  </ButtonRounded>
                  {activeStep !== 0 && (
                    <ButtonRounded
                      color="primary"
                      variant="contained"
                      type="submit"
                      className={classes.actionButton}
                      onClick={handleBack}
                    >
                      Back
                    </ButtonRounded>
                  )}
                  <ButtonRounded
                    disabled={
                      (activeStep === 0 &&
                        values?.email === "" &&
                        values?.phone === "") ||
                      (activeStep === 1 && defaultQuestionsArray.length === 0)
                    }
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.actionButton}
                    onClick={() => handleSubmit(values, actions)}
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </ButtonRounded>
                </Box>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </>
    </Dialog>
  )
}

export default AddApplicantDialog
