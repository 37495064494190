import { Box, Card, CardContent, Typography } from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import ButtonRounded from "components/Common/ButtonRounded"
import CategoryAreaList from "components/Common/CategoryAreaList"
import Address from "components/Common/DisplayData/Address"
import CoverLetter from "components/Common/DisplayData/CoverLetter"
import Education from "components/Common/DisplayData/Education"
import Ocuppation from "components/Common/DisplayData/Occupation"
import Questions from "components/Common/DisplayData/Questions"
import Resume from "components/Common/DisplayData/Resume"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import formatPhoneNumber from "utils/formatPhone"
import getColor from "utils/getColor"

import ApplicantHistory from "../Boards/ApplicantHistory"
import useStyles from "./styles"

const ApplicantData = ({
  applicantData,
  setHide,
  setMoreData,
  downloadPdf,
  moreData,
  openPdfMutation,
  board,
  hide,
}) => {
  const classes = useStyles()
  const location = useLocation()

  const percentageDisplay = (percentage) => {
    if (percentage !== "NaN") {
      return `${percentage}%`
    } else {
      return null
    }
  }

  return (
    <>
      {!hide && (
        <Box width="100%">
          <Typography
            variant="body2"
            className={classes.title}
            style={{ marginTop: "8px" }}
          >
            Cover Letter
          </Typography>
          <CoverLetter
            name={applicantData?.applicant?.cover_letter}
            openMutation={openPdfMutation}
            resumeId={applicantData?.applicant?.cover_letter_id}
            text={applicantData?.applicant?.cover_letter_copy}
            actions={
              <Box>
                <ButtonRounded
                  onClick={() =>
                    downloadPdf(applicantData?.applicant?.cover_letter_id)
                  }
                >
                  <Typography className={classes.buttonText}>VIEW</Typography>
                </ButtonRounded>
              </Box>
            }
          />
          <Typography variant="body2" className={classes.title}>
            Resume
          </Typography>
          <Resume
            openMutation={openPdfMutation}
            name={applicantData?.applicant?.resume}
            resumeId={applicantData?.applicant?.resume_id}
            actions={
              <Box>
                <ButtonRounded
                  onClick={() =>
                    downloadPdf(applicantData?.applicant?.resume_id)
                  }
                >
                  <Typography className={classes.buttonText}>VIEW</Typography>
                </ButtonRounded>
              </Box>
            }
          />
          <Typography
            variant="body2"
            className={classes.title}
            style={{ marginTop: "8px" }}
          >
            Address
          </Typography>
          <Address
            address={applicantData?.applicant?.address}
            city={applicantData?.applicant?.city}
            county={applicantData?.applicant?.county}
            zipCode={applicantData?.applicant?.zip_code}
            state={applicantData?.applicant?.state}
          />
          <Typography variant="body2" className={classes.title}>
            Occupation / Current Position
          </Typography>
          <Ocuppation
            positionTitle={applicantData?.applicant?.position?.[0]?.title}
            companyName={applicantData?.applicant?.position?.[0]?.company_name}
            startDate={applicantData?.applicant?.position?.[0]?.start_date}
            endDate={applicantData?.applicant?.position?.[0]?.end_date}
            current={applicantData?.applicant?.position?.[0]?.current}
          />
          <Typography variant="body2" className={classes.title}>
            Education
          </Typography>
          <Education
            educationAttained={applicantData?.applicant?.highest_education}
            degree={applicantData?.applicant?.degree}
          />
          {/* <Box
            className={classes.qualifiersContainer}
            display="flex"
            border="1px solid purple"
          > */}
          <Typography variant="body2" className={classes.title}>
            Areas of Experience / Expertise / Interest
          </Typography>

          <Card
            style={{
              display: "flex",
              paddingBottom: "10px",
            }}
          >
            <Box width="33%" paddingRight="4px" paddingLeft="12px">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography variant="body2" className={classes.title}>
                  Areas of Experience
                </Typography>
                {board && (
                  <Typography
                    variant="body2"
                    className={classes.percentage}
                    style={getColor(
                      applicantData?.experience?.match_percent ||
                        applicantData?.applicant?.experience?.match_percent
                    )}
                  >
                    {percentageDisplay(
                      applicantData?.experience?.match_percent ||
                        applicantData?.applicant?.experience?.match_percent
                    )}
                  </Typography>
                )}
              </Box>
              {applicantData && (
                <CategoryAreaList
                  data={
                    applicantData?.experience ||
                    applicantData?.applicant?.experience
                  }
                  allCategoryWelcome={
                    applicantData?.all_experience ||
                    applicantData?.applicant?.all_experience
                  }
                  board={board}
                  moreDataString={applicantData?.applicant?.other_experience}
                  type="experience"
                  setHide={setHide}
                  setMoreData={setMoreData}
                />
              )}
            </Box>
            <Box width="33%" paddingRight="4px" paddingLeft="12px">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography variant="body2" className={classes.title}>
                  Areas of Expertise
                </Typography>
                {board && (
                  <Typography
                    style={getColor(
                      applicantData?.expertise?.match_percent ||
                        applicantData?.applicant?.expertise?.match_percent
                    )}
                    variant="body2"
                    className={classes.percentage}
                  >
                    {percentageDisplay(
                      applicantData?.expertise?.match_percent ||
                        applicantData?.applicant?.expertise?.match_percent
                    )}
                  </Typography>
                )}
              </Box>
              {applicantData && (
                <CategoryAreaList
                  data={
                    applicantData?.expertise ||
                    applicantData?.applicant?.expertise
                  }
                  allCategoryWelcome={
                    applicantData?.all_expertise ||
                    applicantData?.applicant?.all_expertise
                  }
                  board={board}
                  moreDataString={applicantData?.applicant?.other_expertise}
                  type="expertise"
                  setHide={setHide}
                  setMoreData={setMoreData}
                />
              )}
            </Box>
            <Box width="33%" paddingRight="4px" paddingLeft="12px">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography variant="body2" className={classes.title}>
                  Areas of Interest
                </Typography>
                {board && (
                  <Typography
                    variant="body2"
                    className={classes.percentage}
                    style={getColor(
                      applicantData?.interest?.match_percent ||
                        applicantData?.applicant?.interest?.match_percent
                    )}
                  >
                    {percentageDisplay(
                      applicantData?.interest?.match_percent ||
                        applicantData?.applicant?.interest?.match_percent
                    )}
                  </Typography>
                )}
              </Box>
              {applicantData && (
                <CategoryAreaList
                  data={
                    applicantData?.interest ||
                    applicantData?.applicant?.interest
                  }
                  allCategoryWelcome={
                    applicantData?.all_interest ||
                    applicantData?.applicant?.all_interest
                  }
                  board={board}
                  moreDataString={applicantData?.applicant?.other_interest}
                  type="interest"
                  setHide={setHide}
                  setMoreData={setMoreData}
                />
              )}
            </Box>
          </Card>
          {/* </Box> */}
          <Typography variant="body2" className={classes.title}>
            References
          </Typography>
          {applicantData?.applicant?.references?.length > 0 ? (
            applicantData?.applicant?.references?.map((reference, i) => {
              return (
                <Card className={classes.card} key={i}>
                  <Box
                    display="flex"
                    width="100%"
                    className={classes.referenceContainer}
                  >
                    <Box flexGrow={1} width="100%">
                      <Typography className={classes.nameText}>
                        {reference.full_name}
                      </Typography>
                      <Box
                        className={classes.textGray}
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Typography variant="body1">
                          {reference.relationship}
                        </Typography>
                        <FiberManualRecordIcon className={classes.dot} />
                        <Typography variant="body1">
                          {formatPhoneNumber(reference.phone)}
                        </Typography>
                        <FiberManualRecordIcon className={classes.dot} />
                        <Typography variant="body1">
                          {reference.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              )
            })
          ) : (
            <Card>
              <CardContent style={{ padding: "15px" }}>
                <Typography>No References Listed</Typography>
              </CardContent>
            </Card>
          )}
          <Typography variant="body2" className={classes.title}>
            Questions
          </Typography>
          <Questions
            defaultQuestions={applicantData?.applicant?.default_answers}
            additionalQuestions={applicantData?.applicant?.additional_answers}
          />
          {applicantData?.applicant_history && (
            <>
              <Typography variant="body2" className={classes.title}>
                Applicant History
              </Typography>
              <ApplicantHistory
                applicantHistory={applicantData?.applicant_history}
              />
            </>
          )}
        </Box>
      )}
      {hide ? (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h3"
              color="primary"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >{`More ${moreData?.type}`}</Typography>
            {location?.search !== "" ? (
              <ButtonRounded
                onClick={() => setHide(false)}
                variant="contained"
                color="primary"
              >
                Back
              </ButtonRounded>
            ) : null}
          </Box>

          <Typography>{moreData?.string}</Typography>
        </Box>
      ) : null}
    </>
  )
}

ApplicantData.propTypes = {
  applicantData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setHide: PropTypes.func,
  setMoreData: PropTypes.func,
  downloadPdf: PropTypes.func,
  openPdfMutation: PropTypes.object,
  moreData: PropTypes.object,
  board: PropTypes.bool,
  hide: PropTypes.bool,
}

export default ApplicantData
