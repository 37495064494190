import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
    "& .MuiListItemText-secondary": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
  },

  dateText: {
    fontSize: "1.2rem",
    lineHeight: "1.2rem",
    fontWeight: 300,
    marginBottom: theme.spacing(2),
  },
}))

export default styles
