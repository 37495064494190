import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    closeButton: {
      position: "absolute",
      top: 1,
      right: 1,
    },
    buttonWrapper: {
      position: "relative",
    },
    container: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
    },
  }),
  {
    name: "WelcomBanner",
  }
)

export default styles
