import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      flexShrink: 0,
      zIndex: 50,
    },
    inputBackgroundColor: {
      backgroundColor: "rgb(89, 89, 89,0.1)",
    },
    icon: {
      color: "#898989",
    },
    welcomeText: {
      fontSize: "1.8rem",
      color: "#898989",
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(4),
      },
    },
  }),
  {
    name: "Searchbar",
  }
)

export default styles
