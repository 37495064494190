import { AppBar, Box, Tab, Tabs } from "@material-ui/core"
import AppContext from "contexts/App"
import MessagesContext from "contexts/Messages"
import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import useStyles from "./styles"

const AppBarPhone = () => {
  const classes = useStyles()

  const location = useLocation()
  const history = useHistory()

  const { token, isAdmin, isSuperAdmin } = useContext(AppContext)
  const { selectedThread, setSelectedThread } = useContext(MessagesContext)

  const [tabValue, setTabValue] = useState("")

  const adminTabs = [
    {
      label: "Dashboard",
      value: "dashboard",
    },
    {
      label: "Applicants",
      value: "applicants",
    },
    {
      label: "Boards",
      value: "boards",
    },
    {
      label: "Messages",
      value: "messages",
    },
    {
      label: "Analytics",
      value: "analytics",
    },
  ]

  const superAdminTabs = [
    {
      label: "Requests",
      value: "requests",
    },
    {
      label: "Entities",
      value: "entities",
    },
    {
      label: "Admins",
      value: "admins",
    },
  ]

  useEffect(() => {
    if (isSuperAdmin && !isAdmin) {
      setTabValue(superAdminTabs?.[0]?.value)
    } else {
      setTabValue(adminTabs?.[0]?.value)
    }
  }, [isSuperAdmin, isAdmin])

  const handleChangeTab = (value) => {
    history.push(`/${value}`)
    setTabValue(value)
  }

  // keeps tab highlighted if an id of a board, message, entity, is selected
  useEffect(() => {
    if (location.pathname.includes("boards")) {
      setTabValue("boards")
    } else if (location.pathname.includes("messages")) {
      setTabValue("messages")
    } else if (location.pathname.includes("entities")) {
      setTabValue("entities")
    } else if (location.pathname.includes("analytics")) {
      setTabValue("analytics")
    } else {
      setTabValue(location.pathname.slice(1))
    }
  }, [location.pathname])

  return token ? (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Box display="flex" flexDirection="row" flexGrow="1" width="100vw">
          <Tabs
            value={tabValue}
            variant={
              (isSuperAdmin && !isAdmin) || (isAdmin && !isSuperAdmin)
                ? "fullWidth"
                : "scrollable"
            }
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            aria-label="mobile-app-bar-tabs"
            style={{ width: "100vw" }}
          >
            {isAdmin &&
              adminTabs.map((x, i) => {
                return (
                  <Tab
                    key={i}
                    fullWidth
                    label={x.label}
                    aria-label={x.label}
                    onClick={() => {
                      handleChangeTab(`${x.value}`)
                      if (x.value === "messages") {
                        setSelectedThread(null)
                      }
                    }}
                    value={x.value}
                    classes={{ wrapper: classes.adminTabWrapper }}
                  />
                )
              })}
            {isSuperAdmin &&
              superAdminTabs.map((x, i) => {
                return (
                  <Tab
                    key={i}
                    fullWidth
                    label={x.label}
                    onClick={() => handleChangeTab(`${x.value}`)}
                    value={x.value}
                    classes={{ wrapper: classes.superTabWrapper }}
                  />
                )
              })}
          </Tabs>
        </Box>
      </AppBar>
    </div>
  ) : null
}

AppBarPhone.propTypes = {
  children: PropTypes.node,
}

export default AppBarPhone
