import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  pageWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  dataContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  textBtn: {
    padding: 0,
    minWidth: 0,
  },
  startIcon: {
    marginRight: "2px",
  },
  leftContainer: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  imageContainer: {
    backgroundColor: "#E2E7EF",
    height: "220px",
    width: "220px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    position: "relative",
  },
  cardRoot: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  avatar: {
    height: "160px",
    width: "160px",
    margin: "auto",
    backgroundColor: "white",
    border: "none",
  },
  pencilIcon: {
    color: theme.palette.primary.dark,
    fontSize: "10px",
    padding: "6px",
    position: "absolute",
    left: "5px",
    bottom: "5px",
  },
  middleContainer: {
    maxWidth: "60%",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

export default styles
