import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      boxShadow: "0px -3px 6px #0000001A",
    },
    container: {
      height: "100%",
      overflow: "auto",
      // overflow: "hidden",
      position: "relative",
      [theme.breakpoints.down("xs")]: {
        overflow: "hidden",
        padding: 0,
      },
    },
  }),
  {
    name: "BoardPage-Citizen",
  }
)

export default styles
