import { Box, Grow, IconButton, Typography } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import AppContext from "contexts/App"
import ClerkAppContext from "contexts/Clerk/ClerkApp"
import PropTypes from "prop-types"
import { useState } from "react"
import { useContext } from "react"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

import useStyles from "./styles"

const WelcomeBanner = ({ children }) => {
  const queryClient = useQueryClient()
  const classes = useStyles()
  const { authUser, setAuthData, token } = useContext(AppContext)
  const { updateNewUserMutation } = useContext(ClerkAppContext)
  const history = useHistory()
  const [showBanner, setShowBanner] = useState(Boolean(authUser?.new_user))

  const disableWelcomeMessage = () => {
    setShowBanner(false)
    updateNewUserMutation
      ?.mutateAsync()
      .then((res) => {
        setAuthData({ token: token, account: res.data })
        queryClient.setQueryData("accountDetails", (oldData) => {
          let newData = res.data
          return newData
        })
      })
      .catch((err) => console.log(err))
  }

  return showBanner ? (
    <Grow in={showBanner}>
      <Box className={classes.container}>
        <Box className={classes.leftContainer}>
          <Typography variant="h2" className={classes.title} color="primary">
            Welcome to the dashboard.
          </Typography>
          <Typography className={classes.subtitle} color="primary">
            Here you will find an overview of your organization. Add your first
            board/council to begin filling the dashboard and receiving
            applicant.
          </Typography>
        </Box>
        <Box className={classes.optionsBox}>
          <Typography variant="h6" className={classes.optionsTitle}>
            There are two options to help get you started:
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography className={classes.optionsText}>
              1. Manually add in each board
              <Typography
                component="span"
                className={classes.hereText}
                onClick={() => {
                  history.push("/boards")
                }}
              >
                here
              </Typography>
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.optionsText}>
              2. Download our pre-made excel template
              <Typography
                component="span"
                className={classes.hereText}
                onClick={() =>
                  (window.location.href =
                    "/matchboard-board-import-template.xlsm")
                }
              >
                here
              </Typography>
            </Typography>
          </Box>
        </Box>
        <IconButton
          onClick={disableWelcomeMessage}
          disabled={updateNewUserMutation?.isLoading}
          className={classes.button}
        >
          <ClearIcon />
        </IconButton>
      </Box>
    </Grow>
  ) : null
}

WelcomeBanner.propTypes = {
  children: PropTypes.node,
}

export default WelcomeBanner
