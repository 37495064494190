import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import AppContext from "contexts/App"
import PropTypes from "prop-types"
import { useContext } from "react"

import useStyles from "./styles"

const ClickConfirmation = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(AppContext)
  const thisDialog = dialogs?.["clickConfirmation"] || {}
  const { open = false, data = {} } = thisDialog

  let action = data?.action || ""
  let confirmText = data?.confirmText || ""
  let cancelText = data?.cancelText || "Close"
  let deferAll = data?.deferAll || false
  // to change copy on Defer All dialog per client's request

  const handleClose = () => {
    closeDialog("clickConfirmation", false)
  }

  const handleDelete = () => {
    closeDialog("clickConfirmation", true)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h6" style={{ lineHeight: 1.6 }}>
          {deferAll
            ? `Do you want to ${action}`
            : `Are you sure you want to ${action}`}
        </Typography>
      </DialogTitle>
      {/* <DialogContent>confirmation</DialogContent> */}
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <ButtonRounded
            color="primary"
            variant="outlined"
            onClick={handleClose}
            type="button"
          >
            {cancelText}
          </ButtonRounded>
          <ButtonRounded
            color="primary"
            variant="contained"
            onClick={handleDelete}
            type="button"
            className={classes.saveButton}
          >
            {confirmText}
          </ButtonRounded>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
ClickConfirmation.propTypes = {
  values: PropTypes.object,
}

export default ClickConfirmation
