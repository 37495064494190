import api from "store/api"
import serialize from "store/serialize"

export const applyBoard = (boardId, data) => {
  return api.post(`/app/boards/${boardId}`, data).then((res) => res.data.data)
}

export const getBoardsById = (boardId) => {
  return api
    .get(`/app/boards/${boardId}`)
    .then((res) => serialize("board", res.data.data.board))
}

export const getBoardMemberRenewal = ({ token }) => {
  return api.get(`/app/boards/renew/${token}`).then((res) => res.data)
}

export const updateBoardMemberRenewal = (memberId, data) => {
  return api.put(`/app/boards/renew/${memberId}`, data).then((res) => res.data)
}
