import { Box } from "@material-ui/core"
import AnalyticsCard from "components/Clerk/Boards/Analytics"
import Page from "pages/Page"

import useStyles from "./styles"

const Analytics = () => {
  const classes = useStyles()

  return (
    <Page>
      <Box className={classes.container}>
        <AnalyticsCard />
      </Box>
    </Page>
  )
}

export default Analytics
