import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    mobileRoot: {
      backgroundColor: "#fff",
      boxShadow: "0px 3px 6px #0000021A",
      zIndex: 20,
    },
    adminTabWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    superTabWrapper: {
      display: "flex",
      justifyContent: "center",
    },
  }),
  {
    name: "AppBarPhone",
  }
)

export default styles
