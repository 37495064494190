import { Box, Typography } from "@material-ui/core"
import SendMessage from "components/Forms/SendMessage"
import MessageContext from "contexts/Message"
import MessagesContext from "contexts/Messages"
import { useContext, useEffect, useRef } from "react"

import MessagesList from "../MessagesList"
import useStyles from "./styles"

const MessageComposer = () => {
  const classes = useStyles()
  const messageContainer = useRef(null)

  const { messages, messageDataLoading } = useContext(MessageContext)
  const { selectedThread } = useContext(MessagesContext)

  // If messageContainer isn't null scroll to bottom
  useEffect(() => {
    if (messageContainer.current) {
      const scrollHeight = messageContainer.current.scrollHeight
      messageContainer.current.scrollTo(0, scrollHeight)
    }
  }, [messages])

  return (
    <>
      {selectedThread?.thread_id ? (
        <Box className={classes.root}>
          <Box className={classes.messageSenderBox} display="flex">
            <Typography variant="h5">{selectedThread.name}</Typography>
            {selectedThread.pronoun && selectedThread?.pronoun_id !== 4 && (
              <Typography className={classes.pronoun}>
                {"(" + selectedThread.pronoun + ")"}
              </Typography>
            )}
          </Box>
          <Box className={classes.messagesListBox} ref={messageContainer}>
            <MessagesList
              messages={messages}
              data={selectedThread}
              isLoading={messageDataLoading}
            />
          </Box>
          <Box>
            <SendMessage
              data={selectedThread}
              messageContainer={messageContainer}
            />
          </Box>
        </Box>
      ) : null}
    </>
  )
}

MessageComposer.propTypes = {}

export default MessageComposer
