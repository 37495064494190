import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    contentContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      // overflow: "auto",
      justifyContent: "space-between",
      marginTop: "20px",
      marginBottom: "20px",
      position: "relative",
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "50px",
      },
    },
    tab: {
      color: "#190D27",
      opacity: 0.3,
      fontWeight: 400,
      minWidth: "initial",
      padding: "6px 12px",
      "&.Mui-selected": {
        backgroundColor: "#f0fafb",
        opacity: 1,
        fontWeight: 700,
      },
    },
    wrapper: {
      justifyContent: "center",
    },
    viewContainer: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      overflow: "auto",
    },
    leftContainer: {
      width: "30%",
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
    middleContainer: {
      width: "30%",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    rightContainer: {
      width: "30%",
      display: "flex",
      justifyContent: "flex-start",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center",
      },
    },
  }),
  {
    name: "MyAccount",
  }
)

export default styles
