import api from "store/api"
import serialize from "store/serialize"

export const addQuestion = (boardId, data) => {
  return api
    .post(`/admin/boards/${boardId}/question`, data)
    .then((res) => res.data.data)
}

export const removeQuestion = (boardId, questionId) => {
  return api
    .delete(`/admin/boards/${boardId}/question/${questionId}`)
    .then((res) => res.data.data)
}

export const updateBoard = (boardId, data) => {
  return api.put(`/admin/boards/${boardId}`, data).then((res) => res.data.data)
}
export const getOptions = () => {
  return api.get("/admin/boards/options").then((res) => res.data.data)
}

export const getBoardApplicants = (boardId) => {
  return api
    .get(`/admin/boards/${boardId}/applicants/`)
    .then((res) => res.data.data)
}

export const getBoardApplicantById = (boardId, applicantId) => {
  return api
    .get(`/admin/boards/${boardId}/applicants/${applicantId}`)
    .then((res) => serialize("applicantResponse", res.data.data))
}

export const getApplicantsWithStatus = (boardId, statusId) => {
  return api
    .get(`/admin/boards/${boardId}/applicants/status/${statusId}`)
    .then((res) => res.data.data.applicants)
}

export const appointApplicant = (boardId, moreData) => {
  const { applicantId, sitting_member_id, position_id } = moreData
  let data = { sitting_member_id: sitting_member_id, position_id: position_id }
  return api
    .post(`/admin/boards/${boardId}/applicants/${applicantId}`, data)
    .then((res) => res.data.data)
}

export const updateBoardMember = (boardId, moreData) => {
  const { id, member_id, ...other } = moreData
  let data = other
  return api
    .put(`/admin/boards/${boardId}/members/${member_id || id}`, data)
    .then((res) => res.data.data)
}
export const addBoardMember = (boardId, data) => {
  return api
    .post(`/admin/boards/${boardId}/members/`, data)
    .then((res) => res.data.data)
}

export const getResume = (resumeId) => {
  return api
    .get(`/admin/boards/file/`, {
      params: {
        id: resumeId,
      },
    })
    .then((res) => res.data.data)
}

export const getBoardReviews = (boardId) => {
  return api
    .get(`/admin/boards/${boardId}/reviews`)
    .then((res) => res.data.data.reviews)
}

export const shareApplicantsForReview = (boardId, data) => {
  return api.post(`/admin/boards/${boardId}/review`, data).then((res) => res)
}

export const addPosition = (mutationData) => {
  let { boardId, position } = mutationData
  let data = { position: position }
  return api
    .post(`/admin/boards/${boardId}/positions`, data)
    .then((res) => res.data.data)
}

export const addApplication = (boardId, data) => {
  return api
    .post(`/admin/boards/${boardId}/applicant/new`, data)
    .then((res) => res.data)
}

export const deletePosition = (mutationData) => {
  let { boardId, positionId } = mutationData
  return api
    .delete(`/admin/boards/${boardId}/positions/${positionId}`)
    .then((res) => res)
}

export const updatePosition = (mutationData) => {
  let { boardId, positionId, position, memberId } = mutationData
  let data = { position: position, member_id: memberId }
  return api
    .put(`/admin/boards/${boardId}/positions/${positionId}`, data)
    .then((res) => res.data.data)
}

export const getBoardAnalytics = (boardId, entityId) => {
  return api
    .get(`/admin/boards/${boardId}/analytics/${entityId}`)
    .then((res) => res.data.data)
}
