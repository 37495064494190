import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: "#fff",
      boxShadow: "0px 3px 6px #0000001A",
    },
    logoBox: {
      cursor: "pointer",
    },
    dataBar: {
      backgroundColor: "#E2E7EF",
      height: "70px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        height: "130px",
      },
    },
    boardData: {
      display: "flex",
      flexDirection: "row",
      width: "80%",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flexWrap: "wrap",
      },
    },
    boardName: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 30px 10px 0",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
        margin: 0,
      },
    },
    boardTerm: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 30px",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
        margin: 0,
      },
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        justifyContent: "flex-end",
      },
    },
    gridContainer: {
      width: "80%",
      margin: "0 auto",
      height: "calc(100% - 144px)",
      paddingBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    gridItem: {
      height: "100%",
      overflow: "auto",
    },
    drawer: {
      height: "88%",
      borderRadius: "20px 20px 0 0",
    },
    collapseLine: {
      height: "4px",
      marginTop: "20px",
      marginBottom: "20px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100px",
      backgroundColor: "#707070",
      opacity: 0.21,
      position: "relative",
      top: 0,
    },
    showingText: {
      fontSize: "1.4rem",
      color: "#898989",
    },
  }),
  {
    name: "InternalReviewContent",
  }
)

export default styles
