import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    container: {
      overflow: "hidden",
      height: "100%",
      display: "flex",
      position: "relative",
      flexDirection: "column",
    },
    titleBox: {
      width: "65%",
      flexWrap: "wrap",
      [theme.breakpoints.down("xs")]: {
        flexGrow: 1,
      },
    },
    upperButtonBox: {
      width: "35%",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      [theme.breakpoints.down("xs")]: {
        width: "15%",
      },
    },
    scrollArea: {
      height: "100%",
      overflowY: "auto",
      padding: "0 30px 0 10px",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        overflowY: "auto",
        overflowX: "hidden",
        padding: "0 30px",
      },
      [theme.breakpoints.down("xs")]: {
        overflowY: "auto",
        overflowX: "hidden",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    actionsContainer: {
      borderTop: "1px solid #E5E5E5",
    },
    applicantsNumber: {
      fontSize: "3.4rem",
    },
    dataContainer: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    infoBoxTitle: {
      fontSize: "1rem",
      color: "#898989",
      fontWeight: 500,
    },
    infoBoxData: {
      color: theme.palette.secondary.dark,
    },
    urlLink: {
      color: theme.palette.secondary.dark,
      wordWrap: "break-word",
      cursor: "pointer",
    },
    buttonBox: {
      flexShrink: "0",
      paddingTop: "30px",
      display: "flex",
      justifyContent: "center",
      marginTop: "auto",
      position: "relative",
      backgroundColor: "white",
    },
    entityName: {
      fontWeight: 500,
      marginRight: "6px",
      fontSize: "1.075em",
    },
    entityType: {
      opacity: 0.5,
      fontSize: "1.075em",
    },
    buttonBoxMobile: {
      flexShrink: "0",
      padding: "20px",
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      position: "relative",
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.primary.main,
    },
  }),
  {
    name: "CitizenBoardView",
  }
)

export default styles
