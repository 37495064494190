import { Box, Container } from "@material-ui/core"
import home from "assets/consumer_background.png"
import RequestClerkBox from "components/Clerk/RequestClerkBox"
import AppContext from "contexts/App"
import { RequestClerkContainer } from "contexts/Clerk/RequestClerk"
import ClerkRequest from "dialogs/ClerkRequest"
import Page from "pages/Page"
import { useContext, useEffect } from "react"

import useStyles from "./styles"

const RequestInvitation = () => {
  const classes = useStyles()
  const { token, removeAuthToken } = useContext(AppContext)

  useEffect(() => {
    removeAuthToken()
  }, [token])

  return (
    <Page>
      <RequestClerkContainer>
        <Container
          disableGutters
          maxWidth="xl"
          style={{
            backgroundImage: `url(${home})`,
            margin: 0,
            padding: 0,
            height: "100vh",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
          }}
        >
          <Box className={classes.textBox}>
            <RequestClerkBox />
          </Box>
        </Container>
        <ClerkRequest />
      </RequestClerkContainer>
    </Page>
  )
}

export default RequestInvitation
