import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import ButtonRounded from "components/Common/ButtonRounded"
import SeatMemberListItem from "components/Common/SeatMemberListItem"
import CitizenAppContext from "contexts/Citizen/CitizenApp"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import transformCaseAndSpaces from "utils/transformCaseAndSpaces"

import useStyles from "./styles"

const IframeBoardCard = ({ data }) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"))
  const { selectedCategories } = useContext(CitizenAppContext)
  const board = data

  const [showMembers, setShowMembers] = useState(false)
  const handleToggleCollapse = () => {
    setShowMembers((oldState) => !oldState)
  }

  const displayTerm = () => {
    if (data?.term?.toLowerCase() === "other") {
      return "Term Limit Varies"
    } else if (data?.term?.toLowerCase() === "no term limit") {
      return "No Term Limit"
    } else {
      return `${transformCaseAndSpaces(board?.term)} Term`
    }
  }

  const findOpenPositions = board?.board_positions.filter(
    (x) =>
      !x.active ||
      dayjs(x.term_expiration).isBefore(dayjs(new Date()).add(90, "days"))
  )
  const findfilledPositions = board?.board_positions.filter(
    (x) =>
      x.active &&
      !dayjs(x.term_expiration).isBefore(dayjs(new Date()).add(90, "days"))
  )
  const openPositions = findOpenPositions?.length
  const filledPositions = findfilledPositions?.length
  const totalPositions = filledPositions + openPositions

  const navigateToEntity = () => {
    const type = board?.entity_type
    const name = board?.entity
    const entityId = board?.entity_id
    const boardId = board?.id
    const entityState = board?.state
    let query = `/boards?entityId=${entityId}&entityState=${entityState}&name=${name}&type=${type}&boardId=${boardId}`
    if (selectedCategories?.length > 0) {
      query = query + `&categories=${selectedCategories?.map((x) => x.id)}`
      history.push(query)
    } else {
      history.push(query)
    }
  }

  return (
    <Card className={classes.container}>
      <CardContent style={{ padding: "18px" }}>
        <Box className={classes.titleBox}>
          <Typography variant="h6">{board?.name}</Typography>
        </Box>
        <Box className={classes.dataContainer} flexDirection="row">
          <Typography
            className={classes.infoBoxData}
            color="secondary"
            variant="body1"
          >
            <span>
              {openPositions} of {totalPositions} Available
            </span>
            <span> • </span>
            <span> {displayTerm()} </span>
          </Typography>
        </Box>
        <Box className={classes.dataContainer}>
          <Typography variant="body1" className={classes.bodyText}>
            {board?.summary}
          </Typography>
        </Box>
        <Box className={classes.dataContainer}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className={classes.infoBoxTitle}>
              CURRENT SEAT MEMBERS
            </Typography>
            <Button color="primary" onClick={handleToggleCollapse}>
              {!showMembers ? (
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.bodyText}
                >
                  VIEW MEMBERS
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.bodyText}
                >
                  HIDE MEMBERS
                </Typography>
              )}
            </Button>
          </Box>
          <Collapse in={showMembers}>
            <Grid
              container
              spacing={3}
              style={
                !xsScreen ? { marginLeft: "5px", marginRight: "5px" } : null
              }
            >
              {board?.board_positions.map((p, i) => {
                return (
                  <Grid item key={i} xs={6} sm={4}>
                    <SeatMemberListItem
                      member={p}
                      vacant={p?.first_name ? false : true}
                      boardTerm={board?.term}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Collapse>
        </Box>
        <Box className={classes.bottomBox}>
          <Box className={classes.chipContainer}>
            {board?.interest?.map?.((x, i) => (
              <Chip
                key={i}
                label={x?.name}
                color="primary"
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
              />
            ))}
          </Box>
          <Box
            height="30px"
            display="flex"
            style={smallScreen ? { justifyContent: "flex-end" } : null}
          >
            <ButtonRounded
              variant="contained"
              color="primary"
              onClick={navigateToEntity}
              classes={{ label: classes.buttonLabel }}
            >
              Learn More
            </ButtonRounded>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

IframeBoardCard.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
}

export default IframeBoardCard
