import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import { updateBoard } from "actions/Clerk/board"
import ConditionalDialogWrapper from "components/Common/ConditionalDialogWrapper"
import UpdateBoardForm from "components/Forms/UpdateBoard"
import AppContext from "contexts/App"
import { useContext } from "react"
import { useMutation } from "react-query"

import useStyles from "./styles"

const UpdateBoardFromEntity = () => {
  const classes = useStyles()

  const { dialogs, closeDialog } = useContext(AppContext)

  const thisDialog = dialogs?.["updateBoardFromEntity"] || {}
  const { open = false, data = {} } = thisDialog
  let board = thisDialog?.data?.board
  const handleClose = () => {
    closeDialog("updateBoardFromEntity")
  }

  const updateBoardMutation = useMutation((data) =>
    updateBoard(board?.id, data)
  )

  return (
    <ConditionalDialogWrapper
      open={open}
      newClasses={{ paper: classes.dialog }}
    >
      <DialogTitle disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2">Update Board</Typography>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <UpdateBoardForm
          board={board}
          updateBoardMutation={updateBoardMutation}
          openFrom="entity"
          handleClose={handleClose}
        />
      </DialogContent>
    </ConditionalDialogWrapper>
  )
}

export default UpdateBoardFromEntity
