import { Box, Card, Typography } from "@material-ui/core"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import PropTypes from "prop-types"

import useStyles from "./styles"

const Questions = ({ defaultQuestions, additionalQuestions }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Box className={classes.container}>
        {defaultQuestions?.map((q, i) => {
          return (
            <Box key={i}>
              <Typography variant="body2" className={classes.question}>
                {q?.question}
              </Typography>
              <Typography>
                <FiberManualRecordIcon className={classes.dot} />
                {q?.answer}
              </Typography>
            </Box>
          )
        })}
        {additionalQuestions?.map((q, i) => {
          return (
            <Box key={i}>
              <Typography variant="body2" className={classes.question}>
                {q?.question}
              </Typography>
              <Typography>
                <FiberManualRecordIcon className={classes.dot} />
                {q?.answer}
              </Typography>
            </Box>
          )
        })}
      </Box>
    </Card>
  )
}

Questions.propTypes = {
  defaultQuestions: PropTypes.array,
  additionalQuestions: PropTypes.array,
}

export default Questions
