import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {},
  },
  [theme.breakpoints.down("xs")]: {
    rightContainer: {
      height: "100vh",
      // display: "flex",
      // justifyContent: "center",
    },
  },
}))

export default styles
