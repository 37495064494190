const UserModel = {
  id: "number",
  first_name: "string",
  last_name: "string",
  references: "array",
  interest: "array",
  expertise: "array",
  experience: "array",
  position: "array",
  review_periods: "array",
}

export default UserModel
