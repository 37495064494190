import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  textGray: {
    color: "#898989",
  },
  dot: {
    fontSize: "8px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  referenceContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  buttonText: {
    fontSize: "1rem",
    color: theme.palette.primary.dark,
  },
  nameText: {
    fontSize: "1.4rem",
  },
  card: {
    width: "100%",
    minHeight: "80px",
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
}))

export default styles
