import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import MessageComposer from "components/Common/Messages/MessageComposer"
import { MessageContainer } from "contexts/Message"
import MessagesContext from "contexts/Messages"
import Page from "pages/Page"
import { useContext } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"

import useStyles from "./styles"

const List = () => {
  const classes = useStyles()
  const theme = useTheme()
  const ipadView = useMediaQuery(theme.breakpoints.down("md"))
  const history = useHistory()
  const match = useRouteMatch()

  const { selectedThread, setSelectedThread } = useContext(MessagesContext)

  return (
    <Page>
      <Box className={classes.root}>
        {ipadView && (
          <Button
            color="primary"
            startIcon={<KeyboardBackspaceIcon />}
            style={{
              backgroundColor: "transparent",
              alignSelf: "flex-start",
            }}
            classes={{
              text: classes.textBtn,
              startIcon: classes.startIcon,
            }}
            onClick={() => {
              setSelectedThread(null)
              history.push("/messages")
            }}
          >
            Back
          </Button>
        )}
        <MessageContainer messageId={match?.params?.message_id}>
          <MessageComposer />
        </MessageContainer>
      </Box>
    </Page>
  )
}

export default List
