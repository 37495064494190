import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  actionButton: {
    margin: theme.spacing(1),
  },

  textField: {
    marginBottom: theme.spacing(2),
  },
  uploadFileContainer: {
    backgroundColor: "#E2E7EF",
    width: "80%px",
    height: "150px",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
  },
  uploadFileSubcontainer: {
    width: "100%",
    height: "80px",
    border: "1px #BCBFC3 dashed",
    borderRadius: "5px",
  },
}))

export default styles
