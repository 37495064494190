import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    applicantContainer: {
      display: "flex",
      flexDirection: "row",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    checkboxContainer: {
      width: "10%",
      display: "flex",
      flexDirection: "row",
    },
    checkbox: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    selected: {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `5px solid ${theme.palette.primary.dark}`,
      paddingLeft: theme.spacing(1.3),
    },
    middleContainer: {
      width: "65%",
      display: "flex",
      flexDirection: "row",
      "&:hover": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        flexDirection: "column",
        flexGrow: 1,
      },
    },
    underline: {
      "&:hover": {
        textDecoration: "underline",
      },
    },
    rightContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexGrow: 1,
      width: "30%",
      justifyContent: "flex-end",
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        width: "40%",
      },
    },
    dateContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "40%",
    },
    starIcon: {
      fontSize: 16,
      // "&:hover": {
      //   color: theme.palette.primary.dark,
      //   filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.9))",
      // },
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      // width: "60%",
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(1),
        flexDirection: "column",
        height: "150px",
      },
    },
    buttonLeftContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    applicationButton: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(1),
        marginLeft: 0,
      },
    },
  }),
  {
    name: "ApplicantsList",
  }
)

export default styles
