import api from "store/api"

export const createSuperAdmin = (data) => {
  return api.post("/admin/super/users", data).then((res) => res.data.data)
}

export const createAdmin = (mutationData) => {
  let { entityId, ...data } = mutationData
  return api
    .post(`/admin/super/entities/${entityId}/user`, data)
    .then((res) => res.data.data)
}

export const updateAdmin = (mutationData) => {
  let { userId, ...data } = mutationData
  return api
    .put(`/admin/super/users/${userId}`, data)
    .then((res) => res.data.data)
}

export const changePassword = (data) => {
  return api.put("/admin/users/auth", data).then((res) => res.data)
}

export const updatePassword = (data) => {
  return api.put("/admin/users/password", data).then((res) => res.data)
}

export const checkApprovalToken = (code) => {
  return api.get(`admin/users/approval/${code}`).then((res) => res.data)
}

export const getAdmins = (query) => {
  return api.get(`admin/super/admins?${query}`).then((res) => res.data)
}

export const updateNewUser = () => {
  return api.post("admin/users/new-user/").then((res) => res.data)
}

export const forgotPassword = (data) => {
  return api.post("/admin/users/forgot", data)
}

export const updateForgotPassword = (data) => {
  return api.put("/admin/users/forgot", data)
}
export const getPin = (data) => {
  return api.post("/admin/users/pin", data)
}

// Get notifications
export const getNotifications = (entityId) => {
  return api
    .get(`/admin/entities/${entityId}/notifications`)
    .then((res) => res.data.data.notifications)
}

export const verifyEmail = (code) => {
  return api.put(`admin/users/email/${code}`).then((res) => res.data)
}

export const checkPhone = (phone) => {
  return api.get(`admin/users/phone/${phone}`).then((res) => res.data)
}

export const checkEmail = (email) => {
  return api.get(`admin/users/email/${email}`).then((res) => res.data)
}
