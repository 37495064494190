import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  inputBox: {
    // marginBottom: theme.spacing(2.5),
  },
  inputLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  form: {
    margin: "0 -30px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  scrollArea: {
    height: "100%",
    overflow: "auto",
    padding: "0 30px",
  },
  scrollAreaEntity: {
    height: "600px",
    overflow: "auto",
    padding: "0 30px",
  },
  buttonBox: {
    flexShrink: "0",
    margin: "10px",
    display: "flex",
    justifyContent: "center",
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  label: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: "1.6rem",
  },
  formHelperText: {
    marginLeft: "0px",
    position: "absolute",
    bottom: "-20px",
  },
  redText: {
    color: theme.palette.error.main,
  },
}))

export default styles
