import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import logo from "assets/logo-icon.svg"
import ButtonRounded from "components/Common/ButtonRounded"
import ListItemWithContent from "components/Common/ListItemWithContent"
import EntityGeneralInfo from "components/Forms/EntityGeneralInfo"
import AppContext from "contexts/App"
import EntityContext from "contexts/Clerk/Entity"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import getScaledImage from "utils/getScaledImage"

import useStyles from "./styles"

const EntityLeftContainer = () => {
  const classes = useStyles()
  const history = useHistory()

  const { entityData, entityDataLoading } = useContext(EntityContext)
  const { openDialog } = useContext(AppContext)

  const openUploadImage = (image) => {
    let data = { image: image, entityId: entityData.id }
    openDialog("uploadEntityImage", data)
  }

  const scaledImage = (img) => {
    return getScaledImage(img, 200, 200)
  }

  const openAddAdmin = () => {
    let data = { from: "entity", entityId: entityData?.id }
    openDialog("createAdmin", data)
  }

  if (entityDataLoading) {
    return (
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    )
  } else {
    return (
      <Box className={classes.pageWrapper}>
        <Box height="48px" pt={1} pl={1}>
          <Button
            color="primary"
            startIcon={<KeyboardBackspaceIcon />}
            style={{ backgroundColor: "transparent" }}
            classes={{
              text: classes.textBtn,
              startIcon: classes.startIcon,
            }}
            onClick={() => history.push("/entities")}
          >
            Back
          </Button>
        </Box>
        <Box className={classes.dataContainer}>
          <Box className={classes.leftColumn}>
            <Box className={classes.imageContainer}>
              <Avatar
                className={classes.avatar}
                alt="current user profile picture"
                src={
                  entityData?.entity_image
                    ? scaledImage(entityData?.entity_image)
                    : logo
                }
              />
              <IconButton
                className={classes.pencilIcon}
                onClick={() => openUploadImage(entityData?.entity_image)}
              >
                <CreateIcon />
              </IconButton>
            </Box>
            <Box marginTop={2} marginBottom={2}>
              <Typography variant="h5">{entityData?.entity}</Typography>
              <Typography variant="subtitle1" style={{ opacity: 0.6 }}>
                {entityData?.state}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.middleColumn}>
            <Card classes={{ root: classes.cardRoot }}>
              <EntityGeneralInfo />
            </Card>
            <Card classes={{ root: classes.cardRoot }}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" style={{ fontSize: "1.4rem" }}>
                  Admin Members ({entityData?.admin_count})
                </Typography>
                <ButtonRounded
                  variant="contained"
                  color="primary"
                  onClick={() => openAddAdmin()}
                  style={{ marginRight: "10px" }}
                >
                  Invite Admin
                </ButtonRounded>
              </Box>

              {entityData?.admins.map((admin, i) => {
                return (
                  <ListItemWithContent
                    key={i}
                    ListItemTextProps={{
                      disableTypography: true,
                    }}
                    primary={
                      <Typography variant="body2" color="primary">
                        {admin?.first_name} {admin?.last_name}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body1">{admin?.email}</Typography>
                        <Typography variant="body1">{admin?.title}</Typography>
                      </>
                    }
                    style={{ padding: 0 }}
                  />
                )
              })}
            </Card>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default EntityLeftContainer
