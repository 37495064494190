import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import { addQuestion, removeQuestion } from "actions/Clerk/board"
import AppContext from "contexts/App"
import ClerkAppContext from "contexts/Clerk/ClerkApp"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"
import { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import useStyles from "./styles"

const BoardDetailsQuestions = ({
  defaultQuestions,
  setDefaultQuestions,
  additionalQuestions,
  setAdditionalQuestions,
  newQuestion,
  setNewQuestion,
  boardId,
}) => {
  const classes = useStyles()
  const { openSnackBar, openDialog, authUser, isClerk } = useContext(AppContext)
  const { options } = useContext(ClerkAppContext)
  const { values, setFieldValue } = useFormikContext()
  const [spinningCan, setSpinningCan] = useState(null)
  //spinning can holds the question that was selected to be deleted

  const queryClient = useQueryClient()

  const addQuestionMutation = useMutation((data) => addQuestion(boardId, data))
  const removeQuestionMutation = useMutation((questionId) =>
    removeQuestion(boardId, questionId)
  )
  const handleChangeDefaultQuestions = (question) => {
    if (defaultQuestions?.includes(question?.id)) {
      setDefaultQuestions((oldState) =>
        oldState.filter((x) => x !== question?.id)
      )
      setFieldValue(
        "default_questions",
        values?.default_questions.filter((x) => x.id !== question?.id)
      )
    } else {
      setDefaultQuestions((oldState) => [...oldState, question?.id])
      setFieldValue("default_questions", [
        ...values.default_questions,
        question?.id,
      ])
    }
  }

  const addQuestionFunc = () => {
    let data = { question: newQuestion }
    addQuestionMutation
      .mutateAsync(data)
      .then((res) => {
        setNewQuestion("")
        setAdditionalQuestions((oldState) => [
          ...oldState,
          { id: res.question_id, question: newQuestion },
        ])
        queryClient.setQueryData(
          ["boardsData", authUser && isClerk],
          (oldData) => {
            let newData = oldData?.map((x) => {
              if (x?.id === boardId) {
                return {
                  ...x,
                  additional_questions: [
                    ...x.additional_questions,
                    { id: res.question_id, question: newQuestion },
                  ],
                }
              } else {
                return x
              }
            })
            return newData
          }
        )
        openSnackBar({ message: "Question added" })
      })
      .catch((err) => {
        openSnackBar({ message: "Error creating question" })
      })
  }

  const openConfirmationDialog = (question) => {
    let data = {
      action: "delete this question?",
      confirmText: "Delete",
    }
    openDialog("clickConfirmation", data).then(() => {
      setSpinningCan(question.id)
      removeQuestionMutation
        .mutateAsync(question?.id)
        .then((res) => {
          setAdditionalQuestions((oldState) =>
            oldState.filter((x) => x !== question)
          )
          queryClient.setQueryData(
            ["boardsData", authUser && isClerk],
            (oldData) => {
              let newData = oldData?.map((x) => {
                if (x?.id === boardId) {
                  return {
                    ...x,
                    additional_questions: x.additional_questions.filter(
                      (x) => x.id !== question.id
                    ),
                  }
                } else {
                  return x
                }
              })
              return newData
            }
          )
          openSnackBar({ message: "Question removed" })
          setSpinningCan(null)
        })
        .catch((err) => {
          openSnackBar({ message: "Error removing question" })
          setSpinningCan(null)
        })
    })
  }

  return (
    <Box className={classes.inputBox}>
      <Box display="flex" flexDirection="column">
        <InputLabel className={classes.inputLabel}>
          Default application questions
        </InputLabel>
        {options?.default_questions?.map((question, i) => {
          return (
            <Box key={question?.id} display="flex" alignItems="center">
              <Checkbox
                checked={defaultQuestions?.includes(question?.id)}
                value={question?.id}
                onChange={() => handleChangeDefaultQuestions(question)}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <Typography>{question?.question} </Typography>
            </Box>
          )
        })}
      </Box>
      <InputLabel className={classes.inputLabel} style={{ marginTop: "20px" }}>
        Additional application questions
      </InputLabel>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TextField
          id="outlined-basic"
          variant="outlined"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          style={{ width: "80%" }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault()
              addQuestionFunc()
              setNewQuestion(e.target.value)
            }
          }}
        />
        <Button
          disabled={newQuestion === "" || addQuestionMutation.isLoading}
          onClick={addQuestionFunc}
          startIcon={
            addQuestionMutation.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <AddCircleOutlineIcon />
            )
          }
        >
          <Typography variant="body1">Add question</Typography>
        </Button>
      </Box>
      {additionalQuestions?.map((question) => {
        return (
          <Box
            key={question?.id}
            width="100%"
            display="flex"
            alignItems="center"
          >
            <IconButton
              color="primary"
              onClick={() => openConfirmationDialog(question)}
            >
              {/* eslint-disable */}
              {removeQuestionMutation.isLoading &&
              spinningCan === question.id ? 
                (
                  <CircularProgress size={20} />
                ) : (
                  <DeleteIcon />
                )
              }
              {/* eslint-enable */}
            </IconButton>
            <Typography>{question?.question || question} </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

BoardDetailsQuestions.propTypes = {
  defaultQuestions: PropTypes.array,
  setDefaultQuestions: PropTypes.func,
  additionalQuestions: PropTypes.array,
  setAdditionalQuestions: PropTypes.func,
  newQuestion: PropTypes.string,
  setNewQuestion: PropTypes.func,
  boardId: PropTypes.number,
}

export default BoardDetailsQuestions
