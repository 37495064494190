import { Box, IconButton, Typography, useMediaQuery } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import CreateIcon from "@material-ui/icons/Create"
import { useTheme } from "@material-ui/styles"
import { getAdmins } from "actions/Clerk/users"
import ButtonRounded from "components/Common/ButtonRounded"
import HoverText from "components/Common/HoverText"
import AppContext from "contexts/App"
import MaterialTable from "material-table"
import Page from "pages/Page"
import { useContext, useRef, useState } from "react"
import { useMutation } from "react-query"
import AutoSizer from "react-virtualized-auto-sizer"
import serialize from "store/serialize"
import formatPhoneNumber from "utils/formatPhone"

import useStyles from "./styles"

const List = () => {
  const theme = useTheme()
  const tabletView = useMediaQuery(theme.breakpoints.down("sm"))
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"))

  const { openDialog } = useContext(AppContext)
  const getAdminsMutation = useMutation(getAdmins)
  const [pageSize, setPageSize] = useState(10)
  const tableRef = useRef()

  const hideMobile = mobileView ? "none" : null
  const hideTablet = tabletView ? "none" : null

  const headCells = [
    {
      title: "First Name",
      field: "first_name",
      render: function firstNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.first_name}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "0px 30px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "0px 30px",
      },
      sorting: false,
    },
    {
      title: "Last Name",
      field: "last_name",
      render: function lastNameText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.last_name}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "0px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "0px 10px",
      },
      sorting: false,
    },
    {
      title: "Entity",
      field: "entity",
      render: function entityText(rowData) {
        return (
          <>
            <HoverText
              variant="subtitle1"
              data={rowData?.entity}
              desktopSize={20}
              phoneSize={20}
            />
          </>
        )
      },
      headerStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "0px 10px",
      },
      cellStyle: {
        width: "15%",
        maxWidth: "15%",
        padding: "0px 10px",
      },
      sorting: false,
    },
    {
      title: "Phone",
      field: "phone",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        height: "25px",
        padding: "0px 10px",
        display: hideTablet,
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "5px 10px",
        display: hideTablet,
      },
      sorting: false,
      render: function emailText(rowData) {
        return (
          <Box>
            <Typography variant="subtitle1">
              {formatPhoneNumber(rowData?.phone)}
            </Typography>
          </Box>
        )
      },
    },
    {
      title: "Email",
      field: "email",
      headerStyle: {
        width: "25%",
        maxWidth: "25%",
        height: "25px",
        padding: "0px 10px",
        display: hideTablet,
      },
      cellStyle: {
        width: "25%",
        maxWidth: "25%",
        padding: "0px 10px",
        display: hideTablet,
      },
      sorting: false,
      render: function emailText(rowData) {
        return (
          <Box>
            <HoverText
              variant="subtitle1"
              data={rowData?.email}
              desktopSize={25}
              phoneSize={25}
            />
          </Box>
        )
      },
    },
    {
      title: "Admin",
      field: "admin",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 10px",
        display: hideMobile,
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 10px",
        display: hideMobile,
      },
      sorting: false,
      render: function emailText(rowData) {
        return (
          <Box display="flex" justifyContent="center">
            {rowData?.admin ? <CheckCircleOutlineIcon color="primary" /> : null}
          </Box>
        )
      },
    },
    {
      title: "Super Admin",
      field: "super_admin",
      headerStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 10px",
        display: hideMobile,
      },
      cellStyle: {
        width: "5%",
        maxWidth: "5%",
        padding: "0px 10px",
        display: hideMobile,
      },
      sorting: false,
      render: function emailText(rowData) {
        return (
          <Box display="flex" justifyContent="center">
            {rowData?.super_admin ? (
              <CheckCircleOutlineIcon color="primary" />
            ) : null}
          </Box>
        )
      },
    },

    {
      title: "",
      field: "",
      headerStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      cellStyle: {
        width: "10%",
        maxWidth: "10%",
        padding: "0px 10px",
      },
      sorting: false,
      render: function ViewDetailsButton(rowData) {
        let data = {
          user: rowData,
          tableRef: tableRef,
        }
        return (
          <IconButton onClick={() => openDialog("updateAdmin", data)}>
            <CreateIcon color="primary" />
          </IconButton>
        )
      },
    },
  ]

  const openNewAdminDialog = () => {
    let data = { from: "adminTable" }
    openDialog("createAdmin", data)
  }

  const classes = useStyles()
  return (
    <Page>
      <Box className={classes.rightContainer} height="100%">
        <Box className={classes.titleContainer}>
          <Typography variant="h2" className={classes.title}>
            Matchboard Admins
          </Typography>
          <ButtonRounded
            color="primary"
            variant="contained"
            onClick={openNewAdminDialog}
          >
            Invite Super Admin
            {/* {mobileView ? "Invite" : "Invite Super Admin"} */}
          </ButtonRounded>
        </Box>
        <AutoSizer style={{ width: "100%", height: "100%" }}>
          {({ width, height }) => {
            return (
              <>
                <MaterialTable
                  tableRef={tableRef}
                  isLoading={getAdminsMutation?.isLoading}
                  options={{
                    toolbar: false,
                    title: "",
                    headerStyle: {
                      backgroundColor: "#fff",
                      position: "sticky",
                      top: 0,
                      whiteSpace: "nowrap",
                      width: "100%",
                      fontSize: "12px",
                      fontWeight: "bold",
                      height: "50px",
                      padding: "5px 10px",
                      color: "#A01F62",
                      borderTop: "0px",
                      paddingTop: "15px",
                    },

                    maxBodyHeight: height - 55,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 25, 50, 100],
                    rowStyle: (rowData, i) => ({
                      backgroundColor: i % 2 !== 0 ? "#fff" : "#F8F8F8",
                      maxHeight: "70px",
                      height: "40px",
                      padding: "5px 10px",
                    }),
                  }}
                  classses={{ root: classes.root }}
                  columns={headCells}
                  data={(query) => {
                    return new Promise((resolve, reject) => {
                      setPageSize(query?.pageSize)
                      let newQuery = `p=${query?.page + 1}&n=${query?.pageSize}`
                      getAdminsMutation
                        ?.mutateAsync(newQuery)
                        .then((res) => {
                          serialize("user", res?.data?.admins).then(
                            (resSeialized) => {
                              resolve({
                                data: resSeialized,
                                page: query.page,
                                totalCount: res?.data?.totals?.admins,
                                pageSize: query?.pageSize,
                              })
                            }
                          )
                        })
                        .catch((err) => {
                          reject({ data: [] })
                        })
                    })
                  }}
                />
              </>
            )
          }}
        </AutoSizer>
      </Box>
    </Page>
  )
}

export default List
