import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles(
  (theme) => ({
    list: {
      width: "100%",
      // height: "100%",
      overflowY: "auto",
      paddingRight: theme.spacing(3),
      "& .infinite-scroll-component__outerdiv": {
        width: "100%",
      },
      // "@media (max-width:500px)": {
      //   width: "95%",
      // },
    },
    noDataImage: {
      width: "212px",
      marginTop: "100px",
    },
    noDataTitle: {
      marginBottom: theme.spacing(2),
      opacity: "0.48",
    },
  }),
  {
    name: "Clerk-BoardsList",
  }
)

export default styles
