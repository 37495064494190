import api from "store/api"
import serialize from "store/serialize"

export const requestsDataList = () => {
  return api
    .get("/admin/super/requests")
    .then((res) => serialize("request", res.data.data))
}

export const approveRequest = (requestId, data) => {
  return api.put(`/admin/super/requests/${requestId}`, data).then((res) => res)
}

export const declineRequest = (requestId) => {
  return api
    .delete(`/admin/super/requests/${requestId}`)
    .then((res) => res.data.data)
}
