import { AppBar as A, Box, Toolbar } from "@material-ui/core"
import { ReactComponent as Logo } from "assets/matchboard-logo.svg"
import PropTypes from "prop-types"

import Menu from "./Menu"
import useStyles from "./styles"

const AppBar = () => {
  const classes = useStyles()

  return (
    <A
      position="static"
      color="secondary"
      elevation={0}
      className={classes.root}
    >
      <Toolbar>
        <Box className={classes.logoBox}>
          <Logo style={{ width: 150 }} />
        </Box>
        <Box flexGrow="1" />
        <Menu />
      </Toolbar>
    </A>
  )
}

AppBar.propTypes = {
  children: PropTypes.node,
}

export default AppBar
