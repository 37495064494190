import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grow,
  Typography,
} from "@material-ui/core"
import logo from "assets/logo-icon.svg"
import HoverText from "components/Common/HoverText"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import getScaledImage from "utils/getScaledImage"

import useStyles from "./styles"

const EntitiesCard = ({ entity }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleClick = () => {
    history.push(`/entities/${entity.id}`)
  }

  const scaledImage = (img) => {
    return getScaledImage(img, 200, 200)
  }

  return (
    <Grow in={true} timeout={350} style={{ transformOrigin: "center" }}>
      <Box className={classes.container}>
        <Card
          className={classes.root}
          raised={true}
          elevation={0}
          onClick={handleClick}
        >
          <Box className={classes.cardMedia}>
            <Avatar
              src={
                entity?.entity_image ? scaledImage(entity?.entity_image) : logo
              }
              alt="Entity Image"
              className={classes.entityImage}
            />
          </Box>
          <CardContent className={classes.cardContent}>
            <Box className={classes.topTextContainer}>
              <HoverText
                variant="h5"
                className={classes.title}
                desktopSize={20}
                phoneSize={20}
                data={entity?.entity}
              />
              <Typography variant="body1" style={{ opacity: 0.6 }}>
                {entity.state}
              </Typography>
            </Box>
            <Box className={classes.bottomTextContainer}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "600" }}
                >
                  {entity?.board_count}
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ color: "#190D27" }}
                  >
                    {" "}
                    Boards
                  </Typography>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ fontWeight: "600" }}
                >
                  {entity?.admin_count}
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ color: "#190D27" }}
                  >
                    {" "}
                    Admins
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Grow>
  )
}

EntitiesCard.propTypes = {
  children: PropTypes.node,
  entity: PropTypes.object,
}

export default EntitiesCard
