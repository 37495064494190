import { Box, Button, CircularProgress, Typography } from "@material-ui/core"
import { getApplicantApplications } from "actions/Clerk/applicants"
import { getResume } from "actions/Clerk/board"
import AvatarComponent from "components/Common/Avatar"
import App from "contexts/App"
import ApplicantsContext, {
  ApplicantsContainer,
} from "contexts/Clerk/Applicants"
import { MessageContainer } from "contexts/Message"
import MessagesContext from "contexts/Messages"
import { isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import { useRouteMatch } from "react-router-dom"

import useStyles from "./styles"

const SidebarContent = () => {
  const classes = useStyles()

  const { isClerk, openSnackBar, openDialog, authUser } = useContext(App)
  const { selectedThread } = useContext(MessagesContext)
  const { applicantsData } = useContext(ApplicantsContext)

  const [loading, setLoading] = useState(true)
  const [applicantHistory, setApplicantHistory] = useState({})
  const getApplicantApplicationsMutation = useMutation((userId) =>
    getApplicantApplications(authUser?.entity_id, userId)
  )
  const getResumeMutation = useMutation(getResume)

  const user = applicantsData?.filter(
    (x) => x?.user_id === selectedThread?.user_id
  )?.[0]

  useEffect(() => {
    if (selectedThread) {
      if (isClerk) {
        // only run this code if profile_image key
        // signifies a citizen/applicant is being viewed from clerk side
        getApplicantApplicationsMutation
          .mutateAsync(selectedThread?.user_id)
          .then((res) => {
            setApplicantHistory(res)
            setLoading(false)
          })
          .catch((err) => {
            console.log(err)
            setLoading(false)
            openSnackBar({ message: "Error opening user applications" })
          })
      } else {
        setLoading(false)
      }
    }
  }, [selectedThread])

  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box className={classes.root} alignSelf="flex-start">
          {selectedThread?.thread_id && (
            <>
              <AvatarComponent
                user={selectedThread}
                className={classes.messageUserAvatar}
              />
              <Typography variant="h5">{selectedThread?.name}</Typography>
              <Button
                size="small"
                color="primary"
                style={!isClerk ? { display: "none" } : null}
                disabled={isEmpty(applicantHistory)}
                onClick={() => {
                  let data = {
                    selected: user,
                    getDataMutation: getApplicantApplicationsMutation,
                    openMutation: getResumeMutation,
                  }
                  openDialog("applicantDetails", data)
                }}
              >
                <Typography variant="button">View Applications</Typography>
              </Button>
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

const MessageSidebar = () => {
  const match = useRouteMatch("/messages/:message_id")

  if (!match?.params) {
    return null
  }

  return (
    <MessageContainer messageId={match?.params?.message_id}>
      <ApplicantsContainer>
        <SidebarContent />
      </ApplicantsContainer>
    </MessageContainer>
  )
}

MessageSidebar.propTypes = {}

export default MessageSidebar
