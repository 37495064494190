import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import EnterPinForm from "components/Forms/EnterPinForm"
import ForgotPasswordForm from "components/Forms/ForgotPasswordForm"
import LoginForm from "components/Forms/LoginForm"
import SignupForm from "components/Forms/SignupForm"
import UpdatePasswordForm from "components/Forms/UpdatePasswordForm"
import App from "contexts/App"
import { useEffect, useMemo, useState } from "react"
import { useContext } from "react"
import { useLocation } from "react-router-dom"

import useStyles from "./styles"

const LoginDialog = () => {
  const classes = useStyles()
  const location = useLocation()

  const { dialogs, closeDialog } = useContext(App)
  const thisDialog = dialogs?.["login"] || {}
  const { open = false, data = {} } = thisDialog

  const [isLogin, setIsLogin] = useState(true)
  const [isForgot, setIsForgot] = useState(false)
  const [isCodeInput, setIsCodeInput] = useState(false)
  const [isUpDatePass, setIsUpDatePass] = useState(false)
  const [resetEmail, setResetEmail] = useState(null)
  const [resetPin, setResetPin] = useState("")
  const [boardPath, setBoardPath] = useState("")

  useEffect(() => {
    const queryString = location.search
    const params = new URLSearchParams(queryString)
    const code = params.get("verify")
    if (code) {
      setIsForgot(true)
    }
  }, [])

  const handleClose = () => {
    closeDialog("login")
    setTimeout(() => {
      setIsLogin(true)
      setIsForgot(false)
      setIsCodeInput(false)
      setIsUpDatePass(false)
      setResetEmail(null)
    }, 300)
  }

  const path = useMemo(
    () =>
      window.location.pathname.includes("board")
        ? setBoardPath(window.location.pathname.replace("/clerk/", ""))
        : "",
    []
  )
  localStorage.setItem("board", boardPath)

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        {!isUpDatePass && (
          <IconButton
            onClick={handleClose}
            className={classes.closeButton}
            data-cy="register-close-button"
          >
            <HighlightOffIcon />
          </IconButton>
        )}
        <Typography variant="h2" className={classes.loginHeader}>
          {!isLogin && !isForgot ? "Sign Up" : ""}
        </Typography>
        <Typography variant="h2" className={classes.loginHeader}>
          {isLogin && !isForgot ? "Log In" : ""}
        </Typography>
        <Typography variant="h2" className={classes.loginHeader}>
          {isForgot && !isCodeInput ? "Forgot Password" : ""}
        </Typography>
        <Typography variant="h2" className={classes.loginHeader}>
          {isCodeInput && !isUpDatePass ? "Check Email for Pin" : ""}
        </Typography>
        <Typography variant="h2" className={classes.loginHeader}>
          {isUpDatePass ? "Update Password" : ""}
        </Typography>
        {!isLogin && (
          <>
            <Typography style={{ textAlign: "center", fontSize: "1.4rem" }}>
              Please create an account to apply for this position
            </Typography>
            <Typography style={{ textAlign: "center", fontSize: "1.2rem" }}>
              *Password must contain at least 8 characters, one uppercase
              letter, one number, and one special character
            </Typography>
          </>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {isLogin && !isForgot && (
          <LoginForm
            signinClick={() => setIsLogin(false)}
            forgotClick={() => setIsForgot(true)}
            boardPath={boardPath}
          />
        )}

        {!isLogin && !isForgot && (
          <SignupForm loginClick={() => setIsLogin(true)} />
        )}
        {isForgot && !isCodeInput && (
          <ForgotPasswordForm
            resetClick={() => setIsCodeInput(true)}
            setEmail={(resetEmail) => setResetEmail(resetEmail)}
          />
        )}
        {isCodeInput && !isUpDatePass && (
          <EnterPinForm
            upDateClick={() => setIsUpDatePass(true)}
            resetEmail={resetEmail}
            setPin={(resetPin) => setResetPin(resetPin)}
            resetPass={() => setIsUpDatePass(true)}
          />
        )}
        {isUpDatePass && (
          <UpdatePasswordForm resetPin={resetPin} handleClose={handleClose} />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default LoginDialog
