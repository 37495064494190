import { Chip } from "@material-ui/core"
import classnames from "clsx"
import PropTypes from "prop-types"

import useStyles from "./styles"

const ChipSquare = ({ className = "", status }) => {
  const classes = useStyles()
  const id = status?.id
  const label = status?.label

  return (
    <Chip
      className={classnames(classes.root, {
        // [`classes.${label}Chip`]: label,
        [classes.appliedChip]: id === 1,
        [classes.viewedChip]: id === 2,
        [classes.appointedChip]: id === 3,
        [classes.deferredChip]: id === 4,
        [classes.noLongerConsiderationChip]: id === 5,
        [classes.otherChip]: id > 5,
      })}
      label={label}
    />
  )
}

ChipSquare.propTypes = {
  className: PropTypes.string,
  status: PropTypes.object,
}

export default ChipSquare
