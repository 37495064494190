import { Box, List, Typography } from "@material-ui/core"
import AppContext from "contexts/App"
import ClerkContext from "contexts/Clerk/ClerkApp"
import PropTypes from "prop-types"
import { useContext } from "react"

import DashboardCard from "../DashboardCard"
import Applied from "./NotificationTypes/Applied"
import MemberExpiration from "./NotificationTypes/MemberExpiration"
import useStyles from "./styles"
const Notifications = () => {
  const classes = useStyles()

  const { token } = useContext(AppContext)
  const { notifications = [] } = useContext(ClerkContext)

  return (
    token && (
      <DashboardCard>
        {notifications.length > 0 ? (
          <List className={classes.root} dense>
            {notifications?.map((notification) => {
              if (notification.type.includes("expiring_member")) {
                return (
                  <Box key={notification?.id} className={classes.listItem}>
                    <MemberExpiration
                      key={notification?.id}
                      notification={notification}
                      classes={classes}
                    />
                  </Box>
                )
              }

              if (notification?.type?.includes("applied")) {
                return (
                  <Box key={notification?.id} className={classes.listItem}>
                    <Applied
                      key={notification?.id}
                      notification={notification}
                      classes={classes}
                    />
                  </Box>
                )
              }
            })}
          </List>
        ) : (
          <Box display="flex" justifyContent="center" margin="50px">
            <Typography style={{ opacity: 0.7 }} variant="h6">
              You have no notifications
            </Typography>
          </Box>
        )}
      </DashboardCard>
    )
  )
}

Notifications.propTypes = {
  children: PropTypes.node,
}

export default Notifications
