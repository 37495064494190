import { Box, CircularProgress, Tabs } from "@material-ui/core"
import HoverText from "components/Common/HoverText"
import LinkTab from "components/Common/LinkTab"
import BoardContext from "contexts/Clerk/Board"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { useContext } from "react"
import { useLocation, useRouteMatch } from "react-router-dom"

import useStyles from "./styles"

const BoardView = ({ children }) => {
  const classes = useStyles()

  const { board, boardApplicantsLoading } = useContext(BoardContext)

  const location = useLocation()

  const match = useRouteMatch("/boards/:board_id")

  const tabsValue = useMemo(() => {
    return location.pathname.split("/")?.[3] || ""
  }, [location.pathname])

  if (boardApplicantsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <Box className={classes.root}>
        <HoverText
          variant="h6"
          className={classes.boardName}
          desktopSize={100}
          phoneSize={100}
          data={board?.name}
        />
        <Box flexShrink="0">
          <Tabs
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            value={tabsValue}
            aria-label="board view tabs"
            className={classes.tabs}
          >
            <LinkTab
              label={`Applicants (${
                board?.applicants ? board?.applicants : 0
              })`}
              to={`/boards/${match?.params?.board_id}`}
              value=""
              className={classes.tab}
              classes={{
                wrapper: classes.tabWrapper,
              }}
            />
            <LinkTab
              label="Board Details"
              to={`/boards/${match?.params?.board_id}/board-details`}
              value="board-details"
              className={classes.tab}
              classes={{
                wrapper: classes.tabWrapper,
              }}
            />
            <LinkTab
              label="Members"
              to={`/boards/${match?.params?.board_id}/members`}
              value="members"
              className={classes.tab}
              classes={{
                wrapper: classes.tabWrapper,
              }}
            />
            <LinkTab
              label="Reviews"
              to={`/boards/${match?.params?.board_id}/reviews`}
              value="reviews"
              className={classes.tab}
              classes={{
                wrapper: classes.tabWrapper,
              }}
            />
            <LinkTab
              label="Analytics"
              to={`/boards/${match?.params?.board_id}/analytics`}
              value="analytics"
              className={classes.tab}
              classes={{
                wrapper: classes.tabWrapper,
              }}
            />
          </Tabs>
        </Box>
        {children}
      </Box>
    )
  }
}

BoardView.propTypes = {
  children: PropTypes.node,
}

export default BoardView
